/* eslint-disable semi */
/* Import */
import { MULTITENANCY } from 'Env';

export default class Base {
  constructor(firebase, applicationId, collectionPrefix) {
    this.collectionPrefix = collectionPrefix;
    this.resetApplicationId(firebase, applicationId);
  }

  resetApplicationId = (firebase, applicationId) => {
    if (!MULTITENANCY) {
      return;
    }

    this.collection = firebase
      .firestore()
      .collection(this.buildCollectionName(applicationId));
  };

  buildCollectionName = applicationId => {
    if (!MULTITENANCY) {
      return this.collectionPrefix;
    }
    return this.collectionPrefix + '_' + applicationId;
  };
}
