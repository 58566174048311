/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Compressor from 'compressorjs';
import Select from 'react-select';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Pages403 from 'Base/pages/pages-403';
import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { initCreate, create, read, update,list_category } from '../../store/product/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { buildTitle, NAME_PROJECT } from 'Apps';

class Product extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let sku = null;

    const path = this.props.match.path;
    if (path.includes('/edit/')) {
      isEdit = true;
      sku = safeDeepGet(this.props.match.params, 'id');
    }

    this.state = {
      error_dlg: false,
      dynamic_title: 'Invalid Image Format',
      dynamic_description: 'Use Format JPG or PNG ',
      isEdit,
      sku,
      image: null,
      imageUpdated: false,
    };

    this.handleImageFile = this.handleImageFile.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  componentDidMount() {
    const { isEdit } = this.state;

    // Load product
    if (!isEdit) {
      this.props.InitCreateProduct();
    } else {
      this.props.ReadProduct(this.state.sku);
    }
    this.props.ListOptionCategory();
  }
  handleCancleClick() {
    this.props.history.push(`/product/list/`);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;

    if (!isEdit) {
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/product/list');
        }
      }
    } else {
      if (this.props.readResult !== prevProps.readResult) {
        const code = safeDeepGet(
          this.props,
          ['readResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const newState = {};
          const name = safeDeepGet(this.props, ['readResult', 'body', 'name']);
          newState['name'] = name;
          const desc = safeDeepGet(this.props, ['readResult', 'body', 'desc']);
          newState['desc'] = desc;
          // const category = safeDeepGet(this.props, ['readResult', 'body', 'category']);
          // newState['category'] = category;
          const image = safeDeepGet(this.props, [
            'readResult',
            'body',
            'img_url',
          ]);
          newState['image'] = image;

          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      } else if (this.props.updateResult !== prevProps.updateResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/product/list');
        }
      }
    }
  }

  handleAlertImg() {
    var toastType = 'error';
    var message = 'Format Gambar Tidak Sesuai!';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  handleImageFile(event) {
    const img = document.createElement('img');
    const image = event.target.files[0];
    let imageFile = '';
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(event.target.files[0]);
    img.onload = () => {
      // debugger;
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if ((width > 200) & (height > 150)) {
        new Compressor(image, {
          quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
          width: width > 800 ? width * 0.2 : width * 0.4,
          height: height > 800 ? height * 0.2 : height * 0.4,
          minWidth: width > 800 ? width * 0.2 : width * 0.4,
          minHeight: height > 800 ? height * 0.2 : height * 0.4,
          success: compressedResult => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.
            imageFile = compressedResult;
            const fakepath = event.target.value;
            const imageExt = fakepath.slice(
              ((fakepath.lastIndexOf('.') - 1) >>> 0) + 2
            );

            if (imageExt !== 'jpg' && imageExt !== 'png') {
              this.handleAlertImg();
              event.target.value = null;
              return;
            }
            this.setState({
              imageUpdated: true,
              image: URL.createObjectURL(imageFile),
              imageFile: imageFile,
              imageExt,
            });
          },
        });
      } else {
        imageFile = image;
        const fakepath = event.target.value;
        const imageExt = fakepath.slice(
          ((fakepath.lastIndexOf('.') - 1) >>> 0) + 2
        );

        if (imageExt !== 'jpg' && imageExt !== 'png') {
          this.handleAlertImg();
          event.target.value = null;
          return;
        }
        this.setState({
          imageUpdated: true,
          image: URL.createObjectURL(imageFile),
          imageFile: imageFile,
          imageExt,
        });
      }
    };
  }

  handleValidSubmit(event, values) {
    const { isEdit, imageUpdated, imageFile, imageExt, category } = this.state;
    const submittedValues = { ...values };

    if (imageUpdated) {
      submittedValues['imageFile'] = imageFile;
      submittedValues['imageExt'] = imageExt;
    }

    if (isEdit) {
      this.props.UpdateProduct(submittedValues);
    } else {
      this.props.CreateProduct(submittedValues);
    }
  }
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'success';
    var message = 'Berhasil Tambah Product';
    if (isEdit) {
      toastType = 'success';
      message = 'Berhasil Update Product';
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  handleOnChangeCategory = category => {
    const value = category.value
    this.setState({ category: value});
  };
  render() {
    const loading = safeDeepGet(this.props, 'loading', true);
    const { isEdit, name, sku, desc, image,category } = this.state;
    const categoryOption = safeDeepGet(this.props, ['ListOptionResult', 'body'], [])
    return (
      <React.Fragment>
        {safeDeepGet(navbar, ['Product', 'edit'], 'off') == 'off' ? (
          <Pages403 />
        ) : (
          <AvForm onValidSubmit={this.handleValidSubmit}>
            <div className="page-content">
              <MetaTags>
                <title>{buildTitle('Product')}</title>
              </MetaTags>
              <Container fluid>
                <Breadcrumbs
                  title="Product"
                  breadcrumbItem={isEdit ? 'Edit' : 'Add'}
                />
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Product Information
                        </CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="name">Display Name</Label>
                              <AvField
                                id="name"
                                name="name"
                                value={name}
                                placeholder="Nama"
                                type="text"
                                errorMessage="Masukkan Nama"
                                className="form-control"
                                validate={{ required: { value: true } }}
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label htmlFor="sku">SKU</Label>
                              <AvField
                                id="sku"
                                name="sku"
                                value={sku}
                                placeholder="SKU"
                                type="text"
                                errorMessage="Masukan SKU dengan benar"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  // Bisa Pakai Huruf Besar
                                  pattern: { value: '^[A-Za-z0-9_-]+$' },
                                  // Tidak bisa pakai Huruf Besar
                                  // pattern: {value: '^[a-z0-9_]+$'},
                                }}
                                disabled={isEdit}
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label htmlFor="desc">Product Description</Label>
                              <AvField
                                id="desc"
                                name="desc"
                                value={desc}
                                rows="9"
                                type="textarea"
                                className="form-control"
                                validate={{
                                  maxLength: { value: 500 },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            {/* <Label htmlFor="category">Category</Label>
                            <Select
                              value={categoryOption.filter(function (option) {
                                return option.value === category;
                              })}
                              onChange={this.handleOnChangeCategory}
                              options={categoryOption}
                              classNamePrefix="select2-selection"
                              isLoading={false}
                              placeholder="Select Category"
                            /> */}
                            <Label htmlFor="image" className="mt-3 form-label">
                              Display Image
                            </Label>
                            <div className="dropzone">
                              {image != null ? (
                                <img
                                  className="mx-auto d-block"
                                  height={225}
                                  src={image}
                                />
                              ) : (
                                <div className="dz-message needsclick">
                                  <div className="mb-2">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Upload your image</h4>
                                </div>
                              )}
                            </div>
                            <p></p>
                            <p>
                              Format gambar .jpg atau .png dengan background
                              Transparant.{' '}
                            </p>
                            <Input
                              className="form-control mt-2"
                              type="file"
                              id="image"
                              onChange={this.handleImageFile}
                            />
                            {this.state.error_dlg ? (
                              <SweetAlert
                                error
                                title={this.state.dynamic_title}
                                onConfirm={() =>
                                  this.setState({ error_dlg: false })
                                }
                              >
                                {this.state.dynamic_description}
                              </SweetAlert>
                            ) : null}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Button
                        onClick={this.handleCancleClick}
                        color="secondary"
                      >
                        Cancel
                      </Button>{' '}
                      <Button type="submit" color="primary" disabled={loading}>
                        Simpan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </AvForm>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['product', 'initCreate'], {}),
  readResult: safeDeepGet(state, ['product', 'read'], {}),
  createResult: safeDeepGet(state, ['product', 'create'], {}),
  ListOptionResult: safeDeepGet(state, ['product', 'list_category'], {}),
  updateResult: safeDeepGet(state, ['product', 'update'], {}),
  loading: safeDeepGet(state, ['product', 'loading'], true),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitCreateProduct: () => dispatch(initCreate()),
  ReadProduct: deviceId => dispatch(read(deviceId)),
  CreateProduct: payload => dispatch(create(payload)),
  UpdateProduct: payload => dispatch(update(payload)),
  ListOptionCategory: () => dispatch(list_category()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
