import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_INGREDIENT,
  DISCOUNT_PRODUCT,
  DELETE_DISCOUNT,
  INPUT_CSV
} from './actionTypes';

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType },
});
export const inputCsv = payload => ({
  type: INPUT_CSV,
  payload
});
export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result },
});

export const list = () => ({
  type: LIST,
});
export const list_ingredients = () => ({
  type: LIST_INGREDIENT,
});
export const discount_product = payload => ({
  type: DISCOUNT_PRODUCT,
  payload,
});
export const delete_discount = payload => ({
  type: DELETE_DISCOUNT,
  payload,
});

export const initCreate = () => ({
  type: INIT_CREATE,
});

export const create = payload => ({
  type: CREATE,
  payload,
});

export const read = sku => ({
  type: READ,
  payload: { sku },
});

export const update = payload => ({
  type: UPDATE,
  payload,
});

export const stockUpdate = payload => ({
  type: STOCK_UPDATE,
  payload,
});

export const delete_ = sku => ({
  type: DELETE,
  payload: { sku },
});
