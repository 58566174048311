export const LOADING = 'LOADING';
export const RESULT = 'RESULT';

export const LIST_BUSINESS_POINTS = 'TRANSACTION_RFID_LIST_BUSINESS_POINTS';
export const LIST = 'TRANSACTION_RFID_LIST';
export const READ = 'TRANSACTION_RFID_READ';
export const REFUND = 'TRANSACTION_RFID_REFUND';
export const UPDATE_REFUND = 'TRANSACTION_RFID_REFUND';
export const SNAPSHOT = 'TRANSACTION_RFID_SNAPSHOT';
export const DOWNLOAD_SNAPSHOT = 'TRANSACTION_RFID_DOWNLOAD_SNAPSHOT';
export const SUMMARY = 'TRANSACTION_RFID_SUMMARY';
export const GET_SALES_ANALYTICS = 'TRANSACTION_RFID_GET_SALES_ANALYTICS';
