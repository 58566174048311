/* eslint-disable no-case-declarations */
import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  PAYOUT_LIST,
  PAYOUT_CREATE,
  ACCOUNT_LIST,
  GET_BALANCE,
  GET_PAYOUT_BALANCE,
  DETAIL_ACCOUNT,
  PAYOUT_REQUEST,
  PAYOUT_VALIDATE,
} from './actionTypes'

const INIT_STATE = {
  loading: true,
}

const Payout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      }
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      }
      switch (action.payload.actionType) {
        case LIST:
          newState['list'] = action.payload.result
          break
        case PAYOUT_LIST:
          newState['payoutlist'] = action.payload.result
          break
        case INIT_CREATE:
          newState['initCreate'] = action.payload.result
          break
        case ACCOUNT_LIST:
          newState['accountList'] = action.payload.result
          break
        case PAYOUT_REQUEST:
          newState['payoutRequest'] = action.payload.result
          break
        case PAYOUT_VALIDATE:
          newState['payoutValidate'] = action.payload.result
          break
        case CREATE:
          newState['create'] = action.payload.result
          break
        case GET_BALANCE:
          newState['get_Balance'] = action.payload.result
          break
        case GET_PAYOUT_BALANCE:
          newState['get_Payout_Balance'] = action.payload.result
          break
        case DETAIL_ACCOUNT:
          newState['detailAccount'] = action.payload.result
          break
        case PAYOUT_CREATE:
          newState['payout_create'] = action.payload.result
          break
        case READ:
          newState['read'] = action.payload.result
          break
        case UPDATE:
          newState['update'] = action.payload.result
          break
        case STOCK_UPDATE:
          newState['stockUpdate'] = action.payload.result
          break
        case DELETE:
          newState['delete'] = action.payload.result
          break
      }
      return newState

    default:
      return state
  }
}

export default Payout
