import {
  CANCEL,
  CREATE,
  DELETE,
  DETAIL_CHAT,
  INIT_CREATE,
  LIST,
  LOADING,
  READ,
  RESULT,
  STOCK_UPDATE,
  UPDATE,
} from './actionTypes';

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType },
});

export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result },
});
export const list = () => ({
  type: LIST,
});
export const initCreate = () => ({
  type: INIT_CREATE,
});
export const create = payload => ({
  type: CREATE,
  payload,
});
export const cancel_ = payload => ({
  type: CANCEL,
  payload,
});
export const detailChat = payload => ({
  type: DETAIL_CHAT,
  payload,
});
export const read = sku => ({
  type: READ,
  payload: { sku },
});

export const update = payload => ({
  type: UPDATE,
  payload,
});

export const stockUpdate = payload => ({
  type: STOCK_UPDATE,
  payload,
});

export const delete_ = payload => ({
  type: DELETE,
  payload,
});
