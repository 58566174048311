/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
// import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

//Import Images
// import error from '../assets/images/error-img.png';
import { APPLICATION_ID, NAME_PROJECT } from 'Apps'
class Pages404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    window.open('https://www.smartvending.cloud/topup');
  }
  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
        
            <MetaTags>
              <title>Payment | {NAME_PROJECT} Vending Machine</title>
            </MetaTags>
          <Container>
            <Row className="mt-4">
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 fw-medium">
                    <i style={{fontSize: 200}} className="bx bxs-wallet text-primary display-3" />
                  </h1>
                  <h3 className="text-uppercase">
                    Sorry, CMS has been disable, Please click button for payment
                    to enable CMS
                  </h3>
                  <h5 className="text-uppercase">Application Id = {APPLICATION_ID}</h5>
                  <div className="mt-5 text-center">
                    <Button
                      type="button"
                      color="primary"
                      className="btn btn-nestle w-sm"
                      onClick={() => this.handleClick()}
                    >
                      Payment

                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Pages404;
