/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  LIST,
  CREATE,
  DELETE,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet, safeDel } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
// import { createdUser, deletedUser } from 'Apps/device'
;

const firebaseInstance = getInstance();
function* list() {
  yield put(loading(LIST));
  const wsResult = yield call(firebaseInstance.category.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const categoryBody = safeDeepGet(wsResult, 'body', {});
    const categoryList = [];
    for(const i in categoryBody){
      categoryList.push(categoryBody[i]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: categoryList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}


function* create(action) {
  yield put(loading(CREATE));
  const payload = safeDeepGet(action, 'payload');
  const wsResult = yield call(firebaseInstance.category.create, payload);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  yield put(result(CREATE, { code }));
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const id = safeDeepGet(payload, 'payload', '');
  const wsResult = yield call(firebaseInstance.category.delete_, id);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  yield put(result(DELETE, { code }));
}

function* categorySaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(CREATE, create);
  yield takeEvery(DELETE, delete_);
}

export default categorySaga;
