import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import { initCreate, create } from '../../store/ingredient/actions';
import Result from 'iotera-base/result';
// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
class CreateIngredient extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let sku = null;

    this.state = {
      isEdit,
      sku,
      image: null,
      imageUpdated: false,
    };
    this.handleSelectcategory = this.handleSelectcategory.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  handleValidSubmit(event, values) {
    // const { isEdit, imageUpdated, imageFile, imageExt } = this.state;

    const category = this.state.selectedcategory;
    const submittedValues = { ...values, category: category };
    this.props.CreateIngredient(submittedValues);
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if(code != 999){
        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/ingredient/list');
        } else {
          this.showToastFailed();
        }
      }
    }
  }
  showToast() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'success';
      var message = 'Berhasil Update Ingredient';
    } else {
      var toastType = 'success';
      var message = 'Berhasil Tambah Ingredient';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'error';
      var message = 'Gagal Update Ingredient';
    } else {
      var toastType = 'error';
      var message = 'Gagal Tambah Ingredient';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidMount() {
    const { isEdit } = this.state;

    // Load product
  }
  handleSelectcategory(value) {
    this.setState({ selectedcategory: value.value });
  }
  render() {
    const options = [
      { value: 'bean', label: 'Bean' },
      { value: 'powder', label: 'Powder' },
    ];
    // const loading = safeDeepGet(this.props, "loading", true);
    const { selectedcategory, isEdit, name, code, stock, qty } = this.state;

    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>Ingredient | Smart Vending Machine</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs
                title="Ingredient"
                breadcrumbItem={isEdit ? 'Edit' : 'Add'}
              />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        Ingredient Information
                      </CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Display Name</Label>
                            <AvField
                              id="name"
                              name="name"
                              value={name}
                              placeholder="Name"
                              type="text"
                              errorMessage="Input Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="type">Code</Label>
                            <AvField
                              id="type"
                              name="type"
                              value={code}
                              placeholder="Code"
                              type="text"
                              errorMessage="Format salah"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: '/([a-zA-Z0-9-]+)/',
                                },
                              }}
                              disabled={isEdit}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="qty">Qty</Label>
                            <AvField
                              id="qty"
                              name="qty"
                              value={qty}
                              placeholder="Qty per package (gram)"
                              type="text"
                              errorMessage="Masukan Angka"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: '/([0-9]+)/',
                                },
                              }}
                              disabled={isEdit}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="category">Category</Label>
                            <Select
                              id="category"
                              name="category"
                              placeholder={'Category'}
                              options={options}
                              className="basic-single"
                              classNamePrefix="select"
                              onChange={this.handleSelectcategory}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      className="btn btn-secondary"
                      role="button"
                      to="/ingredient/list"
                    >
                      Batal
                    </Link>{' '}
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['ingredient', 'initCreate'], {}),
  createResult: safeDeepGet(state, ['ingredient', 'create'], {}),
  loading: safeDeepGet(state, ['ingredient', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  InitCreateProduct: () => dispatch(initCreate()),
  CreateIngredient: payload => dispatch(create(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateIngredient);
