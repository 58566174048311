/* eslint-disable no-undef */
module.exports = {
  google: {
    API_KEY: '',
    CLIENT_ID: '',
    SECRET: '',
  },
  facebook: {
    APP_ID: '',
  },
  fs: false,
  path: false,
  resolve: {
    fallback: {
      fs: false,
    },
  },
}
