export const LOADING = "LOADING"
export const RESULT = "RESULT"

export const LIST = "ROLE_LIST"
export const LIST_VM = "ROLE_LIST_VM"
export const INIT_CREATE = "ROLE_INIT_CREATE"
export const CREATE = "ROLE_CREATE"
export const READ = "ROLE_INFO"
export const UPDATE = "ROLE_UPDATE"
export const STOCK_UPDATE = "STOCK_ROLE_UPDATE"
export const DELETE = "ROLE_DELETE"
