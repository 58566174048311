/* eslint-disable semi */
import React from 'react';

import { Redirect } from 'react-router-dom';

import AlertList from '.../../Base/pages/Alert/index';
import UserProfile from '../../Base/pages/Authentication/UserProfile';
import BusinessPointList from '../../Base/pages/BusinessPoint';
// import BusinessPointPlanogram from "../pages/BusinessPoint/planogram"
import BusinessPointPlanogram from '../../Base/pages/BusinessPoint/bodyPlanogram';
import HistoryPlanogram from '../../Base/pages/BusinessPoint/historyPlanogram';
import BusinessPointInfo from '../../Base/pages/BusinessPoint/info';
import BusinessPointTag from '../pages/Tag/index'
import BusinessPointShareRevenue from '../../Base/pages/BusinessPoint/infoShareRevenue';
import RefundnDetail from '../../Base/pages/Chat/detail';
import RefundChat from '../../Base/pages/Chat/index';
import NotificationPage from '../../Base/pages/Notification/index';
import RefundList from '../../Base/pages/Transaction/Transaction-refund/index';
import TransactionReport from '../../Base/pages/Transaction/Transaction-report/index';
import SnapshotReport from '../../Base/pages/Transaction/Transaction-report/snapshot';
import TransactionDetail from '../../Base/pages/Transaction/detail';
import TransactionList from '../../Base/pages/Transaction/index';
// import Page404 from '../../Base/pages/pages-404';
import AccountList from '../pages/Account/index';
import AccountInfo from '../pages/Account/info';
import couponList from '../pages/Coupon/index';
import Dashboard from '../pages/Dashboard/index';
import ErrorCode from '../pages/Error/index';
import BankAccount from '../pages/Payout/bankAccount';
import PayoutReport from '../pages/Payout/index';
import InfoBank from '../pages/Payout/info';
import ProductList from '../pages/Product';
import ProductInfo from '../pages/Product/info';
import CreateRefiller from '../pages/Refiller/create';
import Refiller from '../pages/Refiller/index';
import RoleList from '../pages/Role/index';
import RoleInfo from '../pages/Role/info';
import StockBusinessPoint from '../pages/Stock/BusinessPoints';
import StockDeliveryOrder from '../pages/Stock/DeliveryOrder';
import StockWarehouse from '../pages/Stock/Warehouse';
import InvoiceList from "../../Base/pages/Invoicing/index"
import InvoiceInfo from "../../Base/pages/Invoicing/info"
import SubscriptionDevice from '../pages/Subs/device'
import SubscriptionTags from '../pages/Subs/index'
import Csv from '../pages/Csv/csv'
import DiscountList from '../pages/Discount/index'
import { safeDeepGet } from 'iotera-base/utility/json';
const obj = JSON.parse(localStorage.getItem('accessNav'))

const MultitenancyRoutes = [
  // Demo routes
  { path: '/dashboard', component: Dashboard },
  { path: '/csv', component: Csv },
  // { path: '/alert/list', component: AlertList },
  { path: '/alert/:type/list', component: AlertList },
  { path: '/alert/history/:id', component: AlertList },
  { path: '/alert/device/:id', component: AlertList },
  { path: '/alert/device-history/:device_id/:key', component: AlertList },
  { path: '/notification/:type/list', component: NotificationPage },
  //profile
  { path: '/profile', component: UserProfile },
  { path: '/role', component: RoleList },
  { path: '/role/create', component: RoleInfo },
  { path: '/role/edit/:id', component: RoleInfo },

  { path: '/account/list', component: AccountList },
  { path: '/account/create', component: AccountInfo},
  { path: '/account/edit/:id', component: AccountInfo },

  { path: '/subs/device', component: SubscriptionDevice},
  { path: '/subs/list', component: SubscriptionTags},
  /* Business point */
  { path: '/business-point/list', component: BusinessPointList },
  { path: '/business-point/create', component: BusinessPointInfo },
  { path: '/business-point/info/:id', component: BusinessPointInfo },
  { path: '/business-point/options/:id', component: BusinessPointShareRevenue },
  { path: '/business-point/planogram/:type/:id', component: BusinessPointPlanogram },
  { path: '/business-point/history/planogram', component: HistoryPlanogram },
  { path: '/business-point/tag', component: BusinessPointTag},

  { path: '/coupon/list', component: couponList },
  { path: '/discount/list', component: DiscountList },
  // product
  { path: '/product/list', component: ProductList },
  { path: '/product/create', component: ProductInfo },
  { path: '/product/edit/:id', component: ProductInfo },

  // transaction
  { path: '/transaction/list', component: TransactionList },
  { path: '/transaction/report', component: TransactionReport },
  { path: '/transaction/snapshot', component: SnapshotReport },
  { path: '/transaction/chat', component: RefundChat },
  { path: '/transaction/detail/:id', component: TransactionDetail },
  { path: '/refund/detail/:id', component: RefundnDetail },
  { path: '/refund/list', component: RefundList },

  //Refiller
  { path: '/refill/list', component: Refiller },
  { path: '/refill/create', component: CreateRefiller },

  // stock
  { path: '/stock/business-point', component: StockBusinessPoint },
  { path: '/stock/warehouse', component: StockWarehouse },
  { path: '/stock/create-do', component: StockDeliveryOrder },

  // Payout
  { path: '/payout/list', component: PayoutReport },
  { path: '/payout/bankaccount', component: BankAccount },
  { path: '/payout/create/bankaccount', component: InfoBank },
  { path: '/payout/edit/:id', component: InfoBank },
  // { path: "*", component: page404 },

  // Category
  // { path: '/category/list', component: CategoryList},

  { path: '/error/code', component: ErrorCode},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  // Invoice
  {path: '/invoice/list', component: InvoiceList},
  {path: '/invoice/:id/info', component: InvoiceInfo},
  {
    path: '/',
    exact: true,
    component: () =>
      safeDeepGet(obj, ['Overview', 'view'], 'off') == 'on' ? (
        <Redirect to="/dashboard" />
      ) :  safeDeepGet(obj, ['Business Point', 'view'], 'off') == 'on' ? (
        <Redirect to="/business-point/list" />
      ) : safeDeepGet(obj, ['Product', 'view'], 'off') == 'on' ? (
        <Redirect to="/product/list" />
      ) :  safeDeepGet(obj, ['Transaction', 'view'], 'off') == 'on' ? (
        <Redirect to="/transaction/list" />
      ) :  safeDeepGet(obj, ['Activity', 'view'], 'off') == 'on' ? (
        <Redirect to="/refund/list" />
      ) :  safeDeepGet(obj, ['Management', 'view'], 'off') == 'on' ? (
        <Redirect to="/role" />
      ) :  safeDeepGet(obj, ['Payout', 'view'], 'off') == 'on' ? (
        <Redirect to="/payout/list" />
      ) : (
        setTimeout(  <Redirect to="/" /> , 3000)
      ),
  },  
];

export { MultitenancyRoutes };
