/* eslint-disable no-extra-semi */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import 'flatpickr/dist/themes/material_blue.css'
import { Col, Container, Row, Button } from 'reactstrap'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

//Import Breadcrumb

// Iotera
import Result from 'iotera-base/result'
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json'

//CSV File
import { CSVLink } from 'react-csv'
import Papa from 'papaparse'
// Redux
import { connect } from 'react-redux'
import { inputCsv } from '../../store/business-point/actions'
// import {
//   readPlanogram,
//   addPlanogram,
//   testcsv,
// } from '../../../NestleFranke/store/business-point/actions';

class AlertDeviceList extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    // let deviceId = null;
    // const path = this.props.match.path;
    // if (path.includes('/alert/')) {
    //   deviceId = safeDeepGet(this.props.match.params, 'id');
    // }
    this.state = {
      deviceId: 'e65311a1-58f1-4c5c-871d-6e911bf64751',
      bpfilterlist: [],
      businessPoints: 'all',
      transactionStatus: 'all',
      dispenseStatus: 'all',
      file: '',
      array: [],

      headerTable: '',
      valuesArray: '',
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB')
    const time1 = date.toDate().toLocaleTimeString('en-GB')
    return date1 + ' ' + time1
  };
  componentDidMount() {
    // this.props.LoadAlertList(deviceId);
    // this.props.ReadPlanogram(deviceId);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page)
    }
  };
  handleOnChange = e => {
    const file = e.target.files[0]
    if (file.size > 5000) {
      this.setState({ file })
    } else {
      this.setState({ file })
    }
    // let valuesArray =[]
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        for (const i in results) {
          const valuesArray = results['data']
          this.setState({ valuesArray })
        }
        const rowsArray = []
        // Iterating data to get column name and their values
        results.data.map(d => {
          rowsArray.push(Object.keys(d))
        })
        this.setState({ rowsArray })
      }.bind(this),
    })
  };

  handleSubmit() {
    const payload = this.state.valuesArray
    this.props.InputCsv(payload)
    this.setState({ valuesArray: []})
  };

  render() {
    // TODO Loading
    const arr_data = this.state.valuesArray
    const headerKeys = Object.keys(Object.assign({}, ...arr_data))
    const dataheader = {}
    const header = []
    for (const id in headerKeys) {
      const data = headerKeys[id]
      safeDeepSet(dataheader, [id, 'text'], data)
      safeDeepSet(dataheader, [id, 'dataField'], data)

      // set formatter text in table
      if (data == 'imgUrl') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.imgUrl}</p>
          </>
        ))
      }
      if (data == 'description') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.description}</p>
          </>
        ))
      }
      header.push(dataheader[id])
    }
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    )
    const { deviceId } = this.state
    const pageOptions = {
      sizePerPage: 25, //Change if to much
      totalSize: arr_data.length, // replace later with size,
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: 'year', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ]
    const formValues = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'body', 'products'],
      []
    )

    const headers = [
      {
        label: 'productId',
        key: 'productId',
      },
      {
        label: 'description',
        key: 'description',
      },
      {
        label: 'displayName',
        key: 'displayName',
      },
      {
        label: 'cupSize',
        key: 'cupSize',
      },
      {
        label: 'selection',
        key: 'selection',
      },
      {
        label: 'order',
        key: 'order',
      },
      {
        label: 'price',
        key: 'price',
      },
      {
        label: 'sizeName',
        key: 'sizeName',
      },
      {
        label: 'imgUrl',
        key: 'imgUrl',
      },
      {
        label: 'DeviceId',
        key: 'id',
      },
      {
        label: 'ingredients 1',
        key: 'ingredients[0].type',
      },
      {
        label: 'amount 1',
        key: 'ingredients[0].amount',
      },
      {
        label: 'ingredients 2',
        key: 'ingredients[1].type',
      },
      {
        label: 'amount 2',
        key: 'ingredients[1].amount',
      },
      {
        label: 'ingredients 3',
        key: 'ingredients[2].type',
      },
      {
        label: 'amount 3',
        key: 'ingredients[2].amount',
      },
      {
        label: 'ingredients 4',
        key: 'ingredients[3].type',
      },
      {
        label: 'amount 4',
        key: 'ingredients[3].amount',
      },
      {
        label: 'productId',
        key: 'productId',
      },
      {
        label: 'active',
        key: 'active',
      },
    ]
    const separator = ',' //if want csv File using commma change with (',') or something else
    const csvLink = {
      headers: headers,
      data: formValues,
      separator: separator,
      filename: 'planogram ' + deviceId + '.csv',
    }
    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Import & Export CSV | Nestle Franke</title>
          </MetaTags>
          <Container fluid>
            <Row className="mb-3">
              <Col xs="3">
                <CSVLink {...csvLink} className="btn btn-franke white mb-3">
                  Export to CSV
                </CSVLink>
              </Col>
              <Col xs="9"></Col>
            </Row>
            <Row>
              <form id="csv-form">
                <input
                  type="file"
                  accept=".csv"
                  id="csvFile"
                  onChange={this.handleOnChange}
                ></input>
                <Button className="btn btn-franke white "onClick={this.handleSubmit}>
                  Import
                </Button>{' '}
              </form>
              <br />

              {arr_data.length == 0 ? null : (
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="productId"
                  columns={header}
                  data={arr_data}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="productId"
                      columns={header}
                      data={arr_data}
                      search
                    >
                      {toolkitprops => (
                        <React.Fragment>
                          <Row className="mb-2"></Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable  noDataIndication="Loading..." 
                                  {...toolkitprops.baseProps}
                                  {...paginationTableProps}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    'table align-middle table-nowrap table-hover'
                                  }
                                  bordered={true}
                                  striped={true}
                                  responsive
                                  ref={this.node}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['alert', 'list_alert_device'], {}),
  loading: safeDeepGet(state, ['alert', 'loading'], true),
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogram'],
    {}
  ),
})

const mapDispatchToProps = dispatch => ({
  // LoadAlertList: payload => dispatch(list_alert_device(payload)),
  // ReadPlanogram: id => dispatch(readPlanogram(id)),
  InputCsv: payload => dispatch(inputCsv(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertDeviceList)
