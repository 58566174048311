/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Label,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, initCreate, update, delete_ } from '../../store/payout/actions';
import Pages403 from 'Base/pages/pages-403';
import { buildTitle, getButtonTheme } from 'Apps';
import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      modal: false,
      data: [],
      BankAccountColumn: [
        {
          text: 'id',
          dataField: 'account_id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.account_id}</>,
        },
        {
          text: 'Name',
          dataField: 'account_name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.account_name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'bank',
          text: 'Bank',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.bank}</p>
            </>
          ),
        },
        {
          dataField: 'account_no',
          text: 'Rekening',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.account_no}</p>
            </>
          ),
        },
        {
          dataField: 'account_email',
          text: 'Email',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.account_email}</p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, account) => (
            <div className="d-flex gap-3">
              <Tippy content={'Edit'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleEditClick(account)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => this.handleDeleteClick(account)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
    this.showToastFailed = this.showToastFailed.bind(this);
    this.showDeleteToast = this.showDeleteToast.bind(this);
    this.handleOnchange = this.handleOnchange.bind(this);
  }

  handleOnchange(value) {
    this.setState({ selectedBank: value.value });
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    // Load business point
    this.props.LoadAccountList();
    this.props.InitCreateProduct();
    if (safeDeepGet(navbar, ['Transaction', 'edit'], 'off') == 'off') {
      this.state.BankAccountColumn.pop();
    }
  }
  handleValidSubmit = (e, values) => {
    const { UpdateAccount, LoadAccountList } = this.props;
    const { data, selectedBank } = this.state;
    const payload = {
      id: data.id,
      name: values.name,
      bank: selectedBank,
      email: values.email,
      rekening: values.rekening,
    };
    UpdateAccount(payload);
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.updateResult !== prevProps.updateResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['updateResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code != 999) {
        if (code === Result.SUCCESS) {
          this.showToast();
          this.toggle();
          // window.location.reload();
          this.componentDidMount();
        } else {
          this.showToastFailed();
        }
      }
    }
  }
  showToastFailed() {
    var toastType = 'error';
    var message = 'Gagal Tambah Account';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/payout/create/bankaccount');
  };

  handleEditClick = row => {
    const account = row;

    this.setState({
      data: {
        id: account.account_id,
        name: account.account_name,
        bank: account.account_bank,
        rekening: account.account_no,
        email: account.account_email,
      },
      selectedBank: account.account_bank,
    });
    this.toggle();
  };
  handleDeleteClick = row => {
    this.props.DeleteAccount(row);
    this.props.LoadAccountList();
    this.showDeleteToast();
    this.componentDidMount();
  };
  showDeleteToast() {
    var toastType = 'danger';
    var message = 'Berhasil Delete Account';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Update Account';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { selectedBank } = this.state;
    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length,
      custom: true,
    };
    const VmList = safeDeepGet(this.props, ['initCreateResult', 'body'], []);
    const defaultSorted = [
      {
        dataField: 'account_id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Account Bank List')}</title>
          </MetaTags>
          {!permision ||
          safeDeepGet(navbar, ['Transaction', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Account Bank" breadcrumbItem="List" />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="account_id"
                        columns={this.state.BankAccountColumn}
                        data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="account_id"
                            columns={this.state.BankAccountColumn}
                            data={list}
                            search
                          >
                            {toolkitprops => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitprops.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    {safeDeepGet(
                                      navbar,
                                      ['Transaction', 'edit'],
                                      'off'
                                    ) == 'on' ? (
                                      <div className="text-sm-end">
                                        <Button
                                          className={getButtonTheme()}
                                          onClick={this.handleAddClick}
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Create Account Bank
                                        </Button>
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          'table align-middle table-nowrap table-hover'
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={this.node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <Modal
                        size="lg"
                        isOpen={this.state.modal}
                        className={this.props.className}
                      >
                        <ModalHeader toggle={this.toggle} tag="h4">
                          {'Ubah Account ' + this.state.data.name}
                        </ModalHeader>
                        <ModalBody>
                          <AvForm onValidSubmit={this.handleValidSubmit}>
                            <Row form>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <Label className="mt-1" htmlFor="rekening">
                                    Rekening :
                                  </Label>
                                  <AvField
                                    type="number"
                                    id="rekening"
                                    name="rekening"
                                    value={this.state.data.rekening || 0}
                                    placeholder="Masukan Rekening..."
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label htmlFor="name">Name : </Label>
                                  <AvField
                                    value={this.state.data.name || ''}
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Masukan Nama..."
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label className="mt-1" htmlFor="email">
                                    Email :
                                  </Label>
                                  <AvField
                                    type="email"
                                    id="email"
                                    value={this.state.data.email || ''}
                                    name="email"
                                    placeholder="Masukan Email"
                                    errorMessage="Masukkan Angka saja"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label className="mt-1" htmlFor="selectbank">
                                    Select Bank :
                                  </Label>
                                  <Select
                                    id="selectbank"
                                    name="selectbank"
                                    options={VmList}
                                    className="mb-3"
                                    maxMenuHeight={150}
                                    placeholder="Select Bank"
                                    onChange={this.handleOnchange}
                                    value={VmList.filter(function (option) {
                                      return option.value === selectedBank;
                                    })}
                                  ></Select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['payout', 'list'], {}),
  loading: safeDeepGet(state, ['payout', 'loading'], true),
  updateResult: safeDeepGet(state, ['payout', 'update'], {}),
  createResult: safeDeepGet(state, ['product', 'create'], {}),
  initCreateResult: safeDeepGet(state, ['payout', 'initCreate'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitCreateProduct: () => dispatch(initCreate()),
  LoadAccountList: () => dispatch(list()),
  UpdateAccount: payload => dispatch(update(payload)),
  DeleteAccount: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankAccount);
