import firebase from 'firebase/compat/app';
import { collection, query, where } from 'firebase/firestore';
 
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import { safeDeepGet } from 'iotera-base/utility/json';

const randomDate = (start, end, startHour, endHour) => {
  var date = new Date(+start + Math.random() * (end - start));
  var hour = (startHour + Math.random() * (endHour - startHour)) | 0;
  date.setHours(hour);
  return date;
};

export default class Snapshot {
  constructor(firebase) {
      this.collection = firebase.firestore().collection('transaction_snapshot');
  }

  random = () => {
    return new Promise((resolve, reject) => {
      const deviceId = '38b4ef27-1acc-442a-a7bf-057c23d2a272';
      // const deviceId =  Math.floor(Math.random() * 3) == 0 ? "d2aa1803-133c-4244-bc52-40475bae2eac" : "8ec5ac6d-7934-49be-8911-15b443bd551e";
      const snapshotId = deviceId + '_11_2021';
      const dataMap = {};
      for (let step = 0; step < 25; step++) {
        var rd = randomDate(
          new Date(2021, 10, 1),
          new Date(2021, 10, 23),
          0,
          24
        );
        var frd = firebase.firestore.Timestamp.fromDate(rd);
        const amount = Math.floor(Math.random() * 11) * 1000;
        const sku =
          Math.floor(Math.random() * 3) == 0
            ? 'SOSRO-TB-ORI-200'
            : 'NESTLE-BB-ORI-189';
        const isDispense = Math.floor(Math.random() * 3) == 0 ? true : false;

        const key = '' + rd.getTime() * 1000;

        const data = {
          device_id: deviceId,
          paymentDetails: {
            paymentAmount: amount,
            paymentMethod: 'QRIS-ESPAY',
          },
          sku: sku,
          timeDetails: {
            firestoreTimeStamp: frd,
            microsecondsSinceEpoch: rd.getTime() * 1000,
          },
          transactionDetails: {
            dispenseTime: rd.getTime() * 1000,
            isDispense: isDispense,
            orderId: '' + rd.getTime() * 1000,
            transactionStatus: 'settlement',
          },
        };

        dataMap[key] = data;
      }

      this.collection.doc(snapshotId).set(dataMap);
    });
  };

  list = payload => {
    return new Promise((resolve, reject) => {
      var filter_month = safeDeepGet(payload, 'month');
      var filter_year = safeDeepGet(payload, 'year');
      var filter_bp = safeDeepGet(payload, 'businessPoints');
      var month = ('0' + (filter_month + 1)).slice(-2);
      var docname = String(filter_year) + '_' + String(month) + '_' + filter_bp;
      let query = this.collection.doc(docname);

      let map = {};
      query.get().then(
        querySnapshot => {
          let code = 0;
          let map = querySnapshot.data();
          resolve({ code: code, body: map });
        },
        error => {
          resolve({ code: -1, body: map });
        }
      );
    });
  };
}
