/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';

import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const SnapshotReport = props => {
  const { reports, filename, date } = props;

  let card_filename = 'no file found';

  if (Object.keys(reports).length > 0) {
    card_filename = filename + '.xlsx';
  }

  let recentfile = [
    {
      id: 1,
      icon: 'mdi mdi-file-document font-size-16 align-middle text-primary me-2',
      file: card_filename,
      date: date + '-01 00:00',
      size: (Object.keys(reports).length * 0.2).toFixed(1),
    },
  ];

  let curr_month = parseInt(date.split('-')[1]);
  let curr_year = parseInt(date.split('-')[0]);
  let next_available = '';

  if (curr_month == 12) {
    next_available = '01-01-' + String(curr_year + 1);
  } else {
    next_available = '01-' + (curr_month + 1) + '-' + date.split('-')[0];
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <Col lg="3">
                  <div className="d-flex">
                    <div className="avatar-md me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                        <i className="bx bx-copy-alt" />
                      </span>
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-1">Next available</h5>
                        <p className="mb-0">{next_available}</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="6" className="align-self-center">
                  <div className="table-responsive">
                    <Table className="table align-middle table-nowrap table-hover mb-0">
                      <thead>
                        <tr>
                          <th scope="col">File name</th>
                          <th scope="col">Date modified</th>
                          <th scope="col">Size</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recentfile.map((recentfile, key) => (
                          <tr key={key}>
                            <td>
                              <Link to="#" className="text-dark fw-medium">
                                <i className={recentfile.icon}></i>{' '}
                                {recentfile.file}
                              </Link>
                            </td>
                            <td>{recentfile.date}</td>
                            <td>{recentfile.size} KB</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col lg="3" className="d-none d-lg-block" align="center">
                  <div className="clearfix mt-4 mt-lg-0">
                    <ExcelFile
                      filename={filename}
                      element={
                        <button type="button" className="btn btn-primary w-sm">
                          <i className="mdi mdi-download d-block font-size-16"></i>
                          Download Report
                        </button>
                      }
                    >
                      <ExcelSheet data={reports} name="Transaction List">
                        <ExcelColumn label="Order Id" value="order_id" />
                        <ExcelColumn
                          label="Business Point"
                          value="business_point"
                        />
                        <ExcelColumn label="Date" value="date" />
                        <ExcelColumn label="Product SKU" value="sku" />
                        <ExcelColumn label="Total" value="total" />
                        <ExcelColumn label="Payment Method" value="method" />
                        <ExcelColumn
                          label="Payment Status"
                          value="payment_status"
                        />
                        <ExcelColumn
                          label="Dispense Status"
                          value="dispense_status"
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SnapshotReport;
