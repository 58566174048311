/* eslint-disable no-unused-vars */
/* eslint-disable semi */
 
import { safeDeepGet } from 'iotera-base/utility/json';

export default class Product {
  constructor(firebase) {
      this.collection = firebase.firestore().collection('planogram_activity');
  }

  list = (device_id, payload) => {
    return new Promise((resolve, reject) => {
      let planogramMap = {};
     
      let query = this.collection;
      if(device_id != null || device_id != undefined) {
        query = query.where('id', '==', device_id).orderBy("__name__", "desc").limit(1);
      } else {
        var filter_bp = safeDeepGet(payload, 'businessPoints');
        var filter_sd = safeDeepGet(payload, 'dateRange')[0];
        var filter_ed = safeDeepGet(payload, 'dateRange')[1];
        if (filter_bp == "all" || filter_bp == null || filter_bp == undefined) {
          filter_bp = null;
        }
        if (filter_sd == "all" || filter_sd == null || filter_sd == undefined) {
          filter_sd = null;
        }
        if (filter_ed == "all" || filter_ed == null || filter_ed == undefined) {
          filter_ed = null;
        }
        if (filter_bp) {
          query = query.where("id", "==", filter_bp);
        }
        if (filter_sd) {
          var startfulldate = filter_sd.getTime();
          query = query.where(
            "time_stamp",
            ">=",
            startfulldate
          );
        }
  
        if (filter_ed) {
          const d = filter_ed;
          d.setDate(d.getDate() + 1);
          var endfulldate = d.getTime();
          query = query.where(
            "time_stamp",
            "<=",
            endfulldate
          );
        }
      }
      
      query.get().then(
        querySnapshot =>  {
          querySnapshot.docs.map(doc => {
            planogramMap[doc.id] = doc.data();
          });
          resolve({ code: 0, body: planogramMap });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: error });
        }
      );
    });
  };

  create = (id, payload, name) => {
    return new Promise((resolve, reject) => {
      const storage = localStorage.getItem('authUser');
      const jsonStorage = JSON.parse(storage);
      const authUser = safeDeepGet(jsonStorage, 'email', '-');
      const insertedPayload = {};
      // for (const key in payload) {
      //     insertedPayload[key] = payload[key];
      // }
      insertedPayload['planogram'] = payload;
      insertedPayload['user'] = authUser;
      insertedPayload['id'] = id;
      insertedPayload['name'] = name;
      const timeStamp = new Date().getTime();
      insertedPayload['time_stamp'] = timeStamp;
      this.collection
        .doc(timeStamp.toString())
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = id => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('device_id', '==', id)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  upsert = (id, planogram) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {
        device_id: id,
        planogram,
      };

      this.collection
        .doc(id)
        .set(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
}
