/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';
import { App_ID } from 'Apps';
// Product state
import {
  LIST,
  DETAIL_INVOICE,
  CREATE
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import Result from 'iotera-base/result';
function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.invoice.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const chatBody = safeDeepGet(wsResult, 'body', {});
    for (const id in chatBody) {
      safeDeepSet(chatBody, [id, 'order_id'], id);
    }
    const chatList = []
    for(const id in chatBody){
        chatList.push(chatBody[id])
    }    
    
    yield put(result(LIST, { code: Result.SUCCESS, body: chatList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* detailChat(actions) {
  yield put(loading(DETAIL_INVOICE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.chat.detail, actions);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const chatBody = safeDeepGet(wsResult, 'body', {});
    for (const id in chatBody) {
      safeDeepSet(chatBody, [id, 'order_id'], id);
    }
    const chatList = []
    for(const id in chatBody){
        chatList.push(chatBody[id])
    }    
    
    yield put(result(DETAIL_INVOICE, { code: Result.SUCCESS, body: chatList }));
    return;
  }
  yield put(result(DETAIL_INVOICE, { result: { code: Result.FAILED } }));
}

function* create(payload){
  yield put(loading(CREATE));
  const firebaseInstance = getInstance();
  const body = safeDeepGet(payload, "payload", {})
  let wsResult = yield call(firebaseInstance.invoice.create, body)
}
function* payoutSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(DETAIL_INVOICE, detailChat);

  yield takeEvery(CREATE, create)
}

export default payoutSaga;
