/* Import */
import { safeGet } from 'iotera-base/utility/json';

import firebase from 'firebase/compat/app';
import Base from './base';

export default class RefundChat extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'refund_chat');
  }

  list = payload => {
    return new Promise((resolve, reject) => {
      var filter_bp = safeGet(payload, 'businessPoints');
      var filter_rs = safeGet(payload, 'refundStatus');
      var filter_sd = safeGet(payload, 'dateRange')[0];
      var filter_ed = safeGet(payload, 'dateRange')[1];
      if (
        filter_bp == 'all' ||
        filter_bp == null ||
        filter_bp == 'All points'
      ) {
        filter_bp = null;
      }
      if (filter_rs == 'all' || filter_rs == null) {
        filter_rs = null;
      }
      if (filter_sd == 'all' || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null) {
        filter_ed = null;
      }
      let query = this.collection;
      if (filter_rs == true) {
        query = query.where('active', '==', true);
      } else if (filter_rs == false) {
        query = query.where('active', '==', false);
      }
      if (filter_bp) {
        query = query.where('business_point', '==', filter_bp);
      }
      if (filter_sd) {
        var startfulldate = firebase.firestore.Timestamp.fromDate(filter_sd);
        query = query.where('created_date', '>=', startfulldate);
      }

      if (filter_ed) {
        const d = filter_ed;
        d.setDate(d.getDate() + 1);
        var endfulldate = firebase.firestore.Timestamp.fromDate(d);

        query = query.where('created_date', '<=', endfulldate);
      }
      const list = {};
      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };

  detail = orderID => {
    return new Promise((resolve, reject) => {
      var orderId = safeGet(orderID, 'payload');
      const list = {};
      this.collection
        .where('__name__', '==', orderId)
        .get()
        .then(
          snapshot => {
            snapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            console.log(error);
            resolve({ code: -1, body: list });
          }
        );
    });
  };

  update = id => {
    return new Promise((resolve, reject) => {
      this.collection
        .where('__name__', '==', id)
        .get()
        .then(snapshot => {
          snapshot.docs.map(doc => {
            if (doc.id != null || doc.id != undefined) {
              this.collection
                .doc(id)
                .set(
                  {
                    active: false,
                  },
                  { merge: true }
                )
                .then(
                  () => {
                    resolve({ code: 0 });
                  },
                  error => {
                    resolve({ code: -1, body: error });
                  }
                );
            }
          });
        });
    });
  };
}
