export const LOADING = "LOADING"
export const RESULT = "RESULT"

export const LIST = "TAG_LIST"
export const LIST_TAGS = "TAG_LIST_TAGS"
export const INIT_CREATE = "TAG_INIT_CREATE"
export const CREATE = "TAG_CREATE"
export const READ = "TAG_INFO"
export const UPDATE = "TAG_UPDATE"
export const STOCK_UPDATE = "STOCK_TAG_UPDATE"
export const DELETE = "TAG_DELETE"
