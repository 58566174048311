/* eslint-disable semi */
import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_BUSINESS_POINTS,
  LIST_ALERT_DEVICE,
  LIST_ALERT_HISTORICAL_BYDEVICE,
  LIST_ALERT_HISTORICAL,
  LIST_ALERT_CURRENT,
} from './actionTypes';

const INIT_STATE = {
  loading: true,
};

const Product = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case LIST:
          newState['list'] = action.payload.result;
          break;
        case LIST_BUSINESS_POINTS:
          newState['list_business_points'] = action.payload.result;
          break;
        case LIST_ALERT_DEVICE:
          newState['list_alert_device'] = action.payload.result;
          break;
        case LIST_ALERT_HISTORICAL_BYDEVICE:
          newState['list_alert_historical_device'] = action.payload.result;
          break;
        case LIST_ALERT_CURRENT:
          newState['list_alert_current'] = action.payload.result;
          break;
        case LIST_ALERT_HISTORICAL:
          newState['list_alert_historical'] = action.payload.result;
          break;
        case INIT_CREATE:
          newState['initCreate'] = action.payload.result;
          break;
        case CREATE:
          newState['create'] = action.payload.result;
          break;
        case READ:
          newState['read'] = action.payload.result;
          break;
        case UPDATE:
          newState['update'] = action.payload.result;
          break;
        case STOCK_UPDATE:
          newState['stockUpdate'] = action.payload.result;
          break;
        case DELETE:
          newState['delete'] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default Product;
