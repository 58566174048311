/* eslint-disable semi */
/* eslint-disable no-undef */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { safeDeepGet } from 'iotera-base/utility/json';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
import MetaTags from 'react-meta-tags';
import { summary } from '../../store/business-point/actions';

class SummaryDevice extends Component {
  constructor(props) {
    super(props);
    const appId = safeDeepGet(this.props.match.params, 'appId');
    const device_id = safeDeepGet(this.props.match.params, 'device_id');
    this.state = {
      appId,
      device_id,
      reports: [
        {
          title: 'Total Sales',
          iconClass: 'bx-money',
          bgClass: 'bg-primary',
          description: '',
        },
        {
          title: 'Total Product',
          iconClass: 'bx-basket',
          bgClass: 'bg-warning',
          description: '',
        },

        {
          title: 'Average Sales / Days',
          iconClass: 'bx-stats',
          bgClass: 'bg-warning',
          description: '',
        },
        {
          title: 'Average Product / Days',
          iconClass: 'bx-stats',
          bgClass: 'bg-warning',
          description: '',
        },
      ],
      active: [
        {
          title: 'Total Days Active',
          iconClass: 'bx-play',
          bgClass: 'bg-warning',
          description: '',
        },
        {
          title: 'Total Days Without Transaction',
          iconClass: 'bx-error',
          bgClass: 'bg-danger',
          description: '',
        },
        {
          title: 'Persentase VM Active',
          iconClass: 'bx-bar-chart',
          bgClass: 'bg-warning',
          description: '',
        },
      ],
    };
  }
  componentDidMount() {
    // Load business point
    const payload = {
      appId: this.state.appId,
      device_id: this.state.device_id,
    };
    this.props.LoadSummaryData(payload);
  }
  componentDidUpdate(prevProps) {
    const sum_update = safeDeepGet(
      this.props,
      ['result', 'result', 'body'],
      []
    );
    if (this.props.result !== prevProps.result && sum_update.length != 0) {
      var stateCopy = Object.assign({}, this.state);
      stateCopy.reports = stateCopy.reports.slice();
      stateCopy.reports[0] = Object.assign({}, stateCopy.reports[0]);
      const numberPrev = sum_update.total_sales.toFixed(2).toString();
      var split_curent = numberPrev.split('.');
      var sisa_curent = split_curent[0].length % 3;
      let rupiah_curent = split_curent[0].substr(0, sisa_curent);
      var ribuan_curent = split_curent[0].substr(sisa_curent).match(/\d{3}/gi);
      if (ribuan_curent) {
        var separator_curent = sisa_curent ? '.' : '';
        rupiah_curent += separator_curent + ribuan_curent.join('.');
      }
      rupiah_curent =
        split_curent[1] != undefined
          ? rupiah_curent + ',' + split_curent[1]
          : rupiah_curent;
      stateCopy.reports[0].value = 'Rp ' +rupiah_curent;

      stateCopy.reports[1] = Object.assign({}, stateCopy.reports[1]);
      stateCopy.reports = stateCopy.reports.slice();
      const number_total_product = sum_update.total_product.toString();
      var split_total_product = number_total_product.split('.');
      var sisa_total_product = split_total_product[0].length % 3;
      let rupiah_total_product = split_total_product[0].substr(0, sisa_total_product);
      var ribuan_total_product = split_total_product[0].substr(sisa_total_product).match(/\d{3}/gi);
      if (ribuan_total_product) {
        var separator_total_product = sisa_total_product ? '.' : '';
        rupiah_total_product += separator_total_product + ribuan_total_product.join('.');
      }
      rupiah_total_product =
        split_total_product[1] != undefined
          ? rupiah_total_product + ',' + split_total_product[1]
          : rupiah_total_product;
      stateCopy.reports[1].value = rupiah_total_product;

      stateCopy.reports[2] = Object.assign({}, stateCopy.reports[2]);
      const numberAvgSales = sum_update.avg_sales.toFixed(2).toString();
      var split_avg_sales = numberAvgSales.split('.');
      var sisa_avg_sales = split_avg_sales[0].length % 3;
      let rupiah_avg_sales = split_avg_sales[0].substr(0, sisa_avg_sales);
      var avg_sales = split_avg_sales[0].substr(sisa_avg_sales).match(/\d{3}/gi);
      if (avg_sales) {
        var separator_curent = sisa_avg_sales ? '.' : '';
        rupiah_avg_sales += separator_curent + avg_sales.join('.');
      }
      rupiah_avg_sales =
        split_avg_sales[1] != undefined
          ? rupiah_avg_sales + ',' + split_avg_sales[1]
          : rupiah_avg_sales;

      stateCopy.reports[2].value = "Rp " + rupiah_avg_sales;

      stateCopy.reports[3] = Object.assign({}, stateCopy.reports[3]);
      const number_avg_product = sum_update.avg_product.toFixed(2).toString();
      var split_avg_product = number_avg_product.split('.');
      var sisa_avg_product = split_avg_product[0].length % 3;
      let rupiah_avg_product = split_avg_product[0].substr(0, sisa_avg_product);
      var ribuan_avg_product = split_avg_product[0].substr(sisa_avg_product).match(/\d{3}/gi);
      if (ribuan_avg_product) {
        var separator_avg_product = sisa_avg_product ? '.' : '';
        rupiah_avg_product += separator_avg_product + ribuan_avg_product.join('.');
      }
      rupiah_avg_product =
        split_avg_product[1] != undefined
          ? rupiah_avg_product + ',' + split_avg_product[1]
          : rupiah_avg_product;
      stateCopy.reports[3].value = rupiah_avg_product;

      stateCopy.active[0] = Object.assign({}, stateCopy.active[0]);
      stateCopy.active[0].value = sum_update.total_days;

      stateCopy.active[1] = Object.assign({}, stateCopy.active[1]);
      stateCopy.active[1].value = sum_update.total_no_trx;

      stateCopy.active[2] = Object.assign({}, stateCopy.active[2]);
      stateCopy.active[2].value = sum_update.avg_vm + '%';

      this.setState(stateCopy);
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Smart Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Dashboards" breadcrumbItem="Overview" />
            <Row>
              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {this.state.reports.map((report, key) => (
                    <Col md="6" key={'_col_' + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.value}</h4>
                            </div>
                            <div
                              className={
                                'mini-stat-icon avatar-sm rounded-circle ' +
                                report.bgClass +
                                ' align-self-center'
                              }
                            >
                              <span className="avatar-title">
                                <i
                                  className={
                                    'bx ' + report.iconClass + ' font-size-24'
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row>
                  {/* Reports Render */}
                  {this.state.active.map((item, key) => (
                    <Col md="6" key={'_col_' + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {item.title}
                              </p>
                              <h4 className="mb-0">{item.value}</h4>
                            </div>
                            <div
                              className={
                                'mini-stat-icon avatar-sm rounded-circle ' +
                                item.bgClass +
                                ' align-self-center'
                              }
                            >
                              <span className="avatar-title">
                                <i
                                  className={
                                    'bx ' + item.iconClass + ' font-size-24'
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['businessPoint', 'summary'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadSummaryData: payload => dispatch(summary(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDevice);
