/* eslint-disable semi */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Alert, Card, CardBody, Col, Container, Button, Row } from 'reactstrap';
import "../../assets/scss/_input-group.scss";
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// actions
import {
  apiError,
  loginUser,
  socialLogin,
} from '../../store/auth/login/actions';

// import images
import profile from '../../../Base/assets/images/profile-img.png';
import logo from '../../../Base/assets/images/logo.svg';
import lightlogo from '../../../Base/assets/images/logo-light.svg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      passwordType: 'password',
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  togglePassword() {
    const { passwordType } = this.state;
    if (passwordType == 'password') {
      this.setState({ passwordType: 'text' });
    } else {
      this.setState({ passwordType: 'password' });
    }
  }
  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.setState({isLoading: true});
    this.props.loginUser(values, this.props.history);
  }

  componentDidMount() {
    this.props.apiError('');
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.error !== prevProps.error) {
        this.setState({isLoading: false});
    }
  };
  render() {
    const { passwordType } = this.state;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <label>Password</label>
                        <div className="input-group-password mb-3" >
                          <AvField
                            name="password"
                            type={passwordType}
                            required
                            style={{ width: "190%" }}

                            placeholder="Enter Password"
                          />
                          <div>
                            <Button
                              outline
                              color="primary"
                              // className="btn btn-outline-primary"

                              onClick={this.togglePassword}
                            >
                              {passwordType === 'password' ? (
                                <i className="mdi mdi-eye"></i>
                              ) : (
                                <i className="mdi mdi-eye"></i>
                              )}
                            </Button>
                          </div>
                          
                        </div>

                        
                        {/* <div className="mb-3">
                          <AvGroup>
                            <AvField
                              name="password"
                              label="Password"
                              type={text}
                              required
                              style={{ width: '50%' }}
                              placeholder="Enter Password"
                            />
                          </AvGroup>
                        </div> */}

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          {this.state.isLoading ? 
                          
                            <div className="loading-spinner" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                              className="lds-ring"
                            >
                              <circle
                                cx="50"
                                cy="50"
                                r="40"
                                stroke="#007bff"
                                strokeWidth="10"
                                strokeDasharray="62.83185307179586 62.83185307179586"
                                fill="none"
                                strokeLinecap="round"
                                transform="rotate(215.582 50 50)"
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="rotate"
                                  calcMode="linear"
                                  values="0 50 50;360 50 50"
                                  keyTimes="0;1"
                                  dur="1s"
                                  begin="0s"
                                  repeatCount="indefinite"
                                ></animateTransform>
                              </circle>
                            </svg>
                          </div>
                          :
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                          }
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" /> Forgot your
                            password?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);
