import { call, put, takeEvery } from "redux-saga/effects";

import { LIST, INIT_CREATE, CREATE, READ, UPDATE, DELETE, STOCK_UPDATE, LIST_OPTIONS, LIST_BEAN } from "./actionTypes";

import { loading, result } from "./actions";
import { getInstance } from "../../firebase/helper";
import { safeDeepGet, safeDeepSet } from "iotera-base/utility/json";
import Result from "iotera-base/result";

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.ingredient.list);
  if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
    const distributorMap = wsResult.body;
    const distributorList = [];
    let i = 0;
    for (const sku in distributorMap) {
      distributorMap[sku].id = sku;
      safeDeepSet(distributorMap, [sku, "id"], i++);
      distributorList.push(distributorMap[sku]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: distributorList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }));
}

function* list_options() {
  yield put(loading(LIST_OPTIONS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.ingredient.listoptions);
  if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
    const listMap = wsResult.body;
    const ingredientList = [];
      for (const id in listMap) {
      let ingreName = safeDeepGet(listMap, [id, "name"]);
      let ingreId = safeDeepGet(listMap, [id, "type"]);
      ingredientList.push({ label: ingreName, value: ingreId });
      }
    yield put(
      result(LIST_OPTIONS, { code: Result.SUCCESS, body: ingredientList })
    );
    return;
  }
  yield put(result(LIST_OPTIONS, { result: { code: Result.FAILED } }));
}

function* list_bean() {
  yield put(loading(LIST_BEAN));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.ingredient.listbeans);
  if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
    const listMap = wsResult.body;
    const ingredientList = [];
      for (const id in listMap) {
      let ingreName = safeDeepGet(listMap, [id, "name"]);
      let ingreId = safeDeepGet(listMap, [id, "type"]);
      ingredientList.push({ label: ingreName, value: ingreId });
      }
    yield put(
      result(LIST_BEAN, { code: Result.SUCCESS, body: ingredientList })
    );
    return;
  }
  yield put(result(LIST_BEAN, { result: { code: Result.FAILED } }));
}

function* create(action) {
  yield put(loading(CREATE));
  const payload = safeDeepGet(action, "payload");
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.ingredient.create, payload);
  const code = safeDeepGet(wsResult, "code");

  yield put(result(CREATE, { code }));
}

function* read(action) {
  yield put(loading(READ));
  const sku = safeDeepGet(action, ["payload", "sku"]);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.read, sku);
  if (safeDeepGet(wsResult, "code") !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function* update(action) {
  yield put(loading(UPDATE));
  const sku = safeDeepGet(action, ["payload", "id"]);
  const payload = safeDeepGet(action, "payload");
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.ingredient.update, sku, payload);
  const code = safeDeepGet(wsResult, "code");

  yield put(result(UPDATE, { code }));
}

function* stockUpdate(action) {
  yield put(loading(STOCK_UPDATE));
  const sku = safeDeepGet(action, ["payload", "sku"]);
  const payload = safeDeepGet(action, "payload");
  const firebaseInstance = getInstance();

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ["payload", "imageFile"]);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ["payload", "imageExt"]);
    const wsResult = yield call(firebaseInstance.productStorage.replace, imageFile, sku, imageExt);
    if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ["body", "url"]);
    }
  }
  if (imageUrl != null) {
    payload["img_url"] = imageUrl;
  }

  const wsResult = yield call(firebaseInstance.product.updateStock, sku, payload);
  const code = safeDeepGet(wsResult, "code");

  yield put(result(STOCK_UPDATE, { code }));
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();

  const id = safeDeepGet(payload, ["payload", "sku"], '');
  const wsResult = yield call(firebaseInstance.ingredient.delete_, id);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
  
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_OPTIONS, list_options);
  yield takeEvery(LIST_BEAN, list_bean);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(STOCK_UPDATE, stockUpdate);
  yield takeEvery(DELETE, delete_);
}

export default productSaga;
