/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import { ENV_APPLICATION_ID, MULTITENANCY } from 'Env';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { APPLICATION_ID, FIREBASE_URL, PLATFORM_URL, buildWsParam } from './index';
import { Buffer } from 'buffer';
const url = 'https://api-serverless.iotera.io/';


export function getDownloadData(body){

  const param = {
    url: FIREBASE_URL + "/getDownloadData",
    body: body,
    timeout: 60000,
  }
  return JPOST(param)
}
export function setPlanogram_Milano(deviceId, ids, names, prices) {
  const body = {
    device_id: deviceId,
    ids,
    names,
    prices,
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam = {
    data: btoaBody
  }
  const param = buildWsParam("/planogram-coffee-milano-set", bodyParam, 60000)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/planogram-coffee-milano-set',
  //   body: {
  //     data: btoaBody,
  //   },
  //   timeout: 60000,
  // };
  return JPOST(param);
}

export function setPlanogram_franke(id, config) {
  const body = {
    id: id,
    configs: config,
    ts: Math.round(new Date().getTime() / 1000).toFixed(0),
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam = {
    data: btoaBody
  }
  const param = buildWsParam("/planogram-coffee-franke-set", bodyParam, 60000)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + `/planogram-coffee-franke-set`,
  //   body: {
  //     data: btoaBody,
  //   },
  //   // timeout: 60000
  // };
  return JPOST(param);
}
export function getTags() {
  const param = buildWsParam("/tags-get")
  return JGET(param);
}
export function createTags(bodyParam) {
  const param = buildWsParam("/tags-create", bodyParam)
  return JPOST(param);
}
export function deleteTags(bodyParam) {
  const param = buildWsParam("/tags-delete", bodyParam)
  return JPOST(param);
}
export function applyTags(bodyParam){
  const param = buildWsParam("/tags-apply", bodyParam)
  return JPOST(param)
}
export function removeTags(bodyParam){
  const param = buildWsParam("/tags-remove", bodyParam)
  return JPOST(param)
}

export function getTasks() {
  const param = buildWsParam("/task-get")
  return JGET(param);
}
export function setplanogram_levending(id, config, configStock, tsNow) {
  const body = {
    id: id,
    configs: config,
    ts: Math.round(tsNow / 1000).toFixed(0),
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam = {
        data: btoaBody,
      }
  const param = buildWsParam("/setplanogram_levending", bodyParam, 60000)
  
  // const param = {
  //   url: url + ENV_APPLICATION_ID + `/setplanogram_levending`,
  //   body: {
  //     data: btoaBody,
  //   },
  //   timeout: 60000
  // };
  return JPOST(param);
}
export function device_list() {
  const param = buildWsParam("/device-get")
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/device-get',
  // };

  return JGET(param);
}

export function account_bank_list() {
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/account-get',
  // };
  const param = buildWsParam("/account-get")
  return JGET(param);
}

export function stockPlanogram(deviceId) {
  const param = buildWsParam("/sensors-get?device_id=" + deviceId)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/sensors-get',
  //   queryParams: {
  //     device_id: deviceId,
  //   },
  // };
  return JGET(param);
}
export function get_notifications() {
  const param = buildWsParam("/task-get")
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/task-get',
  // };
  return JGET(param);
}
export function get_alert_device(device_id) {
  const param = buildWsParam("/get-alert-device?device_id=" +device_id)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/get-alert-device',
  //   queryParams: { device_id: device_id },
  // };
  return JGET(param);
}
export function alert_current() {
  const param = buildWsParam("/get-alert")

  // const param = {
  //   url: url + ENV_APPLICATION_ID + `/get-alert`,
  // };
  return JGET(param);
}

export function get_historical_by_device(device_id, key, start, end) {
  const queryParams= { device_id, key, start, end }
  const param = buildWsParam("/get-alert-historical-device?device_id=" + device_id + "&key=" + key+ "&start="+start + "&end="+end)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/get-historical-by-device',
  //   queryParams: { device_id, key, start, end },
  // };
  return JGET(param);
}
export function alert_historical(id) {
  const queryParams= { key: id, }
  const param = buildWsParam("/get-alert-historical?key="+id)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + `/get-alert-historical`,
  //   queryParams: {
  //     key: id,
  //   },
  // };

  return JGET(param);
}
export function close_notification(task_id) {
  const body= { task_id: task_id }
  const param = buildWsParam("/task-close", body)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/task-close',
  //   body: { task_id: task_id },
  // };
  // console.log(param)
  return JPOST(param);
}
export function stockEmpty(value, operator) {
  const param = buildWsParam("/stock-get?value=" + value + '&operator=' + operator)
  // const param = {
  //   url:
  //     url +
  //     ENV_APPLICATION_ID +
  //     '/stock-get?value=' +
  //     value +
  //     '&operator=' +
  //     operator,
  //   // url: url+ENV_APPLICATION_ID+'/stock',
  //   // queryParams: {
  //   //   value: value,
  //   //   operator: operator,
  //   // },
  // };

  return JGET(param);
}

export function bank_list() {
  const param = buildWsParam("/bank-get")

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/bank-get',
  // };

  return JGET(param);
}

export function payout_list() {
  const param = buildWsParam("/payout-get")

  // const param = {
  //   url: url + ENV_APPLICATION_ID + `/payout-get`,
  // };

  return JGET(param);
}

export function getBalance() {
  const param = {
    url: FIREBASE_URL + `/getBalances`,
    queryParams: {
      app_id: APPLICATION_ID,
      multitenancy: MULTITENANCY,
    },
  };
  return JGET(param);
}
export function BalanceAdmin() {
  const param = {
    url: FIREBASE_URL + `/getBalancesAdmin`,
    queryParams: {
      app_id: APPLICATION_ID,
      multitenancy: MULTITENANCY,
    },
  };
  return JGET(param);
}

export function device_map() {
  return device_list();
}
export function getTime() {
  const param = {
    url: 'https://asia-southeast2-iotera-vending.cloudfunctions.net/getTime',
  };
  return JGET(param);
}
export function sensors(deviceId) {
  const param = buildWsParam("/sensors-get?device_id=" + deviceId)
  
  // const param = {
  //   // url: 'https://api-serverless.iotera.io/1000000021/sensors?device_id='+dev
  //   url: url + ENV_APPLICATION_ID + '/sensors-get?device_id=' + deviceId,
  //   // params: {
  //   //   device_id: deviceId,
  //   // },
  // };
  return JGET(param);
}

export function setRetail(
  deviceId,
  ids,
  names,
  prices,
  actives,
  selections,
  stocks,
  images,
  orders,
  actives_product,
  groups,
  descriptions,
  tsNow
) {
  const body = {
    device_id: deviceId,
    ids,
    names,
    prices,
    actives,
    selections,
    stocks,
    images,
    orders,
    actives_product,
    groups,
    descriptions,
    ts: Math.round(tsNow / 1000).toFixed(0),
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam = { data: btoaBody }
  const param = buildWsParam("/planogram-retail-set", bodyParam, 60000)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/planogram-retail-set',
  //   body: { data: btoaBody },
  // };
  // console.log(body)
  return JPOST(param);
}
export function setPlanogramMCPro(payload) {
  const param = buildWsParam("/set_planogram_mcproc", payload)
  // console.log(param)
  return JPOST(param);
}
export function setPlanogramWater(payload) {
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(payload)))
  ).toString('base64');
  const bodyParam = { data: btoaBody }
  const param = buildWsParam("/planogram-water-set", bodyParam)
  return JPOST(param);
}
export function setStockLevending(
  deviceId,
  stocks,
  recipes,
  tsNow
) {
  const body = {
    device_id: deviceId,
    stocks,
    recipes ,
    ts: Math.round(tsNow / 1000).toFixed(0),
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam = { data: btoaBody }
  const param = buildWsParam("/stock-planogram-levending-set", bodyParam, 600000)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/stock-planogram-levending-set',
  //   body: { data: btoaBody },
  //   timeout: 600000,
  // };
  return JPOST(param);
}
export function getProject(){
  const param = {
    url: "https://asia-southeast2-iotera-vending.cloudfunctions.net/getProjectsList"
  }
  return JGET(param)
}
export function setConfig3(
  deviceId,
  ids,
  names,
  prices,
  actives,
  selections,
  stocks,
  tsNow
) {
  const body = {
    device_id: deviceId,
    ids,
    names,
    prices,
    actives,
    selections,
    stocks,
    ts: Math.round(tsNow / 1000).toFixed(0),
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam = { data: btoaBody }
  const param = buildWsParam("/planogram-combo-porto-set", bodyParam, 1200000)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/planogram-combo-porto-set',
  //   body: { data: btoaBody },
  //   timeout: 600000,
  // };
  // console.log(param)
  return JPOST(param);
}
export function createdUser(email, password) {
  let newemail = email.toLowerCase();
  if (MULTITENANCY) {
    var email_arr = email.split('@');
    newemail = email_arr[0] + '+' + APPLICATION_ID + '@' + email_arr[1];
  }
  const body = {
    email: newemail,
    password: password,
  };
  const btoaBody = btoa(JSON.stringify(body));
  const param = {
    url: FIREBASE_URL + `/createdUser`,
    body: {
      data: btoaBody,
      app_id: APPLICATION_ID,
      multitenancy: MULTITENANCY,
    },
  };
  return JPOST(param);
}

export function login(email, password, application_id, Multitenacy) {
  const url =
    'https://asia-southeast2-iotera-vending.cloudfunctions.net/adminAuthLogin';
  const param = {
    url: url,
    body: {
      email,
      password,
      appid: application_id,
      multitenancy: Multitenacy,
      vm_code: application_id.slice(
        application_id.length - 4,
        application_id.length
      ),
      type: 'role',
    },
  };
  return JPOST(param);
}
export function deletedUser(uid, url) {
  if (url !== undefined) {
    const body = { uid: uid };
    const btoaBody = Buffer.from(
      decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
    ).toString('base64');
    const param = {
      url: url,
      body: {
        data: btoaBody,
      },
    };
    return JPOST(param);
  }
}

export function adminListUser(getUser) {
  if (getUser !== undefined) {
    const param = {
      url: getUser,
    };
    return JGET(param);
  }
}

export function getBpFee(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}
export function getBp(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}
export function createBpFee(url, body) {
  const param = {
    url: url,
    body: body,
  };
  return JPOST(param);
}
export function deleteBpFee(url, body) {
  const param = {
    url: url,
    body: body,
  };
  return JPOST(param);
}

export function getBalanceAdmin(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}

export function createCoupon(amount, ts, type) {
  const bodyParam =  {
    discount: Number(amount),
    exp_ts: ts,
    type: type,
  }
  const param = buildWsParam("/create-coupon", bodyParam)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/create-coupon',
  //   body: {
  //     discount: Number(amount),
  //     exp_ts: ts,
  //     type: type,
  //   },
  // };
  return JPOST(param);
}
export function getCoupon() {
  const param = {
    url: 'https://pay.iotera.io/web/coupon/get',
    headers: {
      'Iotera-Payment-Application-Id': ENV_APPLICATION_ID,
      'Iotera-Payment-Admin-Token':
        '6bf868df24a4357dee20e6d3d6ef1d1944249ad44cb29687446d346f60fc0215',
    },
    body: {
      coupon_id: '',
      code: '2F2S1WY94B',
    },
  };
  return JPOST(param);
}
export function listCoupon() {
  const bodyParam =  {
    application_id: ENV_APPLICATION_ID,
  }
  const param = buildWsParam("/get-coupon", bodyParam)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/get-coupon',
  //   body: {
  //     application_id: ENV_APPLICATION_ID,
  //   },
  // };
  return JPOST(param);
}
export function cancelCoupon(code) {
  const bodyParam =  {
    coupon_id: '',
    code: code,
  }
  const param = buildWsParam("/cancel-coupon", bodyParam)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/cancel-coupon',
  //   body: {
  //     coupon_id: '',
  //     code: code,
  //   },
  // };
  return JPOST(param);
}
export function deleteCoupon(code) {
  const bodyParam =  {
    coupon_id: '',
    code: code,
  }
  const param = buildWsParam("/delete-coupon", bodyParam)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/delete-coupon',
  //   body: {
  //     coupon_id: '',
  //     code: code,
  //   },
  // };
  return JPOST(param);
}

export function payoutRequest(payload) {
  const email = safeDeepGet(payload, 'email', '');
  const id = safeDeepGet(payload, 'id', '');
  const amount = safeDeepGet(payload, 'amount', 0);
  const notes = safeDeepGet(payload, 'notes', '');
  const body = {
    account_id: id,
    amount: Number(amount),
    notes: notes,
    email: email,
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');

  const bodyParam =  {
    data: btoaBody,
  }
  const param = buildWsParam("/payout-request", bodyParam)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/payout-request',
  //   body: {
  //     data: btoaBody,
  //   },
  //   timeout: 600000,
  // };
  // console.log(param)
  // return {code: 200}
  return JPOST(param);
}
export function payoutValidate(payload) {
  const otp = safeDeepGet(payload, 'otp', '');
  const amount = safeDeepGet(payload, 'amount');
  const account_id = safeDeepGet(payload, 'id');
  const account_bank = safeDeepGet(payload, 'bank');
  const account_no = safeDeepGet(payload, 'rekening');
  const account_name = safeDeepGet(payload, 'name', '');
  const storage = localStorage.getItem('authUser');
  const jsonStorage = JSON.parse(storage);
  const authUser = safeDeepGet(jsonStorage, 'email', '-');
  const notes = safeDeepGet(payload, 'notes', '');

  const body = {
    otp: otp,
    amount: Number(amount),
    account_id: account_id,
    account_no: account_no,
    account_name: account_name,
    account_bank: account_bank,
    auth_user: authUser,
    notes: notes,
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam =  {
    data: btoaBody,
  }
  const param = buildWsParam("/payout-validate", bodyParam)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/payout-validate',
  //   body: {
  //     data: btoaBody,
  //   },
  //   timeout: 600000,
  // };
  // console.log(param)
  // return {code: 200}
  return JPOST(param);
}
export function getPlanoggram(deviceId) {
  // const param = {
  //   url:
  //     url + ENV_APPLICATION_ID + `/planogram-coffee-franke-get?id=` + deviceId,
  // };

  const param = buildWsParam("/planogram-coffee-franke-get?id="+ deviceId)
  return JGET(param);
}
export function getPlanoggramLevending(deviceId) {
  // const param = {
  //   url: url + ENV_APPLICATION_ID + `/planogram-levending-get?id=` + deviceId,
  // };
  const param = buildWsParam("/planogram-levending-get?id="+ deviceId)

  return JGET(param);
}
export function refundTrx(payload) {
  const device_id = safeDeepGet(payload, 'device_id', '');
  const id = safeDeepGet(payload, 'id', '');
  const order_id = safeDeepGet(payload, 'order_id', '');
  const amount = safeDeepGet(payload, 'amount', '');
  const reason = safeDeepGet(payload, 'reason', '');

  const ts = new Date().getTime();
  const body = {
    application_id: ENV_APPLICATION_ID,
    multitenancy: MULTITENANCY,
    device_id: device_id,
    transaction_id: order_id,
    id: id,
    amount: amount,
    ts: ts,
    reason: reason,
  };
  let result = id.includes('_');
  if (result) {
    let arr_key = id.split('_');
    const key = arr_key[1];
    safeDeepSet(body, 'refund_key','index-' + key)
  }
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const param = {
    url: FIREBASE_URL + '/refundRequest',
    headers: {
      Authorization: 'apikey ajkhjkhqkjhwjkehkqwe:khkkjqhjkwhjkq',
      'Access-Control-Allow-Origin': '*',
    },
    body: {
      data: btoaBody,
      app_id: APPLICATION_ID,
      multitenancy: MULTITENANCY,
    },
  };
  // const headers= {
  //   Authorization: 'apikey ajkhjkhqkjhwjkehkqwe:khkkjqhjkwhjkq',
  //   'Access-Control-Allow-Origin': '*',
  // };
  // const body = {
  //   application_id: ENV_APPLICATION_ID,
  //   device_id: device_id,
  //   transaction_id: id,
  //   amount: amount,
  //   ts: ts,
  // };
  // return axios.post(FIREBASE_URL + '/refundRequest',body, {headers: headers})
  // console.log(param)
  const paramDumy = {
    url: "https://434309b9e043647bcad07169e55aaeb9.m.pipedream.net",
    body: body
  }; 
  JPOST(paramDumy);
  return JPOST(param);
}

export function post_task_bp(payload, id) {
  const old_value = safeDeepGet(payload, 'old_value', {});
  const new_value = safeDeepGet(payload, 'new_value', {});
  const device_name = safeDeepGet(payload, 'name', '');
  const device_id = id;
  const type_activity = safeDeepGet(payload, 'type_activity', 'delete');
  const body = {
    old_value: old_value,
    new_value: new_value,
    device_name: device_name,
    device_id: device_id,
    type_activity: type_activity,
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');

  const bodyParam = { data: btoaBody }
  const param = buildWsParam("/task-business-point", bodyParam)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/task-business-point',
  //   body: { data: btoaBody },
  // };
  return JPOST(param);
}

export function update_business_point(payload) {
  const device_id = safeDeepGet(payload, 'device_id');
  const device_name = safeDeepGet(payload, 'device_name');
  const vm_code = safeDeepGet(payload, 'vm_code');
  const bodyParam = {
    device_id,
    device_name,
    vm_code,
  }
  const param = buildWsParam("/update-business-point", bodyParam)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/update-business-point',
  //   body: {
  //     device_id,
  //     device_name,
  //     vm_code,
  //   },
  // };
  return JPOST(param);
}

export function getSubscription() {
  const param = buildWsParam("/get-subs")
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/get-subs',
  // };
  return JGET(param);
}
export function clearCash(payload) {
  const param = buildWsParam("/clear-cash", payload)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/clear-cash',
  //   body: payload,
  // };
  return JPOST(param);
}
export function resetFlowmeter(payload) {
  const param = buildWsParam("/reset-flowmeter", payload)
  return JPOST(param);
}

export function CreateDiscount(body) {
    
    const bodyParam= { data: body }
  const param = buildWsParam("/discount-create", bodyParam)

  // const param = {
  //   // url: url + ENV_APPLICATION_ID + "/create-discount",
  //   url: url + ENV_APPLICATION_ID + '/discount-create',
  //   body: { data: body },
  // };
  return JPOST(param);
}
export function cancelDiscount(body) {
  const bodyParam= { data: body }
  const param = buildWsParam("/discount-cancel", bodyParam)
  // const param = {
  //   // url: url + ENV_APPLICATION_ID + "/create-discount",
  //   url: url + ENV_APPLICATION_ID + '/discount-cancel',
  //   body: { data: body },
  // };
  return JPOST(param);
}
export function deleteDiscount(body) {
  const bodyParam= { data: body }
  const param = buildWsParam("/discount-delete", bodyParam)
  // const param = {
  //   // url: url + ENV_APPLICATION_ID + "/create-discount",
  //   url: url + ENV_APPLICATION_ID + '/discount-delete',
  //   body: { data: body },
  // };
  return JPOST(param);
}

export function createPayment(body) {
  const bodyParam= { data: body }
  const param = buildWsParam("/create-payment", bodyParam)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/create-payment',
  //   body: { data: body },
  // };
  // console.log(param)
  return JPOST(param);
}
export function cancelPayment(order_id) {
  const body = {
    order_id,
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');

  const bodyParam= { data: btoaBody }
  const param = buildWsParam("/cancel-subs", bodyParam)
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/cancel-subs',
  //   body: { data: btoaBody },
  // };
  // console.log(param)
  return JPOST(param);
}

export function deviceNonSubs() {
  const param = buildWsParam("/device-nonsubs-get")
  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/device-nonsubs-get',
  // };
  return JGET(param);
}

export function detailDeviceSubs(key) {
  const param = buildWsParam("/device-tags?key="+key)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/device-tags',
  //   queryParams: {
  //     key: key,
  //   },
  // };
  return JGET(param);
}

export function unTagDeviceSub(body) {
  const param = buildWsParam("/untag-device", body)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/untag-device',
  //   body: body,
  // };
  return JPOST(param);
}
export function unRegisterSub(body) {
  const param = buildWsParam("/unregister-device", body)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/unregister-device',
  //   body: body,
  // };
  return JPOST(param);
}

export function extendSub(body) {
  const param = buildWsParam("/extend-sub", body)

  // const param = {
  //   url: url + ENV_APPLICATION_ID + '/extend-sub',
  //   body: body,
  // };
  return JPOST(param);
}

export function getUser(deviceId) {
  const param = buildWsParam("/user-rfid-get?id=" +  deviceId)

  // const param = {
  //   url:
  //     `https://serverless-api.iotera.io/api/` +
  //     ENV_APPLICATION_ID +
  //     `/user?id=` +
  //     deviceId,
  // };

  return JGET(param);
}
export function setUser(id, config) {
  const body= {
    id: id,
    configs: config,
  }
  const param = buildWsParam("/user-rfid-set" , body)

  // const param = {
  //   url:
  //     `https://serverless-api.iotera.io/api/` + ENV_APPLICATION_ID + `/setuser`,
  //   body: {
  //     id: id,
  //     configs: config,
  //   },
  //   // timeout: 60000
  // };
  return JPOST(param);
}
export function getPlanogramMilano(device_id) {
  const param = buildWsParam("/planogram-coffee-milano-get?id="+device_id )
  // const param = {
  //   url: PLATFORM_URL + APPLICATION_ID + '/planogram-coffee-milano-get',
  //   queryParams: {
  //     id: device_id,
  //   },
  // };
  return JGET(param);
}


export function dummyData(body){
  const param = {
    url: "https://434309b9e043647bcad07169e55aaeb9.m.pipedream.net",
    body: body
  };
  return JPOST(param);

}

export function searchData(order_id){
  const body = {
    order_id: order_id
  }
  if(MULTITENANCY){
    safeDeepSet(body, 'app_id', APPLICATION_ID)
    safeDeepSet(body, 'multitenancy', MULTITENANCY)
  }
  
  const param  = {
    url: FIREBASE_URL + "/searchOrderId",
    body: body
  }
  return JPOST(param)
}