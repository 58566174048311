/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'

import { connect } from 'react-redux'

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json'

import { get_vm_status } from '../../store/dashboard/actions'

// Pages Components
import Earning from './earning'
//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb'
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        {
          title: 'Proyek Terdaftar',
          iconClass: 'bx-copy-alt',
          bgClass: 'bg-primary',
          description: '',
        },
        {
          title: 'Jumlah alert',
          iconClass: 'bx-copy-alt',
          bgClass: 'bg-warning',
          description: '',
        },
      ],
      chartSeries: [],
      periodType: 'yearly',
    }
  }

  componentDidMount() {
    this.props.GetVmStatus()
  }


  render() {
    const vm_status = safeDeepGet(this.props, ['vmstatusresult', 'body'], [])
    const vm_card = safeDeepGet(vm_status, ['card'], [])
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Smart Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Dashboards" breadcrumbItem="Overview" />
            <Row>
              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {this.state.reports.map((report, key) => (
                    <Col md="6" key={'_col_' + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{vm_card[key]}</h4>
                            </div>
                            <div
                              className={
                                'mini-stat-icon avatar-sm rounded-circle ' +
                                report.bgClass +
                                ' align-self-center'
                              }
                            >
                              <span className="avatar-title">
                                <i
                                  className={
                                    'bx ' + report.iconClass + ' font-size-24'
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>

                <Row className="clearfix">
                  {/* earning */}
                  <Earning />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  test:state,
  vmstatusresult: safeDeepGet(state, ['dashboardAdmin', 'vm_status'], {}),
  loading: safeDeepGet(state, ['dashboardAdmin', 'loading'], true),
})

const mapDispatchToProps = dispatch => ({
  GetVmStatus: () => dispatch(get_vm_status()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
