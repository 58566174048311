/* eslint-disable semi */
import {
    LOADING,
    RESULT,
    LIST,
    INIT_CREATE,
    CREATE,
    READ,
    UPDATE,
    DETAIL_CHAT,
    DELETE,
    STOCK_UPDATE,
    CANCEL,
    CHECK_PAYMENT,
    EDIT,
    LIST_SUBS,
    LIST_DEVICE_SUBS,
    UNTAG_DEVICE,
    EXTEND_SUBS
  } from "./actionTypes";
  
  export const loading = actionType => ({
    type: LOADING,
    payload: { actionType },
  });
  
  export const result = (actionType, result) => ({
    type: RESULT,
    payload: { actionType, result },
  });
  export const list = () => ({
    type: LIST,
  });
  export const list_subs = () => ({
    type: LIST_SUBS,
  });
  export const initCreate = () => ({
      type: INIT_CREATE,
    });
  export const create = payload => ({
    type: CREATE,
    payload,
  });
  export const list_device = key => ({
    type: LIST_DEVICE_SUBS,
    key,
  });
  export const checkPayment = payload => ({
    type: CHECK_PAYMENT,
    payload,
  });
  export const unTagDevice = payload => ({
    type: UNTAG_DEVICE,
    payload,
  });
  export const extendedSub = payload => ({
    type: EXTEND_SUBS,
    payload,
  });
  export const cancel_ = payload => ({
    type: CANCEL,
    payload,
  });
  export const detailChat = payload => ({
    type: DETAIL_CHAT,
    payload,
  });
  export const edit = payload => ({
    type: EDIT,
    payload,
  });
  export const read = sku => ({
    type: READ,
    payload: { sku },
  });
  
  export const update = payload => ({
    type: UPDATE,
    payload,
  });
  
  export const stockUpdate = payload => ({
    type: STOCK_UPDATE,
    payload,
  });
  
  export const delete_ = payload => ({
    type: DELETE,
    payload,
  });
  