import firebase from "firebase/compat/app"
export default class BusinessPoint {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('role');
  }

  list = email => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection.where('email', '==' , email).get().then(
        querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
                code = 0;
                map = { ...querySnapshot.docs[0].data() };
                localStorage.setItem("role", JSON.stringify(map))

            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  delete_ = uid => {
    this.collection.doc(uid).delete();
  };
  create = (id, payload) => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'code' ||
          key === 'email' ||
          key === 'role' 
        ) {
          insertedPayload[key] = payload[key];
        }
    }
      this.collection
      .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };


}