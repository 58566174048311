export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST_BUSINESS_POINTS = "TRANSACTION_ADMIN_LIST_BUSINESS_POINTS";
export const LIST = "TRANSACTION_ADMIN_LIST";
export const READ = "TRANSACTION_ADMIN_READ";
export const REFUND = "TRANSACTION_ADMIN_REFUND";
export const UPDATE_REFUND = "TRANSACTION_ADMIN_REFUND";
export const REFUNDED = "TRANSACTION_ADMIN_REFUNDED";
export const REFUND_LIST = "TRANSACTION_ADMIN_REFUND_LIST";
export const SNAPSHOT = "TRANSACTION_ADMIN_SNAPSHOT";
export const SUMMARY = "TRANSACTION_ADMIN_SUMMARY";
export const GET_SALES_ANALYTICS = "TRANSACTION_ADMIN_GET_SALES_ANALYTICS"
export const DETAIL = "TRANSACTION_ADMIN_DETAIL"