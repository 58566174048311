/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';
import { APPLICATION_ID } from 'Apps';
// Product state
import { Buffer } from 'buffer'

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  PAYOUT_LIST,
  ACCOUNT_LIST,
  PAYOUT_CREATE,
  DETAIL_ACCOUNT,
  GET_BALANCE,
  PAYOUT_REQUEST,
  PAYOUT_VALIDATE,
} from './actionTypes';
import {
  account_bank_list,
  bank_list,
  payout_list,
  getBalance,
  payoutValidate,
  payoutRequest
} from '../../webservice/device';
import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import Result from 'iotera-base/result';
let balanc = {};

function* get_Balance() {
  yield put(loading(GET_BALANCE));
  let wsResult = yield call(getBalance);
  if (safeDeepGet(wsResult, 'code') == 200) {
    balanc = safeDeepGet(wsResult, 'body', {});
    const firebaseInstance = getInstance();
    wsResult = yield call(firebaseInstance.balance.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const balanceBody = safeDeepGet(wsResult, ['body', 'data'], {});
      const balanaces = safeDeepGet(balanc, 'balance', 0)
      safeDeepSet(balanceBody,'balance', balanaces)
      yield put(
        result(GET_BALANCE, { code: Result.SUCCESS, body: balanceBody })
      );
      return;
    } else {
      yield put(result(PAYOUT_CREATE, { code: Result.FAILED }));
    }
  } else {
    yield put(result(PAYOUT_CREATE, { code: Result.FAILED }));
  }
}
function* payout_create(payload) {
  yield put(loading(PAYOUT_CREATE));
  const amount = safeDeepGet(payload, ['payload', 'amount']);
  const account_id = safeDeepGet(payload, ['payload', 'id']);
  const account_bank = safeDeepGet(payload, ['payload', 'bank']);
  const account_no = safeDeepGet(payload, ['payload', 'rekening']);
  const account_name = safeDeepGet(payload, ['payload', 'name']);
  const storage = localStorage.getItem('authUser');
  const jsonStorage = JSON.parse(storage);
  const authUser = safeDeepGet(jsonStorage, 'email', '-');
  const wsResult = yield call(
    create_payout,
    amount,
    account_id,
    account_bank,
    account_no,
    account_name,
    authUser
  );
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    yield put(result(PAYOUT_CREATE, { code: Result.SUCCESS }));
  } else if (safeDeepGet(wsResult, 'code') == 200) {
    yield put(result(PAYOUT_CREATE, { code: Result.SUCCESS }));
  } else {
    yield put(result(PAYOUT_CREATE, { result: { code: Result.FAILED } }));
  }
}

function create_payout(
  amount,
  account_id,
  account_bank,
  account_no,
  account_name,
  authUser
) {
  const body = {
    application_id: APPLICATION_ID,
    amount: Number(amount),
    account_id: account_id,
    account_no: account_no,
    account_name: account_name,
    account_bank: account_bank,
    auth_user: authUser,
  }
  const btoaBody =  Buffer.from(decodeURIComponent(encodeURIComponent(JSON.stringify(body)))).toString('base64');
  var result = JPOST({
    url: `https://asia-southeast2-iotera-nestle.cloudfunctions.net/payout`,
    headers: {
      Authorization: 'apikey ajkhjkhqkjhwjkehkqwe:khkkjqhjkwhjkq',
    },
    body: {
      data: btoaBody,
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}
// function* create_payout2(payload) {
//   const firebaseInstance = getInstance();
//   const amount = Number(safeDeepGet(payload, ['payload', 'amount']));
//   const timestamp = new Date().getTime();
//   const applicationId = APPLICATION_ID;
//   const accId =  safeDeepGet(payload, ['payload', 'id']);
//   const accName = safeDeepGet(payload, ['payload', 'name']);
//   const accBank = safeDeepGet(payload, ['payload', 'bank']);
//   const accNo = safeDeepGet(payload, ['payload', 'rekening']);

//   const now = Date.now();
//   const status = 'created';
//   const notes = 'PO' + timestamp;
//   const createdTs = timestamp - 5000; // biar masuk itungn saldo dikurangi 5 sec
//   // const createdFirestore = firestore.Timestamp.fromMillis(createdTs);
//   let transactionFee = 5000;
//   if (accBank == 'gopay') {
//     transactionFee = 1000;
//   }

//   if (amount < 10000) {
//     // return response.status(400).send('amount must more than 10.000');
//   }
//   const total = amount + transactionFee;
//   const bal = balanc;
//   const balance = bal.balance;
//   const balancePayout = bal.balance_payout;
//   const dataUpdate = {
//     balance: balance - total,
//     balance_payout: balancePayout - total,
//     commit_point: now,
//     flag_payout: false,
//   };
//   const data = {
//     dataUpdate,
//     createdTs,
//     notes,
//     transactionFee,
//     amount,
//     accId,
//     accName,
//     accNo,
//     accBank,
//     total,
//     status,
//   };
//   let wsResult = yield call(firebaseInstance.balance.payout, data);
//   const code = safeDeepGet(wsResult, 'code');
//   if (code == Result.SUCCESS) {
//     wsResult = yield call(firebaseInstance.payout.payout, data);
//     if (code == Result.SUCCESS) {
//       try {
//         const postBody = {
//           payouts: [
//             {
//               account_id: accId,
//               amount: amount,
//               notes: notes,
//             },
//           ],
//         };

//         const reqBody = JSON.stringify(postBody);
//         const headers = {
//           'Content-Type': 'application/json',
//           'Content-Length': reqBody.length,
//           'Iotera-Payment-Admin-Token':
//           '6bf868df24a4357dee20e6d3d6ef1d1944249ad44cb29687446d346f60fc0215',
//           'Iotera-Payment-Application-Id': APPLICATION_ID,
//         };
//         wsResult = yield call(createpayout, reqBody, headers, postBody);
//         if (safeDeepGet(wsResult, ['response', 'code']) == Result.SUCCESS) {
//           yield put(result(PAYOUT_CREATE, { code: Result.SUCCESS }));
//         } else if (safeDeepGet(wsResult, ['response', 'code']) == 200) {
//           yield put(result(PAYOUT_CREATE, { code: Result.SUCCESS }));
//         } else {
//           yield put(result(PAYOUT_CREATE, { result: { code: Result.FAILED } }));
//         }

//       } catch (error) {
//         yield put(result(PAYOUT_CREATE, { result: { code: Result.FAILED, message: error} }));
//       }
//     }
//   }
// }
// function createpayout(reqBody,headers, postBody) {
//   var result = JPOST({
//     url: `https://pay.iotera.io/web/payout/transaction/create`,
//     // mode: 'no-cors',
//     headers: headers,
//     body: postBody,
//    })
//     .then(function (response) {
//       return  {
//         response,
//       }
//     })
//   return result;
// }

function* payoutlist(payload) {
  yield put(loading(PAYOUT_LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.payout.list, payload);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const payoutBody = wsResult.body;
    const payoutMap = {};
    const payoutList = [];
    let i = 0
       for (const id in payoutBody) {
      safeDeepSet(payoutBody, [id, 'id'], i++);
      safeDeepSet(payoutBody, [id, 'payoutId'], id);
      payoutMap[i++] = safeDeepGet(payoutBody, id);
    }
    wsResult = yield call(bank_list);
    if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
      const bankBody = safeDeepGet(wsResult, ['body', 'banks']);
      const bankMap = {};
      for (const id in bankBody) {
        const code = bankBody[id].code;
        bankMap[code] = bankBody[id];
      }
      for (const id in payoutMap) {
        const codebank = safeDeepGet(payoutMap, [id, 'account', 'acc_bank']);
        const NameBank = safeDeepGet(bankMap, [codebank, 'name']);
        safeDeepSet(payoutMap, [id, 'bank'], NameBank);
        payoutList.push(payoutMap[id]);
      }
    }
    yield put(result(PAYOUT_LIST, { code: Result.SUCCESS, body: payoutList }));
    return;
  }
  yield put(result(PAYOUT_LIST, { result: { code: Result.FAILED } }));
}

function* detailAccount(payload) {
  const filterId = safeDeepGet(payload, 'payload');
  yield put(loading(DETAIL_ACCOUNT));
  let wsResult = yield call(account_bank_list);
  if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
    let accountMap = safeDeepGet(wsResult, ['body', 'data'], []);
    wsResult = yield call(bank_list);
    const accountList = [];
    if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
      const bankBody = safeDeepGet(wsResult, ['body', 'banks']);
      const bankMap = {};
           for (const id in bankBody) {
        const code = bankBody[id].code;
        bankMap[code] = bankBody[id];
      }
      for (const id in accountMap) {
        const accountid = accountMap[id].account_id;
        const jsonBank = accountMap[id];
        const codebank = jsonBank.account_bank;
        const NameBank = safeDeepGet(bankMap, [codebank, 'name']);
        safeDeepSet(jsonBank, ['bank'], NameBank);
        if (accountid == filterId) {
          accountList.push(accountMap[id]);
        }
      }
    }
    yield put(
      result(DETAIL_ACCOUNT, { code: Result.SUCCESS, body: accountList })
    );
    return;
  }
  yield put(result(DETAIL_ACCOUNT, { result: { code: Result.FAILED } }));
}
function* list() {
  yield put(loading(LIST));
  let wsResult = yield call(account_bank_list);
  if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
    let accountMap = safeDeepGet(wsResult, ['body', 'data'], []);
    wsResult = yield call(bank_list);
    const accountList = [];
    if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
      const bankBody = safeDeepGet(wsResult, ['body', 'banks']);
      const bankMap = {};
           for (const id in bankBody) {
        const code = bankBody[id].code;
        bankMap[code] = bankBody[id];
      }
      for (const id in accountMap) {
        const jsonBank = accountMap[id];
        const codebank = jsonBank.account_bank;
        const NameBank = safeDeepGet(bankMap, [codebank, 'name']);
        safeDeepSet(jsonBank, ['bank'], NameBank);
        accountList.push(accountMap[id]);
      }
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: accountList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* initCreate() {
  yield put(loading(INIT_CREATE));
  const wsResult = yield call(bank_list);
  const bankBody = safeDeepGet(wsResult, ['body', 'banks']);
  const bankFilterList = [{ label: 'All Bank', value: 'all' }];
  for (const i in bankBody) {
    const value = bankBody[i].code;
    const label = bankBody[i].name;
    bankFilterList.push({ label: label, value: value });
  }
  yield put(
    result(INIT_CREATE, { code: Result.SUCCESS, body: bankFilterList })
  );
}

function* accountList() {
  yield put(loading(ACCOUNT_LIST));
  const wsResult = yield call(account_bank_list);
  const accountBody = safeDeepGet(wsResult, ['body', 'data']);
  const accountFillterList = [{ label: 'All Account', value: 'all' }];
  for (const i in accountBody) {
    const value = accountBody[i].account_id;
    const label = accountBody[i].account_name;
    accountFillterList.push({ label: label, value: value });
  }
  yield put(
    result(ACCOUNT_LIST, { code: Result.SUCCESS, body: accountFillterList })
  );
}

function createAccount(accountNo, name, email, selectedBank) {
  const body = {
      account_name: name,
      account_no: accountNo,
      account_bank: selectedBank,
      account_email: email,
  }
  const btoaBody = Buffer.from(decodeURIComponent(encodeURIComponent(JSON.stringify(body)))).toString('base64');
  var result = JPOST({
    url: 'https://serverless-api.iotera.io/api/' + APPLICATION_ID + '/account-create',
    body: {
    data: btoaBody
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}

function* create(action) {
  yield put(loading(CREATE));
  const accountNo = safeDeepGet(action, ['payload', 'accountNo']);
  const email = safeDeepGet(action, ['payload', 'email']);
  const name = safeDeepGet(action, ['payload', 'name']);
  const selectedBank = safeDeepGet(action, ['payload', 'selectedBank']);
  const wsResult = yield call(
    createAccount,
    accountNo,
    name,
    email,
    selectedBank
  );
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const code = safeDeepGet(wsResult, 'code');
    yield put(result(CREATE, { code: Result.SUCCESS }));
  } else if (safeDeepGet(wsResult, 'code') == 200) {
    const code = safeDeepGet(wsResult, 'code');
    yield put(result(CREATE, { code: Result.SUCCESS }));
  } else {
    yield put(result(CREATE, { code: Result.FAILED }));
  }
}

function* read(action) {
  yield put(loading(READ));
  const sku = safeDeepGet(action, ['payload', 'sku']);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.read, sku);
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function updateAccount(id, accountNo, name, email, selectedBank) {
  const body = {
    account_id: id,
      account_name: name,
      account_no: accountNo,
      account_bank: selectedBank,
      account_email: email,
  }
  const btoaBody = Buffer.from(decodeURIComponent(encodeURIComponent(JSON.stringify(body)))).toString('base64');
  var result = JPOST({
    url: 'https://serverless-api.iotera.io/api/' + APPLICATION_ID + '/account-update',
    body: {
      data: btoaBody
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}

function* update(action) {
  yield put(loading(UPDATE));
  const id = safeDeepGet(action, ['payload', 'id']);
  const accountNo = safeDeepGet(action, ['payload', 'rekening']);
  const email = safeDeepGet(action, ['payload', 'email']);
  const name = safeDeepGet(action, ['payload', 'name']);
  const selectedBank = safeDeepGet(action, ['payload', 'bank']);
  const wsResult = yield call(
    updateAccount,
    id,
    accountNo,
    name,
    email,
    selectedBank
  );
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS) {
    yield put(result(UPDATE, { code: Result.SUCCESS }));
  } else if (code == 200) {
    yield put(result(UPDATE, { code: Result.SUCCESS }));
  } else {
    yield put(result(UPDATE, { code: Result.FAILED }));
  }
}

function* stockUpdate(action) {
  yield put(loading(STOCK_UPDATE));
  const sku = safeDeepGet(action, ['payload', 'sku']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile']);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt']);
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url']);
    }
  }
  if (imageUrl != null) {
    payload['img_url'] = imageUrl;
  }

  const wsResult = yield call(
    firebaseInstance.product.updateStock,
    sku,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(STOCK_UPDATE, { code }));
}

function deleteAccount(account_id) {
  var result = JPOST({
    url: 'https://serverless-api.iotera.io/api/' + APPLICATION_ID + '/account-remove',
    queryParams: {
      account_id: btoa(account_id),
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}
function* delete_(payload) {
  yield put(loading(DELETE));
  const account_id = safeDeepGet(payload, ['payload', 'account_id'], '');
  const wsResult = yield call(deleteAccount, account_id);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* payout_validate(payload) {
  yield put(loading(PAYOUT_VALIDATE))
  const otp = safeDeepGet(payload, ['payload', 'otp'], {})
  const wsResult = yield call(payoutValidate, otp)
  const code = safeDeepGet(wsResult, 'code', Result.UNKNOWN_ERROR)
  if (code == Result.HTTP.OK) {
    yield put(result(PAYOUT_VALIDATE, { code: Result.SUCCESS }))
    return
  } else {
    yield put(result(PAYOUT_VALIDATE, { code: Result.FAILED}))
  }
}
function* payout_request(payload) {
  yield put(loading(PAYOUT_REQUEST))
  const data = safeDeepGet(payload, 'payload', {})
  const wsResult = yield call(payoutRequest, data)
  const code = safeDeepGet(wsResult, 'code', Result.UNKNOWN_ERROR)
  if (code == Result.HTTP.OK) {
    yield put(result(PAYOUT_REQUEST, { code: Result.SUCCESS }))
    return
  } else {
    yield put(result(PAYOUT_REQUEST, { code: Result.FAILED}))
  }
}
function* payoutSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(PAYOUT_LIST, payoutlist);
  yield takeEvery(ACCOUNT_LIST, accountList);
  yield takeEvery(PAYOUT_CREATE, payout_create);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(STOCK_UPDATE, stockUpdate);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(DETAIL_ACCOUNT, detailAccount);
  yield takeEvery(GET_BALANCE, get_Balance);
  yield takeEvery(PAYOUT_VALIDATE, payout_validate)
  yield takeEvery(PAYOUT_REQUEST, payout_request)
}

export default payoutSaga;
