export const LOADING = 'LOADING';
export const RESULT = 'RESULT';

export const LIST = 'PRODUCT_LIST';
export const INIT_CREATE = 'PRODUCT_INIT_CREATE';
export const DISCOUNT_PRODUCT = 'PRODUCT_DISCOUNT_PRODUCT';
export const DELETE_DISCOUNT = 'PRODUCT_DELETE_DISCOUNT';
export const CREATE = 'PRODUCT_CREATE';
export const READ = 'PRODUCT_INFO';
export const UPDATE = 'PRODUCT_UPDATE';
export const STOCK_UPDATE = 'STOCK_PRODUCT_UPDATE';
export const DELETE = 'PRODUCT_DELETE';
export const LIST_INGREDIENT = 'LIST_INGREDIENT';
export const INPUT_CSV = "PRODUCT_INPUT_CSV";