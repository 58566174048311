import { call, put, takeEvery } from "redux-saga/effects";

import { LIST, INIT_CREATE, CREATE, READ, UPDATE, DELETE, STOCK_UPDATE, LIST_OPTIONS } from "./actionTypes";

import { loading, result } from "./actions";
import { getInstance } from "../../firebase/helper";
import { safeDeepGet, safeDeepSet } from "iotera-base/utility/json";
import Result from "iotera-base/result";
import {createdUser, deletedUser} from '../../webservice/device'
function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.distributor.list);
  if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
    const distributorMap = wsResult.body;
    const distributorList = [];
    let i = 0;
    for (const sku in distributorMap) {
      distributorMap[sku].id = sku;
      safeDeepSet(distributorMap, [sku, "id"], i++);
      distributorList.push(distributorMap[sku]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: distributorList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }));
}

function* list_distributor() {
  yield put(loading(LIST_OPTIONS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.distributor.list);
  if (safeDeepGet(wsResult, "code") == Result.SUCCESS) {
    const listMap = wsResult.body;
    const distributorList = [];
      for (const id in listMap) {
      let distributorName = safeDeepGet(listMap, [id, "name"]);
      let distributorId = safeDeepGet(listMap, [id, "code"]);
      distributorList.push({ label: distributorName, value: distributorId });
      }
    yield put(
      result(LIST_OPTIONS, { code: Result.SUCCESS, body: distributorList })
    );
    return;
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }));
}

function* create(action) {
  yield put(loading(CREATE));
  const payload = safeDeepGet(action, "payload");
  const id = safeDeepGet(action, ["payload", "id"]);
  const email = safeDeepGet(action, ["payload", "email"]);
  const pass = safeDeepGet(action, ["payload", "pass"]);
  const firebaseInstance = getInstance();

  const wsResult = yield call(createdUser, email, pass);

  const code = safeDeepGet(wsResult, 'code');
  const body = safeDeepGet(wsResult, 'body');
  if (code === Result.SUCCESS || code === Result.HTTP.OK) {
    const uid = safeDeepGet(wsResult, ['body','uid'], '')

    // Add to Role
    yield call(firebaseInstance.role.create, id, payload)

    // Add to Distributor
    yield call(firebaseInstance.distributor.create, id,  payload, uid);
  }
  yield put(result(CREATE, { code, body }));
}

function* read(action) {
  yield put(loading(READ));
  const sku = safeDeepGet(action, ["payload", "sku"]);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.read, sku);
  if (safeDeepGet(wsResult, "code") !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function* update(action) {
  yield put(loading(UPDATE));
  const sku = safeDeepGet(action, ["payload", "id"]);
  const payload = safeDeepGet(action, "payload");
  const firebaseInstance = getInstance();

  const wsResult = yield call(firebaseInstance.distributor.update, sku, payload);
  const code = safeDeepGet(wsResult, "code");

  yield put(result(UPDATE, { code }));
}



function* delete_(payload) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  const userId = safeDeepGet(payload, ['payload', 'id'], '');
  const userUid = safeDeepGet(payload, ['payload', 'uid'], '');
  let wsResult = yield call(deletedUser, userUid);
  const code = safeDeepGet(wsResult, 'code')
  if(code == Result.SUCCESS || code == Result.HTTP.OK) {
    wsResult = yield call(firebaseInstance.distributor.delete_, userId);
    const code = safeDeepGet(wsResult, 'code');
    yield put(result(DELETE, { code: code }));
  } else {
    yield put(result(DELETE, { code: Result.FAILED }));

  }
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_OPTIONS, list_distributor);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(DELETE, delete_);
}

export default productSaga;
