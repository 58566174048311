/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, delete_ } from '../../store/product/actions';
import Pages403 from 'Base/pages/pages-403';
import{ NAVBAR, PERMISION, buildTitle } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
class ProductList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      productListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'img_url',
          text: '',
          formatter: (cellContent, row) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img className="avatar-md" src={row.img_url} />
                </div>
              </div>
            </>
          ),
        },
        {
          dataField: 'sku',
          text: 'SKU',
          sort: true,
          formatter: (cellContent, row) => <>{row.sku}</>,
        },
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          text: 'Category',
          dataField: 'category',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="font-size-14 mb-1">
                  {row.category}
              </p>
            </>
          ),
        },
        {
          dataField: 'desc',
          text: 'Description',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.desc}</p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, product) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleEditClick(product)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.handleDeleteClick(product.sku)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleAddCategory = this.handleAddCategory.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Product';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleDeleteClick = row => {
    this.props.DeleteProduct(row);
    this.props.LoadProductList();
    this.showToast();
  };

  componentDidMount() {
    if (safeDeepGet(navbar, ['Product', 'edit'], 'off') == 'off') {
      this.state.productListColumns.pop();
    }
    // Load Product
    this.props.LoadProductList();
  }

  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/product/create');
  };
  
  handleAddCategory = arg => {
    this.props.history.push('/category/list');
  };

  handleEditClick = row => {
    this.props.history.push(
      `/product/edit/${safeDeepGet(row, 'sku', 'default')}`
    );
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    // const code = safeDeepGet(
    //   this.props,
    //   ['result', 'code'],
    //   Result.UNKNOWN_ERROR
    // );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc', // desc or asc
      },
    ];

    // const selectRow = {
    //   mode: 'checkbox',
    // };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle("Product List")}</title>
          </MetaTags>
          {!permision || safeDeepGet(navbar, ['Product', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Product" breadcrumbItem="List" />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={this.state.productListColumns}
                        data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={this.state.productListColumns}
                            data={list}
                            search
                          >
                            {toolkitprops => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitprops.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    {safeDeepGet(
                                      navbar,
                                      ['Product', 'edit'],
                                      'off'
                                    ) == 'off' ? null : (
                                      <div className="text-sm-end">
                                        <Button
                                          color="primary"
                                          className="font-16 btn-block btn btn-primary"
                                          onClick={this.handleAddCategory}
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Category List
                                        </Button>
                                      </div>
                                    )}
                                  </Col>
                                  <Col sm="2">
                                    {safeDeepGet(
                                      navbar,
                                      ['Product', 'edit'],
                                      'off'
                                    ) == 'off' ? null : (
                                      <div className="text-sm-end">
                                        <Button
                                          color="primary"
                                          className="font-16 btn-block btn btn-primary"
                                          onClick={this.handleAddClick}
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Create New Product
                                        </Button>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                         
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          'table align-middle table-nowrap table-hover'
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={this.node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['product', 'list'], {}),
  loading: safeDeepGet(state, ['product', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  LoadProductList: () => dispatch(list()),
  DeleteProduct: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
