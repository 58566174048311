/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import Leaflet from 'leaflet'
import { Map, Marker, TileLayer, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

// Iotera
import Result from 'iotera-base/result'
import { safeDeepSet, safeDeepGet } from 'iotera-base/utility/json'

import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import markerblue from '../../assets/images/markerblue.png'
import markergreen from '../../assets/images/markergreen.png'
let DefaultIcon = Leaflet.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
})
Leaflet.Marker.prototype.options.icon = DefaultIcon

const DashboardMap = props => {
  const { vm_maps } = props
  const arr_vm_maps = Array.from(vm_maps)
  //  Create the Icon
  const LeafIcon = Leaflet.Icon.extend({
    options: {},
  })
  
  const blueIcon = new LeafIcon({
      iconUrl: markerblue,
    }),
    greenIcon = new LeafIcon({
      iconUrl: markergreen,
    })
  if (arr_vm_maps.length > 0) {
    let arr_bounds = []
    let arr_icons = []
    for (const i in arr_vm_maps) {
      arr_bounds.push([arr_vm_maps[i].loc._lat, arr_vm_maps[i].loc._long])
      if (safeDeepGet(arr_vm_maps[i], 'online')) {
        safeDeepSet(arr_vm_maps[i], 'vm_icon', greenIcon)
        arr_icons.push(greenIcon)
      } else {
        safeDeepSet(arr_vm_maps[i], 'vm_icon', blueIcon)
        arr_icons.push(blueIcon)
      }
    }
    const bounds = Leaflet.latLngBounds(arr_bounds)
    return (
      <React.Fragment>
        <Map
          center={arr_bounds[0]}
          bounds={bounds}
          boundsOptions={{ padding: [50, 50] }}
          zoom={13}
          scrollWheelZoom={false}
          style={{ height: '300px' }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {arr_vm_maps.length - 1
            ? arr_vm_maps.map((marker, key) => (
                <Marker
                  key={marker.id}
                  position={[marker.loc._lat, marker.loc._long]}
                  icon={arr_icons[key]}
                >
                  <Popup>{marker.name}</Popup>
                </Marker>
              ))
            : arr_vm_maps.map((marker, key) => (
                <Marker
                  key={marker.id}
                  position={[marker.loc._lat, marker.loc._long]}
                  icon={arr_icons[key]}
                >
                  <Popup>{marker.name}</Popup>
                </Marker>
              ))}
        </Map>
      </React.Fragment>
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}

export default DashboardMap
