import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import { connect } from "react-redux";

// Iotera
import { safeDeepGet } from "iotera-base/utility/json";

import {
  get_vm_status,
} from "../../store/dashboard/actions";
// Pages Components
import Earning from "./earning";
import DashboardMap from "./dashboardmap";

//Import Breadcrumb
import Breadcrumbs from "../../../Base/components/Common/Breadcrumb";
// import favicon from '../../Base/assets/favicon.ico';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [
        {
          title: "VM Terdaftar",
          iconClass: "bx-copy-alt",
          bgClass: "bg-primary",
          description: "",
        },
        {
          title: "VM Nyala",
          iconClass: "bx-archive-in",
          bgClass: "bg-success",
          description: "",
        },
        {
          title: "VM Mati",
          iconClass: "bx-purchase-tag-alt",
          bgClass: "bg-secondary",
          description: "",
        },
        {
          title: "Jumlah alert",
          iconClass: "bx-copy-alt",
          bgClass: "bg-warning",
          description: "",
        },
      ],
      chartSeries: [],
      periodType: "yearly",
    };
  }

  componentDidMount() {
    this.props.GetVmStatus();
  }

  componentDidUpdate(prevProps) {
    // if (prevProps !== this.props) {
    //   this.setState({ ...this.state, chartSeries: this.props.chartsData });
    // }
  }

  render() {
    const vm_status = safeDeepGet(
      this.props,
      ["vmstatusresult", "body"],
      []
    );
    const vm_card = safeDeepGet(vm_status,["card"],[]);
    const vm_map = safeDeepGet(vm_status,["map"],[]);

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Nestle Milano Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Dashboards"
              breadcrumbItem="Overview (Milano)"
            />
            <Row>
              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {this.state.reports.map((report, key) => (
                    <Col md="3" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{vm_card[key]}</h4>
                            </div>
                            <div className={"mini-stat-icon avatar-sm rounded-circle "+ report.bgClass +" align-self-center"}>
                              <span className="avatar-title-nestle">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>

                <Row className="clearfix">
                  {/* earning */}
                  <Earning />
                  {/* map */}
                  <Col xl="5" className="d-flex">
                    <Card className="flex-fill">
                      <CardBody>
                        <h4 className="card-title mb-4">Peta VM</h4>
                        <p>Biru = VM mati, hijau = VM nyala</p>
                        <div id="leaflet-map" className="leaflet-map">
                          <DashboardMap vm_maps={vm_map}/>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  vmstatusresult: safeDeepGet(state, ["dashboard", "vm_status"], {}),
  loading: safeDeepGet(state, ["dashboard", "loading"], true),
});

const mapDispatchToProps = dispatch => ({
  GetVmStatus: () => dispatch(get_vm_status()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);