/* eslint-disable react/prop-types */
import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
// Iotera
import { safeDeepGet } from 'iotera-base/utility/json'
import { APPLICATION_ID } from 'Apps'
import ReactApexChart from 'react-apexcharts'
 

const Earning = props => {
  const { data } = props
  const options = {
    chart: {
      toolbar: 'false',
      dropShadow: {
        enabled: !0,
        color: '#000',
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    xaxis: {
      tickAmount: 3,
    },
    dataLabels: {
      enabled: !1,
    },
    colors: ['#556ee6'],
    stroke: {
      curve: 'smooth',
      width: 3,
    },
  }

  const card_values = data.card_values
  const series = [
    {
      name: 'Revenue',
      data: data.chart_data,
    },
  ]

  let card_change = safeDeepGet(card_values, ['card_change'], 0).toFixed(2)
  let card_sum_current = safeDeepGet(card_values, ['card_sum_current'], 0)
  let card_sum_prev = safeDeepGet(card_values, ['card_sum_prev'], 0)
  let badge_change = 'badge-soft-danger'
  if (card_change >= 0) {
    badge_change = 'badge-soft-success'
  }
  // Card Current
  const stringNumber = card_sum_current.toFixed(2).toString()
  // var number_string = stringNumber.replace(/[^,\d]/g, '').toString()
  // console.log(number_string)
  var split = stringNumber.split('.')
  var sisa = split[0].length % 3
  let rupiah = split[0].substr(0, sisa)
  var ribuan = split[0].substr(sisa).match(/\d{3}/gi)

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    var separator = sisa ? '.' : ''
    rupiah += separator + ribuan.join('.')
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah
  // Card Prev
  const numberPrev = card_sum_prev.toFixed(2).toString()
  // var number_string_prev = numberPrev.replace(/[^,\d]/g, '').toString()
  var split_prev = numberPrev.split('.')
  var sisa_prev = split_prev[0].length % 3
  let rupiah_prev = split_prev[0].substr(0, sisa_prev)
  var ribuan_prev = split_prev[0].substr(sisa_prev).match(/\d{3}/gi)

  // tambahkan titik jika yang di input sudah menjadi angka ribuan_prev
  if (ribuan_prev) {
    var separator_prev = sisa_prev ? '.' : ''
    rupiah_prev += separator_prev + ribuan_prev.join('.')
  }

  rupiah_prev =
    split_prev[1] != undefined
      ? rupiah_prev + ',' + split_prev[1]
      : rupiah_prev

  return (
    <React.Fragment>
      <Col xl="12" className="d-flex align-self-stretch">
        <Card className="flex-fill">
          <CardBody>
            <h4 className="card-title mb-4">Nett Settlement</h4>
            <Row>
              <Col lg="4">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>Bulan ini</p>
                    <h4>Rp {rupiah}</h4>
                    <div>
                      <span
                        className={`badge ${badge_change} font-size-12 me-1`}
                      >
                        {' '}
                        {card_change}%{' '}
                      </span>{' '}
                      dari bulan lalu
                    </div>
                  </div>
                  {APPLICATION_ID == '1000000159' ? (
                    <div>
                      <Link to="#" className="btn btn-nestle btn-sm white">
                        View Details{' '}
                        <i className="mdi mdi-chevron-right ms-1"></i>
                      </Link>
                    </div>
                  ) : APPLICATION_ID == '1000000211' ? (
                    <div>
                      <Link to="#" className="btn btn-franke white btn-sm">
                        View Details{' '}
                        <i className="mdi mdi-chevron-right ms-1"></i>
                      </Link>
                    </div>
                  ) : APPLICATION_ID == '1000000232' ? (
                    <div>
                      <Link to="#" className="btn btn-hamparan white btn-sm">
                        View Details{' '}
                        <i className="mdi mdi-chevron-right ms-1"></i>
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <Link to="#" className="btn btn-primary btn-sm">
                        View Details{' '}
                        <i className="mdi mdi-chevron-right ms-1"></i>
                      </Link>
                    </div>
                  )}

                  <div className="mt-4">
                    <p className="mb-2">Bulan lalu</p>
                    <h5>Rp {rupiah_prev}</h5>
                  </div>
                </div>
              </Col>

              <Col lg="8">
                <div id="line-chart" className="apex-charts" dir="ltr">
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Earning
