/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Label,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, delete_, create } from '../../store/User/actions';

class UserList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const appId = safeDeepGet(this.props.match.params, 'appId');

    this.state = {
      modal: false,
      refiller: [],
      appId,
      UserListColumns: [
        {
          text: 'uid',
          dataField: 'uid',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.uid}</>,
        },
        {
          text: 'Email',
          dataField: 'email',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.email}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'creationTime',
          text: 'Created',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">
                {this.handleLocalDate(
                  safeDeepGet(row, ['metadata', 'creationTime'], '-')
                )}
              </p>
            </>
          ),
        },
        {
          dataField: 'lastSignInTime',
          text: 'Last Sign In',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">
                {this.handleLocalDate(
                  safeDeepGet(row, ['metadata', 'lastSignInTime'], '-')
                )}
              </p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, data) => (
            <div className="d-flex gap-3">
              {/* <Tippy content={'Edit'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleEditClick(data)}
                  ></i>
                </Link>
              </Tippy> */}
              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => this.handleDeleteClick(data)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  handleLocalDate(date) {
    let localDate = '';
    if (date == '-' || date == '' || date == null || date == undefined) {
      localDate = date;
    } else {
      var myDate = new Date(date);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const date1 = myDate.toLocaleDateString('en-GB', options);
      const time = myDate.toLocaleTimeString('en-GB');
      localDate = date1 + ' ' + time;
    }
    return localDate;
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    // Load business point
    this.props.LoadUserList(this.state.appId);
  }
  /* Handle click */
  handleAddClick = arg => {
    this.toggle();
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast();
        this.toggle();
        this.props.LoadUserList(this.state.appId);
        // setTimeout(window.location.reload(), 4000);
      }
    }
    if (this.props.deleteResult !== prevProps.deleteResult) {
      const code = safeDeepGet(
        this.props,
        ['deleteResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToastDelete();
        setTimeout(this.props.LoadUserList(this.state.appId), 2000);
        // setTimeout(window.location.reload(), 4000);
      }
    }
  }
  handleValidSubmit(event, values) {
    this.props.CreateUser(values, this.state.appId);
  }
  handleDeleteClick = row => {
    this.props.DeleteUser(row, this.state.appId);

    // setTimeout(this.props.LoadUserList(this.state.appId), 3000);
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Daftar User';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastDelete() {
    var toastType = 'success';
    var message = 'Berhasil Delete User';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'email', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>User List | Admin </title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="User" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.UserListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.UserListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New User
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {'Create A new User'}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <Row form>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label htmlFor="email">Email : </Label>
                                <AvField
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="Masukan Email..."
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                              <div className="mb-3">
                                <Label htmlFor="password">Password : </Label>
                                <AvField
                                  type="password"
                                  id="password"
                                  name="password"
                                  placeholder="Masukan password..."
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Submit
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['User', 'list'], {}),
  createResult: safeDeepGet(state, ['User', 'create'], {}),
  loading: safeDeepGet(state, ['User', 'loading'], true),
  updateResult: safeDeepGet(state, ['User', 'update'], {}),
  deleteResult: safeDeepGet(state, ['User', 'delete'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadUserList: appId => dispatch(list(appId)),
  DeleteUser: (payload, appId) => dispatch(delete_(payload, appId)),
  CreateUser: (payload, appId) => dispatch(create(payload, appId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
