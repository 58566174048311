export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "RFID_LIST";
export const LIST_VM = "RFID_LIST_VM";
export const INIT_CREATE = "RFID_INIT_CREATE";
export const CREATE = "RFID_CREATE";
export const READ = "RFID_INFO";
export const UPDATE = "RFID_UPDATE";
export const STOCK_UPDATE = "STOCK_RFID_UPDATE";
export const DELETE = "RFID_DELETE";
