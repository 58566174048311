/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  InputGroup,
  Label,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import Select from 'react-select';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import {
  list,
  delete_,
  create,
  cancel_,
  list_product,
  list_business_points,
} from '../../store/discount/actions';
 
import Pages403 from 'Base/pages/pages-403';
import{ NAVBAR, PERMISION, buildTitle } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
class DiscountList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const appId = safeDeepGet(this.props.match.params, 'appId');
    this.refDatePicker = React.createRef();

    this.state = {
      modal: false,
      refiller: [],
      appId,
      DiscountListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          text: 'Business Point',
          dataField: 'bpName',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.bpName}
                </Link>
              </h5>
            </>
          ),
        },
        {
          text: 'Product Discount',
          dataField: 'productName',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.productName}
                </Link>
              </h5>
            </>
          ),
        },
        {
          text: 'Discount',
          dataField: 'discount',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.discount}
                </Link>
              </h5>
            </>
          ),
        },

        {
          dataField: 'created_ts',
          text: 'Created',
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                {this.handleLocalDate(safeDeepGet(row, 'created_ts', '-'))}
              </h5>
            </>
          ),
        },
        {
          dataField: 'expired_ts',
          text: 'Expired',
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                {this.handleLocalDate(safeDeepGet(row, 'expired_ts', '-'))}
              </h5>
            </>
          ),
        },
        {
          dataField: 'active',
          text: 'Active',
          formatter: (cellContent, row) => (
            <>
              <button
                type="button"
                style={{ borderRadius: '23px', fontSize: '12' }}
                className={
                  'btn  btn-sm btn-' + (row.active ? 'success' : 'danger')
                }
              >
                {row.active ? 'Active' : 'Cancelled'}
              </button>
            </>
          ),
        },

        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, data) => (
            <div className="d-flex gap-3">
              {data.active ? (
                <Tippy content={'Cancel'}>
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-window-close font-size-18"
                      id="cancletoolTip"
                      onClick={() => this.handleCancelClick(data)}
                    ></i>
                  </Link>
                </Tippy>
              ) : (
                <Link to="#">
                  <i
                    style={{ color: 'transparent' }}
                    className="mdi mdi-window-close font-size-18"
                    id="cancletoolTip"
                    // onClick={() => this.handleCancelClick(data)}
                  ></i>
                </Link>
              )}

              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => this.handleDeleteClick(data)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleBpChange = this.handleBpChange.bind(this);
  }

  handleLocalDate(date) {
    let localDate = '';
    if (date == '-' || date == '' || date == null || date == undefined) {
      localDate = date;
    } else {
      var myDate = new Date(date);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const date1 = myDate.toLocaleDateString('en-GB', options);
      const time = myDate.toLocaleTimeString('en-GB');
      localDate = date1 + ' ' + time;
    }
    return localDate;
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    this.props.LoadBusinessPointList();
    this.props.LoadDiscountList();
    this.props.LoadProductList();
    if (safeDeepGet(navbar, ['Product', 'edit'], 'off') == 'off') {
      this.state.DiscountListColumns.pop();
    }
  }
  /* Handle click */
  handleAddClick = arg => {
    this.toggle();
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast();
        this.toggle();
        this.props.LoadDiscountList(this.state.appId);
      }
    }
    if (this.props.deleteResult !== prevProps.deleteResult) {
      const code = safeDeepGet(
        this.props,
        ['deleteResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToastDelete();
        this.props.LoadDiscountList();
      }
    }
    if (this.props.cancelResult !== prevProps.cancelResult) {
      const code = safeDeepGet(
        this.props,
        ['cancelResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToastCancel();

        this.props.LoadDiscountList();
        // setTimeout(window.location.reload(), 4000);
      }
    }
  }
  handleValidSubmit(event, values) {
    const { exp_ts, product, bp } = this.state;
    const submittedValues = { ...values, exp_ts: exp_ts, product, bp };
    this.props.CreateDiscount(submittedValues);
  }
  handleCancelClick(row) {
    const submittedValues = { ...row };
    this.props.CancelDiscount(submittedValues);
  }
  handleDeleteClick = row => {
    const submittedValues = { ...row };
    this.props.DeleteDiscount(submittedValues);
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Membuat Diskon';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastCancel() {
    var toastType = 'success';
    var message = 'Berhasil Membatalkan Diskon';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastDelete() {
    var toastType = 'success';
    var message = 'Berhasil Menghapus Diskon';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handleDateRange = date => {
    const ts = date[0].getTime();
    this.setState({ date, exp_ts: ts });
  };
  handleProductChange = value => {
    this.setState({ product: value.value });
  };
  handleBpChange = value => {
    this.setState({ bp: value.value });
  };
  render() {
    // TODO Loading
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;
    const productOptions = safeDeepGet(
      this.props,
      ['resultProduct', 'body'],
      []
    );
    const BpOptions = safeDeepGet(
      this.props,
      ['resultBusinessPoint', 'body'],
      []
    );
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'expired_ts', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };

    const { date } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle("Discount List")}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Discount" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.DiscountListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.DiscountListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  {safeDeepGet(
                                    navbar,
                                    ['Product', 'edit'],
                                    'off'
                                  ) == 'off' ? null : (
                                    <div className="text-sm-end">
                                      <Button
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={this.handleAddClick}
                                      >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Create New Discount
                                      </Button>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {'Create A new Discount'}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <Row>
                            <Col sm={6}>
                              <Label htmlFor="bp">Business Point: </Label>
                              <Select
                                className="mb-3"
                                options={BpOptions}
                                onChange={this.handleBpChange}
                              ></Select>
                            </Col>
                            <Col sm={6}>
                              <Label htmlFor="product">Product: </Label>
                              <Select
                                className="mb-3"
                                options={productOptions}
                                onChange={this.handleProductChange}
                              ></Select>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label htmlFor="discount">Discount : </Label>
                                <AvField
                                  type="number"
                                  id="discount"
                                  name="discount"
                                  placeholder="Masukan Discount..."
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={6}>
                              <Label htmlFor="expired">Expired : </Label>
                              <InputGroup className="mb-3">
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Date"
                                  options={{
                                    mode: 'single',
                                    // dateFormat: 'Y-m-d',
                                    minDate: 'today',
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }}
                                  value={date}
                                  onChange={this.handleDateRange}
                                  ref={this.refDatePicker}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Submit
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['discount', 'list'], {}),
  resultProduct: safeDeepGet(state, ['discount', 'list_product'], {}),
  resultBusinessPoint: safeDeepGet(
    state,
    ['discount', 'list_business_points'],
    {}
  ),
  createResult: safeDeepGet(state, ['discount', 'create'], {}),
  loading: safeDeepGet(state, ['discount', 'loading'], true),
  updateResult: safeDeepGet(state, ['discount', 'update'], {}),
  deleteResult: safeDeepGet(state, ['discount', 'delete'], {}),
  cancelResult: safeDeepGet(state, ['discount', 'cancel_'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  LoadDiscountList: () => dispatch(list()),
  LoadProductList: () => dispatch(list_product()),
  LoadBusinessPointList: () => dispatch(list_business_points()),
  DeleteDiscount: payload => dispatch(delete_(payload)),
  CreateDiscount: payload => dispatch(create(payload)),
  CancelDiscount: payload => dispatch(cancel_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountList);
