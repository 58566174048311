/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import ReactExport from 'react-data-export';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
// Strap
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  CardTitle,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
// Router
// Table
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
import { create, list, delete_ } from '../../store/tags/actions';


class BusinessPoint extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const appId = safeDeepGet(this.props.match.params, 'appId');

    this.state = {
      appId,
      type: { label: 'Share revenue', value: 'share_revenue' },
      role: '',
      modalEdit: false,
      modalDate: false,
      success_dlg: false,
      error_dlg: false,
      subscription: false,
      rfid: false,
      dynamic_title: '',
      device_id: '',
      trx: '',
      dynamic_description: '',
      confirm_alert: false,
      Columns: [
        {
          text: 'ID Tags',
          dataField: 'id',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p style={{ fontWeight: 'bold' }} className="mb-1">
                {row.id}
              </p>
            </>
          ),
        },
        {
          dataField: 'name',
          text: 'Name Tags',
          sort: true,
          //   headerAlign: (column, colIndex) => 'center',
          // align: 'center',
          formatter: (cellContent, row) => (
            <>
              <p style={{ fontWeight: 'bold' }} className="mb-1">
                {row.name}
              </p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, row) => (
            <>
              <div className="d-flex gap-2">
                <Tippy content={'Delete'}>
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      // onClick={() => this.handleDeleteClick(row.device_id)}
                      onClick={() =>
                        this.setState({
                          confirm_alert: true,
                          value: row.id,
                        })
                      }
                    ></i>
                  </Link>
                </Tippy>
              </div>
            </>
          ),
        },
      ],
    };
    this.showToast = this.showToast.bind(this);
    this.showToastFailed = this.showToastFailed.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleDate = this.toggleDate.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handelChangeType = this.handelChangeType.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleGraphClick = this.handleGraphClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleValidSubmitDate = this.handleValidSubmitDate.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handelChangeBp = this.handelChangeBp.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handelChangeTrx = this.handelChangeTrx.bind(this);
    this.handleSummaryClick = this.handleSummaryClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handelChangeCategory = this.handelChangeCategory.bind(this);
    this.handleCategoryNameChange = this.handleCategoryNameChange.bind(this);
    this.handelChangeMasukan = this.handelChangeMasukan.bind(this);
  }
  handelChangeType(value) {
    this.setState({ type: value.value });
  }
  handelChangeMasukan(value) {
    this.setState({ masukan: value.value });
  }
  handelChangeCategory(value) {
    this.setState({ category: value.value });
  }
  handleChange(value) {
    const text = value.target.value;
    const textLower = text.toLowerCase();
    const noSpace = textLower.replace(/ /g, '');
    // if (this.state.type == 'other') {
    //   const categoryName = this.state.formatedName + ':' + noSpace;
    //   this.setState({ nameValue: categoryName });
    // } else {
    //   const categoryName = this.state.type + ':' + noSpace;

    //   this.setState({ nameValue: categoryName });
    // }

    const categoryName = 'subscription:' + noSpace;

    this.setState({ nameValue: categoryName });
  }
  handleCategoryNameChange(value) {
    const text = value.target.value;
    const textLower = text.toLowerCase();
    const noSpace = textLower.replace(/ /g, '_');
    this.setState({ formatedName: noSpace });
  }
  handleSummaryClick = row => {
    const { appId } = this.state;
    const deviceId = row.device_id;
    // console.log('/admin/summary/' + appId+'/'+deviceId)
    this.props.history.replace('/admin/summary/' + appId + '/' + deviceId);
  };
  handleValidDate(value) {
  }
  handelChangeBp(value) {
    this.setState({ device_id: value.value, label: value.label });
  }
  handelChangeTrx(value) {
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const device_idMap = [];
    const nameMap = [];
    for (const i in list) {
      const deviceid = safeDeepGet(list[i], 'device_id', '');
      device_idMap.push(deviceid);
      const name = safeDeepGet(list[i], 'business_point', '');
      nameMap.push(name);
    }

    const payload = {
      device: device_idMap,
      businessPoint: nameMap,
      type: value.value,
    };
    this.props.Download(payload);
    this.setState({ trx: value.value, labeltrx: value.label });
  }

  componentDidMount() {
    // Load business point
    this.props.LoadTagList(this.state.appId);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        this.showToast('Success');
        this.props.LoadTagList(this.state.appId);
        if (this.state.isEdit) {
          this.toggleEdit();
        } else {
          this.toggle();
        }
      } else if (code === Result.FAILED) {
        this.showToastFailed('Failed');
        // this.toggle();
      }
    }
    if (this.props.updateActiveResult !== prevProps.updateActiveResult) {
      const code = safeDeepGet(
        this.props,
        ['updateActiveResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        this.showToast('Success');
        this.props.LoadTagList(this.state.appId);
        this.toggleDate();
      }
    }
    if (this.props.deleteResult !== prevProps.deleteResult) {
      const code = safeDeepGet(
        this.props,
        ['deleteResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS || code === Result.HTTP.OK) {
        this.props.LoadTagList(this.state.appId);
        this.setState({
          confirm_alert: false,
          success_dlg: true,
          dynamic_title: 'Deleted',
          dynamic_description: 'Business Point has been deleted.',
        });
      }
    }
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      subscription: false,
      rfid: false,
      type: '',
      isEdit: false,
    }));
  }
  toggleEdit() {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit,
      // isEdit: !prevState.isEdit,
    }));
  }
  toggleDate() {
    this.setState(prevState => ({
      modalDate: !prevState.modalDate,
      // isEdit: !prevState.isEdit,
    }));
  }
  handleAddClick = () => {
    this.setState({ nameValue: '' });
    this.toggle();
  };

  handleDateClick = row => {
    this.toggleDate();
    const idDate = safeDeepGet(row, 'device_id', safeDeepGet(row, 'id', ''));
    const date = safeDeepGet(
      this.props,
      ['readResult', 'result', 'body', idDate, 'active_date'],
      new Date().getTime()
    );
    const startDate = safeDeepGet(
      this.props,
      ['readResult', 'result', 'body', idDate, 'start_date'],
      new Date().getTime()
    );
    const endDate = safeDeepGet(
      this.props,
      ['readResult', 'result', 'body', idDate, 'end_date'],
      new Date().getTime()
    );

    const active_date = new Date(date);
    const start_date = new Date(startDate);
    const end_date = new Date(endDate);
    this.setState({ idDate, active_date, start_date, end_date });
  };
  handleGraphClick = row => {
    const device_id = safeDeepGet(row, 'device_id', '');
    this.props.history.replace(
      '/admin/business-point/' + this.state.appId + '/' + device_id
    );
  };
  handleEditClick = arg => {
    const data = arg;
    this.toggleEdit();
    const sub = safeDeepGet(data, ['fee', 'daily_subscription'], null);
    const device_id = safeDeepGet(data, 'device_id', null);
    const business_point = safeDeepGet(data, 'business_point', null);
    const type = safeDeepGet(data, 'type', null);
    let subscription = false;
    if (sub !== null) {
      subscription = true;
    }
    const rfid = safeDeepGet(data, ['fee', 'rfid'], null);
    let crfid = false;
    if (rfid !== null) {
      crfid = true;
    }

    this.setState({
      data,
      device_id,
      label: business_point,
      subscription: subscription,
      rfid: crfid,
      isEdit: true,
      type,
    });
  };
  handleOkClick() {
    this.setState({ success_dlg: false });
    this.props.LoadTagList(this.state.appId);
  }
  handleDeleteClick = row => {
    const { appId } = this.state;

    const data = {
      appId,
      id: row,
    };
    this.props.DeleteTag(data);
  };
  handleValidSubmit(event, values) {
    const {appId, start_date, end_date } = this.state
    const submittedValues = {
      ...values,
      start_date: start_date.getTime(),
      end_date: end_date.getTime(),
      appId,
    };
    this.props.CreatedTag(submittedValues);
  }
  handleDate = active_date => {
    this.setState({ active_date: active_date[0] });
  };
  handleStartDate = start_date => {
    this.setState({ start_date: start_date[0] });
  };
  handleEndDate = end_date => {
    this.setState({ end_date: end_date[0] });
  };
  handleValidSubmitDate(event, values) {
    const submittedValues = {
      device_id: this.state.idDate,
      date: this.state.active_date.getTime(),
      startDate: this.state.start_date.getTime(),
      endDate: this.state.end_date.getTime(),
      id: this.state.appId,
    };
    this.props.UpdateActive(submittedValues);
  }
  handleChecked(e) {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (value == 'subscription') {
      if (isChecked) {
        this.setState({ subscription: true });
      } else {
        this.setState({ subscription: false });
      }
    } else if (value == 'rfid') {
      if (isChecked) {
        this.setState({ rfid: true });
      } else {
        this.setState({ rfid: false });
      }
    }
  }
  showToast(msg) {
    if (msg == 'Success') {
      var toastType = 'success';
      var message = msg;
    } else {
      var toastType = 'danger';
      var message = msg;
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed(msg) {
    var toastType = 'danger';
    var message = msg;
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  format_report = list => {
    const dataSet = [];
    if (list.length > 0) {
      for (const el in list) {
        const data = safeDeepGet(list[el], 'data', []),
          trx_data = {
            device_id: safeDeepGet(list[el], 'device_id', '-'),
            business_point: safeDeepGet(list[el], 'name', '-'),
            jan: safeDeepGet(data[0], 'y', 0),
            feb: safeDeepGet(data[1], 'y', 0),
            mar: safeDeepGet(data[2], 'y', 0),
            apr: safeDeepGet(data[3], 'y', 0),
            mei: safeDeepGet(data[4], 'y', 0),
            jun: safeDeepGet(data[5], 'y', 0),
            jul: safeDeepGet(data[6], 'y', 0),
            agt: safeDeepGet(data[7], 'y', 0),
            sep: safeDeepGet(data[8], 'y', 0),
            oct: safeDeepGet(data[9], 'y', 0),
            nov: safeDeepGet(data[10], 'y', 0),
            dec: safeDeepGet(data[11], 'y', 0),
          };
        dataSet.push(trx_data);
      }
      return dataSet;
    }
  };
  render() {
    // TODO Loading
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { device_id, idDate } = this.state;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'active_date',
        order: 'desc',
      },
    ];

    const {
      name,
      nameValue,
      start_date,
      end_date,
    } = this.state;
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Tags | Admin</title>
          </MetaTags>

          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Tags" breadcrumbItem="List" />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.handleOkClick()}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="name"
                      columns={this.state.Columns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="name"
                          columns={this.state.Columns}
                          data={list}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="6">
                                  <div className="search-box me-2 mb-2 d-inline-block mt-2">
                                    <div className="position-relative ">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="4"></Col>
                                <Col sm="2">
                                  <div className="text-sm-end mt-2">
                                    <Button
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create Tags
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={'id'}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={'table align-middle table-nowrap'}
                                  headerWrapperClasses={'thead-light'}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  ref={this.node}
                                />
                              </div>

                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                              {this.state.confirm_alert ? (
                                <SweetAlert
                                  title="Are you sure?"
                                  warning
                                  showCancel
                                  confirmButtonText="Yes, delete it!"
                                  confirmBtnBsStyle="success"
                                  cancelBtnBsStyle="danger"
                                  onConfirm={() =>
                                    this.handleDeleteClick(this.state.value)
                                  }
                                  onCancel={() =>
                                    this.setState({
                                      confirm_alert: false,
                                    })
                                  }
                                >
                                  You won&apos;t be able to revert this!
                                </SweetAlert>
                              ) : null}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>

                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {'Create Tags'}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <Row>
                            <Col xs="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="6">
                                      {/* <FormGroup className="mb-3 select2-container">
                                        <Label>Tag Category :</Label>
                                        <Select
                                          onChange={this.handelChangeCategory}
                                          value={typeOptions.filter(function (
                                            option
                                          ) {
                                            return option.value === category;
                                          })}
                                          options={CategoryOptions}
                                        />
                                      </FormGroup> */}
                                      {/* {category == 'other' ? (
                                        <FormGroup className="mb-3 select2-container">
                                          <AvField
                                            value={catergoryName}
                                            name="catergoryName"
                                            label="Catergory Name"
                                            placeholder="Catergory Name"
                                            type="text"
                                            onChange={
                                              this.handleCategoryNameChange
                                            }
                                            errorMessage="Masukkan Nama"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </FormGroup>
                                      ) : null} */}
                                      <FormGroup className="mb-3 select2-container">
                                        <AvField
                                          value={name}
                                          name="name"
                                          label="Name Tag"
                                          placeholder="Nama"
                                          type="text"
                                          onChange={this.handleChange}
                                          errorMessage="Masukkan Nama"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup className="mb-3 select2-container">
                                        <AvField
                                          value={nameValue}
                                          name="id"
                                          label="ID Tag"
                                          placeholder="id"
                                          type="text"
                                          className="form-control"
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Start Date</Label>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="dd M,yyyy"
                                          options={{
                                            maxDate: 'today',
                                            // enableTime: true,
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={start_date}
                                          onChange={this.handleStartDate}
                                          ref={this.refDatePicker}
                                        />
                                      </FormGroup>

                                      <FormGroup className="mb-3 select2-container">
                                        <Label>End Date</Label>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="dd M,yyyy"
                                          options={{
                                            // maxDate: 'today',
                                            // enableTime: true,
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={end_date}
                                          onChange={this.handleEndDate}
                                          ref={this.refDatePicker}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>

                              <div className="d-flex flex-wrap gap-2 float-end mb-4">
                                <Link
                                  className="btn btn-secondary"
                                  role="button"
                                  onClick={this.toggle}
                                  to="#"
                                >
                                  Batal
                                </Link>{' '}
                                <Button type="submit" color="primary">
                                  Simpan
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['tags', 'list'], {}),
  vm_result: safeDeepGet(state, ['tags', 'list_vm'], {}),
  createResult: safeDeepGet(state, ['tags', 'create'], {}),
  loading: safeDeepGet(state, ['tags', 'loading'], true),
  readResult: safeDeepGet(state, ['tags', 'readInfo'], {}),
  updateActiveResult: safeDeepGet(state, ['tags', 'update_time', 'result'], {}),
  deleteResult: safeDeepGet(state, ['tags', 'delete'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  LoadTagList: appId => dispatch(list(appId)),
  DeleteTag: payload => dispatch(delete_(payload)),
  CreatedTag: payload => dispatch(create(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPoint);
