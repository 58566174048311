/* eslint-disable no-self-assign */
/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import axios from 'axios';
const momenttz = require('moment-timezone');
const moment = require('moment');
import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
import Pages403 from 'Base/pages/pages-403';
import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import {
  Label,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Button,
} from 'reactstrap';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import { connect } from 'react-redux';
 
// Iotera
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';

// import { get_nett_settlement } from '../../../store/dashboard/actions';

import {
  list_business_points,
  summary,
  get_sales_analytics,
  get_snapshot_monthly,
} from '../../../store/transaction/actions';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
//Import Components
import TransactionSummary from '../Transaction-report/transaction-summary'; //
import ProductSummary from '../Transaction-report/product-summary'; //
import Earning from '../Transaction-report/earning';
import SalesAnalytics from '../Transaction-report/sales-analytics';
import BPSalesAnalytics from '../Transaction-report/bp-sales-analytics';
import XlsxPopulate from 'xlsx-populate';
import { buildTitle, APPLICATION_ID, PROJECT_HANDLE } from 'Apps';

const bankOptions = [
  { label: 'All', value: 'all' },
  { label: 'RFID', value: 'RFID' },
  { label: 'CASH', value: 'CASH' },
  { label: 'QRIS', value: 'QRIS-MIDTRANS' },
];
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
class TransactionReport extends Component {
  constructor(props) {
    super(props);
    this.refDateRangePicker = React.createRef();
    var current_month = new Date();
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let bank = params.get('bank_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    let businessPoints = { value: 'all' };
    let payment = { value: 'all' };
    var current_month = new Date();
    var startDate = new Date(
      current_month.getFullYear(),
      current_month.getMonth(),
      1
    );
    if (name != undefined || name != null) {
      businessPoints = { value: name };
    }
    if (bank != undefined || bank != null) {
      payment = { value: bank };
    }
    let arrDate = [startDate, current_month];
    if (start != undefined || start != null) {
      arrDate = [dateStart, dateEnd];
    }
    this.state = {
      bpfilterlist: [],
      bank: payment,
      businessPoints: businessPoints,
      dateRange: arrDate,
      reports: [
        {
          icon: 'bx bx-copy-alt',
          title: 'Landlord Profit Share',
          value: 'Rp 0',
          badgeValue: '+ 0.0%',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-copy-alt',
          title: 'Average Daily Transactions',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Average Daily Refund',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
      ],
      Cups: [
        {
          icon: 'bx bx-archive-in',
          title: 'Average Daily Settlement',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Total Settlement',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
      ],
      selectedMonthYear:
        current_month.getFullYear().toString() +
        '-' +
        (current_month.getMonth() + 1).toString(),
    };
    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleMonthYear = this.handleMonthYear.bind(this);
    this.handleBanks = this.handleBanks.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleClearDateRange = this.handleClearDateRange.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    const payload = {
      businessPoints: businessPoints.value,
      month: parseInt(this.state.selectedMonthYear.split('-')[1]) - 1,
      year: parseInt(this.state.selectedMonthYear.split('-')[0]),
      dateRange: this.state.dateRange,
      bank: this.state.bank.value,
    };
    this.props.LoadSnapshotMonthly(payload);
    // this.props.GetNettSettlement(payload);
    // this.props.Summary(payload);
    // this.props.GetSalesAnalytics(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (businessPoints.value == 'all') {
      searchParams.delete('device_id');
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('device_id', businessPoints.value);
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    }
  };

  handleBanks = bank => {
    this.setState({ bank });

    const payload = {
      bank: bank.value,
      businessPoints: this.state.businessPoints.value,
      month: parseInt(this.state.selectedMonthYear.split('-')[1]) - 1,
      year: parseInt(this.state.selectedMonthYear.split('-')[0]),
      dateRange: this.state.dateRange,
    };
    this.props.LoadSnapshotMonthly(payload);
    // this.props.GetNettSettlement(payload);
    // this.props.Summary(payload);
    // this.props.GetSalesAnalytics(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (bank.value == 'all') {
      searchParams.delete('bank_status');
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('bank_status', bank.value);
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    }
  };

  handleMonthYear = selectedMonthYear => {
    let val = selectedMonthYear.target.value;
    this.setState({ selectedMonthYear: val });

    const payload = {
      businessPoints: this.state.businessPoints.value,
      month: parseInt(val.split('-')[1]) - 1,
      year: parseInt(val.split('-')[0]),
      dateRange: [null, null],
      bank: this.state.bank.value,
    };
    this.props.LoadSnapshotMonthly(payload);

    // this.props.GetNettSettlement(payload);
    // this.props.Summary(payload);
    // this.props.GetSalesAnalytics(payload);
  };
  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      const timezone = 'Asia/Jakarta';
      moment.tz.setDefault(timezone);
      const now = momenttz.tz(dateRange[0], timezone);
      const Date_1 = now.clone().subtract(0, 'days').startOf('days');
      const startTime = Date_1.valueOf();
      var startfulldate = new Date(startTime);

      const end = momenttz.tz(dateRange[1], timezone);
      const Date_2 = end.clone().subtract(0, 'days').endOf('days');
      const endTime = Date_2.valueOf();
      var endfulldate = new Date(endTime);

      this.setState({ dateRange: [startfulldate, endfulldate] });
      var code = '';
      var type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
        }
      }
      const data = { code: code, type: type };
      const Date_3 = end.clone().subtract(0, 'days').endOf('days');
      const endTimePayload = Date_3.valueOf();
      var endPayload = new Date(endTimePayload);
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: [startfulldate, endPayload],
        bank: this.state.bank.value,
        data: data,
        month:  startfulldate.getMonth(),
        year: startfulldate.getFullYear(),
      };
      this.props.LoadSnapshotMonthly(payload);
      const oneDay = 24 * 60 * 60 * 1000;

      const difference = Math.round(
        Math.abs((startfulldate - endfulldate) / oneDay)
      );
      const diffDays = difference;
      this.setState({ diffDays });

      var searchParams = new URLSearchParams(window.location.search);
      if (startfulldate == null || startfulldate == undefined) {
        searchParams.delete('dateStart');
        searchParams.delete('dateEnd');
        this.props.history.push({
          pathname: '/transaction/report',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('dateStart', startfulldate.getTime());
        searchParams.set('dateEnd', endfulldate.getTime());
        this.props.history.push({
          pathname: '/transaction/report',
          search: searchParams.toString(),
        });
      }
    }
  };

  handleClearDateRange = () => {
    console.log('dateRange is cleared');

    if (!this.refDateRangePicker.current?.flatpickr) {
      return;
    } else {
      this.refDateRangePicker.current.flatpickr.clear();
      this.setState(() => ({ dateRange: [null, null] }));
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: [null, null],
        bank: this.state.bank.value,
        month: parseInt(this.state.selectedMonthYear.split('-')[1]) - 1,
        year: parseInt(this.state.selectedMonthYear.split('-')[0]),
      };
      // this.props.GetNettSettlement(payload);
      // this.props.Summary(payload);
      // this.props.GetSalesAnalytics(payload);
    }
  };
  componentDidMount() {
    var current_month = new Date();
    if (
      PROJECT_HANDLE == 'Nestle' ||
      APPLICATION_ID == '1000000211' ||
      APPLICATION_ID == '1000000229' ||
      APPLICATION_ID == '1000000209' ||
      APPLICATION_ID == '1000000226' 
    ) {
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
        }
      }
      const data = { code: code, type: type };
      this.props.LoadBusinessPointFillter(data);
      var current_month = new Date();
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        data: data,
        bank: this.state.bank.value,
        month: current_month.getMonth(),
        year: current_month.getFullYear(),
      };
      this.props.LoadSnapshotMonthly(payload);
    } else {
      this.props.LoadBpFilterList();
      var current_month = new Date();
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        bank: this.state.bank.value,
        month: current_month.getMonth(),
        year: current_month.getFullYear(),
      };
      // this.setState({ dateRange: this.state.dateRange });
      // this.props.GetNettSettlement(payload);
      this.props.LoadSnapshotMonthly(payload);
      // this.props.Summary(payload);
      // this.props.GetSalesAnalytics(payload);
    }
  }

  componentDidUpdate(prevProps) {
    const sum_update = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'body'],
      []
    );
    let average = 0;
    if (this.state.diffDays != null) {
      average = this.state.diffDays;
    } else {
      const date = new Date();
      average = date.getDate();
    }
    if (
      this.props.snapshotMonthlyresult !== prevProps.snapshotMonthlyresult &&
      sum_update.length != 0
    ) {
      var stateCopy = Object.assign({}, this.state);
      stateCopy.reports = stateCopy.reports.slice();
      // Landlord Profit Share
      if (
        this.state.businessPoints == 'all' ||
        this.state.businessPoints.value == 'all'
      ) {
        stateCopy.reports[0] = Object.assign({}, stateCopy.reports[0]);
        stateCopy.reports[0].value = 'Rp 0';
        stateCopy.reports[0].badgeValue = 0 + ' %';
        if (sum_update.landlord_change > 0) {
          stateCopy.reports[0].color = 'success';
          stateCopy.reports[0].chevron = 'mdi mdi-chevron-up ms-1 text-success';
        } else {
          stateCopy.reports[0].color = 'danger';
          stateCopy.reports[0].chevron =
            'mdi mdi-chevron-down ms-1 text-danger';
        }
      } else {
        stateCopy.reports[0] = Object.assign({}, stateCopy.reports[0]);
        stateCopy.reports[0].value =
          'Rp ' + sum_update.landlord_current.toFixed(2);
        stateCopy.reports[0].badgeValue =
          sum_update.landlord_change.toFixed(2) + ' %';
        if (sum_update.landlord_change > 0) {
          stateCopy.reports[0].color = 'success';
          stateCopy.reports[0].chevron = 'mdi mdi-chevron-up ms-1 text-success';
        } else {
          stateCopy.reports[0].color = 'danger';
          stateCopy.reports[0].chevron =
            'mdi mdi-chevron-down ms-1 text-danger';
        }
      }

      // Average Daily Transactions
      stateCopy.reports[1] = Object.assign({}, stateCopy.reports[1]);
      const curent = sum_update.trx_current / average;
      const numberPrev = curent.toFixed(0).toString();
      // const arr_string = numberPrev.split('.')
      var number_string_curent = numberPrev.replace(/[^,\d]/g, '').toString();
      var split_curent = number_string_curent.split(',');
      var sisa_curent = split_curent[0].length % 3;
      let rupiah_curent = split_curent[0].substr(0, sisa_curent);
      var ribuan_curent = split_curent[0].substr(sisa_curent).match(/\d{3}/gi);

      // tambahkan titik jika yang di input sudah menjadi angka ribuan_curent
      if (ribuan_curent) {
        var separator_curent = sisa_curent ? '.' : '';
        rupiah_curent += separator_curent + ribuan_curent.join('.');
      }
      rupiah_curent =
        split_curent[1] != undefined
          ? rupiah_curent + ',' + split_prev[1]
          : rupiah_curent;
      stateCopy.reports[1].value = 'Rp ' + rupiah_curent;
      // change to format other than rupiah
      // stateCopy.reports[1].value =
      //   'Rp ' + sum_update.trx_current / (30.0).toFixed(2);
      stateCopy.reports[1].badgeValue = sum_update.trx_change.toFixed(2) + ' %';
      if (sum_update.trx_change > 0) {
        stateCopy.reports[1].color = 'success';
        stateCopy.reports[1].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.reports[1].color = 'danger';
        stateCopy.reports[1].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      // Average Daily Settlement
      stateCopy.Cups[0] = Object.assign({}, stateCopy.Cups[0]);
      stateCopy.Cups[0].value =
        (sum_update.settlement_current / average).toFixed(0) + ' x';
      stateCopy.Cups[0].badgeValue =
        sum_update.settlement_change.toFixed(2) + ' %';
      if (sum_update.settlement_change > 0) {
        stateCopy.Cups[0].color = 'success';
        stateCopy.Cups[0].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.Cups[0].color = 'danger';
        stateCopy.Cups[0].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }
      //Total cups
      stateCopy.Cups[1] = Object.assign({}, stateCopy.Cups[1]);
      stateCopy.Cups[1].value = sum_update.settlement_current.toFixed(0) + ' x';
      stateCopy.Cups[1].badgeValue =
        sum_update.settlement_change.toFixed(2) + ' %';
      if (sum_update.settlement_change > 0) {
        stateCopy.Cups[1].color = 'success';
        stateCopy.Cups[1].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.Cups[1].color = 'danger';
        stateCopy.Cups[1].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      // Average Daily Refund
      stateCopy.reports[2] = Object.assign({}, stateCopy.reports[2]);

      const refund = sum_update.refund_current / average;
      const numberRefund = refund.toFixed(0).toString();
      var number_string_Refund = numberRefund.replace(/[^,\d]/g, '').toString();
      var split_Refund = number_string_Refund.split(',');
      var sisa_Refund = split_Refund[0].length % 3;
      let rupiah_Refund = split_Refund[0].substr(0, sisa_Refund);
      var ribuan_Refund = split_Refund[0].substr(sisa_Refund).match(/\d{3}/gi);

      // // tambahkan titik jika yang di input sudah menjadi angka ribuan_Refund
      if (ribuan_Refund) {
        var separator_curent = sisa_Refund ? '.' : '';
        rupiah_Refund += separator_curent + ribuan_Refund.join('.');
      }
      rupiah_Refund =
        split_Refund[1] != undefined
          ? rupiah_Refund + ',' + split_Refund[1]
          : rupiah_Refund;

      if (
        this.state.businessPoints == 'all' ||
        this.state.businessPoints.value == 'all'
      ) {
        stateCopy.reports[2].value = 'Rp ' + 0;
        stateCopy.reports[2].badgeValue = 0 + ' %';
        if (sum_update.refund_change >= 0) {
          stateCopy.reports[2].color = 'success';
          stateCopy.reports[2].chevron = 'mdi mdi-chevron-up ms-1 text-success';
        } else {
          stateCopy.reports[2].color = 'danger';
          stateCopy.reports[2].chevron =
            'mdi mdi-chevron-down ms-1 text-danger';
        }
      } else {
        stateCopy.reports[2].value = 'Rp ' + rupiah_Refund;
        stateCopy.reports[2].badgeValue =
          sum_update.refund_change.toFixed(2) + ' %';
        if (sum_update.refund_change >= 0) {
          stateCopy.reports[2].color = 'success';
          stateCopy.reports[2].chevron = 'mdi mdi-chevron-up ms-1 text-success';
        } else {
          stateCopy.reports[2].color = 'danger';
          stateCopy.reports[2].chevron =
            'mdi mdi-chevron-down ms-1 text-danger';
        }
      }
      // change to format other than rupiah
      // stateCopy.reports[3].value =
      //   'Rp ' + sum_update.refund_current / (30.0).toFixed(2);
      this.setState(stateCopy);
    }
  }

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : 0;
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  handleExport = list => {
    var data = list;
    let header = [
      'Device ID',
      'Business Point',
      'Tanggal 01',
      'Tanggal 02',
      'Tanggal 03',
      'Tanggal 04',
      'Tanggal 05',
      'Tanggal 06',
      'Tanggal 07',
      'Tanggal 08',
      'Tanggal 09',
      'Tanggal 10',
      'Tanggal 11',
      'Tanggal 12',
      'Tanggal 13',
      'Tanggal 14',
      'Tanggal 15',
      'Tanggal 16',
      'Tanggal 17',
      'Tanggal 18',
      'Tanggal 19',
      'Tanggal 20',
      'Tanggal 21',
      'Tanggal 22',
      'Tanggal 23',
      'Tanggal 24',
      'Tanggal 25',
      'Tanggal 26',
      'Tanggal 27',
      'Tanggal 28',
      'Tanggal 29',
      'Tanggal 30',
      'Tanggal 31',
    ];

    const { selectedMonthYear, businessPoints, dateRange } = this.state;
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    XlsxPopulate.fromBlankAsync().then(async workbook => {
      const sheet1 = workbook.sheet(0);
      sheet1.column('A').width(40);
      sheet1.column('B').width(40);
      let sheetData;
      sheetData = this.getSheetData(data, header);
      sheetData.shift();
      const totalColumns = sheetData[0].length;
      sheet1
        .range('A1:A2')
        .value('Business Point')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1
        .range('B1:B2')
        .value('Serial Number')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1
        .range('C1:D1')
        .value('Tanggal 01')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('C2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('D2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('E1:F1')
        .value('Tanggal 02')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('E2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('F2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('G1:H1')
        .value('Tanggal 03')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('G2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('H2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('I1:J1')
        .value('Tanggal 04')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('I2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('J2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('K1:L1')
        .value('Tanggal 05')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('K2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('L2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('M1:N1')
        .value('Tanggal 06')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('M2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('N2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('O1:P1')
        .value('Tanggal 07')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('O2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('P2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('Q1:R1')
        .value('Tanggal 08')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('Q2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('R2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('S1:T1')
        .value('Tanggal 09')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('S2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('T2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('U1:V1')
        .value('Tanggal 10')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('U2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('V2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('W1:X1')
        .value('Tanggal 11')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('W2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('X2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('Y1:Z1')
        .value('Tanggal 12')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('Y2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('Z2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('AA1:AB1')
        .value('Tanggal 13')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AA2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AB2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('AC1:AD1')
        .value('Tanggal 14')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AC2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AD2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('AE1:AF1')
        .value('Tanggal 15')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AE2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AF2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('AG1:AH1')
        .value('Tanggal 16')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AG2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AH2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AI1:AJ1')
        .value('Tanggal 17')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AI2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AJ2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AK1:AL1')
        .value('Tanggal 18')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AK2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AL2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AM1:AN1')
        .value('Tanggal 19')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AM2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AN2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AO1:AP1')
        .value('Tanggal 20')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AO2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AP2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AQ1:AR1')
        .value('Tanggal 21')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AQ2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AR2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AS1:AT1')
        .value('Tanggal 22')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AS2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AT2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AU1:AV1')
        .value('Tanggal 23')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AU2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AV2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AW1:AX1')
        .value('Tanggal 24')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AW2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AX2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AY1:AZ1')
        .value('Tanggal 25')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AY2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AZ2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BA1:BB1')
        .value('Tanggal 26')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BA2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BB2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BC1:BD1')
        .value('Tanggal 27')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BC2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BD2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BE1:BF1')
        .value('Tanggal 28')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BE2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BF2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BG1:BH1')
        .value('Tanggal 29')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BG2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BH2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BI1:BJ1')
        .value('Tanggal 30')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BI2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BJ2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('BK1:BL1')
        .value('Tanggal 31')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BK2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BL2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('A3').value(sheetData).style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      var report_filename = '.xlsx';
      var tenant = PROJECT_HANDLE;
      if (PROJECT_HANDLE === 'Fuji') {
        tenant = 'VendkiosK';
      } else if (PROJECT_HANDLE == 'Nestle') {
        tenant = 'Milano';
      }
      if (bp_name == undefined) {
        if (dateRange[0] == undefined) {
          report_filename =
            tenant + '_Trx_Report_All_' + selectedMonthYear + '.xlsx';
        } else {
          report_filename =
            tenant +
            '_Trx_Report_All_' +
            dateRange[0].toLocaleDateString('en-GB') +
            ' to ' +
            dateRange[1].toLocaleDateString('en-GB') +
            '.xlsx';
        }
      } else {
        if (dateRange[0] == undefined) {
          report_filename =
            tenant +
            '_Trx_Report_' +
            bp_name +
            '_' +
            selectedMonthYear +
            '.xlsx';
        } else {
          report_filename =
            tenant +
            '_Trx_Report_' +
            bp_name +
            '_' +
            dateRange[0].toLocaleDateString('en-GB') +
            ' to ' +
            dateRange[1].toLocaleDateString('en-GB') +
            '.xlsx';
        }
      }
      return workbook.outputAsync().then(res => {
        saveAs(res, report_filename);
      });
    });
  };
  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    if (list.length > 0) {
      for (const el in list) {
        const dataArr = safeDeepGet(list[el], 'data', 0);
        trx_data = {
          business_point: safeDeepGet(list[el], 'name', '-'),
          serial_number: safeDeepGet(
            list[el],
            'serial_number',
            safeDeepGet(list[el], 'deviceId', '-')
          ),
        };
        // console.log(dataArr);
        for (const i in dataArr) {
          const date = safeDeepGet(dataArr[i], 'x');
          // console.log(date)
          const total = safeDeepGet(dataArr[i], 'y', 0);
          const settle = safeDeepGet(dataArr[i], 'settle', 0);
          safeDeepSet(trx_data, 'total' + i, total);
          safeDeepSet(trx_data, 'settle' + i, settle);
        }
        dataSet.push(trx_data);
      }
      const dataArr = Object.keys(safeDeepGet(list[0], 'data', 0));
      const keys = Object.keys(list[0]);
      const columns = [];
      for (const el in keys) {
        if (keys[el] !== 'data') {
          columns.push({
            title: keys[el],
            width: { wch: 40 },
            border: {
              bottom: { style: 'thin', color: { rgb: '000000' } },
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            },
          });
        }
      }
      for (const el in dataArr) {
        columns.push({
          title: 'Tanggal ' + (Number(dataArr[el]) + 1),
          alignment: {
            horizontal: 'center',
          },
          border: {
            bottom: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
          },
          width: { wpx: 80 },
        });
      }
      const data = [];
      for (const el in list) {
        const listData = [];
        const dataArr = safeDeepGet(list[el], 'data', 0);

        listData.push({
          value: safeDeepGet(list[el], 'deviceId', '-'),
          style: {
            font: { sz: '12' },
            alignment: {
              wrapText: true,
            },
            border: {
              bottom: { style: 'thin', color: { rgb: '000000' } },
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            },
          },
        });
        listData.push({
          value: safeDeepGet(list[el], 'serial_number', '-'),
          style: {
            font: { sz: '12' },
            alignment: {
              wrapText: true,
            },
            border: {
              bottom: { style: 'thin', color: { rgb: '000000' } },
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            },
          },
        });
        for (const i in dataArr) {
          const total = safeDeepGet(dataArr[i], 'y', 0);
          const settle = safeDeepGet(dataArr[i], 'settle', 0);
          listData.push({
            value: total,
            style: {
              font: { sz: '12' },
              alignment: {
                wrapText: true,
                horizontal: 'center',
              },
              border: {
                bottom: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              numFmt: '0',
            },
          });
          listData.push({
            value: settle,
            style: {
              font: { sz: '12' },
              alignment: {
                wrapText: true,
                horizontal: 'center',
              },
              border: {
                bottom: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              numFmt: '0',
            },
          });

          // safeDeepSet(trx_data, i, total);
          // safeDeepSet(trx_data, "settle"+i, settle);
        }
        data.push(listData);
      }
      const objDataSet = { columns: columns, data: data };
      const multiDataSet = [objDataSet];
      return dataSet;
    }
  };

  render() {
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const nett_settlement = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'nett_settlement'],
      []
    );
    const sales_analytics = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'sales_analytics'],
      []
    );
    const bp_sales_analytics = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'bp_sales_analytics'],
      []
    );
    // const downloadData = safeDeepGet(nett_settlement, 'download_data', []);
    const downloadData = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'download_data'],
      []
    );
    const report_list = this.format_report(downloadData);
    const { selectedMonthYear } = this.state;
    let { dateRange, bank, businessPoints } = this.state;

    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    let report_filename = ' ';
    if (bp_name == undefined) {
      if (dateRange[0] == undefined) {
        report_filename = 'Trx_Report_All_' + selectedMonthYear;
      } else {
        report_filename =
          'Trx_Report_All_' +
          dateRange[0].toLocaleDateString('en-GB') +
          ' to ' +
          dateRange[1].toLocaleDateString('en-GB');
      }
    } else {
      if (dateRange[0] == undefined) {
        report_filename = 'Trx_Report_' + bp_name + '_' + selectedMonthYear;
      } else {
        report_filename =
          'Trx_Report_' +
          bp_name +
          '_' +
          dateRange[0].toLocaleDateString('en-GB') +
          ' to ' +
          dateRange[1].toLocaleDateString('en-GB');
      }
    }
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let payment = params.get('bank_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    if (start != undefined || start != null) {
      dateRange = [dateStart, dateEnd];
    } else {
      dateRange = dateRange;
    }
    if (payment != undefined || payment != null) {
      bank = payment;
    } else {
      bank = bank.value;
    }
    if (name != undefined || name != null) {
      businessPoints = name;
    } else {
      businessPoints = businessPoints.value;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          
            <MetaTags>
              <title>{buildTitle('Transaction Report')}</title>
            </MetaTags>
          
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs title="Transaction" breadcrumbItem="Reports" />

              <Row className="clearfix mb-3">
                <Col sm="2">
                  <FormGroup className="mb-2 ajax-select select2-container">
                    <Select
                      defaultValue={bpfilterlist[0]}
                      placeholder={'Business Point'}
                      // value={businessPoints}
                      value={bpfilterlist.filter(function (option) {
                        return option.value === businessPoints;
                      })}
                      onChange={this.handleBusinessPoints}
                      options={bpfilterlist}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
                <Col sm="2">
                  <FormGroup className="mb-2 ajax-select select2-container">
                    <Select
                      placeholder={'Payment'}
                      // defaultValue={bankOptions[0]}
                      // value={bank}
                      value={bankOptions.filter(function (option) {
                        return option.value === bank;
                      })}
                      onChange={this.handleBanks}
                      options={bankOptions}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>

                <Col sm="2" align="center">
                  <div className="clearfix mt-4 mt-lg-0">
                    <Button
                      type="button"
                      color="primary"
                      className="btn btn-nestle w-sm"
                      disabled={downloadData.length == 0 ? true : false}
                      onClick={() => this.handleExport(report_list)}
                    >
                      <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                      Download Excel
                    </Button>
                    {/* <ExcelFile
                    filename={report_filename}
                    element={
                      <button
                        type="button"
                        className="btn btn-primary w-sm"
                        disabled={downloadData.length == 0 ? true : false}
                      >
                        <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                        Download Excel
                      </button>
                    }
                  >
                    <ExcelSheet dataSet={report_list} name="Organization" />
                  </ExcelFile> */}
                  </div>
                </Col>
                <Col sm="3">
                  <Label className="col-md-12 col-form-label text-sm-end">
                    Date Range
                  </Label>
                </Col>
                <Col sm="3" className="text-end">
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd M,yyyy"
                      options={{
                        mode: 'range',
                        dateFormat: 'Y-m-d',
                      }}
                      value={dateRange}
                      onChange={this.handleDateRange}
                      // ref={this.refDateRangePicker}
                    />
                    {/* <Button
                    type="reset"
                    color="secondary"
                    onClick={this.handleClearDateRange}
                  >
                    Clear
                  </Button> */}
                  </InputGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">TRANSACTION SUMMARY</h4>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <Row>
                    {/*mimi widgets */}
                    <TransactionSummary reports={this.state.reports} />
                  </Row>
                </Col>
              </Row>

              {/* earning */}
              <Row className="clearfix">
                <Earning data={nett_settlement} />
              </Row>
              <Row>
                <Col xl="12">
                  <Row>
                    {/*mimi widgets */}
                    <ProductSummary reports={this.state.Cups} />
                  </Row>
                </Col>
              </Row>
              {/* sales anytics */}
              <Row className="clearfix">
                <SalesAnalytics series={sales_analytics} />
              </Row>

              <Row className="clearfix">
                <BPSalesAnalytics series={bp_sales_analytics} />
              </Row>
            </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
  summaryresult: safeDeepGet(state, ['transaction', 'summary'], {}),
  snapshotMonthlyresult: safeDeepGet(
    state,
    ['transaction', 'get_snapshot_monthly'],
    {}
  ),
  netsettlementresult: safeDeepGet(state, ['dashboard', 'nett_settlement'], {}),
  salesanalyticsresult: safeDeepGet(
    state,
    ['transaction', 'get_sales_analytics'],
    {}
  ),
  loading: safeDeepGet(state, ['dashboard', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  LoadBpFilterList: () => dispatch(list_business_points()),
  LoadBusinessPointFillter: payload => dispatch(list_business_points(payload)),
  Summary: (businessPoints, month, year, dateRange, bank) =>
    dispatch(summary(businessPoints, month, year, dateRange, bank)),
  GetNettSettlement: (businessPoints, month, year, dateRange, bank) =>
    dispatch(get_nett_settlement(businessPoints, month, year, dateRange, bank)),
  GetSalesAnalytics: (businessPoints, month, year, dateRange, bank) =>
    dispatch(get_sales_analytics(businessPoints, month, year, dateRange, bank)),
  LoadSnapshotMonthly: (businessPoints, month, year, dateRange, bank) =>
    dispatch(
      get_snapshot_monthly(businessPoints, month, year, dateRange, bank)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReport);
