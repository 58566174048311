import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getInstance } from "../../../firebase/helper"
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"
import { MULTITENANCY_HANDLE, APPLICATION_ID } from 'Apps/index';


const firebaseInstance = getInstance()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
      let email = user.email.toLowerCase();
      if(MULTITENANCY_HANDLE){
        var email_arr = email.split('@')
        email = email_arr[0]+"+"+APPLICATION_ID+'@'+email_arr[1]
      }
      const response = yield call(firebaseInstance.forgetPassword, email)
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
   
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

export default forgetPasswordSaga
