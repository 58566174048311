/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import firebase from 'firebase/compat/app';
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class balance extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'balances');
  }
  payout = payload => {
    return new Promise((resolve, reject) => {
      const balancesDoc = "data";
      const dataUpdate = safeDeepGet(payload, ['dataUpdate'], {});
      const createdTs = safeDeepGet(payload, ['createdTs'], {});

      this.collection
          .doc(balancesDoc)
          .update(dataUpdate)
          .then(
            () => {
              resolve({ code: 0 });
            },
            error => {
              resolve({ code: -1, body: error });
            }
          );
    });
  };

  list = () => {
    return new Promise((resolve, reject) => {
      const list = {}
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };
}
