export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "TAGS_LIST";
export const LIST_VM = "TAGS_LIST_VM";
export const INIT_CREATE = "TAGS_INIT_CREATE";
export const CREATE = "TAGS_CREATE";
export const READ = "TAGS_INFO";
export const UPDATE = "TAGS_UPDATE";
export const STOCK_UPDATE = "STOCK_TAGS_UPDATE";
export const DELETE = "TAGS_DELETE";
