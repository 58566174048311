import React, { Component } from 'react';
import { Button, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
import './datatables.scss';

// Table data
const stocks = [
  { bpname: 'Office Redboks', address: 'Pergudangan Daan Mogot no 3' },
  { bpname: 'Office Redboks 2', address: 'Pergudangan Daan Mogot no 3' },
];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      stockData: stocks,
    };
  }

  renderTableData(stock_data) {
    return stock_data.map((stock, index) => {
      var row_class = 'table-info';
      const { name, qty } = stock; //destructuring

      if (qty < 5) {
        row_class = 'table-warning';
      } else {
        row_class = 'table-success';
      }

      return (
        <tr className={row_class} key={name}>
          <td>{name}</td>
          <td> {qty}</td>
        </tr>
      );
    });
  }

  render() {
    const columns = [
      {
        dataField: 'bpname',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'address',
        text: 'Address',
        sort: true,
      },
      {
        dataField: 'list_minuman',
        isDummyField: true,
        text: 'List Minuman',
        formatter: (cellContent, product) => (
          <Link className="text-info" to="/drink">
            <i className="mdi mdi-menu font-size-18" id="menutooltip"></i>
          </Link>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: 'id',
        order: 'asc',
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: stocks.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: this.state.stockData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: 'checkbox',
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock di Business Points | Nestle Franke Vending Machines</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Stock" breadcrumbItem="Business Points" />
            <Row className="mb-2">
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      Stock Product di Business Points
                    </CardTitle>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.stockData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.stockData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable    
                                      keyField={'bpname'}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap'
                                      }
                                      headerWrapperClasses={'thead-light'}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatatableTables;
