import { safeDeepGet, has, safeDeepSet } from 'iotera-base/utility/json';

export default class Stock {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('stock');
  }

  list = deviceId => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('__name__', '==', deviceId)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  create = (id, payload) => {
    return new Promise((resolve, reject) => {
      this.collection.doc(id).delete();

      this.collection
        .doc(id)
        .set(payload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'name' || key === 'type' || key === 'category') {
          updatedPayload[key] = payload[key];
        }
      }

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
}
