import { call, put, takeEvery } from 'redux-saga/effects';

// Product state
import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_INGREDIENT,
  DISCOUNT_PRODUCT,
  DELETE_DISCOUNT,
  INPUT_CSV,
} from './actionTypes';
import { PROJECT } from '../../../Project';
import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';

function* list_ingredients() {
  yield put(loading(LIST_INGREDIENT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.ingredient.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const IngredientMap = wsResult.body;
    const ingregientList = [{ label: 'Water', value: 'water' }];
    for (const ingre in IngredientMap) {
      let ingre_name = safeDeepGet(IngredientMap, [ingre, 'name']);
      let ingre_type = safeDeepGet(IngredientMap, [ingre, 'type']);
      ingregientList.push({ label: ingre_name, value: ingre_type });
    }
    yield put(
      result(LIST_INGREDIENT, { code: Result.SUCCESS, body: ingregientList })
    );
    return;
  }
  yield put(result(LIST_INGREDIENT, { result: { code: Result.FAILED } }));
}

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const productMap = wsResult.body;
    const productList = [];
    let i = 0;
    for (const sku in productMap) {
      safeDeepSet(productMap, [sku, 'id'], i++);
      productList.push(productMap[sku]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: productList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }));
}

function* create(action) {
  yield put(loading(CREATE));
  const sku = safeDeepGet(action, ['payload', 'sku']);

  const ingredients = [];
  const selectedVM = safeDeepGet(action, ['payload', 'selectedVM']);
  const selectedVM2 = safeDeepGet(action, ['payload', 'selectedVM2']);
  const selectedVM3 = safeDeepGet(action, ['payload', 'selectedVM3']);
  const selectedVM4 = safeDeepGet(action, ['payload', 'selectedVM4']);
  const amount = safeDeepGet(action, ['payload', 'amount']);
  const amount2 = safeDeepGet(action, ['payload', 'amount2']);
  const amount3 = safeDeepGet(action, ['payload', 'amount3']);
  const amount4 = safeDeepGet(action, ['payload', 'amount4']);
  if (selectedVM) {
    if (amount) {
      ingredients.push({ type: selectedVM, amount: amount });
    }
  }
  if (selectedVM2) {
    if (amount2) {
      ingredients.push({ type: selectedVM2, amount: amount2 });
    }
  }
  if (selectedVM3) {
    if (amount3) {
      ingredients.push({ type: selectedVM3, amount: amount3 });
    }
  }
  if (selectedVM4) {
    if (amount4) {
      ingredients.push({ type: selectedVM4, amount: amount4 });
    }
  }
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile']);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt']);
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url']);
    }
  }
  if (imageUrl != null) {
    payload['imgUrl'] = imageUrl;
  }

  const wsResult = yield call(
    firebaseInstance.product.create,
    sku,
    payload,
    ingredients
  );
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(CREATE, { code }));
}

function* read(action) {
  yield put(loading(READ));
  const sku = safeDeepGet(action, ['payload', 'sku']);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.read, sku);
  const ingredientsMaps = wsResult.body.ingredients;
  const maps = wsResult.body;
  for (const id in ingredientsMaps) {
    var type = safeDeepGet(ingredientsMaps[id], ['type']);
    var amount = safeDeepGet(ingredientsMaps[id], ['amount']);
    safeDeepSet(wsResult.body, [id, 'type'], type);
    safeDeepSet(wsResult.body, [id, 'amount'], amount);
  }

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function* update(action) {
  yield put(loading(UPDATE));
  const sku = safeDeepGet(action, ['payload', 'name']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const ingredients = [];
  const selectedVM = safeDeepGet(action, ['payload', 'selectedVM']);
  const selectedVM2 = safeDeepGet(action, ['payload', 'selectedVM2']);
  const selectedVM3 = safeDeepGet(action, ['payload', 'selectedVM3']);
  const selectedVM4 = safeDeepGet(action, ['payload', 'selectedVM4']);
  const amount = safeDeepGet(action, ['payload', 'amount']);
  const amount2 = safeDeepGet(action, ['payload', 'amount2']);
  const amount3 = safeDeepGet(action, ['payload', 'amount3']);
  const amount4 = safeDeepGet(action, ['payload', 'amount4']);
  if (selectedVM || amount) {
    ingredients.push({ type: selectedVM, amount: amount });
  }
  if (selectedVM2 || amount2) {
    ingredients.push({ type: selectedVM2, amount: amount2 });
  }
  if (selectedVM3 || amount3) {
    ingredients.push({ type: selectedVM3, amount: amount3 });
  }
  if (selectedVM4 || amount4) {
    ingredients.push({ type: selectedVM4, amount: amount4 });
  }
  payload['ingredients'] = ingredients;
  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile']);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt']);
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url']);
    }
  }
  if (imageUrl != null) {
    payload['imgUrl'] = imageUrl;
  }

  const wsResult = yield call(firebaseInstance.product.update, sku, payload);
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(UPDATE, { code }));
}

function* stockUpdate(action) {
  yield put(loading(STOCK_UPDATE));
  const sku = safeDeepGet(action, ['payload', 'sku']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile']);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt']);
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url']);
    }
  }
  if (imageUrl != null) {
    payload['img_url'] = imageUrl;
  }

  const wsResult = yield call(
    firebaseInstance.product.updateStock,
    sku,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(STOCK_UPDATE, { code }));
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  const sku = safeDeepGet(payload, ['payload', 'sku'], '');
  const wsResult = yield call(firebaseInstance.product.delete_, sku);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}
function* delete_discount(payload) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  const id = safeDeepGet(payload, ['payload', 'sku'], '');
  const wsResult = yield call(firebaseInstance.product.deleteDiscount, id);
  yield call(firebaseInstance.planogram.deleteDiscount, id)
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* discount_product(action) {
  yield put(loading(DISCOUNT_PRODUCT));
  const firebaseInstance = getInstance();
  const productId = safeDeepGet(action, ['payload', 'sku']);
  const discountPrice = safeDeepGet(action, ['payload', 'discountPrice']);
  const wsResult = yield call(
    firebaseInstance.product.setDiscount,
    productId,
    discountPrice
  );
  yield call(firebaseInstance.planogram.setDiscount, productId, discountPrice)
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DISCOUNT_PRODUCT, { code }));
}

function* inputCsv(action) {
  yield put(loading(INPUT_CSV));
  const payload = safeDeepGet(action, ['payload']);
  for (const i in payload) {
    const ingredient = safeDeepGet(payload[i], ['ingredients 1']);
    const ingredient2 = safeDeepGet(payload[i], ['ingredients 1']);
    const ingredient3 = safeDeepGet(payload[i], ['ingredients 2']);
    const ingredient4 = safeDeepGet(payload[i], ['ingredients 3']);
    const amount = safeDeepGet(payload[i], ['amount 1']);
    const amount2 = safeDeepGet(payload[i], ['amount 2']);
    const amount3 = safeDeepGet(payload[i], ['amount 3']);
    const amount4 = safeDeepGet(payload[i], ['amount 4']);
    const ingredients = [];
    if (ingredient) {
      if (amount) {
        ingredients.push({ type: ingredient, amount: Number(amount) });
      }
    }
    if (ingredient2) {
      if (amount2) {
        ingredients.push({ type: ingredient2, amount: Number(amount2) });
      }
    }
    if (ingredient3) {
      if (amount3) {
        ingredients.push({ type: ingredient3, amount: Number(amount3) });
      }
    }
    if (ingredient4) {
      if (amount4) {
        ingredients.push({ type: ingredient4, amount: Number(amount4) });
      }
    }
    payload[i].ingredients = ingredients;
  }
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.product.addCsv, payload);
  yield put(result(INPUT_CSV, { code: wsResult }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_INGREDIENT, list_ingredients);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(STOCK_UPDATE, stockUpdate);
  yield takeEvery(DISCOUNT_PRODUCT, discount_product);
  yield takeEvery(DELETE_DISCOUNT, delete_discount);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(INPUT_CSV, inputCsv);
}

export default productSaga;
