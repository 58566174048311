/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import { createdUser, deletedUser } from 'Apps/device';
import { FIREBASE_URL } from 'Apps';

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.account.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const roleBody = safeDeepGet(wsResult, 'body', {});
    const roleList = [];
    for (const role in roleBody) {
      roleList.push(roleBody[role]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: roleList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* initCreate() {
  yield put(loading(INIT_CREATE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.role.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS) {
    const dataList = []
    const roleBody = safeDeepGet(wsResult, 'body', {});
    for(const role in roleBody){
      const name = safeDeepGet(roleBody,[role,'name'],'')
      dataList.push({ label:name, value:role})
    }
    yield put(
      result(INIT_CREATE, {
        code: Result.SUCCESS,
        body: dataList,
      })
    );
  } else {
    yield put(result(INIT_CREATE, { code: Result.FAILED, body: [] }));
  }
}
function* create(action) {
  yield put(loading(CREATE));

  const payload = safeDeepGet(action, 'payload')
  const email = safeDeepGet(action, ['payload', 'email'])
  const pass = safeDeepGet(action, ['payload', 'pass'])
  const url = FIREBASE_URL + "/createdUser"
  const firebaseInstance = getInstance()
  // Add to Firebase Auth
  const wsResult = yield call(createdUser, email, pass, url)
  const code = safeDeepGet(wsResult, 'code')
  if (code === Result.SUCCESS || code === Result.HTTP.OK) {
    const uid = safeDeepGet(wsResult, ['body','uid'], '')
    yield call(firebaseInstance.account.create, payload, uid)
  }

  yield put(result(CREATE, { code }))
}
function* list_vm() {
  yield put(loading(LIST_VM));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.refiller.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const listMap = wsResult.body;
    const refillerList = [];
    for (const id in listMap) {
      let refillerName = safeDeepGet(listMap, [id, 'name']);
      let refillerId = safeDeepGet(listMap, [id, 'code']);
      refillerList.push({ label: refillerName, value: refillerId });
    }
    yield put(result(LIST_VM, { code: Result.SUCCESS, body: refillerList }));
    return;
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }));
}
function* read(action) {
  yield put(loading(READ));
  const name = safeDeepGet(action, ['payload', 'name']);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.account.read, name);
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function* update(action) {
  yield put(loading(UPDATE));
  const id = safeDeepGet(action, ['payload', 'email']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.account.update, id, payload);
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(UPDATE, { code }));
}


function* delete_(payload) {
  yield put(loading(DELETE));
  const email = safeDeepGet(payload, ['payload', 'email']);
  const uid = safeDeepGet(payload, ['payload', 'uid']);
  const firebaseInstance = getInstance();
  const url = FIREBASE_URL +'/deletedUser'
  let wsResult = yield call(deletedUser, uid, url)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code === Result.SUCCESS || code === Result.HTTP.OK) {
    yield call(firebaseInstance.account.delete_, email)
  }
  yield put(result(DELETE, { code }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(LIST_VM, list_vm);
}

export default productSaga;
