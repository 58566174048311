/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Input,
  Label,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, update, delete_ } from '../../store/account/actions';
import { APPLICATION_ID, NAME_PROJECT, buildTitle, getButtonTheme, getTableAccount, } from 'Apps';

class AccountList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const AccountListColumns = getTableAccount(this.handleEditClick, this.handleDeleteClick)
    this.state = {
      modal: false,
      modalNav: false,
      Role: [],
      AccountListColumns,
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  componentDidMount() {
    // Load business point
    this.props.LoadAccountList();
    // this.props.InitCreateRefiller();

  }
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/account/create');
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.deleteResult !== prevProps.deleteResult) {
      const code = safeDeepGet(
        this.props,
        ['deleteResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if(code === Result.SUCCESS || code === Result.HTTP.OK){
        this.showToast('success');
        this.props.LoadAccountList();
      } else if (code === Result.FAILED) {
        this.showToast('failed');
        this.props.LoadAccountList();
      }
    }
  }
  handleEditClick = row => {
    const name = safeDeepGet(row, 'name', '');
    this.props.history.push('/account/edit/' + name);
  };
  handleDeleteClick = row => {
    this.props.DeleteAccount(row);
  };
  showToast(msg) {
    var toastType = 'success';
    var message = 'Berhasil Delete Account';
    if(msg == 'failed'){
      toastType = 'error';
      message = 'Gagal Delete Account';
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Account List')}</title>
          </MetaTags>
          
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Account" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.AccountListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.AccountListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      className={getButtonTheme()} 
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Account
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                       
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  
  result: safeDeepGet(state, ['accountRole', 'list'], {}),
  loading: safeDeepGet(state, ['accountRole', 'loading'], true),
  updateResult: safeDeepGet(state, ['accountRole', 'update'], {}),
  deleteResult: safeDeepGet(state, ['accountRole', 'delete'], {}),
  test: state
});

const mapDispatchToProps = dispatch => ({
  LoadAccountList: () => dispatch(list()),
  UpdateAccount: payload => dispatch(update(payload)),
  // InitCreateRefiller: () => dispatch(initCreate()),
  DeleteAccount: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
