/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
// Strap
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Row,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Router
// Table
import Result from 'iotera-base/result';
// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import { NAME_PROJECT, buildTitle, PROJECT_HANDLE, REFUND_PERIOD } from 'Apps';

import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
import { detailChat } from '../../store/chat/actions';
import { updateRefund, refunded } from '../../store/transaction/actions';
class DetailTransactions extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    let orderId = null;
    const path = this.props.match.path;
    if (path.includes('/detail')) {
      orderId = safeDeepGet(this.props.match.params, 'id');
    }

    this.state = {
      role: '',
      orderId,
      products: [],
      modal: false,
    };
    this.handleValidDate = this.handleValidDate.bind(this);
    this.handleValidTime = this.handleValidTime.bind(this);
    this.handleValidRefundDate = this.handleValidRefundDate.bind(this);
    this.handleRefundedMidTrans = this.handleRefundedMidTrans.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  handleDetail = arg => {
    const trx = arg;
    // this.toggleDetail();
    this.props.history.push(
      `/transaction/detail/${safeDeepGet(trx, 'order_id', 'default')}`
    );
  };
  handleValidTime = date => {
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return time1;
  };
  handleValidRefundDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    return date1;
  };
  handleRefund = row => {
    const payload = {
      order_id: safeDeepGet(row, 'id', ' '),
      transaction_status: 'refund_pending',
    };
    this.props.RefundTransaction(payload);
    this.componentDidMount();
  };
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleValidRefundSubmit = (e, values) => {
    const { onRefund } = this.props;
    const { products } = this.state;
    const refunded = {
      id: products.id,
      amount: products.amount,
      device_id: products.device_id,
    };
    onRefund(refunded);
    this.toggle();
    this.handleRefund(products);
    this.componentDidMount();
    this.showToast();
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.refundResult !== prevProps.refundResult) {
      const code = safeDeepGet(
        this.props,
        ['refundResult', 'code'],
        Result.UNKNOWN_ERROR
      );
    }
  }
  handleRefundedMidTrans = arg => {
    const product = arg;
    this.setState({
      products: {
        id: product.detail.order_id,
        amount: product.payment.amount,
        device_id: product.product.device_id,
      },
    });
    this.toggle();
  };

  timeConvert(n) {
    var num = n;
    var day = num / 60 / 24;
    var rday = Math.floor(day);
    var hours = (day - rday) * 24;
    var rhours = Math.round(hours);
    return rday + ' Days(s) and ' + rhours + ' hours(s) ';
  }
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'info';
    var message = 'Refund sedang di Proses';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '10000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidMount() {
    const order_id = this.state.orderId;
    this.props.DetailTransaction(order_id);
  }
  handleImage = arg => {
    const trx = arg;
    this.setState({
      trx,
    });
    // this.toggleDetail();
    window.open(safeDeepGet(trx, 'img_url', 'default'));
  };
  render() {
    // TODO Loading
    const arr_list = safeDeepGet(this.props, ['result', 'body'], []);
    const list = arr_list[0];
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;
    return (
      <React.Fragment>
        <div className="page-content">
            <MetaTags>
            <title>{buildTitle("Detail Refund")}</title>
            </MetaTags>
          
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Transaction" breadcrumbItem="Detail" />
            <Row>
              <Col lg="12">
                <Card>
                  {arr_list.length !== 0 ? (
                    <CardBody>
                      <Row>
                        <Col lg={7}>
                          <div className="table-responsive mb-2">
                            <Table className="table align-middle table-nowrap">
                              <thead>
                                <tr>
                                  <th scope="col" className="align-middle">Order Id</th>
                                  <th scope="row">
                                    <div>
                                      <h5 className="text-truncate font-size-14">
                                        {list.order_id}
                                      </h5>
                                    </div>
                                  </th>
                                </tr>

                                <tr>
                                  <th scope="col" className="align-middle">Product</th>
                                  <th scope="row">
                                    <div>
                                      <h5 className="text-truncate font-size-14">
                                        {list.product}
                                      </h5>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th scope="col" className="align-middle">Business Point</th>
                                  <td>
                                    <div>
                                      <h5 className="text-truncate font-size-14">
                                        {list.business_point}
                                      </h5>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col" className="align-middle">Mobile Number</th>
                                  <td>
                                    <div>
                                      <span className="text-primary">
                                        {list.mobile_number}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col" className="align-middle">Status Refund</th>
                                  <td>
                                    <div>
                                      <span
                                        className={
                                          list.active
                                            ? 'text-danger'
                                            : 'text-success'
                                        }
                                      >
                                        {list.active
                                          ? 'Not Refund'
                                          : 'Refunded'}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col" className="align-middle">Reason</th>

                                  <td>
                                    <div>
                                      <h5 className="text-wrap font-size-14">
                                        {safeDeepGet(list, 'reason', '-')}
                                      </h5>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col" className="align-middle">Request Created</th>
                                  <td>
                                    <div>
                                      <h5 className="text-truncate font-size-14">
                                        {this.handleValidDate(
                                          list.created_date
                                        )}
                                      </h5>
                                    </div>
                                  </td>
                                </tr>
                              </thead>
                            </Table>
                          </div>
                          <div>
                            <Button
                              type="button"
                              color="success"
                              className="btn-sm btn-rounded btn-success"
                              onClick={() => this.handleDetail(list)}
                            >
                              Detail
                            </Button>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <img
                                src={list.img_url}
                                style={{
                                  display: 'block',
                                  maxWidth: '650px',
                                  maxHeight: '650px',
                                  width: 'auto',
                                  height: 'auto',
                                }}
                                onClick={() => this.handleImage(list)}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardBody></CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  ) : null}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['chat', 'detailChat'], {}),
  loading: safeDeepGet(state, ['chat', 'loading'], true),
  refundResult: safeDeepGet(state, ['transaction', 'refunded'], {}),
  test: state,
});
DetailTransactions.propTypes = {
  onRefund: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  DetailTransaction: payload => dispatch(detailChat(payload)),
  RefundTransaction: payload => dispatch(updateRefund(payload)),
  onRefund: refund => dispatch(refunded(refund)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailTransactions);
