import React, { Component } from 'react';

import { Container, Row, Col, Card, CardBody, Label, Button } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';

import { connect } from 'react-redux';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import { Link } from 'react-router-dom';
import ReactExport from 'react-data-export';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import {
  list_business_points,
  snapshot,
  download_snapshot,
} from '../../store/transaction/actions';
import { list_distributor } from '../../store/distributor/actions';
import { list_vm } from '../../store/refiller/actions';
import { PROJECT } from '../../../Project';
//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
class Snapshot extends Component {
  constructor(props) {
    super(props);
    this.refDatePicker = React.createRef();
    var current_month = new Date();
    this.state = {
      bpfilterlist: [],
      loading: true,
      businessPoints: { label: 'All Refiller', value: 'all' },
      Snapshot: 'all',
      reports: [
        {
          icon: 'bx bx-copy-alt',
          title: 'Landlord Profit Share',
          value: 'Rp 0',
          badgeValue: '+ 0.0%',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-copy-alt',
          title: 'Average Daily Transactions',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-archive-in',
          title: 'Average Daily Settlement',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Average Daily Refund',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
      ],
      selectedMonthYear: 
      current_month.getFullYear().toString() +
      '-' +
      ('0' + (current_month.getMonth() + 1)).slice(-2).toString(),
    };
    this.handleSnapshot = this.handleSnapshot.bind(this);
    this.handleBusinessPoint = this.handleBusinessPoint.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleValidDate = this.handleValidDate.bind(this);
    this.handleRefundDate = this.handleRefundDate.bind(this);
  }
  handleSnapshot = Snapshot => {
    this.setState({ Snapshot });
  };
  handleBusinessPoint = businessPoints => {
    this.setState({ businessPoints });
    let val = this.state.selectedMonthYear;

    const payload = {
      role: 'refiller',
      fillter: businessPoints.value ? businessPoints.value : businessPoints,
      month: val == null ? null : parseInt(val.split('-')[1]) - 1,
      year: val == null ? null : parseInt(val.split('-')[0]),
    };
    this.props.DownloadSnapshot(payload);
  };
  handleMonthYear = selectedMonthYear => {
    let val = selectedMonthYear.target.value;
    this.setState({ selectedMonthYear: val });

    const payload = {
      role: 'refiller',
      fillter: this.state.businessPoints.value
        ? this.state.businessPoints.value
        : this.state.businessPoints,
      month: val == null ? null : parseInt(val.split('-')[1]) - 1,
      year: val == null ? null : parseInt(val.split('-')[0]),
    };
    this.props.DownloadSnapshot(payload);
  };
  handleDownload = () => {
    let val = this.state.selectedMonthYear;

    const payload = {
      role: 'refiller',
      month: val == null ? null : parseInt(val.split('-')[1]) - 1,
      year: val == null ? null : parseInt(val.split('-')[0]),
      fillter: this.state.businessPoints.value
        ? this.state.businessPoints.value
        : this.state.businessPoints,
    };
    this.props.DownloadSnapshot(payload);
  };
  handleValidDate = date => {
    if (date) {
      const date1 = date.toDate().toLocaleDateString('en-GB');
      const time1 = date.toDate().toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    }
  };
  handleRefundDate = date => {
    if (date) {
      const d = new Date(date).toLocaleDateString('en-GB');
      return d;
    }
  };
  componentDidMount() {
    // this.props.Snapshot();
    let code = '';
    let type = '';
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj == null) {
      code = 'all';
      type = 'all';
    } else {
      if (obj.role == 'admin') {
        code = null;
        type = null;
      } else {
        code = obj.code;
        type = obj.role;
      }
    }
    const data = { code: code, type: type };

    this.props.LoadBpFilterList(data);
    let val = this.state.selectedMonthYear;

    const payload = {
      role: 'refiller',
      month: val == null ? null : parseInt(val.split('-')[1]) - 1,
      year: val == null ? null : parseInt(val.split('-')[0]),
      fillter: this.state.businessPoints.value
        ? this.state.businessPoints.value
        : this.state.businessPoints,
    };
    this.props.DownloadSnapshot(payload);
  }
  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    let i = 1;
    if (list.length > 0) {
      for (const el in list) {
       
        let dispense = list[el].detail.dispense_status;
        const transactionStatus = list[el].detail.transaction_status;
        const firebaseTimestamp = list[el].time.firestore_timestamp;
        var transactionTime = firebaseTimestamp.toDate();
        let finalStatus = ''
        if (transactionStatus == 'settlement' && dispense == 'success') {
          finalStatus = 'Transaksi Sukses';
        } else if (
          transactionStatus == 'settlement' &&
          dispense == 'no_dispense'
        ) {
          var twoDaysLater = transactionTime.setDate(
            transactionTime.getDate() + 2
          );
          var now = new Date();
          if (now < twoDaysLater) {
            finalStatus = 'Gagal Dispense (Potential Refund)';
          } else {
            // more than 2 * 24 hours
            finalStatus = 'Transaksi Sukses';
          }
        } else if (transactionStatus == 'pending') {
          finalStatus = 'Tidak Terjadi Transaksi';
        } else if (transactionStatus == 'refund') {
          finalStatus = 'Refund';
        }
        const provider = safeDeepGet(
          list[el],
          ['payment', 'detail', 'issuer'],
          ''
        );
        let paymentIssuer = '';
        if (provider == '') {
          paymentIssuer = safeDeepGet(list[el], ['payment', 'method'], '');
        } else {
          if (provider == 'AIRPAY SHOPEE') {
            paymentIssuer = 'SHOPEEPAY';
          } else {
            paymentIssuer = provider;
          }
        }
        const amount = list[el].payment.amount;

        const Persentase = 7 / 1000;
        const mdr = amount * Persentase;
        const net = amount - mdr;

        let sn = '';
        // if (list[el].sn.slice(0, 2) == 'VM') {
        //   sn = list[el].sn.slice(2, 10);
        // } else {
        //   sn = list[el].sn;
        // }

        var trxId = '';

        if (paymentIssuer == 'RFID') {
          trxId = this.handleValidDate(list[el].time.firestore_timestamp);
        } else {
          trxId = list[el].payment.detail.transaction_id;
        }
        var firebaseRefundTimestamp = safeDeepGet(
          list[el],
          ['detail', 'refund_time'],
          null
        );
        let refund = '';

        if (
          firebaseRefundTimestamp != null ||
          firebaseRefundTimestamp != undefined
        ) {
          const time = firebaseRefundTimestamp;
          const timestirng = time.toString();
          const splitString = timestirng.slice(0, 13);

          var refundTime = this.handleRefundDate(Number(splitString));
          if (refundTime != null) {
            refund = refundTime;
          }
        }

        trx_data = {
          no: i++,
          date: this.handleValidDate(list[el].time.firestore_timestamp),
          business_point: list[el].name,
          sn: sn,
          distributor: list[el].NameDistributor,
          channel: list[el].channel,
          sku: list[el].product.sku,
          total: amount,
          mdr: mdr,
          net: net,
          provider: paymentIssuer,
          order_id: safeDeepGet(list[el], ['detail', 'order_id'], ''),
          trx_id: trxId,
          payment_status: list[el].detail.transaction_status,
          refundTime: refund,
          dispense_status: safeDeepGet(list[el], ['detail', 'dispense_status']),
          finalStatus: finalStatus,
        };
        dataSet.push(trx_data);
      }
    } else {
      dataSet.push(trx_data);
    }
    return dataSet;
  };
  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  handleExport = list => {
    var data = list;
    let headerAdmin = [
      'No',
      'Time',
      'Business Point',
      'SN Milano',
      'Distributor',
      'Channel',
      'Product Name',
      'Price on Time (Include Promo)',
      'MDR',
      'Net Price',
      'Provider',
      'Order Id',
      'TRX ID',
      'Transaction',
      'Refund Time',
      'Dispense Status',
      'Final Status',
    ];
    let header = [
      'No',
      'Time',
      'Business Point',
      'SN Milano',
      'Distributor',
      'Channel',
      'Product Name',
      'Price on Time (Include Promo)',
      'MDR',
      'Net Price',
      'Provider',
      'Order Id',
      'TRX ID',
      'Refund Time',
      'Final Status',
    ];

    const obj = JSON.parse(localStorage.getItem('role'));
    const { selectedMonthYear, businessPoints } = this.state;
    let val = selectedMonthYear;
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    let date = 'all';
    let month = '';
    let year = '';
    let A2 = '';
    if (val == null) {
      month = 'all';
      year = '';
      A2 = 'ALL TRX REPORT';
    } else {
      date = new Date(
        parseInt(val.split('-')[0]),
        parseInt(val.split('-')[1]) - 1,
        1
      );
      month = date.toLocaleString('default', { month: 'long' });
      year = parseInt(val.split('-')[0]);
      A2 = 'TRX REPORT ' + month + ' ' + year;
    }
    XlsxPopulate.fromBlankAsync().then(async workbook => {
      const sheet1 = workbook.sheet(0);
      let sheetData;
      if (obj == null) {
        sheetData = this.getSheetData(data, headerAdmin);
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheetData = this.getSheetData(data, headerAdmin);
        } else {
          sheetData = this.getSheetData(data, header);
        }
      }
      const totalColumns = sheetData[0].length;
      sheet1.cell('A15').value(sheetData);
      sheet1.cell('A1').value(bp_name);
      sheet1.cell('A2').value(A2);

      // CUP Sales
      sheet1.cell('A3').value('TOTAL CUP SALES');
      sheet1
        .cell('A3')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });

      if (obj == null) {
        sheet1
          .cell('B3')
          .formula(
            '=COUNTIFS($Q:$Q,"Transaksi Sukses")+COUNTIFS($Q:$Q,"Refund")'
          );
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($Q:$Q,"Transaksi Sukses")+COUNTIFS($Q:$Q,"Refund")'
            );
        } else {
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($O:$O,"Transaksi Sukses")+COUNTIFS($O:$O,"Refund")'
            );
        }
      }
      sheet1
        .cell('B3')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });

      // Total Sales
      sheet1.cell('A4').value('TOTAL SALES (VALUE)');
      sheet1
        .cell('A4')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1
          .cell('B4')
          .formula(
            '=SUMIF($Q:$Q,"Transaksi Sukses",$H:$H)+SUMIF($Q:$Q,"Refund",$H:$H)'
          );
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1
            .cell('B4')
            .formula(
              '=SUMIF($Q:$Q,"Transaksi Sukses",$H:$H)+SUMIF($Q:$Q,"Refund",$H:$H)'
            );
        } else {
          sheet1
            .cell('B4')
            .formula(
              '=SUMIF($0:$0,"Transaksi Sukses",$H:$H)+SUMIF($0:$0,"Refund",$H:$H)'
            );
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($O:$O,"Transaksi Sukses")+COUNTIFS($O:$O,"Refund")'
            );
        }
      }
      sheet1.cell('B4').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      //Refund
      sheet1.cell('A5').value('REFUND');
      sheet1
        .cell('A5')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1.cell('B5').formula('=SUMIF($Q:$Q,"Refund",$H:$H)');
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1.cell('B5').formula('=SUMIF($Q:$Q,"Refund",$H:$H)');
        } else {
          sheet1.cell('B5').formula('=SUMIF($O:$O,"Refund",$H:$H)');
        }
      }
      sheet1.cell('B5').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      // Total Sales (Actual)
      sheet1.cell('A6').value('TOTAL SALES (ACTUAL)');
      sheet1.cell('B6').formula('B4-B5');
      sheet1.cell('B6').style('numberFormat', 'Rp #,###.0; -');

      // MDR
      sheet1.cell('A7').value('MDR');
      sheet1
        .cell('A7')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1.cell('B7').formula('=SUMIFS($I:$I,$Q:$Q,"Transaksi Sukses")');
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1.cell('B7').formula('=SUMIFS($I:$I,$Q:$Q,"Transaksi Sukses")');
        } else {
          sheet1.cell('B7').formula('=SUMIFS($I:$I,$O:$O,"Transaksi Sukses")');
        }
      }
      sheet1.cell('B7').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      sheet1.cell('C7').value('0.7%');

      //Net Tranfer
      sheet1.cell('A10').value('Net Transfer to Distributor');
      sheet1.cell('B10').formula('=B6-B7');
      sheet1.cell('B10').style('numberFormat', 'Rp #,###.0; -');

      sheet1.cell('A12').value('PPn');
      sheet1.cell('B12').formula('=B10*11%/110%');
      sheet1.cell('B12').style('numberFormat', 'Rp #,###.0; -');
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style('bold', true);
      sheet1.range('A15:' + endColumn + '15').style('fill', '2172d7');
      // range.style('border', true);

      var filename;
      if (obj == null) {
        filename = 'report_Report_Transaction_Ho_' + bp_name + '.xlsx';
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          filename = 'report_Report_Transaction_Ho_' + bp_name + '.xlsx';
        } else {
          filename = 'report_Report_Transaction_Dist_' + bp_name + '.xlsx';
        }
      }

      return workbook.outputAsync().then(res => {
        saveAs(res, filename);
      });
    });
  };
  render() {
    const { selectedMonthYear, businessPoints } = this.state;
    const optionSnapshot = safeDeepGet(
      this.props,
      ['snapshotresult', 'body', 'options'],
      []
    );
    const options = safeDeepGet(this.props, ['bpfilterresult', 'body'], []);
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const loading = safeDeepGet(this.props, ['result', 'loading'], true);
    // this.setState({loading})
    const report_list = this.format_report(list);
    let val = selectedMonthYear;
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    let date = 'all';
    let month = ' ';
    let year = ' ';
    if (val == null) {
      month = 'all';
      year = ' ';
    } else {
      date = new Date(
        parseInt(val.split('-')[0]),
        parseInt(val.split('-')[1]) - 1,
        1
      );
      month = date.toLocaleString('default', { month: 'long' });
      year = parseInt(val.split('-')[0]);
    }

    const report_filename = bp_name + ' Snapshot ' + month + ' ' + year;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Transaction Snapshot Refiiler | Smart Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Snapshot" breadcrumbItem="Refiller" />
            <Row>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col lg="4" className="align-self-center">
                      <Label>Refiller</Label>
                      <Select
                        // defaultValue={options[0]}
                        value={businessPoints}
                        placeholder={'All Refiller'}
                        onChange={this.handleBusinessPoint}
                        options={options}
                        classNamePrefix="select2-selection"
                        maxMenuHeight={100}
                      />
                    </Col>
                    <Col lg="4" className="align-self-center">
                      <Label>Month & Year</Label>
                      <input
                        className="form-control input"
                        type="month"
                        // value="2019-05"
                        id="month-input"
                        value={selectedMonthYear}
                        onChange={this.handleMonthYear}
                      />
                    </Col>
                    {/* <Col lg="3" className="align-self-center">
                      <Select
                        defaultValue={optionSnapshot[0]}
                        value={Snapshot}
                        onChange={this.handleSnapshot}
                        options={optionSnapshot}
                        classNamePrefix="select2-selection"
                        maxMenuHeight={100}
                      />
                    </Col> */}
                    <Col lg="4" className="d-none d-lg-block" align="center">
                      <div className="clearfix mt-4 mt-lg-0">
                        <Button
                          type="button"
                          color="primary"
                          className="btn btn-primary w-sm"
                          disabled={loading}
                          onClick={() => this.handleExport(report_list)}
                        >
                          <i className="mdi mdi-download d-block font-size-16"></i>
                          Download Report
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  snapshotresult: safeDeepGet(state, ['transaction', 'snapshot'], {}),
  bpfilterresult: safeDeepGet(state, ['refiller', 'list_vm'], {}),
  summaryresult: safeDeepGet(state, ['transaction', 'summary'], {}),
  netsettlementresult: safeDeepGet(state, ['dashboard', 'nett_settlement'], {}),
  salesanalyticsresult: safeDeepGet(
    state,
    ['transaction', 'get_sales_analytics'],
    {}
  ),
  loading: safeDeepGet(state, ['dashboard', 'loading'], true),
  result: safeDeepGet(state, ['transaction', 'download_snapshot'], []),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  LoadBpFilterList: () => dispatch(list_vm()),
  DownloadSnapshot: payload => dispatch(download_snapshot(payload)),
  Snapshot: () => dispatch(snapshot()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snapshot);
