export const LOADING = "LOADING"
export const RESULT = "RESULT"

export const LIST = "ERROR_EXPLANATION_LIST"
export const LIST_VM = "ERROR_EXPLANATION_LIST_VM"
export const INIT_CREATE = "ERROR_EXPLANATION_INIT_CREATE"
export const CREATE = "ERROR_EXPLANATION_CREATE"
export const READ = "ERROR_EXPLANATION_INFO"
export const UPDATE = "ERROR_EXPLANATION_UPDATE"
export const STOCK_UPDATE = "STOCK_ERROR_EXPLANATION_UPDATE"
export const DELETE = "ERROR_EXPLANATION_DELETE"
