import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"


// Dashboard 
import dashboard from "./dashboard/reducer"

// Business point
import businessPoint from "./business-point/reducer"

// Product
import product from "./product/reducer"

//Payout
import payout from "./payout/reducer"
// Transaction
import transaction from "./transaction/reducer"
import rfid from "./rfid/reducer"

import chat from "./chat/reducer"
import refiller from "./refiller/reducer"
import role from "./Role/reducer"
import coupon from "./coupon/reducer"
import discount from "./discount/reducer"
import accountRole from "./account/reducer"
import alert from "./alert/reducer"
import errorEx from "./errorEx/reducer"
import subs from "./subs/reducer"
import invoice from "./invoice/reducer"
import category from "./category/reducer"
import projectConfig from "./projectConfig/reducer"
import tag from "./tag/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  dashboard,
  businessPoint,
  product,
  transaction,
  payout,
  chat,
  discount,
  coupon,
  errorEx,
  subs,
  invoice,
  alert,
  refiller,
  role,
  accountRole,
  category,
  projectConfig,
  rfid,
  tag
})

export default rootReducer
