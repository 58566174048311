/* eslint-disable no-undef */
// User Create new Invoice

// Invoice muncul di Table

// User upload data di Table

/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  Label,
  Table,
  InputGroup,
} from 'reactstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import axios from 'axios';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
// Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
import { REFUND_PERIOD, PROJECT_HANDLE, buildTitle } from 'Apps';
import { list, create } from '../../store/invoice/actions';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Pages403 from '../pages-403';
import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      success_dlg: false,
      error_dlg: false,
      modal: false,
      chatListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          searchable: false,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'invoice_id',
          text: 'Invoice Id',
          sort: true,
          editable: false,
          formatter: (cellContent, row) => (
            <>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className="mb-1"
                onClick={() => this.handleDetail(row)}
              >
                {row.invoice_id}
              </p>
            </>
          ),
        },
        {
          dataField: 'date',
          text: 'Request Date',
          editable: false,
          sort: true,
          formatter: (cellContent, row) => (
            <p className="mb-1">{this.handleValidDate(row.ts)}</p>
          ),
        },
        {
          dataField: 'invoice',
          text: 'Bukti Perhitungan',
          sort: true,
          editable: false,
          formatter: (cellContent, row) => (
            <>
              {row.invoice == '' ? (
                <Button
                  type="button"
                  color="primary"
                  className="btn btn-nestle w-sm"
                  onClick={() => this.handleDetail(row)}
                >
                  <i className="mdi mdi-upload font-size-12 m-auto"></i> Upload
                </Button>
              ) : (
                <Button
                  type="button"
                  color="primary"
                  className="btn btn-nestle w-sm"
                  onClick={() =>
                    this.handleDownload(
                      row.bukti_transfer,
                      'invoice_' + row.id + '.' + row.invoice_ext,
                      row.invoice_ext
                    )
                  }
                >
                  <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                  Download
                </Button>
              )}
            </>
          ),
        },
        {
          dataField: 'bukti_transfer',
          text: 'Bukti Transfer',
          sort: true,
          editable: false,
          formatter: (cellContent, row) => (
            <>
              {row.bukti_transfer == '' ? (
                <Button
                  type="button"
                  color="primary"
                  className="btn btn-nestle w-sm"
                  onClick={() => this.handleDetail(row)}
                >
                  <i className="mdi mdi-upload font-size-12 m-auto"></i> Upload
                </Button>
              ) : (
                <Button
                  type="button"
                  color="primary"
                  className="btn btn-nestle w-sm"
                  onClick={() =>
                    this.handleDownload(
                      row.bukti_transfer,
                      'bukti_transfer_' + row.id + '.' + row.bukti_transfer_ext,
                      row.invoice_ext
                    )
                  }
                >
                  <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                  Download
                </Button>
              )}
            </>
          ),
        },
      ],
    };
    this.showToast = this.showToast.bind(this);
    // this.handleFile = this.handleFile.bind(this);
  }

  handleDownload = async (url, fileName, ext) => {
    if (ext == 'jpeg' || ext == 'jpg' || ext == 'png') {
      axios
        .get(url, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      window.open(url)
    }
  };

  componentDidMount() {
    this.props.InvoiceList();
  }
  handleDetail(row) {
    this.props.history.push('/invoice/' + row.id + '/info');
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        this.props.InvoiceList();
      } else if (code == Result.FAILED) {
        console.log(code);
      }
    }
  };
  handleCreateClick() {
    const year = new Date().getFullYear();
    const ts_request = new Date().getTime();
    const id = 'INVC' + ts_request + year;
    const payload = {
      invoice_no: id,
      ts_request,
    };
    this.props.CreateInvoice(payload);
    this.setState({
      confirm_alert: false,
      success_dlg: true,
      dynamic_title: 'Created',
      dynamic_description: 'Invoice has been created.',
    });
  }
  showToast() {
    var toastType = 'success';
    var message = 'Invoice Deleted';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
    window.location.reload();
  }

  handleValidDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'invoice_id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          
            <MetaTags>
              <title>{buildTitle('Invoice List')}</title>
            </MetaTags>
          {!permision ||
          safeDeepGet(navbar, ['Transaction', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Invoice" breadcrumbItem="List" />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="order_id"
                        columns={this.state.chatListColumns}
                        data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="order_id"
                            columns={this.state.chatListColumns}
                            data={list}
                            search
                          >
                            {toolkitprops => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="9"></Col>
                                  <Col sm="3">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded mb-2 me-2"
                                        onClick={() =>
                                          this.setState({
                                            confirm_alert: true,
                                          })
                                        }
                                        // href="/business-point/create"
                                      >
                                        <i className="mdi mdi-plus me-1" /> New
                                        Invoice
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        cellEdit={cellEditFactory({
                                          mode: 'click',
                                          blurToSave: true,
                                        })}
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          'table align-middle table-nowrap table-hover'
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={this.node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {this.state.confirm_alert ? (
                                  <SweetAlert
                                    title="Are you sure?"
                                    warning
                                    showCancel
                                    confirmButtonText="Yes, Create it!"
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() => this.handleCreateClick()}
                                    onCancel={() =>
                                      this.setState({
                                        confirm_alert: false,
                                      })
                                    }
                                  >
                                    You won&apos;t be able to revert this!
                                  </SweetAlert>
                                ) : null}
                                {this.state.success_dlg ? (
                                  <SweetAlert
                                    success
                                    title={this.state.dynamic_title}
                                    onConfirm={() =>
                                      this.setState({ success_dlg: false })
                                    }
                                  >
                                    {this.state.dynamic_description}
                                  </SweetAlert>
                                ) : null}
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}
class BuktiTransfer extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
  };
  static defaultProps = {
    value: 0,
  };
  getValue = (e, values) => {
    const { onUpdate, row, ...rest } = this.props;
    const selection = safeDeepGet(row, 'selection', '');
    const product = safeDeepGet(row, 'product', '');
    const amountHpp = Number(safeDeepGet(values, 'hpp', 0));
    const amountShare = Number(safeDeepGet(values, 'share', 0));
    const price = amountHpp + amountShare;
    const body = {
      selection: selection,
      product: product,
      Hpp: amountHpp,
      Share: amountShare,
      total: price,
    };
    this.props.onPriceChange(body);
    onUpdate(price);
    return price;
  };

  render() {
    const { value, onUpdate, modal, row, ...rest } = this.props;
    return [
      <Modal
        key="modal"
        size="lg"
        isOpen={modal}
        className={this.props.className}
      >
        <ModalHeader tag="h4">{'Set Price'}</ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={this.getValue}>
            <Row>
              <div className="mb-3">
                <Label className="mt-1" htmlFor="selectAccount">
                  HPP :
                </Label>
                <AvField
                  type="number"
                  id="hpp"
                  name="hpp"
                  value={safeDeepGet(row, 'hpp', 0)}
                  placeholder="Masukan HPP..."
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
            </Row>
            <Row>
              <div className="mb-3">
                <Label className="mt-1" htmlFor="selectAccount">
                  {nameBenefit}
                </Label>
                <AvField
                  type="number"
                  id="share"
                  name="share"
                  value={safeDeepGet(row, 'share', 0)}
                  placeholder={'Masukan ' + nameBenefit + ' ....'}
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
            </Row>

            <Row>
              <Col></Col>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>,
    ];
  }
}
class InvoiceUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
    };
  }
  static propTypes = {
    value: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
    modal: PropTypes.bool,
  };
  static defaultProps = {
    value: 0,
  };
  static defaultProps = {
    value: 0,
  };
  getValue() {
    return this.state.file;
  }
  handleFile(event) {
    const fakepath = event.target.value;
    const fileExt = fakepath.slice(((fakepath.lastIndexOf('.') - 1) >>> 0) + 2);
    this.setState({
      fileUpdated: true,
      file: URL.createObjectURL(event.target.files[0]),
      fileFile: event.target.files[0],
      fileExt,
    });
  }
  render() {
    const { value, onUpdate, ...rest } = this.props;
    console.log(value);
    return [
      <AvForm key="form" onValidSubmit={this.handleValidSubmit}>
        <Row>
          <Input {...rest} key="file" type="file" onChange={this.handleFile} />
        </Row>
        <Row>
          <Button key="submit" type="submit" color="primary">
            Simpan
          </Button>
        </Row>
      </AvForm>,
    ];
  }
}
const mapStateToProps = state => ({
  createResult: safeDeepGet(state, ['invoice', 'create'], {}),
  result: safeDeepGet(state, ['invoice', 'list'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  CreateInvoice: payload => dispatch(create(payload)),
  InvoiceList: () => dispatch(list()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
