export const LOADING = 'LOADING';
export const RESULT = 'RESULT';

export const LIST_BUSINESS_POINTS = 'TRANSACTION_LIST_BUSINESS_POINTS';
export const LIST = 'TRANSACTION_LIST';
export const READ = 'TRANSACTION_READ';
export const REFUND = 'TRANSACTION_REFUND';
export const UPDATE_REFUND = 'TRANSACTION_REFUND';
export const SNAPSHOT = 'TRANSACTION_SNAPSHOT';
export const DOWNLOAD_SNAPSHOT = 'TRANSACTION_DOWNLOAD_SNAPSHOT';
export const SUMMARY = 'TRANSACTION_SUMMARY';
export const GET_SALES_ANALYTICS = 'TRANSACTION_GET_SALES_ANALYTICS';
export const REFUNDED = "TRANSACTION_REFUNDED";
export const REFUND_LIST = "TRANSACTION_REFUND_LIST";
export const DETAIL = "TRANSACTION_DETAIL"
export const SNAPSHOT_DAILY = "TRANSACTION_SNAPSHOT_DAILY"
export const SNAPSHOT_MONTHLY = "TRANSACTION_SNAPSHOT_MONTHLY"