export default class Distributor {

    constructor(firebase) {
      this.collection = firebase.firestore().collection("distributor");
    }
  
    list = () => {
      return new Promise((resolve, reject) => {
        const list = {};
        this.collection.get().then(
          querySnapshot => {
            querySnapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            resolve({ code: -1, body: list });
          }
        );
      });
    };
  
    create = (id, payload) => {
      return new Promise((resolve, reject) => {
        delete payload.email;
        delete payload.pass;
        const insertedPayload = {};
        for (const key in payload) {
          if (
            key === 'code' ||
            key === 'name' ||
            key === 'telegram_group' 
          ) {
            insertedPayload[key] = payload[key];
          }
      }
        this.collection
        .doc(id)
          .set(insertedPayload)
          .then(
            () => {
              resolve({ code: 0 });
            },
            error => {
              resolve({ code: -1, body: error });
            }
          );
      });
    };
  
    read = sku => {
      return new Promise((resolve, reject) => {
        let map = {};
        this.collection
          .where('sku', '==', sku)
          .get()
          .then(
            querySnapshot => {
              let code = -1;
              if (querySnapshot.size > 0) {
                code = 0;
                map = { ...querySnapshot.docs[0].data() };
              }
              resolve({ code: code, body: map });
            },
            error => {
              resolve({ code: -1, body: map });
            }
          );
      });
    };
  
    update = (id, payload) => {
      return new Promise((resolve, reject) => {
        
        const updatedPayload = {};
        for (const key in payload) {
          if (
            key === 'code' ||
            key === 'name' ||
            key === 'telegram_group' 
          ) {
            updatedPayload[key] = payload[key];
          }
        }
  
        this.collection
          .doc(id)
          .update(updatedPayload)
          .then(
            () => {
              resolve({ code: 0 });
            },
            error => {
              resolve({ code: -1, body: error });
            }
          );
      });
    };
  
    updateStock = (id, payload) => {
      return new Promise((resolve, reject) => {
        
        const updatedPayload = {};
        for (const key in payload) {
          if (
            key === 'name' ||
            key === 'desc' ||
            key === 'warehouse_stock'
          ) {
            updatedPayload[key] = payload[key];
          }
        }
  
        this.collection
          .doc(id)
          .update(updatedPayload)
          .then(
            () => {
              resolve({ code: 0 });
            },
            error => {
              resolve({ code: -1, body: error });
            }
          );
      });
    };
    delete_ = deviceId => {
      this.collection
        .doc(deviceId)
        .delete()
    };
  }
  