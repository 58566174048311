export default class Product {
  constructor(firebase) {
    this.collection = firebase.firestore().collection('product');
    this.FieldValue = firebase.firestore.FieldValue;
  }
  addCsv = payload => {
    return new Promise((resolve, reject) => {
      for (var i in payload) {
        var id = payload[i]['sku'];
        payload[i]['sku'] = payload[i]['sku'];
        payload[i]['price'] = Number(payload[i]['price']);
        payload[i]['order'] = Number(payload[i]['order']);
        delete payload[i]['DeviceId'];
        delete payload[i]['amount 1'];
        delete payload[i]['amount 2'];
        delete payload[i]['amount 3'];
        delete payload[i]['amount 4'];
        delete payload[i]['ingredients 1'];
        delete payload[i]['ingredients 2'];
        delete payload[i]['ingredients 3'];
        delete payload[i]['ingredients 4'];
        if (id !== undefined) {
          this.collection
            .doc(id)
            .set(payload[i])
            .then(
              () => {
                resolve({
                  code: 0,
                  Message: 'Berhasil Upload Csv Ke Database',
                });
              },
              error => {
                resolve({ code: -1, body: error });
              }
            );
        }
      }
    });
  };
  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };

  create = (id, payload, ingredients) => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'display_name' ||
          key === 'price' ||
          key === 'size_name' ||
          key === 'description' ||
          key === 'sku' ||
          key === 'img_url'
        ) {
          insertedPayload[key] = payload[key];
        }
      }
      insertedPayload['ingredients'] = ingredients;

      if (payload.sizeName == 'M') {
        insertedPayload['cup_size'] = 7;
      } else {
        insertedPayload['cup_size'] = 11;
      }

      // Add sku
      insertedPayload['name'] = id;

      // Add

      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = sku => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('__name__', '==', sku)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'displayName' ||
          key === 'price' ||
          key === 'sizeName' ||
          key === 'description' ||
          key === 'ingredients' ||
          key === 'imgUrl'
        ) {
          updatedPayload[key] = payload[key];
        }
      }
      if (payload.sizeName == 'M') {
        updatedPayload['cupSize'] = '7';
      } else {
        updatedPayload['cupSize'] = '11';
      }
      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  updateStock = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'name' || key === 'desc' || key === 'warehouse_stock') {
          updatedPayload[key] = payload[key];
        }
      }

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = id => {
    return new Promise((resolve, reject) => {
      this.collection
      .doc(id)
      .delete()
      .then(
        () => {
          resolve({ code: 0 });
        },
        error => {
          resolve({ code: -1, body: error });
        }
        );
      });
  };

  setDiscount = (id, discountPrice) => {
    return new Promise((resolve, reject) => {
      this.collection
        .doc(id)
        .update({ discountPrice: discountPrice })
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  deleteDiscount = id => {
    return new Promise((resolve, reject) => {
      this.collection
        .doc(id)
        .update({ discountPrice: this.FieldValue.delete() })
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
}
