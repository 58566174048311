import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

import { list_options, list_bean } from '../../store/ingredient/actions';
import { list, refill_list, create } from '../../store/stock/actions';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
class StockBusinessPoint extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let deviceId = null;

    deviceId = safeDeepGet(this.props.match.params, 'id');

    this.state = {
      isEdit,
      deviceId,
      image: null,
      imageUpdated: false,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleChangecanister1 = this.handleChangecanister1.bind(this);
    this.handleChangecanister2 = this.handleChangecanister2.bind(this);
    this.handleChangecanister3 = this.handleChangecanister3.bind(this);
    this.handleChangecanister4 = this.handleChangecanister4.bind(this);
    this.handleChangeBeans = this.handleChangeBeans.bind(this);
    this.handleRefill = this.handleRefill.bind(this);
    this.handleRefilllid = this.handleRefilllid.bind(this);
    this.handleRefillWater = this.handleRefillWater.bind(this);
    this.handleRefillstir = this.handleRefillstir.bind(this);
    this.handleRefillcupm = this.handleRefillcupm.bind(this);
    this.handleRefillcupl = this.handleRefillcupl.bind(this);
    this.handleRefillCanister4 = this.handleRefillCanister4.bind(this);
    this.handleRefillCanister3 = this.handleRefillCanister3.bind(this);
    this.handleRefillCanister2 = this.handleRefillCanister2.bind(this);
    this.handleRefillCanister1 = this.handleRefillCanister1.bind(this);
    this.handleRefillBeans = this.handleRefillBeans.bind(this);
    this.showToastAdd = this.showToastAdd.bind(this);
  }

  handleChangecanister1(value) {
    this.setState({ canister1: value.value });
  }

  handleChangecanister2(value) {
    this.setState({ canister2: value.value });
  }
  handleChangecanister3(value) {
    this.setState({ canister3: value.value });
  }

  handleChangecanister4(value) {
    this.setState({ canister4: value.value });
  }

  handleChangeBeans(value) {
    this.setState({ beans: value.value });
  }
  showToastAdd() {
    var toastType = 'success';
    var message = 'Berhasil Update Stock';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleValidSubmit(event, values) {
    const {
      isEdit,
      deviceId,
      beans,
      canister1,
      canister2,
      canister3,
      canister4,
    } = this.state;
    const submittedValues = {
      ...values,
      canister1: canister1,
      canister2: canister2,
      canister3: canister3,
      canister4: canister4,
      beans: beans,
      id: deviceId,
    };
    this.props.StockCreate(submittedValues);
    this.props.history.replace('/business-point/list');
    this.showToastAdd();
  }
  // Refill All
  handleRefill() {
    const { canister1, canister2, canister3, canister4, beans } = this.state;
    const newState = {};

    const amountbeans = safeDeepGet(this.props, [
      'refillStock',
      'body',
      beans,
      'max',
    ]);
    newState['amountbeans'] = Number(amountbeans);
    const amount1 = safeDeepGet(
      this.props,
      ['refillStock', 'body', canister1, 'max'],
      0
    );
    newState['amount1'] = Number(amount1);
    const amount2 = safeDeepGet(
      this.props,
      ['refillStock', 'body', canister2, 'max'],
      0
    );
    newState['amount2'] = Number(amount2);
    const amount3 = safeDeepGet(
      this.props,
      ['refillStock', 'body', canister3, 'max'],
      0
    );
    newState['amount3'] = Number(amount3);
    const amount4 = safeDeepGet(
      this.props,
      ['refillStock', 'body', canister4, 'max'],
      0
    );
    newState['amount4'] = Number(amount4);
    newState['water'] = 19000;
    newState['cupl'] = 300;
    newState['cupm'] = 300;
    newState['stir'] = 300;
    newState['lid'] = 300;

    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefilllid() {
    const newState = {};
    newState['lid'] = 300;

    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillWater() {
    const newState = {};
    newState['water'] = 19000;
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillstir() {
    const newState = {};
    newState['stir'] = 300;
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillcupm() {
    const newState = {};
    newState['cupm'] = 300;
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillcupl() {
    const newState = {};
    newState['cupl'] = 300;
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillCanister1() {
    const { canister2, canister3, canister1, beans, amount1 } = this.state;
    const newState = {};
    let amount = amount1;
 
    const maxCanister1 = safeDeepGet(this.props, [
      'refillStock',
      'body',
      canister1,
      'max',
    ]);
    if (amount + Number(maxCanister1) <= 1000) {
      amount += Number(maxCanister1);
      newState['amount1'] = Number(amount);
    } else {
      amount = 1000;
      newState['amount1'] = Number(amount);
    }
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillCanister2() {
    const { canister2, amount2} = this.state;
    const newState = {};
    let amount = amount2;
    const maxCanister2 = safeDeepGet(this.props, [
      'refillStock',
      'body',
      canister2,
      'max',
    ]);
    if (amount + Number(maxCanister2) <= 1000) {
      amount += Number(maxCanister2);
      newState['amount2'] = Number(amount);
    } else {
      amount = 1000;
      newState['amount2'] = Number(amount);
    }
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillCanister3() {
    const { canister3, amount3 } = this.state;
    const newState = {};
    let amount = amount3;
    const maxCanister3 = safeDeepGet(this.props, [
      'refillStock',
      'body',
      canister3,
      'max',
    ]);
    if (amount + Number(maxCanister3) <= 1000) {
      amount += Number(maxCanister3);
      newState['amount3'] = Number(amount);
    } else {
      amount = 1000;
      newState['amount3'] = Number(amount);
    }
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillCanister4() {
    const { canister1, canister2, canister3, canister4, beans } = this.state;
    const newState = {};
    let amount = amount4;
    const maxCanister4 = safeDeepGet(this.props, [
      'refillStock',
      'body',
      canister4,
      'max',
    ]);
    if (amount + Number(maxCanister4) <= 1000) {
      amount += Number(maxCanister4);
      newState['amount4'] = Number(amount);
    } else {
      amount = 1000;
      newState['amount4'] = Number(amount);
    }
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }
  handleRefillBeans() {
    const { beans, amountbeans } = this.state;
    const newState = {};
    let amount = amountbeans;
  
    const maxbeans = safeDeepGet(this.props, [
      'refillStock',
      'body',
      beans,
      'max',
    ]);
    if (amount + Number(maxbeans) <= 4000) {
      amount += Number(maxbeans);
      newState['amountbeans'] = Number(amount);
    } else {
      amount = 4000;
      newState['amountbeans'] = Number(amount);
    }
    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }

  componentDidMount() {
    const { isEdit, deviceId } = this.state;
    this.props.ListIngredient();
    this.props.ListBeans();
    this.props.ListIngredientRefil();
    this.props.ReadStock(deviceId);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;

    if (this.props.readstock !== prevProps.readstock) {
      const code = safeDeepGet(
        this.props,
        ['readstock', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        const newState = {};
        const lid = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', 'lid'],
          0
        );
        newState['lid'] = lid;
        const water = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', 'water'],
          0
        );
        newState['water'] = water;
        const cupm = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', 'cup_m'],
          0
        );
        newState['cupm'] = cupm;
        const cupl = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', 'cup_l'],
          0
        );
        newState['cupl'] = cupl;
        const stir = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', 'stir'],
          0
        );
        newState['stir'] = stir;
        const beans = safeDeepGet(this.props, ['readstock', 'body', 'bean', 0]);
        newState['beans'] = beans;
        const amountbeans = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', beans],
          0
        );
        newState['amountbeans'] = amountbeans;
        const cek = safeDeepGet(
          this.props,
          ['readstock', 'body', 'powder', 0],
          0
        );
        const canister1 = safeDeepGet(this.props, [
          'readstock',
          'body',
          'filter',
          0,
          'canOrder1',
        ]);
        newState['canister1'] = canister1;
        const canister2 = safeDeepGet(this.props, [
          'readstock',
          'body',
          'filter',
          0,
          'canOrder2',
        ]);
        newState['canister2'] = canister2;
        const canister3 = safeDeepGet(this.props, [
          'readstock',
          'body',
          'filter',
          0,
          'canOrder3',
        ]);
        newState['canister3'] = canister3;
        const canister4 = safeDeepGet(this.props, [
          'readstock',
          'body',
          'filter',
          0,
          'canOrder4',
        ]);
        newState['canister4'] = canister4;
        const amount1 = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', canister1],
          0
        );
        newState['amount1'] = amount1;
        const amount2 = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', canister2],
          0
        );
        newState['amount2'] = amount2;
        const amount3 = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', canister3],
          0
        );
        newState['amount3'] = amount3;
        const amount4 = safeDeepGet(
          this.props,
          ['readstock', 'body', 'product', canister4],
          0
        );
        newState['amount4'] = amount4;
        if (!isEmpty(newState)) {
          this.setState(newState);
        }
      }
    } else if (this.props.updateResult !== prevProps.updateResult) {
      // Success update info
      const code = safeDeepGet(
        this.props,
        ['updateResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.props.history.replace('/business-point/list');
      }
    }
  }

  render() {
    const {
      isEdit,
      deviceId,
      lid,
      water,
      cupm,
      cupl,
      stir,
      beans,
      amountbeans,
      canister1,
      canister2,
      canister3,
      canister4,
      amount4,
      amount1,
      amount2,
      amount3,
    } = this.state;
    // const loading = safeDeepGet(this.props, "loading", true);

    const ingredientOptions = safeDeepGet(
      this.props,
      ['ingredientOptions', 'body'],
      []
    );
    const beanOptions = safeDeepGet(this.props, ['beanOptions', 'body'], []);
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>Stock Management Vending Machine</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Stock Management" breadcrumbItem="Edit" />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Stock Management</CardTitle>
                      <p className="card-title-desc">
                        Fill all information below
                      </p>
                      <Label>Beans : </Label>
                      <Row className="inner mb-3">
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangeBeans}
                            value={
                              // isEdit
                              //   ?
                              beanOptions.filter(function (option) {
                                return option.value === beans;
                              })
                              // : this.state.selectedVM
                            }
                            options={beanOptions}
                            isDisabled={true}
                          />
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            disabled={true}
                            id="amountbeans"
                            name="amountbeans"
                            value={amountbeans}
                            placeholder="Beans "
                            type="number"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            color="success"
                            onClick={this.handleRefillBeans}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Label>Canister 1: </Label>
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangecanister1}
                            value={
                              // isEdit
                              // ?
                              ingredientOptions.filter(function (option) {
                                return option.value === canister1;
                              })
                              // : this.state.canister1
                            }
                            options={ingredientOptions}
                            isDisabled={true}
                          />
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            disabled={true}
                            id="amount1"
                            name="amount1"
                            value={amount1}
                            placeholder="Canister 1"
                            type="number"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            color="success"
                            onClick={this.handleRefillCanister1}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Label>Canister 2: </Label>
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangecanister2}
                            value={
                              // isEdit
                              //   ?
                              ingredientOptions.filter(function (option) {
                                return option.value === canister2;
                              })
                              // :
                              //  this.state.canister2
                            }
                            options={ingredientOptions}
                            isDisabled={true}
                          />
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            disabled={true}
                            id="amount2"
                            name="amount2"
                            value={amount2}
                            placeholder="Canister 2"
                            type="number"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            color="success"
                            onClick={this.handleRefillCanister2}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Label>Canister 3: </Label>
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangecanister3}
                            value={
                              // isEdit
                              //   ?
                              ingredientOptions.filter(function (option) {
                                return option.value === canister3;
                              })
                              // : this.state.canister3
                            }
                            options={ingredientOptions}
                            isDisabled={true}
                          />
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            disabled={true}
                            id="amount3"
                            name="amount3"
                            value={amount3}
                            placeholder="Canister 3"
                            type="number"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            color="success"
                            onClick={this.handleRefillCanister3}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Label>Canister 4: </Label>
                        <Col sm="6" className="mb-3">
                          <Select
                            className="single-select"
                            classNamePrefix="react-select"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            placeholder={'Pilih Bahan'}
                            onChange={this.handleChangecanister4}
                            value={
                              // isEdit
                              //   ?
                              ingredientOptions.filter(function (option) {
                                return option.value === canister4;
                              })
                              // : this.state.canister4
                            }
                            options={ingredientOptions}
                            isDisabled={true}
                          />
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            disabled={true}
                            id="amount4"
                            name="amount4"
                            value={amount4}
                            placeholder="Canister 4"
                            type="number"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            color="success"
                            onClick={this.handleRefillCanister4}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            label="Water"
                            disabled={true}
                            id="water"
                            name="water"
                            value={water}
                            placeholder="Water"
                            type="text"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            className="mt-4"
                            color="success"
                            onClick={this.handleRefillWater}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            label="Cup L"
                            disabled={true}
                            id="cupl"
                            name="cupl"
                            value={cupl}
                            placeholder="Cup L"
                            type="text"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            className="mt-4"
                            color="success"
                            onClick={this.handleRefillcupl}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            label="Cup M"
                            disabled={true}
                            id="cupm"
                            name="cupm"
                            value={cupm}
                            placeholder="Cup M"
                            type="text"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            className="mt-4"
                            color="success"
                            onClick={this.handleRefillcupm}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            label="Stir"
                            disabled={true}
                            id="stir"
                            name="stir"
                            value={stir}
                            placeholder="Stir"
                            type="text"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            className="mt-4"
                            color="success"
                            onClick={this.handleRefillstir}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                        <Col sm="5" className="mb-3">
                          <AvField
                            label="Lid"
                            disabled={true}
                            id="lid"
                            name="lid"
                            value={lid}
                            placeholder="Lid"
                            type="text"
                            errorMessage="Masukkan Angka"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <Button
                            className="mt-4"
                            color="success"
                            onClick={this.handleRefilllid}
                          >
                            Refill
                          </Button>{' '}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      className="btn btn-secondary"
                      role="button"
                      to="/business-point/list"
                    >
                      Batal
                    </Link>{' '}
                    {/* <Button color="success" onClick={this.handleRefill}>
                      Refill Stock
                    </Button>{' '} */}
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['businessPoint', 'initCreate'], {}),
  readInfoResult: safeDeepGet(state, ['businessPoint', 'readInfo'], {}),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  ingredientOptions: safeDeepGet(state, ['ingredient', 'list_options'], []),
  beanOptions: safeDeepGet(state, ['ingredient', 'list_bean'], []),
  readstock: safeDeepGet(state, ['stock', 'list'], []),
  refillStock: safeDeepGet(state, ['stock', 'refill_list'], []),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitBusinessPointInfoCreate: () => dispatch(initCreate()),
  StockCreate: payload => dispatch(create(payload)),
  ListIngredientRefil: () => dispatch(refill_list()),
  ListIngredient: () => dispatch(list_options()),
  ListBeans: () => dispatch(list_bean()),
  ReadStock: deviceId => dispatch(list(deviceId)),
  ReadBusinessPointInfo: id => dispatch(readInfo(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockBusinessPoint);
