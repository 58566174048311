import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { APPLICATION_ID } from 'Apps/index'
import 'react-drawer/lib/react-drawer.css';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

// Import menuDropdown
import ProfileMenu from '../../Base/components/CommonForBoth/TopbarDropdown/ProfileMenu';

import logo from '../../Base/assets/images/logo.svg';
import logoLight from '../../Base/assets/images/logo-light.png';
import logoLightSvg from '../../Base/assets/images/logo-light.svg';
import logoDark from '../../Base/assets/images/logo-dark.png';

// Redux Store
import { toggleRightSidebar } from '../store/actions';

//i18n
import { withTranslation } from 'react-i18next';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: 'right',
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }


  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="19" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                marginLeft: '58vw',
              }}
              className="mt-2 d-flex"
            >
              <p className="mt-1 ">Application ID {APPLICATION_ID}</p>
            </div>
            <div className="d-flex">

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
  toggleRightSidebar: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
);
