/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'
import { safeDeepGet } from 'iotera-base/utility/json'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
} from 'reactstrap'
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb'
import MetaTags from 'react-meta-tags'
import { read } from '../../store/Project/actions'

class DashedLine extends Component {
  constructor(props) {
    super(props)
    const appId = safeDeepGet(this.props.match.params, 'appId')
    this.state = {
      appId,
      options: {
        chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
        colors: ['#556ee6', '#34c38f'],
        dataLabels: { enabled: !1 },
        stroke: { width: [3, 4, 3], curve: 'straight', dashArray: [0, 8, 5] },
        title: { text: 'Share Revenue', align: 'left' },
        markers: { size: 0, hover: { sizeOffset: 6 } },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Agt',
            'Sep',
            'Oct',
            'Nov',
            'Des',
          ],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (e) {
                  return e
                },
              },
            },
            {
              title: {
                formatter: function (e) {
                  return e
                },
              },
            },
          ],
        },
        grid: { borderColor: '#f1f1f1' },
      },
    }
  }
  componentDidMount() {
    // Load business point
    const payload = {
      appId: this.state.appId,
    }
    this.props.LoadChartData(payload)
  }
  render() {
    const chart_data = safeDeepGet(this.props, ['result', 'body'], [])
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Chart Share Revenue | Admin</title>
          </MetaTags>

          <Container fluid>
            <Breadcrumbs title="Chart" breadcrumbItem="Share Revenue" />
            <Row className="mb-2">
              <Col sm="4">
                <FormGroup className="mb-3 ajax-select select2-container"></FormGroup>
              </Col>
              <Col sm="2"></Col>
              <Col sm="2" className="d-none d-lg-block" align="center"></Col>

              <Col sm="4">
              
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ReactApexChart
                      options={this.state.options}
                      series={chart_data}
                      type="line"
                      height="380"
                      className="apex-charts"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['Project', 'read'], {}),
})

const mapDispatchToProps = dispatch => ({
  LoadChartData: payload => dispatch(read(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashedLine)
