import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  READ_PLANOGRAM,
  UPDATE_PLANOGRAM,
  DELETE,
  STOCK_BUSINESS_POINT,
  CONFIG_PLANOGRAM,
  HISTORY,
  INPUT_CSV,
  CLOSE_NOTIFICATIONS,
  NOTIFICATIONS,
  SUMMARY,
  UPDATE_TIME,
} from "./actionTypes";

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType }
});
export const list_history = payload => ({
  type: HISTORY,
  payload
});
export const update_time = payload => ({
  type: UPDATE_TIME,
  payload
});
export const summary = payload => ({
  type: SUMMARY,
  payload,
});
export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result }
});

export const list = payload => ({
  type: LIST,
  payload
});
export const configList = () => ({
  type: CONFIG_PLANOGRAM,
});
export const stockBusinessPoint = payload => ({
  type: STOCK_BUSINESS_POINT,
  payload
});
export const notification = () => ({
  type: NOTIFICATIONS,
});
export const close_notification = payload => ({
  type: CLOSE_NOTIFICATIONS,
  payload
});
export const initCreate = () => ({
  type: INIT_CREATE,
});

export const create = payload => ({
  type: CREATE,
  payload
});
export const inputCsv = payload => ({
  type: INPUT_CSV,
  payload
});

export const readInfo = id => ({
  type: READ_INFO,
  payload: { id }
});

export const updateInfo = payload => ({
  type: UPDATE_INFO,
  payload
});

export const readPlanogram = id => ({
  type: READ_PLANOGRAM,
  payload: { id }
});

export const updatePlanogram = payload => ({
  type: UPDATE_PLANOGRAM,
  payload
});

export const delete_ = deviceId => ({
  type: DELETE,
  payload: { deviceId }
});
