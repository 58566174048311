import {
    LOADING,
    RESULT,
    GET_VM_STATUS,
    GET_NETT_SETTLEMENT,
    GET_SNAPSHOT
} from "./actionTypes";
export const get_snapshot = (payload) => ({
    type: GET_SNAPSHOT,
    payload
});
export const loading = (actionType) => ({
    type: LOADING,
    payload: { actionType },
});

export const result = (actionType, result) => ({
    type: RESULT,
    payload: { actionType, result },
});

// VM status data - 4 cards di atas
export const get_vm_status = () => ({
    type: GET_VM_STATUS,
});

// charts data
export const get_nett_settlement = (payload) => ({
    type: GET_NETT_SETTLEMENT,
    businessPoints: payload.businessPoints,
    month: payload.month,
    year: payload.year,
    date: payload.dateRange

});

