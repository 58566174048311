import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Redux
import { connect } from 'react-redux';
import { list, update, delete_ } from '../../store/ingredient/actions';
import Select from 'react-select';

class IngredientList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      modal: false,
      ingredients: [],
      IngredientListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'category',
          text: 'Category',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.category}</p>
            </>
          ),
        },
        {
          dataField: 'type',
          text: 'Code',
          formatter: (cellContent, row) => (
            <>
              <p className="text-wrap">{row.type}</p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, ingredient) => (
            <div className="d-flex gap-3">
              {/* <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleEditClick(ingredient)}
                ></i>
              </Link> */}
              <Tippy content={'Delete'}>
                <Link className="text-danger" to="#">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => this.handleDeleteClick(ingredient.id)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  handleDeleteClick = row => {
    this.props.DeleteIngredient(row);
    this.props.LoadIngredientList();
    this.showToast();
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Businnes Point';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  componentDidMount() {
    // Load Ingredient
    this.props.LoadIngredientList();
  }
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/ingredient/create');
  };
  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    const { selectedcategory } = this.state;

    const options = [
      { value: 'bean', label: 'Bean' },
      { value: 'powder', label: 'Powder' },
    ];
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);

    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Ingredient List | Nestle Franke Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Ingredient" breadcrumbItem="List" />
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={this.state.IngredientListColumns}
              data={list}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={this.state.IngredientListColumns}
                  data={list}
                  search
                >
                  {toolkitprops => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitprops.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="8">
                          <div className="text-sm-end">
                            <Button
                              className="font-16 btn-block btn btn-franke"
                              onClick={this.handleAddClick}
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Create New ingredient
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable    
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              defaultSorted={defaultSorted}
                              classes={
                                'table align-middle table-nowrap table-hover'
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={this.node}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['ingredient', 'list'], {}),
  loading: safeDeepGet(state, ['ingredient', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  LoadIngredientList: () => dispatch(list()),
  UpdateIngredient: payload => dispatch(update(payload)),
  DeleteIngredient: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientList);
