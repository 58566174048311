/* eslint-disable no-unused-vars */

/* eslint-disable semi */

/* Import */
import { safeGet } from 'iotera-base/utility/json';

import firebase from 'firebase/compat/app';
import Base from './base';

export default class TransactionSnapshotMonthly extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'transaction_snapshot_monthly');
  }

  list = payload => {
    return new Promise((resolve, reject) => {
     
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };
  detail = orderID => {
    return new Promise((resolve, reject) => {
      var orderId = safeGet(orderID, 'payload');
      const list = {};
      this.collection
        .where('__name__', '==', orderId)
        .get()
        .then(
          snapshot => {
            snapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            console.log(error);
            resolve({ code: -1, body: list });
          }
        );
    });
  };
  update = id => {
    return new Promise((resolve, reject) => {
      this.collection
        .where('__name__', '==', id)
        .get()
        .then(snapshot => {
          snapshot.docs.map(doc => {
            if (doc.id != null || doc.id != undefined) {
              this.collection
                .doc(id)
                .set(
                  {
                    active: false,
                  },
                  { merge: true }
                )
                .then(
                  () => {
                    resolve({ code: 0 });
                  },
                  error => {
                    resolve({ code: -1, body: error });
                  }
                );
            }
          });
        });
    });
  };
}
