import {isNone} from 'iotera-base/utility/object'
export const PROJECT = !isNone(window._env_.PROJECT_HANDLE) ? window._env_.PROJECT_HANDLE : 'Redboks'
export const APP_ID = !isNone(window._env_.APPLICATION_ID) ? window._env_.APPLICATION_ID : '1000000209'
//TODO PROJECT      ||    APP_ID
//TODO Dev          ||  1000000021
//TODO Redboks      ||  1000000209
//TODO Demo         ||  1000000021
//TODO Nestle       ||  1000000159
//TODO Franke       ||  1000000211
//TODO Indesso      ||  1000000226
//TODO Fuji         ||  1000000021 / 1000000229 (VendKios)
//TODO FujiMinex    ||  1000000229
//TODO FujiSoltova  ||  1000000230
//TODO Hamparan     ||  1000000232
//TODO TTM          ||  1000000233
//TODO Inaco        ||  1000000238