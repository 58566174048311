import {
  LOADING,
  RESULT,
  INITCONFIG,
} from "./actionTypes";

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType },
});

export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result },
});

export const initConfig = () => ({
  type: INITCONFIG,
});
