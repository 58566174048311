/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import { APPLICATION_ID, formatedNumber, getButtonNettTheme } from 'Apps';
import ReactApexChart from 'react-apexcharts';
import FitText from 'react-fittext';

const Earning = props => {
  const { data } = props;
  const options = {
    chart: {
      toolbar: 'false',
      dropShadow: {
        enabled: !0,
        color: '#000',
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    xaxis: {
      tickAmount: 3,
    },
    dataLabels: {
      enabled: !1,
    },
    colors: ['#556ee6'],
    stroke: {
      curve: 'smooth',
      width: 3,
    },
  };

  const card_values = data.card_values;
  const series = [
    {
      name: 'Revenue',
      data: data.chart_data,
    },
  ];

  let card_change = safeDeepGet(card_values, ['card_change'], 0).toFixed(0);
  let card_sum_current = safeDeepGet(card_values, ['card_sum_current'], 0);
  let card_sum_prev = safeDeepGet(card_values, ['card_sum_prev'], 0);
  let card_mdr_current = safeDeepGet(card_values, ['card_mdr_current'], 0);
  let badge_change = 'badge-soft-danger';
  if (card_change >= 0) {
    badge_change = 'badge-soft-success';
  }
  return (
    <React.Fragment>
      <Col xl="12" className="d-flex align-self-stretch">
        <Card className="flex-fill">
          <CardBody>
            <h4 className="card-title mb-4">Total Settlement</h4>
            <Row>
              <Col lg="2">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>Bulan ini</p>
                    <FitText maxFontSize={'24px'}>
                      <h4>
                        Rp{' '}
                        {formatedNumber(card_sum_current.toFixed(0).toString())}
                      </h4>
                    </FitText>
                    <div>
                      <span
                        className={`badge ${badge_change} font-size-12 me-1`}
                      >
                        {' '}
                        {card_change}%{' '}
                      </span>{' '}
                      dari bulan lalu
                    </div>
                  </div>
                  <div className="text-muted">
                    <div className="mb-4">
                      <p>MDR Bulan ini</p>
                      <FitText maxFontSize={'20px'}>
                        <h4>
                          Rp{' '}
                          {formatedNumber(
                            card_mdr_current.toFixed(0).toString()
                          )}
                        </h4>
                      </FitText>
                    </div>
                  </div>
                  <div>
                    <Link to="#" className={getButtonNettTheme()}>
                      View Details{' '}
                      <i className="mdi mdi-chevron-right ms-1"></i>
                    </Link>
                  </div>

                  <div className="mt-4">
                    <p className="mb-2">Bulan lalu</p>
                    <FitText maxFontSize={'20px'}>
                      <h5>
                        Rp {formatedNumber(card_sum_prev.toFixed(0).toString())}
                      </h5>
                    </FitText>
                  </div>
                </div>
              </Col>
              <Col lg="10">
                <div id="line-chart" className="apex-charts" dir="ltr">
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Earning;
