export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "ALERT_LIST";
export const INIT_CREATE = "ALERT_INIT_CREATE";
export const CREATE = "ALERT_CREATE";
export const READ = "ALERT_INFO";
export const UPDATE = "ALERT_UPDATE";
export const STOCK_UPDATE = "STOCK_ALERT_UPDATE";
export const DELETE = "ALERT_DELETE";
export const LIST_BUSINESS_POINTS = "LIST_BUSINESS_POINTS";
export const LIST_ALERT_DEVICE = "LIST_ALERT_DEVICE";
