/* eslint-disable semi */
export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "INVOICE_LIST";
export const INIT_CREATE = "INVOICE_INIT_CREATE";
export const CREATE = "INVOICE_CREATE";
export const READ = "INVOICE_INFO";
export const UPDATE = "INVOICE_UPDATE";
export const STOCK_UPDATE = "STOCK_INVOICE_UPDATE";
export const DELETE = "INVOICE_DELETE";
export const DETAIL_INVOICE = "DETAIL_INVOICE";
