/* eslint-disable semi */
import React from 'react';
import { Redirect } from 'react-router-dom';

//Business Point Pages
import BusinessPointList from 'Base/pages/BusinessPoint';
import BusinessPointInfo from 'Base/pages/BusinessPoint/info';
import BusinessPointPlanogram from 'Base/pages/BusinessPoint/bodyPlanogram';
import BusinessPointInfoPlanogram from 'Base/pages/BusinessPoint/infoPlanogram';
import BusinessPointCalibration from 'SvNestleFranke/pages/BusinessPoint/Calibrations';

import NestleProduct from 'SvNestleFranke/pages/Product';
import NestleProductInfo from 'SvNestleFranke/pages/Product/ingredients';
import NestleProductCreate from 'SvNestleFranke/pages/Product/info';
import HistoryPlanogram from "Base/pages/BusinessPoint/historyPlanogram"
import RefundList from 'Base/pages/Transaction/Transaction-refund/index'
import NestleIngredient from 'SvNestleFranke/pages/Ingredient';
import NestleIngredientCreate from 'SvNestleFranke/pages/Ingredient/create';
import NotificationPage from "Base/pages/Notification/index"

// Transaction Pages
import TransactionList from 'Base/pages/Transaction/index';
import TransactionReport from 'Base/pages/Transaction/Transaction-report';

// Transaction Pages
import StockBusinessPoint from 'Base/pages/Stock/BusinessPoints';
import StockWarehouse from 'Base/pages/Stock/Warehouse';
import StockDeliveryOrder from 'Base/pages/Stock/DeliveryOrder';

// Dashboard
// import DashboardNestle from '../pages/Dashboard/index';
import Dashboard from 'Base/pages/Dashboard/index';

import UserProfile from 'Base/pages/Authentication/UserProfile';

import AlertList from 'Base/pages/Alert/index';
import AlertDeviceList from 'Base/pages/Alert/alertdevice';


import BankAccount from "Base/pages/Payout/bankAccount"
import PayoutReport from "Base/pages/Payout/index"
import InfoBank from "Base/pages/Payout/info"
import CsV from 'SvNestleFranke/pages/Csv/csv';
import TransactionDetail from 'Base/pages/Transaction/detail'
import RFID from 'SvNestleFranke/pages/RFID/index'
// import TransactionRFID from '../pages/RFID/transaction'
import RefundChat from 'Base/pages/Chat/index'
import RefundnDetail from 'Base/pages/Chat/detail'
import couponList from "Base/pages/Coupon/index"
import RoleList from 'Base/pages/Role/index'
import RoleInfo from 'Base/pages/Role/info'
import AccountList from 'Base/pages/Account/index'
import AccountInfo from 'Base/pages/Account/info'
import ErrorCode from 'Base/pages/Error/index'

const superAdmin = [
  // Nestle routes
  { path: '/error/code', component: ErrorCode},
  { path: '/alert/device/:id', component: AlertList },
  { path: '/dashboard', component: Dashboard },
  { path: '/csv', component: CsV },
  { path: "/coupon/list", component: couponList },
  // Payout
  { path: "/payout/list", component: PayoutReport },
  { path: "/payout/bankaccount", component: BankAccount },
  { path: "/payout/create/bankaccount", component: InfoBank },
  { path: "/payout/edit/:id", component: InfoBank },
  // profile
  { path: '/profile', component: UserProfile },
  /* Business point */
  { path: '/business-point/list', component: BusinessPointList },
  { path: '/business-point/create', component: BusinessPointInfo },
  { path: '/business-point/info/:id', component: BusinessPointInfo },
  { path: '/business-point/planogram/:id', component: BusinessPointPlanogram },
  { path: '/business-point/edit/:id', component: BusinessPointInfoPlanogram },
  { path: '/business-point/alert/:id', component: AlertDeviceList },
  { path: '/business-point/options/:id', component: BusinessPointCalibration },

  { path: "/business-point/history/planogram", component: HistoryPlanogram },
  { path: "/refund/list", component: RefundList },
  { path: '/role', component: RoleList },
  { path: '/role/create', component: RoleInfo },
  { path: '/role/edit/:id', component: RoleInfo },

  { path: '/account/list', component: AccountList },
  { path: '/account/create', component: AccountInfo},
  { path: '/account/edit/:id', component: AccountInfo },
  // product
  { path: '/product/list', component: NestleProduct },
  { path: '/product/ingredient', component: NestleProductInfo },
  { path: '/product/create', component: NestleProductCreate },
  { path: '/product/edit/:id', component: NestleProductCreate },

  { path: '/ingredient/list', component: NestleIngredient },
  { path: '/ingredient/create', component: NestleIngredientCreate },
  { path: '/ingredient/edit/:id', component: NestleIngredientCreate },

  // transaction
  { path: '/transaction/list', component: TransactionList },
  { path: '/transaction/report', component: TransactionReport },

  { path: '/transaction/detail/:id', component: TransactionDetail},
  { path: "/transaction/chat", component: RefundChat },
  { path: "/refund/detail/:id", component: RefundnDetail },
 
  { path: '/alert/:type/list', component: AlertList },
  { path: '/alert/history/:id', component: AlertList },
  { path: '/alert/device/:id', component: AlertList },
  { path: '/alert/device-history/:device_id/:key', component: AlertList },
  { path: '/notification/:type/list', component: NotificationPage },
  // stock
  { path: '/stock/business-point', component: StockBusinessPoint },
  { path: '/stock/warehouse', component: StockWarehouse },
  { path: '/stock/create-do', component: StockDeliveryOrder },

  { path: "/notification/list", component: NotificationPage},

  // RFID
  { path: '/RFID/list/:id', component: RFID },
  { path: '/transaction/rfid', component: TransactionList},
  {
    path: '/',
    exact: true,
    component: () =>
        <Redirect to="/dashboard" />
  },
];


let indessoRoutes = superAdmin;

export { indessoRoutes };
