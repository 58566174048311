/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  INITCONFIG,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
// import { createdUser, deletedUser } from 'Apps/device'

function* initConfig() {
  yield put(loading(INITCONFIG));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.projectConfig.list);
  console.log(wsResult)
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const configList = {}
    yield put(result(INITCONFIG, { code: Result.SUCCESS, body: configList }));
    return;
  }
  yield put(result(INITCONFIG, { result: { code: Result.FAILED } }));
}

function* projectConfigSaga() {
  yield takeEvery(INITCONFIG, initConfig);
 
}

export default projectConfigSaga;
