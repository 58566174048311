/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class Product extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'product');
  }
  
  updatePrice= (payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'Hpp' || key === 'Share' || key === 'total') {
          updatedPayload[key] = payload[key];
        }
      }
      const id = payload['product'];
      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    })
  }
  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.orderBy("name").get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: error });
        }
      );
    });
  };

  create = (id, payload) => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'name' ||
          key === 'sku' ||
          key === 'desc' ||
          key === 'img_url'
        ) {
          insertedPayload[key] = payload[key];
        }
      }

      // Add sku
      insertedPayload['sku'] = id;
      insertedPayload['category'] = safeDeepGet(payload, 'category', '');

      // Add
      insertedPayload['warehouse_stock'] = 0;
      if (payload['instant1']) {
        insertedPayload['instant1'] = payload['instant1'];
      }
      if (payload['instant2']) {
        insertedPayload['instant2'] = payload['instant2'];
      }
      if (payload['instant3']) {
        insertedPayload['instant3'] = payload['instant3'];
      }
      if (payload['instant4']) {
        insertedPayload['instant4'] = payload['instant4'];
      }
      if (payload['instant5']) {
        insertedPayload['instant5'] = payload['instant5'];
      }
      if (payload['out_time']) {
        insertedPayload['out_time'] = Number(payload['out_time']);
      }
      if (payload['out_time_instant1']) {
        insertedPayload['out_time_instant1'] = Number(payload['out_time_instant1']);
      }
      if (payload['out_time_instant2']) {
        insertedPayload['out_time_instant2'] = Number(payload['out_time_instant2']);
      }
      if (payload['out_time_instant3']) {
        insertedPayload['out_time_instant3'] = Number(payload['out_time_instant3']);
      }
      if (payload['out_time_instant4']) {
        insertedPayload['out_time_instant4'] = Number(payload['out_time_instant4']);
      }
      if (payload['out_time_instant5']) {
        insertedPayload['out_time_instant5'] = Number(payload['out_time_instant5']);
      }
      if (payload['stir_time']) {
        insertedPayload['stir_time'] = Number(payload['stir_time']);
      }
      if (payload['stir_time_instant1']) {
        insertedPayload['stir_time_instant1'] = Number(payload['stir_time_instant1']);
      }
      if (payload['stir_time_instant2']) {
        insertedPayload['stir_time_instant2'] = Number(payload['stir_time_instant2']);
      }
      if (payload['stir_time_instant3']) {
        insertedPayload['stir_time_instant3'] = Number(payload['stir_time_instant3']);
      }
      if (payload['stir_time_instant4']) {
        insertedPayload['stir_time_instant4'] = Number(payload['stir_time_instant4']);
      }
      if (payload['stir_time_instant5']) {
        insertedPayload['stir_time_instant5'] = Number(payload['stir_time_instant5']);
      }
      if (payload['water_instant1']) {
        insertedPayload['water_instant1'] = Number(payload['water_instant1']);
      }
      if (payload['water_instant2']) {
        insertedPayload['water_instant2'] = Number(payload['water_instant2']);
      }
      if (payload['water_instant3']) {
        insertedPayload['water_instant3'] = Number(payload['water_instant3']);
      }
      if (payload['water_instant4']) {
        insertedPayload['water_instant4'] = Number (payload['water_instant4']);
      }
      if (payload['water_instant5']) {
        insertedPayload['water_instant5'] = Number (payload['water_instant5']);
      }
      if (payload['water']) {
        insertedPayload['water'] = Number(payload['water']);
      }
      if (payload['hot']) {
        insertedPayload['hot'] = payload['hot'];
      }

      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = sku => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('sku', '==', sku)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'name' || key === 'desc' || key === 'img_url') {
          updatedPayload[key] = payload[key];
        }
      }
      updatedPayload['category'] = safeDeepGet(payload, 'category', '');

      if(payload['hot']){
        updatedPayload['hot'] = payload['hot'];
      }
      if (payload['instant1']) {
        updatedPayload['instant1'] = payload['instant1'];
      }
      if (payload['instant2']) {
        updatedPayload['instant2'] = payload['instant2'];
      }
      if (payload['instant3']) {
        updatedPayload['instant3'] = payload['instant3'];
      }
      if (payload['instant4']) {
        updatedPayload['instant4'] = payload['instant4'];
      }
      if (payload['instant5']) {
        updatedPayload['instant5'] = payload['instant5'];
      }
      if (payload['out_time']) {
        updatedPayload['out_time'] = Number(payload['out_time']);
      }
      if (payload['out_time_instant1']) {
        updatedPayload['out_time_instant1'] = Number(payload['out_time_instant1']);
      }
      if (payload['out_time_instant2']) {
        updatedPayload['out_time_instant2'] = Number(payload['out_time_instant2']);
      }
      if (payload['out_time_instant3']) {
        updatedPayload['out_time_instant3'] = Number(payload['out_time_instant3']);
      }
      if (payload['out_time_instant4']) {
        updatedPayload['out_time_instant4'] = Number(payload['out_time_instant4']);
      }
      if (payload['out_time_instant5']) {
        updatedPayload['out_time_instant5'] = Number(payload['out_time_instant5']);
      }
      if (payload['stir_time']) {
        updatedPayload['stir_time'] = Number(payload['stir_time']);
      }
      if (payload['stir_time_instant1']) {
        updatedPayload['stir_time_instant1'] = Number(payload['stir_time_instant1']);
      }
      if (payload['stir_time_instant2']) {
        updatedPayload['stir_time_instant2'] = Number(payload['stir_time_instant2']);
      }
      if (payload['stir_time_instant3']) {
        updatedPayload['stir_time_instant3'] = Number(payload['stir_time_instant3']);
      }
      if (payload['stir_time_instant4']) {
        updatedPayload['stir_time_instant4'] = Number(payload['stir_time_instant4']);
      }
      if (payload['stir_time_instant5']) {
        updatedPayload['stir_time_instant5'] = Number(payload['stir_time_instant5']);
      }
      if (payload['water_instant1']) {
        updatedPayload['water_instant1'] = Number(payload['water_instant1']);
      }
      if (payload['water_instant2']) {
        updatedPayload['water_instant2'] = Number(payload['water_instant2']);
      }
      if (payload['water_instant3']) {
        updatedPayload['water_instant3'] = Number(payload['water_instant3']);
      }
      if (payload['water_instant4']) {
        updatedPayload['water_instant4'] = Number (payload['water_instant4']);
      }
      if (payload['water_instant5']) {
        updatedPayload['water_instant5'] = Number (payload['water_instant5']);
      }
      if (payload['water']) {
        updatedPayload['water'] = Number(payload['water']);
      }

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            console.log(error)
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  updateStock = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (key === 'name' || key === 'desc' || key === 'warehouse_stock') {
          updatedPayload[key] = payload[key];
        }
      }

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = deviceId => {
    this.collection.doc(deviceId).delete();
  };
}
