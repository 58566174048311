import {
  LOADING,
  RESULT,
  LIST_BUSINESS_POINTS,
  LIST,
  READ,
  REFUND,
  UPDATE_REFUND,
  SUMMARY,
  SNAPSHOT,
  DOWNLOAD_SNAPSHOT,
  GET_SALES_ANALYTICS,
} from './actionTypes';

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType },
});

export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result },
});

export const list_business_points = payload => ({
  type: LIST_BUSINESS_POINTS,
  payload
});

export const list = payload => ({
  type: LIST,
  dateRange: payload.dateRange,
  transactionStatus: payload.transactionStatus,
  dispenseStatus: payload.dispenseStatus,
  businessPoints: payload.businessPoints,
});

export const read = transactionId => ({
  type: READ,
  payload: { transactionId },
});

export const updateRefund = payload => ({
  type: UPDATE_REFUND,
  payload,
});

export const refund = transactionId => ({
  type: REFUND,
  payload: { transactionId },
});

export const summary = payload => ({
  type: SUMMARY,
  businessPoints: payload.businessPoints,
  month: payload.month,
  year: payload.year,
  date: payload.dateRange,
});
export const download_snapshot = payload => ({
  type: DOWNLOAD_SNAPSHOT,
  businessPoints: payload.businessPoints,
  month: payload.month,
  year: payload.year,
});

export const snapshot = () => ({
  type: SNAPSHOT,
});

export const get_sales_analytics = payload => ({
  type: GET_SALES_ANALYTICS,
  businessPoints: payload.businessPoints,
  month: payload.month,
  year: payload.year,
  date: payload.dateRange,
});
