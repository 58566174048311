/* eslint-disable semi */
import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
  DOWNLOAD,
  EDIT,
  DETAIL,
  SET_PRICE_SUBS,
} from "./actionTypes";

export const loading = actionType => ({
  type: LOADING,
  payload: { actionType },
});

export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result },
});

export const list = appId => ({
  type: LIST,
  appId,
});
export const list_vm = (appId) => ({
  type: LIST_VM,
  appId,
});

export const initCreate = appId => ({
    type: INIT_CREATE,
    appId
  });

export const create = payload => ({
  type: CREATE,
  payload,
});
export const edit = payload => ({
  type: EDIT,
  payload,
});

export const read = payload => ({
  type: READ,
  payload
});
export const detail = payload => ({
  type: DETAIL,
  payload
});
export const set_price_subs = payload => ({
  type: SET_PRICE_SUBS,
  payload
});
export const download = payload => ({
  type: DOWNLOAD,
  payload
});

export const update = payload => ({
  type: UPDATE,
  payload,
});

export const stockUpdate = payload => ({
  type: STOCK_UPDATE,
  payload,
});

export const delete_ = payload => ({
  type: DELETE,
  payload,
});
