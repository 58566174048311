/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import {MULTITENANCY_HANDLE } from 'Apps/index'
export default class Storage {
  constructor(firebase, applicationId) {
    this.storage = firebase.storage();
    this.resetApplicationId(applicationId);
  }

  resetApplicationId = applicationId => {
    this.basePath = this.buildBasePath(applicationId);
  };

  buildBasePath = applicationId => {
    if (!MULTITENANCY_HANDLE) {
      return 'product';
    }
    return `${applicationId}/product`;
  };

  replace = (file, sku, ext) => {
    return new Promise((resolve, reject) => {
      let metadata;
      if (ext === 'png') {
        metadata = {
          contentType: 'image/png',
        };
      } else {
        metadata = {
          contentType: 'image/jpeg',
        };
      }

      const filename = sku + '.' + ext;

      this.storage
        .ref(`${this.basePath}/${filename}`)
        .put(file, metadata)
        .then(
          snapshot => {
            snapshot.ref.getDownloadURL().then(url => {
              resolve({ code: 0, body: { url } });
            });
          },
          error => {
            resolve({ code: -1 });
          }
        );
    });
  };

  delete_ = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };
}
