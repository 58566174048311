import {
  LOADING,
  RESULT,
  LIST,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  READ_PLANOGRAM,
  UPDATE_PLANOGRAM,
  DELETE,
  STOCK_BUSINESS_POINT,
  CONFIG_PLANOGRAM,
  INPUT_CSV,
  HISTORY,
  NOTIFICATIONS,
  CLOSE_NOTIFICATIONS,
  SUMMARY,

  UPDATE_TIME
} from './actionTypes';

const INIT_STATE = {
  loading: true,
};

const BusinessPoint = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case LIST:
          newState['list'] = action.payload.result;
          break;
        case SUMMARY:
          newState['summary'] = action.payload.result;
          break;
        case NOTIFICATIONS:
          newState['notification'] = action.payload.result;
          break;
        case CLOSE_NOTIFICATIONS:
          newState['close_notification'] = action.payload.result;
          break;
        case HISTORY:
          newState['list_history'] = action.payload.result;
          break;
        case INPUT_CSV:
          newState['inputCsv'] = action.payload.result;
          break;
        case CONFIG_PLANOGRAM:
          newState['configList'] = action.payload.result;
          break;
        case STOCK_BUSINESS_POINT:
          newState['stockBusinessPoint'] = action.payload.result;
          break;
        case INIT_CREATE:
          newState['initCreate'] = action.payload.result;
          break;
        case CREATE:
          newState['create'] = action.payload.result;
          break;
        case READ_INFO:
          newState['readInfo'] = action.payload.result;
          break;
        case UPDATE_TIME:
          newState['update_time'] = action.payload.result;
          break;
        case UPDATE_INFO:
          newState['updateInfo'] = action.payload.result;
          break;
        case READ_PLANOGRAM:
          newState['readPlanogram'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM:
          newState['updatePlanogram'] = action.payload.result;
          break;
        case DELETE:
          newState['delete'] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default BusinessPoint;
