/* eslint-disable semi */
export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "BPFEE_LIST";
export const LIST_VM = "BPFEE_LIST_VM";
export const INIT_CREATE = "BPFEE_INIT_CREATE";
export const CREATE = "BPFEE_CREATE";
export const EDIT = "BPFEE_EDIT";
export const READ = "BPFEE_INFO";
export const DETAIL = "BPFEE_DETAIL";
export const UPDATE = "BPFEE_UPDATE";
export const STOCK_UPDATE = "STOCK_BPFEE_UPDATE";
export const DELETE = "BPFEE_DELETE";
export const DOWNLOAD = "BPFEE_DOWNLOAD";
export const SET_PRICE_SUBS = "BPFEE_SET_PRICE_SUBS";
