/* eslint-disable semi */
/* eslint-disable no-unused-vars */

/* eslint-disable react/prop-types */

/* Import */
import React, { Component } from 'react';

import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { safeGet } from 'iotera-base/utility/json';

import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
import { get_vm_status, get_role } from '../../store/dashboard/actions';

import DashboardMap from './dashboardmap';
import Earning from './earning';
import Pages403 from 'Base/pages/pages-403';
import { NAVBAR, PERMISION, buildTitle } from 'Apps';
const navbar = NAVBAR;
const permision = PERMISION;
const decimal = require('decimal');
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [
        {
          title: 'VM Terdaftar',
          iconClass: 'bx-copy-alt',
          bgClass: 'bg-primary',
          description: '',
        },
        {
          title: 'VM Nyala',
          iconClass: 'bx-archive-in',
          bgClass: 'bg-success',
          description: '',
        },
        {
          title: 'VM Mati',
          iconClass: 'bx-purchase-tag-alt',
          bgClass: 'bg-secondary',
          description: '',
        },
        {
          title: 'Jumlah alert',
          iconClass: 'bx-copy-alt',
          bgClass: 'bg-warning',
          description: '',
        },
      ],
      chartSeries: [],
      periodType: 'yearly',
    };
  }

  componentDidMount() {
    const userData = JSON.parse(localStorage.getItem('authUser'));
    const email = localStorage.getItem('email');
    // const email = safeGet(userData, 'email', "")
    this.props.GetVmStatus();
    this.props.GetRoleUser(email);

    // let price = 10000;
    // let mdrPrice = 0
    // const mdr = 0.007;
    // const sharing = 0.95;
    // const landlord = 0.80;
    // console.log("price " + price)


    // const mdrQris = price * mdr;
    // price = price - mdrQris;
    // mdrPrice = price;
    // console.log("mdr Qris: " + mdrQris)
    // console.log("price mdr: " + price)

    // const sharingRevenueFee = decimal(1).sub(sharing).toNumber();
    // const amountSharing = decimal(mdrPrice).mul(sharingRevenueFee).toNumber();
    // price = price - amountSharing;
    // console.log("sharing amount: " + amountSharing)
    // console.log("price sharing: " + price)

    // const sharingRevenueLandlord = decimal(1).sub(landlord).toNumber();
    // const amountLandlord = decimal(mdrPrice)
    //   .mul(sharingRevenueLandlord)
    //   .toNumber();
    // price = price - amountLandlord;
    // console.log("landlord amount: " + amountLandlord)
    // console.log("price final: " + price)

    // console.log("-------------------------------")
  }

  componentDidUpdate(prevProps) {
    // if (prevProps !== this.props) {
    //   this.setState({ ...this.state, chartSeries: this.props.chartsData });
    // }
  }

  render() {
    const vm_status = safeGet(this.props, ['vmstatusresult', 'body'], {});
    const vm_card = safeGet(vm_status, ['card'], []);
    const vm_map = safeGet(vm_status, ['map'], []);
    const data = safeGet(vm_status, ['card'], '');
    if (data.length === 24) {
      location.replace(`/payment`);
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Dashboard')}</title>
          </MetaTags>
          {safeGet(navbar, ['Overview', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs title="Dashboards" breadcrumbItem="Overview" />
              <Row>
                <Col xl="12">
                  <Row>
                    {/* Reports Render */}
                    {this.state.reports.map((report, key) => (
                      <Col md="3" key={'_col_' + key}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  {report.title}
                                </p>
                                <h4 className="mb-0">{vm_card[key]}</h4>
                              </div>
                              <div
                                className={
                                  'mini-stat-icon avatar-sm rounded-circle ' +
                                  report.bgClass +
                                  ' align-self-center'
                                }
                              >
                                <span className="avatar-title">
                                  <i
                                    className={
                                      'bx ' + report.iconClass + ' font-size-24'
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  <Row className="clearfix">
                    {/* earning */}
                    <Earning />
                    {/* map */}
                    <Col xl="5" className="d-flex">
                      <Card className="flex-fill">
                        <CardBody>
                          <h4 className="card-title mb-4">Peta VM</h4>
                          <p>Biru = VM mati, hijau = VM nyala</p>
                          <div id="leaflet-map" className="leaflet-map">
                            <DashboardMap vm_maps={vm_map} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  vmstatusresult: safeGet(state, ['dashboard', 'vm_status'], {}),
  loading: safeGet(state, ['dashboard', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  GetVmStatus: () => dispatch(get_vm_status()),
  GetRoleUser: payload => dispatch(get_role(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
