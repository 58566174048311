/* eslint-disable no-unused-vars */

/* eslint-disable react/prop-types */

/* eslint-disable semi */

/* Import */
import React, { Component } from 'react';

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import Result from 'iotera-base/result';
import { safeGet } from 'iotera-base/utility/json';

import Breadcrumbs from 'Base/components/Common/Breadcrumb';

import { create, delete_, list, update } from '../../store/errorEx/actions';

import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation';
import 'tippy.js/dist/tippy.css';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { buildTitle } from 'Apps';

class ErrorCodeList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      modal: false,
      modalNav: false,
      Role: [],
      isEdit: false,
      ErrorCodeListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          text: 'Error Code',
          dataField: 'errorcode',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">{row.errorcode}</h5>
            </>
          ),
        },
        {
          dataField: 'description',
          text: 'Deskripsi',
          formatter: (cellContent, row) => (
            <>
              <p className="font-size-14 mb-1">{row.description}</p>
            </>
          ),
        },
        {
          dataField: 'handling',
          text: 'Penanganan',
          formatter: (cellContent, row) => (
            <>
              <p className="font-size-14 mb-1">{row.handling}</p>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, row) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleEditClick(row)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.handleDeleteClick(row.errorcode)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  componentDidMount() {
    // Load business point
    this.props.LoadErrorCodeList();
  }

  componentDidUpdate(prevProps, p) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast('create');
        this.toggle();
        this.props.LoadErrorCodeList();
      } else if (code === Result.FAILED) {
        this.showToast('failedcreate');
      }
    }
    if (this.props.updateResult !== prevProps.updateResult) {
      // Success create
      const code = safeGet(
        this.props,
        ['updateResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast('update');
        this.setState({ data: {}, isEdit: false });
        this.toggle();
        this.props.LoadErrorCodeList();
      } else if (code == Result.FAILED) {
        this.showToast('failedupdate');
      }
    }
    if (this.props.deleteResult !== prevProps.deleteResult) {
      // Success create
      const code = safeGet(
        this.props,
        ['deleteResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast('delete');
        this.props.LoadErrorCodeList();
      } else if (code == Result.FAILED) {
        this.showToast('faileddelete');
      }
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleValidSubmit = (e, values) => {
    const submittedValues = { ...values };
    const { isEdit } = this.state;
    if (isEdit) {
      this.props.UpdateError(submittedValues);
    } else {
      this.props.CreateError(submittedValues);
    }
  };

  /* Handle click */
  handleAddClick = arg => {
    this.toggle();
    this.setState({ isEdit: false });
  };

  handleEditClick = row => {
    this.setState({ data: row, isEdit: true });
    this.toggle();
  };

  handleDeleteClick = errorcode => {
    this.props.DeleteError(errorcode);
    // this.props.LoadErrorCodeList();
  };

  showToast(msg) {
    var toastType = 'success';
    var message = 'Berhasil membuat Error Kode';
    if (msg == 'delete') {
      message = 'Berhasil Delete Kode';
    } else if (msg == 'update') {
      message = 'Berhasil Update Kode';
    } else if (msg == 'failedcreate') {
      toastType = 'error';
      message = 'Gagal membuat Error Kode';
    } else if (msg == 'failedupdate') {
      toastType = 'error';
      message = 'Gagal update Error Kode';
    } else if (msg == 'faileddelete') {
      message = 'Gagal menghapus Error Kode';
      toastType = 'error';
    }
    console.log(msg);
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'errorcode', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };
    const { isEdit, data, errorcode, description, handling } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle("Error Code List")}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Error Code" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.ErrorCodeListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.ErrorCodeListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Error Code
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {isEdit
                          ? 'Edit Code Error ' + data.errorcode
                          : 'Create New Error Code '}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <div>
                            <FormGroup className="mb-3">
                              <Label className="mt-1" htmlFor="id">
                                Kode Error :
                              </Label>
                              <AvField
                                type="text"
                                id="errorcode"
                                name="errorcode"
                                value={isEdit ? data.errorcode : errorcode}
                                placeholder="Masukan Kode Error..."
                                className="form-control"
                                readOnly={isEdit}
                                validate={{
                                  required: {
                                    value: true,
                                    pattern: { value: '^[A-Za-z0-9_-]+$' },
                                  },
                                }}
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label className="mt-1" htmlFor="id">
                                Deskripsi Eror :
                              </Label>
                              <AvInput
                                value={isEdit ? data.description : description}
                                type="textarea"
                                name="description"
                                id="description"
                                placeholder="Deskripsi"
                                required
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label className="mt-1" htmlFor="id">
                                Penanganan Eror :
                              </Label>
                              <AvInput
                                value={isEdit ? data.handling : handling}
                                type="textarea"
                                name="handling"
                                id="handling"
                                placeholder="Penanganan"
                                required
                              />
                            </FormGroup>
                          </div>
                          <div className="d-flex flex-wrap gap-2 float-end mb-4">
                            <Button type="submit" color="primary">
                              Simpan
                            </Button>
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeGet(state, ['errorEx', 'list'], {}),
  loading: safeGet(state, ['errorEx', 'loading'], true),
  updateResult: safeGet(state, ['errorEx', 'update'], {}),
  createResult: safeGet(state, ['errorEx', 'create'], {}),
  deleteResult: safeGet(state, ['errorEx', 'delete'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadErrorCodeList: () => dispatch(list()),
  UpdateError: payload => dispatch(update(payload)),
  CreateError: payload => dispatch(create(payload)),
  DeleteError: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorCodeList);
