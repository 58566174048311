/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  LIST,
  CREATE,
  READ,
  UPDATE,
  DELETE,

} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet, safeDel } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
// import { createdUser, deletedUser } from 'Apps/device'
;

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.errorEx.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const errorExList = [];
    const errorExBody = safeDeepGet(wsResult, 'body', []);
    for(const i in errorExBody) {
      errorExList.push(errorExBody[i]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: errorExList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* create(action) {
  yield put(loading(CREATE));
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.errorEx.create, payload);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    yield put(result(CREATE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(CREATE, { code: Result.FAILED }));
}



function* update(action) {
  yield put(loading(UPDATE));
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.errorEx.update, payload);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    yield put(result(UPDATE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(UPDATE, { code: Result.FAILED }));
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const id = safeDeepGet(payload,'payload');
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.errorEx.delete_, id);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  yield put(result(DELETE, { code }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(CREATE, create);
  yield takeEvery(UPDATE, update);
  yield takeEvery(DELETE, delete_);
}

export default productSaga;
