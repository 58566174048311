/* eslint-disable semi */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { safeDeepGet } from 'iotera-base/utility/json';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

// Import menuDropdown
import ProfileMenu from '../../Base/components/CommonForBoth/TopbarDropdown/ProfileMenu';

import logo from '../../Base/assets/images/logo.svg';
import logoLight from '../../Base/assets/images/logo-light.png';
import logoLightSvg from '../../Base/assets/images/logo-light.svg';
import logoDark from '../../Base/assets/images/logo-dark.png';
import logoSv from '../../Base/assets/images/smartvending/horizontalColour.png';
// Redux Store
import { toggleRightSidebar } from '../store/actions';
import { notification } from '../store/business-point/actions';
//i18n

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: 'right',
      role: '',
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * Toggle sidebar
   */
  componentDidMount() {
    // Load business point
    // this.props.GetNotifications();
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj != null) {
      this.setState({ role: obj.role });
    }
  }
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }
  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSv} alt="" height="30" width="100" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoSv} alt="" height="30" width="100" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSv} alt="" height="30" width="100" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoSv} alt="" height="30" width="100" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>

            <div className="d-flex mx-22">
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
};

const mapStatetoProps = state => ({
  result: safeDeepGet(state, ['businessPoint', 'notification'], {}),
  test: state,
  // const { layoutType } = state.Layout;

  // return { layoutType };
});
const mapDispatchToProps = dispatch => ({
  GetNotifications: () => dispatch(notification()),
});
export default connect(mapStatetoProps, mapDispatchToProps)(Header);
