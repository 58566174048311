/* eslint-disable semi */
import {
    LOADING,
    RESULT,
    LIST,
    INIT_CREATE,
    CREATE,
    READ,
    UPDATE,
    DETAIL_CHAT,
    DELETE,
    STOCK_UPDATE,
    CANCEL,
    LIST_PRODUCT,
    LIST_BUSINESS_POINTS,
    
  } from "./actionTypes";
  
  export const loading = actionType => ({
    type: LOADING,
    payload: { actionType },
  });
  
  export const result = (actionType, result) => ({
    type: RESULT,
    payload: { actionType, result },
  });
  export const list = () => ({
    type: LIST,
  });
  export const list_business_points = () => ({
    type: LIST_BUSINESS_POINTS,
  });
  export const list_product = () => ({
    type: LIST_PRODUCT,
  })
  export const initCreate = () => ({
      type: INIT_CREATE,
    });
  export const create = payload => ({
    type: CREATE,
    payload,
  });
  export const cancel_ = payload => ({
    type: CANCEL,
    payload,
  });
  export const detailChat = payload => ({
    type: DETAIL_CHAT,
    payload,
  });
  export const read = sku => ({
    type: READ,
    payload: { sku },
  });
  
  export const update = payload => ({
    type: UPDATE,
    payload,
  });
  
  export const stockUpdate = payload => ({
    type: STOCK_UPDATE,
    payload,
  });
  
  export const delete_ = payload => ({
    type: DELETE,
    payload,
  });
  