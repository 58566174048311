/* eslint-disable semi */
/* Import */
import { APPLICATION_ID, MULTITENANCY_HANDLE } from 'Apps';

import adminLayout from 'Admin/components';
import baseLayout from 'Base/components';
import svFrankeLayout from 'SvNestleFranke/components';
import svNestleLayout from 'SvNestleMilano/components';
import MultitenancyLayout from 'Multitenancy/components';
export default () => {
  let layout = baseLayout;
  switch (MULTITENANCY_HANDLE) {
    case false:
      switch (APPLICATION_ID) {
        case '1000000159':
          layout = svNestleLayout;
          break;
        case '1000000211':
          layout = svFrankeLayout;
          break;
        case 'Admin':
          layout = adminLayout;
          break;
        default:
          layout = baseLayout;
          break;
      }
      break;
    case true:
      layout = MultitenancyLayout;
      break;
  }
  return layout;
};
