/* eslint-disable no-undef */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

// Product state
import {
  GET_VM_STATUS,
  GET_NETT_SETTLEMENT,
  GET_SNAPSHOT,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { device_list as deviceMap } from '../../webservice/device';
import {
  has,
  safeDeepGet,
  safeDeepSet,
  forceDeepSet,
} from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import { SHARING_PROFIT_PERCENTAGE } from 'Apps';
// time
const momenttz = require('moment-timezone');
const moment = require('moment');
function* get_vm_status() {
  yield put(loading(GET_VM_STATUS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointBody = wsResult.body;

    const businessPointList = [];
    const businessPointMap = {};
    for (const id in businessPointBody) {
      const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
      businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
    }

    wsResult = yield call(deviceMap);
    if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      const devices = safeDeepGet(wsResult, ['body', 'devices']);
      const bpStatus = {
        bpTotal: 0,
        bpOnline: 0,
        bpOffline: 0,
        bpAlert: 0,
      };
      let i = 0;
      for (const deviceId in businessPointMap) {
        const online = safeDeepGet(
          devices,
          [deviceId, 'online', 'is_online'],
          false
        );
        safeDeepSet(businessPointMap, [deviceId, 'id'], i++);
        safeDeepSet(businessPointMap, [deviceId, 'online'], online);
        businessPointList.push(businessPointMap[deviceId]);
        if (online) {
          bpStatus.bpOnline += 1;
        } else {
          bpStatus.bpOffline += 1;
        }
        bpStatus.bpTotal += 1;
      }
      var statusMap = { card: Object.values(bpStatus), map: businessPointList };

      yield put(
        result(GET_VM_STATUS, { code: Result.SUCCESS, body: statusMap })
      );
      return;
    }
  }
  yield put(result(GET_VM_STATUS, { code: Result.FAILED }));
}

function* get_nett_settlement(payload) {
  yield put(loading(GET_NETT_SETTLEMENT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(
    firebaseInstance.transaction.nett_settlement,
    payload
  );
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const nsCurrentMap = wsResult.body;
    var payload_prev = payload;
    safeDeepSet(payload_prev, ['month'], safeDeepGet(payload, ['month']) - 1);
    wsResult = yield call(
      firebaseInstance.transaction.nett_settlement,
      payload_prev
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const nsPrevMap = wsResult.body;
      let sum_per_current = sum_per_date(nsCurrentMap);
      let sum_current = sum_month(nsCurrentMap);
      let sum_prev = sum_month(nsPrevMap);
      let temp_change = 0.0;
      if (sum_prev == 0 && sum_current > 0) {
        temp_change = 100.0;
      } else if (sum_prev == 0 && sum_current == 0) {
        temp_change = 0.0;
      } else {
        temp_change = (sum_current / sum_prev - 1.0) * 100.0;
      }
      const firebaseInstance = getInstance();
      const businessPointMap = {};
      wsResult = yield call(firebaseInstance.businessPoint.list);
      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const businessPointBody = wsResult.body;
        for (const id in businessPointBody) {
          const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
          businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
        }
      }
      let downloadData = download(nsCurrentMap, businessPointMap);
      const nett_settlement = {
        card_values: {
          card_sum_current: sum_current,
          card_sum_prev: sum_prev,
          card_change: temp_change,
        },
        chart_data: sum_per_current,
        download_data: downloadData,
      };
      yield put(
        result(GET_NETT_SETTLEMENT, {
          code: Result.SUCCESS,
          body: nett_settlement,
        })
      );
      return;
    }
  }
  yield put(result(GET_NETT_SETTLEMENT, { result: { code: Result.FAILED } }));
}

function* get_snapshot(payload) {
  yield put(loading(GET_SNAPSHOT));
  const data = safeDeepGet(payload, 'payload', {});
  const month = safeDeepGet(data, 'month', 0);
  const year = safeDeepGet(data, 'year', 0);
  const today = new Date();
  const timezone = 'Asia/Jakarta';
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.snapshotMonthly.list);
  const code = safeDeepGet(wsResult, 'code', Result.UNKNOWN_ERROR);
  if (code != Result.SUCCESS) {
    yield put(result(GET_SNAPSHOT, { code: Result.FAILED }));
    return;
  }
  var lastMonth = year + '-' + ('0' + month).slice(-2);
  if (month == 0) {
    lastMonth = year - 1 + '-' + ('0' + 12).slice(-2);
  }

  const lastMonthBody = safeDeepGet(wsResult, ['body', lastMonth], {});

  const lastAmount = safeDeepGet(lastMonthBody, ['total', 'total_sales'], 0);

  const start = new Date(year, month, 1);
  moment.tz.setDefault(timezone);
  const date = momenttz.tz(start, timezone);
  let Month_1 = date.clone().subtract(0, 'month').startOf('month');
  let Month_2 = date.clone().subtract(0, 'month').endOf('month');
  const startMonth = Month_1.valueOf();
  const endMonth = Month_2.valueOf();
  const MonthBefore = new Date(startMonth);
  const MonthAfter = new Date(endMonth);

  const payload_daily = {
    type: 'DASHBOARD',
    month: month,
    year: year,
    date: [MonthBefore, MonthAfter],
  };
  wsResult = yield call(firebaseInstance.snapshotDaily.list, payload_daily);
  const earning = {
    1: {
      x: '01',
      y: 0,
    },
    2: {
      x: '02',
      y: 0,
    },
    3: {
      x: '03',
      y: 0,
    },
    4: {
      x: '04',
      y: 0,
    },
    5: {
      x: '05',
      y: 0,
    },
    6: {
      x: '06',
      y: 0,
    },
    7: {
      x: '07',
      y: 0,
    },
    8: {
      x: '08',
      y: 0,
    },
    9: {
      x: '09',
      y: 0,
    },
    10: {
      x: '10',
      y: 0,
    },
    11: {
      x: '11',
      y: 0,
    },
    12: {
      x: '12',
      y: 0,
    },
    13: {
      x: '13',
      y: 0,
    },
    14: {
      x: '14',
      y: 0,
    },
    15: {
      x: '15',
      y: 0,
    },
    16: {
      x: '16',
      y: 0,
    },
    17: {
      x: '17',
      y: 0,
    },
    18: {
      x: '18',
      y: 0,
    },
    19: {
      x: '19',
      y: 0,
    },
    20: {
      x: '20',
      y: 0,
    },
    21: {
      x: '21',
      y: 0,
    },
    22: {
      x: '22',
      y: 0,
    },
    23: {
      x: '23',
      y: 0,
    },
    24: {
      x: '24',
      y: 0,
    },
    25: {
      x: '25',
      y: 0,
    },
    26: {
      x: '26',
      y: 0,
    },
    27: {
      x: '27',
      y: 0,
    },
    28: {
      x: '28',
      y: 0,
    },
    29: {
      x: '29',
      y: 0,
    },
    30: {
      x: '30',
      y: 0,
    },
    31: {
      x: '31',
      y: 0,
    },
  };
  let currentAmount = 0;
  const dailyBody = safeDeepGet(wsResult, 'body');
  for (const item in dailyBody) {
    const dateSlice = item.slice(-2);
    const id = Number(dateSlice);
    forceDeepSet(
      earning,
      [id.toString(), 'y'],
      safeDeepGet(dailyBody, [item, 'total', 'total_sales'], 0)
    );
    const amountCash = safeDeepGet(
      dailyBody,
      [item, 'detail_method', 'CASH', 'total_sales'],
      0
    );
    const amountQris = safeDeepGet(
      dailyBody,
      [item, 'detail_method', 'QRIS', 'total_sales'],
      0
    );
    const amountRFID = safeDeepGet(
      dailyBody,
      [item, 'detail_method', 'RFID', 'total_sales'],
      0
    );
    const mdr = amountQris * 0.007;
    const total = amountQris - mdr + amountCash + amountRFID;
    //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
    const sharing = total * SHARING_PROFIT_PERCENTAGE;
    const nettAmount = safeDeepGet(
      dailyBody,
      [item, 'total', 'total_sales'],
     0
    );

    currentAmount += nettAmount;
  }
  moment.tz.setDefault(timezone);
  const now = momenttz.tz(today, timezone);
  let Date_1 = now.clone().subtract(2, 'days').startOf('days');
  let Date_2 = now.clone().subtract(0, 'days').endOf('days');
  if (today.getDate() === 1) {
    if (today.getMonth() === month) {
      Date_1 = now.clone().subtract(0, 'days').startOf('days');
      Date_2 = now.clone().subtract(0, 'days').endOf('days');
    } else {
      Date_1 = now.clone().subtract(2, 'days').startOf('days');
      Date_2 = now.clone().subtract(2, 'days').endOf('days');
    }
  }
  const startTime = Date_1.valueOf();
  const endTime = Date_2.valueOf();
  const DaysBefore = new Date(startTime);
  const DaysAfter = new Date(endTime);
  if (today.getMonth() === month) {
    const payload_trx = {
      dateRange: [DaysBefore, DaysAfter],
    };
    wsResult = yield call(firebaseInstance.transaction.list, payload_trx);
    const trxBody = safeDeepGet(wsResult, 'body', {});
    for (const trx in trxBody) {
      const timeStamp = safeDeepGet(trxBody, [trx, 'time', 'timestamp'], {});
      const date = new Date(timeStamp).getDate();
      if (
        safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
        'settlement'
      ) {
        earning[date].y += safeDeepGet(trxBody, [trx, 'payment', 'amount'], 0);
        currentAmount += safeDeepGet(trxBody, [trx, 'payment', 'amount'], 0);
      }
    }
  } else if (today.getDate() === 1) {
    if (today.getMonth() - 1 == month) {
      const payload_trx = {
        dateRange: [DaysBefore, DaysAfter],
      };
      wsResult = yield call(firebaseInstance.transaction.list, payload_trx);
      const trxBody = safeDeepGet(wsResult, 'body', {});
      for (const trx in trxBody) {
        const timeStamp = safeDeepGet(trxBody, [trx, 'time', 'timestamp'], {});
        const date = new Date(timeStamp).getDate();
        if (
          safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
          'settlement'
        ) {
          earning[date].y += safeDeepGet(trxBody, [trx, 'payment', 'amount'], 0);
          currentAmount += safeDeepGet(trxBody, [trx, 'payment', 'amount'], 0);
        }
      }
    }
  }
  const chart_data = [];
  for (const i in earning) {
    chart_data.push(earning[i]);
  }

  let sum_current = currentAmount;
  let sum_prev = lastAmount;
  let temp_change = 0.0;
  if (sum_prev == 0 && sum_current > 0) {
    temp_change = 100.0;
  } else if (sum_prev == 0 && sum_current == 0) {
    temp_change = 0.0;
  } else {
    temp_change = (sum_current / sum_prev - 1.0) * 100.0;
  }
  const nett_settlement = {
    card_values: {
      card_sum_current: sum_current,
      card_sum_prev: sum_prev,
      card_change: temp_change,
    },
    chart_data: chart_data,
  };

  yield put(
    result(GET_SNAPSHOT, {
      code: Result.SUCCESS,
      body: nett_settlement,
    })
  );
}
function* dashboardSaga() {
  yield takeEvery(GET_VM_STATUS, get_vm_status);
  yield takeEvery(GET_NETT_SETTLEMENT, get_nett_settlement);
  yield takeEvery(GET_SNAPSHOT, get_snapshot);
}

function sum_month(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}

function sum_per_date(nsMap) {
  let now = new Date();
  for (const ns in nsMap) {
    var len = Object.keys(nsMap[ns]).length;
    var val = safeDeepGet(nsMap, [ns]);
    {
      nsMap
        ? (now = val.time.firestore_timestamp.toDate())
        : (now = new Date());
    }
  }

  var resultArr = [];
  var dateArr = [];
  const month = now.getMonth();
  const year = now.getFullYear();
  const date = new Date(year, month, 1);
  const dates = [];
  let obj = {};
  while (date.getMonth() === month) {
    const day = new Date(date);
    dates.push(('0' + day.getDate()).slice(-2));
    date.setDate(date.getDate() + 1);
  }
  for (const i in dates) {
    obj = { x: dates[i], y: 0 };
    resultArr.push(obj);
  }
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    let val_date = val.time.firestore_timestamp
      .toDate()
      .toLocaleString(undefined, { day: '2-digit' });
    var index = dateArr.indexOf(val_date);
    if (index == -1) {
      dateArr.push(val_date);
      if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
        resultArr[Number(val_date) - 1].y += val.payment.amount;
      }
    } else {
      if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
        resultArr[Number(val_date) - 1].y += val.payment.amount;
      }
    }
  }
  return resultArr;
}
function download(nsMap, bpMap) {
  let now = new Date();
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    {
      nsMap
        ? (now = val.time.firestore_timestamp.toDate())
        : (now = new Date());
    }
  }
  var dateArr = [];
  var arrData = [];
  const month = now.getMonth();
  const year = now.getFullYear();
  const date = new Date(year, month, 1);
  const dates = [];
  let obj = {};
  let objSettle = {};
  while (date.getMonth() === month) {
    const day = new Date(date);
    dates.push(('0' + day.getDate()).slice(-2));
    date.setDate(date.getDate() + 1);
  }

  for (const i in bpMap) {
    obj = { device_id: safeDeepGet(bpMap, [i, 'id']), data: {} };
    arrData.push(obj);
  }
  // for (const i in arrData) {
  //   arrData[i].data = salesArr;
  // }
  const dataMap = {};
  for (const i in arrData) {
    const device_id = safeDeepGet(arrData[i], 'id', '');
    dataMap[device_id] = arrData[i];
  }
  const arrMap = [];
  for (const ns in nsMap) {
    arrMap.push(safeDeepGet(nsMap, [ns]));
  }

  let downloadat = {};

  for (const i in bpMap) {
    const device_id = safeDeepGet(bpMap, [i, 'id']);
    const data_filter = arrMap.filter(
      element => safeDeepGet(element, ['product', 'device_id']) == device_id
    );

    var salesArr = [];
    for (const i in dates) {
      obj = { x: dates[i], y: 0, settle: 0 };
      salesArr.push(obj);
    }
    for (const i in data_filter) {
      const time = data_filter[i];
      let val_date = time.time.firestore_timestamp
        .toDate()
        .toLocaleString(undefined, { day: '2-digit' });
      var index = dateArr.indexOf(val_date);
      if (index == -1) {
        dateArr.push(val_date);
        if (
          safeDeepGet(time, ['detail', 'transaction_status']) == 'settlement'
        ) {
          salesArr[Number(val_date) - 1].y += time.payment.amount;
          salesArr[Number(val_date) - 1].settle += 1;
        }
      } else {
        if (
          safeDeepGet(time, ['detail', 'transaction_status']) == 'settlement'
        ) {
          salesArr[Number(val_date) - 1].y += time.payment.amount;
          salesArr[Number(val_date) - 1].settle += 1;
        }
      }
    }
    const bpName = safeDeepGet(bpMap, [device_id, 'name'], []);
    forceDeepSet(downloadat, [device_id, 'deviceId'], device_id);
    forceDeepSet(downloadat, [device_id, 'name'], bpName);
    forceDeepSet(downloadat, [device_id, 'data'], salesArr);
  }
  const downloadList = [];
  for (const i in downloadat) {
    downloadList.push(safeDeepGet(downloadat, i));
  }
  return downloadList;
}

export default dashboardSaga;
