import React, { Component } from 'react';
import { isEmpty, size } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  InputGroup,
} from 'reactstrap';
import { DropdownButton } from 'react-bootstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
//Import Breadcrumb
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import {
  list_business_points,
  list,
  read,
} from '../../store/transactionrfid/actions';
 
// import { Columns } from "../../../Map/"
const paymentOptions = [
  { label: 'All transactions', value: 'all' },
  { label: 'Settlement', value: 'settlement' },
  { label: 'Refund Pending', value: 'refund_pending' },
  { label: 'Cancel', value: 'cancel' },
  { label: 'Failure', value: 'failure' },
  { label: 'Pending', value: 'pending' },
  { label: 'Refunded', value: 'refunded' },
];

const dispenseOptions = [
  { label: 'All dispenses', value: 'all' },
  { label: 'No Dispense', value: 'no_dispense' },
  { label: 'Success', value: 'success' },
  { label: 'Pending', value: 'pending' },
];
const refundOptions = [
  { label: 'No Refund', value: 'all' },
  { label: 'Refund', value: 'refund' },
];
const dispenseNestleOptions = [
  { label: 'All dispenses', value: 'all' },
  { label: 'No Dispense', value: false },
  { label: 'Success', value: true },
];
class Transaction extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - 2));
    const date1 = twoDay.toLocaleDateString('en-GB');
    const time1 = twoDay.toLocaleTimeString('en-GB');
    const twoDayBefore = date1 + ' ' + time1;
    var one_day = 1000 * 60;

    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - 1);
    let endDate = new Date();
    this.state = {
      modal: false,
      products: [],
      confirm_msg: false,
      confirm_alert: false,
      bpfilterlist: [],
      dateRange: [newDate, endDate],
      role: '',
      anchor: false,
      businessPoints: 'all',
      transactionStatus: 'all',
      dispenseStatus: 'all',
      SvFranketransactionColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'detail.order_id',
          text: 'Order',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p style={{ fontWeight: 'bold' }} className="mb-1">
                {safeDeepGet(row, ['detail', 'order_id'])}
              </p>
              <p className="mb-0"></p>
              <p className="mb-1">
                {this.handleValidDate(row.time.firestore_timestamp)}
              </p>
              {/* <p className="mb-1">{row.name}</p> */}
            </>
          ),
        },
        {
          dataField: 'name',
          text: 'Business Point',
          sort: true,
        },
        {
          dataField: 'product.sku',
          text: 'Product',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <Tippy content={row.product.sku}>
                <button
                  style={{ border: 'none' }}
                  className={'font-size-12 btn btn-outline-light'}
                >
                  {row.nameProduct}
                </button>
              </Tippy>
            </>
          ),
        },
        {
          dataField: 'total',
          text: 'Total',
          formatter: (cellContent, row) => (
            <>
              {row.detail.transaction_status === 'refunded' ? (
                <p style={{ color: 'red' }}>{'-' + row.payment.amount}</p>
              ) : (
                <p>{row.payment.amount}</p>
              )}
            </>
          ),
        },
        {
          dataField: 'paymentStatus',
          text: 'Payment Status',
          formatter: (cellContent, row) => (
            <>
              {row.detail.transaction_status == 'cancel' ? (
                <Tippy content="Dibatalkan oleh pembeli, atau timeout dari sisi vending mesin">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      row.badgeclass
                    }
                    color={row.badgeclass}
                  >
                    {row.detail.transaction_status}
                  </span>
                </Tippy>
              ) : row.detail.transaction_status == 'failure' ? (
                <Tippy content="Kegagalan Komunikasi dengan bank">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      row.badgeclass
                    }
                    color={row.badgeclass}
                  >
                    {row.detail.transaction_status}
                  </span>
                </Tippy>
              ) : row.detail.transaction_status == 'refunded' ? (
                <Tippy content="Dana sudah dikembalikan ke pembeli">
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      row.badgeclass
                    }
                    color={row.badgeclass}
                  >
                    {row.detail.transaction_status}
                  </span>
                </Tippy>
              ) : (
                <Tippy content={row.payment.method}>
                  <span
                    style={{ border: 'none' }}
                    className={
                      'font-size-12 badge badge-pill badge-soft-' +
                      row.badgeclass
                    }
                    color={row.badgeclass}
                  >
                    {row.detail.transaction_status}
                  </span>
                </Tippy>
              )}
            </>
          ),
        },
        {
          dataField: 'dispensestatus',
          text: 'Dispense Status',
          formatter: (cellContent, row) => (
            <button
              style={{ border: 'none' }}
              className={'font-size-12 badge-soft-' + row.badge}
              color={row.badge}
            >
              {row.detail.dispense_status}
            </button>
          ),
        },
        {
          dataField: 'payment.detail.rfid',
          text: 'RFID',
          formatter: (cellContent, row) => <p>{row.payment.detail.rfid}</p>,
        },
      ],
    };

    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleTransactionStatus = this.handleTransactionStatus.bind(this);
    this.handleDispenseStatus = this.handleDispenseStatus.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.handleRefunded = this.handleRefunded.bind(this);
    this.handleRefundedMidTrans = this.handleRefundedMidTrans.bind(this);
    this.handleRefundDate = this.handleRefundDate.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  timeConvert(n) {
    var num = n;
    var day = num / 60 / 24;
    var rday = Math.floor(day);
    var hours = (day - rday) * 24;
    var rhours = Math.round(hours);
    return rday + ' Days(s) and ' + rhours + ' hours(s) ';
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleRefundedMidTrans = arg => {
    const product = arg;
    this.setState({
      products: {
        id: product.detail.order_id,
        amount: product.payment.amount,
        device_id: product.product.device_id,
      },
    });
    this.toggle();
  };
  handleClick() {
    this.setState({
      drp_secondary_sm: !this.state.drp_secondary_sm,
    });
  }
  handleClose = () => {
    this.setState(prevState => ({
      anchor: !prevState.anchor,
    }));
  };
  handleRefundDate = date => {
    if (date) {
      const d = new Date(0, 0, 0, 0, 0, 0, date).toLocaleDateString('en-GB');
      return d;
    }
  };
  handleValidRefundSubmit = (e, values) => {
    const { onRefund } = this.props;
    const { products } = this.state;
    const refunded = {
      id: products.id,
      amount: products.amount,
      device_id: products.device_id,
    };
    onRefund(refunded);
    this.toggle();
    this.handleRefund(products);
    let code = '';
    let type = '';
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj == null) {
      code = 'all';
      type = 'all';
    } else {
      if (obj.role == 'admin') {
        code = null;
        type = null;
      } else {
        code = obj.code;
        type = obj.role;
        this.setState({ role: obj.role });
      }
    }
    const data = { code: code, type: type };
    const payload1 = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      data: data,
    };
    this.props.LoadTransactionList(payload1);
  };
  handleRefund = row => {
    const payload = {
      order_id: safeDeepGet(row, 'id', ' '),
      transaction_status: 'refund_pending',
    };
    this.props.RefundTransaction(payload);
    let code = '';
    let type = '';
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj == null) {
      code = 'all';
      type = 'all';
    } else {
      if (obj.role == 'admin') {
        code = null;
        type = null;
      } else {
        code = obj.code;
        type = obj.role;
        this.setState({ role: obj.role });
      }
    }
    const data = { code: code, type: type };
    const payload1 = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      data: data,
    };
    this.props.LoadTransactionList(payload1);
  };

  handleRefunded = row => {
    if (confirm('Are you sure you want to complete the refund process?')) {
      var time = Date.now();
      const payload = {
        order_id: safeDeepGet(row, ['detail', 'order_id'], ' '),
        transaction_status: 'refunded',
        refund_time: time,
      };
      this.props.RefundTransaction(payload);
    } else {
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
      };
      this.props.LoadTransactionList(payload1);
    }
    const payload1 = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
    };
    this.props.LoadTransactionList(payload1);
  };

  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    let code = '';
    let type = '';
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj == null) {
      code = 'all';
      type = 'all';
    } else {
      if (obj.role == 'admin') {
        code = null;
        type = null;
      } else {
        code = obj.code;
        type = obj.role;
        this.setState({ role: obj.role });
      }
    }
    const data = { code: code, type: type };
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      data: data,
    };
    this.props.LoadTransactionList(payload);
  };

  handleTransactionStatus = transactionStatus => {
    this.setState({ transactionStatus });
    let code = '';
    let type = '';
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj == null) {
      code = 'all';
      type = 'all';
    } else {
      if (obj.role == 'admin') {
        code = null;
        type = null;
      } else {
        code = obj.code;
        type = obj.role;
        this.setState({ role: obj.role });
      }
    }
    const data = { code: code, type: type };
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      data: data,
    };
    this.props.LoadTransactionList(payload);
  };

  handleDispenseStatus = dispenseStatus => {
    this.setState({ dispenseStatus });
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: dispenseStatus.value,
    };
    this.props.LoadTransactionList(payload);
  };

  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRange });
      let code = '';
      let type = '';
      const obj = JSON.parse(localStorage.getItem('role'));
      if (obj == null) {
        code = 'all';
        type = 'all';
      } else {
        if (obj.role == 'admin') {
          code = null;
          type = null;
        } else {
          code = obj.code;
          type = obj.role;
          this.setState({ role: obj.role });
        }
      }
      const data = { code: code, type: type };
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        data: data,
      };
      this.props.LoadTransactionList(payload);
    }
  };

  componentDidUpdate() {}

  componentDidMount() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    let newDate = new Date(year, month, day - 1);
    let endDate = new Date();
    this.setState({ dateRange: [newDate, endDate] });

    var current_month = new Date();
    let code = '';
    let type = '';
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj == null) {
      code = 'all';
      type = 'all';
    } else {
      if (obj.role == 'admin') {
        code = null;
        type = null;
      } else {
        code = obj.code;
        type = obj.role;
        this.setState({ role: obj.role });
      }
    }
    const data = { code: code, type: type };
    this.props.LoadBusinessPointFillter(data);
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      data: data,
    };
    this.props.LoadTransactionList(payload);
    // Load transactions
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { orders } = this.props;
    if (!isEmpty(orders) && size(prevProps.orders) !== size(orders)) {
      this.setState({ orders: {}, isEdit: false });
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { orders } = this.props;
    this.setState({
      orders: orders.filter(order =>
        Object.keys(order).some(
          key =>
            typeof order[key] === 'string' &&
            order[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    });
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeleteOrder = order => {
    const { onDeleteOrder } = this.props;
    if (order.id !== undefined) {
      onDeleteOrder(order);
      this.onPaginationPageChange(1);
    }
  };

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  handleValidTime = date => {
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return time1;
  };
  handleValidRefundDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    return date1;
  };

  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    if (list.length > 0) {
      let i = 1;
      for (const el in list) {
        let dispense = list[el].detail.dispense_status;
        const transactionStatus = list[el].detail.transaction_status;
        const firebaseTimestamp = list[el].time.firestore_timestamp;
        var transactionTime = firebaseTimestamp.toDate();

        let finalStatus = null;
        if (transactionStatus == 'settlement' && dispense == 'success') {
          finalStatus = 'Transaksi Sukses';
        } else if (
          transactionStatus == 'settlement' &&
          dispense == 'no_dispense'
        ) {
          var twoDaysLater = transactionTime.setDate(
            transactionTime.getDate() + 2
          );
          var now = new Date();
          if (now < twoDaysLater) {
            finalStatus = 'Gagal Dispense (Potential Refund)';
          } else {
            // more than 2 * 24 hours
            finalStatus = 'Transaksi Sukses';
          }
        } else if (transactionStatus == 'pending') {
          finalStatus = 'Tidak Terjadi Transaksi';
        } else if (transactionStatus == 'refund') {
          finalStatus = 'Refund';
        }
        const provider = safeDeepGet(
          list[el],
          ['payment', 'detail', 'issuer'],
          ''
        );
        let paymentIssuer = '';
        if (provider == '') {
          paymentIssuer = safeDeepGet(list[el], ['payment', 'method'], '');
        } else {
          if (provider == 'AIRPAY SHOPEE') {
            paymentIssuer = 'SHOPEEPAY';
          } else {
            paymentIssuer = provider;
          }
        }
        const amount = list[el].payment.amount;

        const Persentase = 7 / 1000;
        const mdr = amount * Persentase;
        const net = amount - mdr;

        let sn = '';
        // if (list[el].sn.slice(0, 2) == 'VM') {
        //   sn = list[el].sn.slice(2, 10);
        // } else {
        //   sn = list[el].sn;
        // }

        var trxId = '';

        if (paymentIssuer == 'RFID') {
          trxId = this.handleValidDate(list[el].time.firestore_timestamp);
        } else {
          trxId = list[el].payment.detail.transaction_id;
        }
        var firebaseRefundTimestamp = safeDeepGet(
          list[el],
          ['detail', 'refund_time'],
          null
        );
        let refund = '';

        if (
          firebaseRefundTimestamp != null ||
          firebaseRefundTimestamp != undefined
        ) {
          const time = firebaseRefundTimestamp;
          const timestirng = time.toString();
          const splitString = timestirng.slice(0, 13);

          var refundTime = this.handleRefundDate(Number(splitString));
          if (refundTime != null) {
            refund = refundTime;
          }
        }

        trx_data = {
          no: i++,
          date: this.handleValidDate(list[el].time.firestore_timestamp),
          business_point: list[el].name,
          sn: sn,
          distributor: list[el].NameDistributor,
          channel: list[el].channel,
          sku: list[el].product.sku,
          total: amount,
          mdr: mdr,
          net: net,
          provider: paymentIssuer,
          order_id: safeDeepGet(list[el], ['detail', 'order_id'], ''),
          trx_id: trxId,
          payment_status: list[el].detail.transaction_status,
          refundTime: refund,
          dispense_status: safeDeepGet(list[el], ['detail', 'dispense_status']),
          finalStatus: finalStatus,
        };
        dataSet.push(trx_data);
      }
      return dataSet;
    }
  };
  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  handleExport = list => {
    var data = list;
    let headerAdmin = [
      'No',
      'Time',
      'Business Point',
      'SN Milano',
      'Distributor',
      'Channel',
      'Product Name',
      'Price on Time (Include Promo)',
      'MDR',
      'Net Price',
      'Provider',
      'Order Id',
      'TRX ID',
      'Transaction',
      'Refund Time',
      'Dispense Status',
      'Final Status',
    ];
    let header = [
      'No',
      'Time',
      'Business Point',
      'SN Milano',
      'Distributor',
      'Channel',
      'Product Name',
      'Price on Time (Include Promo)',
      'MDR',
      'Net Price',
      'Provider',
      'Order Id',
      'TRX ID',
      'Refund Time',
      'Final Status',
    ];

    const obj = JSON.parse(localStorage.getItem('role'));
    const { selectedMonthYear, businessPoints } = this.state;
    let val = selectedMonthYear;
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    let date = 'all';
    let month = '';
    let year = '';
    let A2 = '';
    if (val == null) {
      month = 'all';
      year = '';
      A2 = 'ALL TRX REPORT';
    } else {
      date = new Date(
        parseInt(val.split('-')[0]),
        parseInt(val.split('-')[1]) - 1,
        1
      );
      month = date.toLocaleString('default', { month: 'long' });
      year = parseInt(val.split('-')[0]);
      A2 = 'TRX REPORT ' + month + ' ' + year;
    }
    XlsxPopulate.fromBlankAsync().then(async workbook => {
      const sheet1 = workbook.sheet(0);
      let sheetData;
      if (obj == null) {
        sheetData = this.getSheetData(data, headerAdmin);
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheetData = this.getSheetData(data, headerAdmin);
        } else {
          sheetData = this.getSheetData(data, header);
        }
      }

      const totalColumns = sheetData[0].length;
      sheet1.cell('A15').value(sheetData);
      sheet1.cell('A1').value(bp_name);
      sheet1.cell('A2').value(A2);

      // CUP Sales
      sheet1.cell('A3').value('TOTAL CUP SALES');
      sheet1
        .cell('A3')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });

      if (obj == null) {
        sheet1
          .cell('B3')
          .formula(
            '=COUNTIFS($Q:$Q,"Transaksi Sukses")+COUNTIFS($Q:$Q,"Refund")'
          );
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($Q:$Q,"Transaksi Sukses")+COUNTIFS($Q:$Q,"Refund")'
            );
        } else {
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($O:$O,"Transaksi Sukses")+COUNTIFS($O:$O,"Refund")'
            );
        }
      }
      sheet1
        .cell('B3')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });

      // Total Sales
      sheet1.cell('A4').value('TOTAL SALES (VALUE)');
      sheet1
        .cell('A4')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1
          .cell('B4')
          .formula(
            '=SUMIF($Q:$Q,"Transaksi Sukses",$H:$H)+SUMIF($Q:$Q,"Refund",$H:$H)'
          );
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1
            .cell('B4')
            .formula(
              '=SUMIF($Q:$Q,"Transaksi Sukses",$H:$H)+SUMIF($Q:$Q,"Refund",$H:$H)'
            );
        } else {
          sheet1
            .cell('B4')
            .formula(
              '=SUMIF($0:$0,"Transaksi Sukses",$H:$H)+SUMIF($0:$0,"Refund",$H:$H)'
            );
          sheet1
            .cell('B3')
            .formula(
              '=COUNTIFS($O:$O,"Transaksi Sukses")+COUNTIFS($O:$O,"Refund")'
            );
        }
      }
      sheet1.cell('B4').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      //Refund
      sheet1.cell('A5').value('REFUND');
      sheet1
        .cell('A5')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1.cell('B5').formula('=SUMIF($Q:$Q,"Refund",$H:$H)');
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1.cell('B5').formula('=SUMIF($Q:$Q,"Refund",$H:$H)');
        } else {
          sheet1.cell('B5').formula('=SUMIF($O:$O,"Refund",$H:$H)');
        }
      }
      sheet1.cell('B5').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      // Total Sales (Actual)
      sheet1.cell('A6').value('TOTAL SALES (ACTUAL)');
      sheet1.cell('B6').formula('B4-B5');
      sheet1.cell('B6').style('numberFormat', 'Rp #,###.0; -');

      // MDR
      sheet1.cell('A7').value('MDR');
      sheet1
        .cell('A7')
        .style({ fontColor: '2172d7', fontSize: '11', fontFamily: 'Calibri' });
      if (obj == null) {
        sheet1.cell('B7').formula('=SUMIFS($I:$I,$Q:$Q,"Transaksi Sukses")');
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          sheet1.cell('B7').formula('=SUMIFS($I:$I,$Q:$Q,"Transaksi Sukses")');
        } else {
          sheet1.cell('B7').formula('=SUMIFS($I:$I,$O:$O,"Transaksi Sukses")');
        }
      }
      sheet1.cell('B7').style({
        fontColor: '2172d7',
        fontSize: '11',
        fontFamily: 'Calibri',
        numberFormat: 'Rp #,###.0; -',
      });

      sheet1.cell('C7').value('0.7%');

      //Net Tranfer
      sheet1.cell('A10').value('Net Transfer to Distributor');
      sheet1.cell('B10').formula('=B6-B7');
      sheet1.cell('B10').style('numberFormat', 'Rp #,###.0; -');

      sheet1.cell('A12').value('PPn');
      sheet1.cell('B12').formula('=B10*11%/110%');
      sheet1.cell('B12').style('numberFormat', 'Rp #,###.0; -');
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style('bold', true);
      sheet1.range('A15:' + endColumn + '15').style('fill', '2172d7');
      // range.style('border', true);

      var filename;
      if (obj == null) {
        filename = 'report_Report_Transaction_Ho_' + bp_name + '.xlsx';
      } else {
        if (obj.role == 'Admin' || obj.role == null) {
          filename = 'report_Report_Transaction_Ho_' + bp_name + '.xlsx';
        } else {
          filename = 'report_Report_Transaction_Dist_' + bp_name + '.xlsx';
        }
      }

      return workbook.outputAsync().then(res => {
        saveAs(res, filename);
      });
    });
  };
  render() {
    // TODO Loading
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const {
      businessPoints,
      transactionStatus,
      dispenseStatus,
      dateRange,
      role,
      refundStatus,
    } = this.state;
    let list = [];

    {
      Project == 'Svfranke'
        ? role == 'refiller'
          ? []
          : (list = safeDeepGet(this.props, ['result', 'body'], []))
        : (list = safeDeepGet(this.props, ['result', 'body'], []));
    }

    // const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    //pagination customization
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(Order),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id',
        order: 'desc',
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };
    const report_list = this.format_report(list);
    let report_filename = '';
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    if (dateRange[1] == null) {
      var endDate = new Date();
      var startDate = new Date();
      startDate.setDate(1);
      report_filename =
        'Trx ' +
        bp_name +
        ' ' +
        startDate.toLocaleDateString('en-GB') +
        ' ke ' +
        endDate.toLocaleDateString('en-GB');
    } else {
      report_filename =
        'Trx ' +
        bp_name +
        ' ' +
        dateRange[0].toLocaleDateString('en-GB') +
        ' ke ' +
        dateRange[1].toLocaleDateString('en-GB');
    }
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <PaginationProvider
              pagination={paginationFactory(pageOptions || [])}
              keyField="id"
              columns={this.state.SvFranketransactionColumns || []}
              data={list}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={list}
                  columns={this.state.SvFranketransactionColumns || []}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="2">
                          <FormGroup className="mb-3 ajax-select select2-container">
                            <Select
                              defaultValue={bpfilterlist[0]}
                              value={businessPoints}
                              onChange={this.handleBusinessPoints}
                              options={bpfilterlist}
                              classNamePrefix="select2-selection"
                              isLoading={false}
                              placeholder="Business Point"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={paymentOptions[0]}
                            isSearchable={false}
                            onChange={this.handleTransactionStatus}
                            value={transactionStatus}
                            name="color"
                            options={paymentOptions}
                            placeholder="Payment Status"
                          />
                        </Col>
                        <Col sm="2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={dispenseOptions[0]}
                            isSearchable={false}
                            onChange={this.handleDispenseStatus}
                            value={dispenseStatus}
                            name="color"
                            options={dispenseOptions}
                            placeholder="Dispense Status"
                          />
                        </Col>
                        <Col sm="4">
                          <Row>
                            <Col sm="4">
                              <Label className="col-md-12 col-form-label text-sm-end">
                                Date Range
                              </Label>
                            </Col>
                            <Col sm="8">
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="dd M,yyyy"
                                  options={{
                                    maxDate: new Date(),
                                    mode: 'range',
                                    dateFormat: 'Y-m-d',
                                  }}
                                  value={dateRange}
                                  onChange={this.handleDateRange}
                                  ref={this.refDatePicker}
                                />
                                {/* <Button
                                  type="reset"
                                  color="secondary"
                                  onClick={this.handleClearDateRange}
                                >
                                  Clear
                                </Button> */}
                              </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          sm="2"
                          className="d-none d-lg-block"
                          align="center"
                        >
                          <div className="clearfix mt-4 mt-lg-0">
                            <Button
                              type="button"
                              color="primary"
                              className="btn btn-nestle w-sm"
                              // disabled={loading}
                              onClick={() => this.handleExport(report_list)}
                            >
                              <i className="mdi mdi-download d-block font-size-16"></i>
                              Download Report
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <div className="table-responsive">
                        <ReactTooltip place="top" effect="solid" />
                        <BootstrapTable    
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          classes={
                            'table align-middle table-nowrap table-check'
                          }
                          headerWrapperClasses={'table-light'}
                          ref={this.node}
                        />
                      </div>
                      <div className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
            <Modal
              size="lg"
              isOpen={this.state.modal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle} tag="h4">
                {'Refund Order ID ' + this.state.products.id}
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.handleValidRefundSubmit}>
                  <Row form>
                    <Col className="col-12">
                      <div className="mb-3">
                        <AvField
                          name="orderId"
                          label="Masukan Jumlah Refund"
                          type="number"
                          errorMessage="Invalid amount"
                          validate={{
                            required: { value: true },
                            min: { value: 0.01 },
                            max: { value: this.state.products.amount },
                          }}
                          value={this.state.products.amount || ''}
                        />
                      </div>
                      <p>
                        {'Batas minimum refund Rp. 0.01.- dan Max Rp. ' +
                          this.state.products.amount +
                          '.-'}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                          disabled={this.state.products.button}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bpfilterresult: safeDeepGet(
    state,
    ['transactionrfid', 'list_business_points'],
    {}
  ),
  result: safeDeepGet(state, ['transactionrfid', 'list'], {}),
  loading: safeDeepGet(state, ['transactionrfid', 'loading'], true),
  test: state
});
Transaction.propTypes = {
  onRefund: PropTypes.func,
};
const mapDispatchToProps = dispatch => ({
  LoadBpFilterList: () => dispatch(list_business_points()),
  LoadBusinessPointFillter: payload => dispatch(list_business_points(payload)),
  LoadTransactionList: (
    businessPoints,
    transactionStatus,
    dispenseStatus,
    dateRange,
    data
  ) =>
    dispatch(
      list(businessPoints, transactionStatus, dispenseStatus, dateRange, data)
    ),
  ReadTransaction: transactionId => dispatch(read(transactionId)),
  RefundTransaction: payload => dispatch(updateRefund(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
