/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Pages403 from 'Base/pages/pages-403';
import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Table,
  InputGroup,
} from 'reactstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
// Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
import { refund_list } from '../../../store/transaction/actions';
import { list_business_points } from '../../../store/transaction/actions';
import { REFUND_PERIOD, APPLICATION_ID, buildTitle } from 'Apps';

class RefundList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();
    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - REFUND_PERIOD);
    let endDate = new Date();
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start))
    let dateEnd = new Date(Number(end))
    let businessPoints = { value: 'all' };
    let arrDate = [newDate, endDate]
    if (name != undefined || name != null) {
      businessPoints = { value: name };
    }
    if(start != undefined || start != null){
      arrDate =[dateStart, dateEnd];
    } else {
      arrDate = [newDate, endDate]
    }
    this.state = {
      businessPoints: businessPoints,
      payment: 'all',
      refundStatus: 'all',
      dispenseStatus: 'all',
      bpfilterlist: [],
      dateRange: arrDate,

      chatListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          searchable: false,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'order_id',
          text: 'Order Id',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className="mb-1"
                onClick={() => this.handleDetail(row)}
              >
                {row.order_id}
              </p>
            </>
          ),
        },
        {
          dataField: 'reason',
          text: 'Refund Reason',
          sort: true,
          formatter: (cellContent, row) => (
            <p className="mb-1">{safeDeepGet(row, 'reason','-')}</p>
          ),
        },
        {
          dataField: 'time_stamp',
          text: 'Refund Date',
          sort: true,
          formatter: (cellContent, row) => (
            <p className="mb-1">{this.handleValidDate(row.time_stamp)}</p>
          ),
        },
        {
          text: 'Business Point',
          dataField: 'nameBp',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.nameBp}
                </Link>
              </p>
            </>
          ),
        },
        {
          dataField: 'user',
          text: 'Refund by',
          sort: true,
          formatter: (cellContent, row) => <>{row.user}</>,
        },
      ],
    };
    this.showToast = this.showToast.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
  }
  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: this.state.dateRange,
    };
    this.props.LoadRefundList(payload);

    var searchParams = new URLSearchParams(window.location.search);
      if (businessPoints.value == 'all') {
        searchParams.delete('device_id');
        this.props.history.push({
          pathname: '/refund/list',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('device_id', businessPoints.value);
        this.props.history.push({
          pathname: '/refund/list',
          search: searchParams.toString(),
        });
      }
  };
  componentDidMount() {
    // Load business point
    this.props.LoadBpFilterList();
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
    };
    this.props.LoadRefundList(payload);
  }
  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRange });
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: dateRange,
      };
      this.props.LoadRefundList(payload);
      var searchParams = new URLSearchParams(window.location.search);
      if (dateRange[0] == null || dateRange[0] == undefined) {
        searchParams.delete('dateStart');
        searchParams.delete('dateEnd');
        this.props.history.push({
          pathname: '/refund/list',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('dateStart', dateRange[0].getTime());
        searchParams.set('dateEnd', (dateRange[1].getTime() - 1000));
        this.props.history.push({
          pathname: '/refund/list',
          search: searchParams.toString(),
        });
      }
    }
  };
  handleImage = arg => {
    const trx = arg;
    this.setState({
      trx,
    });
    // this.toggleDetail();
    window.open(safeDeepGet(trx, 'img_url', 'default'));
  };
  handleDetail = arg => {
    const trx = arg;
    this.setState({
      trx,
    });
    // this.toggleDetail();
    this.props.history.push(
      `/transaction/detail/${safeDeepGet(trx, 'order_id', 'default')}`
    );
  };
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Product';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
    window.location.reload();
  }

  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    let {
      businessPoints,
      refundStatus,
      dateRange,
    } = this.state;
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'time_stamp', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let refund = params.get('refund_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start))
    let dateEnd = new Date(Number(end))
    if(start != undefined || start != null){
      dateRange =[dateStart, dateEnd];
    } else {
      dateRange = dateRange
    }
    if (name != undefined || name != null) {
      businessPoints = name;
    } else {
      businessPoints = businessPoints.value;
    }
    if (refund != undefined || refund != null) {
      refundStatus = refund;
    } else {
      refundStatus = refundStatus.value;
    }
    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
              <title>{buildTitle('History Refund List')}</title>
            </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Refund" breadcrumbItem="History Refund" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="time_stamp"
                      columns={this.state.chatListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="time_stamp"
                          columns={this.state.chatListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="2">
                                  <FormGroup className="mb-3 ajax-select select2-container">
                                    <Select
                                      defaultValue={bpfilterlist[0]}
                                      value={bpfilterlist.filter(function (
                                        option
                                      ) {
                                        return option.value  === businessPoints;
                                      })}
                                      onChange={this.handleBusinessPoints}
                                      options={bpfilterlist}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      placeholder="Business Point"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="2"></Col>
                                <Col sm="2"></Col>
                                <Col sm="2"></Col>
                                <Col sm="4">
                                  <Row>
                                    <Col sm="4">
                                      <Label className="col-md-12 col-form-label text-sm-end">
                                        Date Range
                                      </Label>
                                    </Col>
                                    <Col sm="8">
                                      <InputGroup>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="dd M,yyyy"
                                          options={{
                                            maxDate: new Date(),
                                            mode: 'range',
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={dateRange}
                                          onChange={this.handleDateRange}
                                          ref={this.refDatePicker}
                                        />
                                        {/* <Button
                                  type="reset"
                                  color="secondary"
                                  onClick={this.handleClearDateRange}
                                >
                                  Clear
                                </Button> */}
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable    
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['transaction', 'refund_list'], {}),
  loading: safeDeepGet(state, ['transaction', 'loading'], true),
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
});

const mapDispatchToProps = dispatch => ({
  LoadRefundList: payload => dispatch(refund_list(payload)),
  LoadBpFilterList: () => dispatch(list_business_points()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundList);
