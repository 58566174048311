/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { App_ID } from 'Apps';
import { Buffer } from 'buffer';
// Product state
import {
  LIST,
  DETAIL_CHAT,
  CREATE,
  CANCEL,
  DELETE,
  CHECK_PAYMENT,
  EDIT,
  LIST_SUBS,
  LIST_DEVICE_SUBS,
  UNTAG_DEVICE,
  EXTEND_SUBS,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet, safeDel } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {
  deviceNonSubs,
  createPayment,
  deleteCoupon,
  getSubscription,
  cancelPayment,
  detailDeviceSubs,
  unTagDeviceSub,
  unRegisterSub,
  extendSub,
} from 'Apps/device'
;
 
function* list_subs() {
  yield put(loading(LIST_SUBS));
  let wsResult = yield call(getSubscription);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const subsMap = safeDeepGet(
      wsResult,
      ['body', 'body', 'subscription_details'],
      []
    );
    const subsBody = [];
    for (const sub in subsMap) {
      subsBody.push(subsMap[sub]);
    }
    const firebaseInstance = getInstance();
    wsResult = yield call(firebaseInstance.subs.list);
    const listMap = safeDeepGet(wsResult, 'body', {});
    for (const i in listMap) {
      safeDeepSet(listMap, [i, 'id'], i);
      subsBody.push(listMap[i]);
    }
    let disabled = false;
    if (subsBody.find(e => e.status === 'On Progress')) {
      disabled = true;
    }
    if (subsBody.find(e => e.status === 'Extended')) {
      disabled = true;
    }
    yield put(
      result(LIST_SUBS, { code: Result.SUCCESS, body: subsBody, disabled })
    );
    return;
  }
  yield put(result(LIST_SUBS, { result: { code: Result.FAILED } }));
}
function* list() {
  yield put(loading(LIST));
  let wsResult = yield call(deviceNonSubs);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const deviceBody = safeDeepGet(wsResult, ['body', 'devices'], []);
    const deviceList = [];
    for (const id in deviceBody) {
      safeDeepSet(
        deviceBody[id],
        'name',
        safeDeepGet(deviceBody[id], 'label', '')
      );
      safeDeepSet(deviceBody[id], 'active', 'off');
      deviceList.push(deviceBody[id]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: deviceList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}
function* create(action) {
  yield put(loading(CREATE));
  const payload = safeDeepGet(action, ['payload', 'nav'], []);
  const duration = safeDeepGet(action, ['payload', 'duration'], []);
  const PO = safeDeepGet(action, ['payload', 'po'], '');
  const date = new Date();
  var newDate = new Date(date.setMonth(date.getMonth() + duration));
  const name = safeDeepGet(action, ['payload', 'name'], '');
  const phone = safeDeepGet(action, ['payload', 'phone'], 0);
  const submittedValues = [];
  const tsDuration = newDate.getTime();
  var amount = 0;
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.config.list);
  const price = safeDeepGet(wsResult, ['body', 'subsPrice', 'price'], 999999999)
  for (const i in payload) {
    const active = safeDeepGet(payload[i], 'active', 'off');
    const device_id = safeDeepGet(payload[i], 'id');

    if (active != 'off') {
      submittedValues.push(device_id);
      amount += price;
    }
  }

  if (submittedValues.length < 0) {
    yield put(result(CREATE, { code: Result.FAILED, body: {} }));
    return;
  }
  const body = {
    price: amount * duration,
    method: 'QRIS-MIDTRANS',
    name: name,
    phone: phone.toString(),
  };
  const btoaBody = Buffer.from(decodeURIComponent(encodeURIComponent(JSON.stringify(body)))).toString('base64');
  wsResult = yield call(createPayment, btoaBody);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const url = safeDeepGet(wsResult, ['body', 'body', 'url'], ' ');
    const transaction_id = safeDeepGet(
      wsResult,
      ['body', 'body', 'transaction_id'],
      ' '
    );
    const firebaseInstance = getInstance();
    const total = amount * duration;
    wsResult = yield call(
      firebaseInstance.subs.create,
      transaction_id,
      body,
      url,
      total,
      PO,
      tsDuration,
      submittedValues
    );
    yield put(
      result(CREATE, {
        code: Result.SUCCESS,
        body: { url: url, order_id: transaction_id, amount },
      })
    );
    return;
  }
  yield put(result(CREATE, { code: Result.FAILED }));
}
// function* checkPayment(payload) {
//   yield put(loading(CHECK_PAYMENT));
//   const order_id = safeDeepGet(payload, 'payload', '');
//   const firebaseInstance = getInstance();
//   const wsResult = yield call(firebaseInstance.subs.checkPayment, order_id);
//   if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
//     yield delay(6000);
//     yield call(firebaseInstance.subs.delete_, order_id);
//     yield put(result(CHECK_PAYMENT, { code: Result.SUCCESS }));
//     return;
//   }
//   yield put(result(CHECK_PAYMENT, { code: Result.FAILED }));
// }
function* edit(action) {
  yield put(loading(EDIT));
  const payload = safeDeepGet(action, 'payload', []);
  const submittedValues = [];
  for (const i in payload) {
    const active = safeDeepGet(payload[i], 'subs', 'off');
    const device_id = safeDeepGet(payload[i], 'id');
    if (active != 'off') {
      submittedValues.push(device_id);
    }
  }
  const body = {
    device: submittedValues,
  };
  const wsResult = yield call();
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    yield put(
      result(EDIT, {
        code: Result.SUCCESS,
        body: { url: 'Data Berhasil di Buat' },
      })
    );
    return;
  }
  yield put(result(EDIT, { code: Result.FAILED }));
}
function* cancel_(payload) {
  yield put(loading(CANCEL));
  const order_id = safeDeepGet(payload, 'payload', {});
  if (order_id == '') {
    yield put(result(CANCEL, { code: Result.SUCCESS }));
    return;
  }
  const wsResult = yield call(cancelPayment, order_id);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    yield put(result(CANCEL, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(CANCEL, { code: Result.FAILED }));
}
function* delete_(payload) {
  yield put(loading(DELETE));
  const key = safeDeepGet(payload, 'payload', '');
  const body = {
    key: key,
  };
  const wsResult = yield call(unRegisterSub, body);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    yield put(result(DELETE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(DELETE, { code: Result.FAILED }));
}

function* list_device(payload) {
  yield put(loading(LIST_DEVICE_SUBS));
  const key = safeDeepGet(payload, 'key', '');
  const wsResult = yield call(detailDeviceSubs, key);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const deviceBody = safeDeepGet(wsResult, ['body', 'devices'], []);
    const deviceList = [];
    for (const i in deviceBody) {
      const label = safeDeepGet(deviceBody[i], 'label', '');
      const id = safeDeepGet(deviceBody[i], 'id', '');
      deviceList.push({ label, id, subs: 'on' });
    }
    yield put(
      result(LIST_DEVICE_SUBS, { code: Result.SUCCESS, body: deviceList })
    );
    return;
  }
  yield put(result(LIST_DEVICE_SUBS, { code: Result.FAILED }));
}
function* unTagDevice(payload) {
  yield put(loading(UNTAG_DEVICE));
  const table = safeDeepGet(payload, ['payload', 'table'], []);
  const deviceBody = [];
  for (const i in table) {
    const device_id = safeDeepGet(table[i], 'id', '');
    const subs = safeDeepGet(table[i], 'subs', '');
    if (subs == 'off') {
      deviceBody.push(device_id);
    }
  }
  const key = safeDeepGet(payload, ['payload', 'key'], '');
  const body = {
    key: key,
    device_ids: deviceBody,
  };
  console.log(body)
  const wsResult = yield call(unTagDeviceSub, body);
  console.log(wsResult)
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    yield put(result(UNTAG_DEVICE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(UNTAG_DEVICE, { code: Result.FAILED }));
}

function* extendedSub(action) {
  yield put(loading(EXTEND_SUBS));
  const payload = safeDeepGet(action, 'payload', {});
  const key = safeDeepGet(payload, 'old_key', '');
  const phone = safeDeepGet(payload, 'phone', '');
  const end_ts = safeDeepGet(payload, 'end_ts', '');
  const name = safeDeepGet(payload, 'name', '');
  const duration = safeDeepGet(payload, 'duration', 0);
  const PO = safeDeepGet(payload, 'new_key', '');
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.config.list);
  const price = safeDeepGet(wsResult, ['body', 'subsPrice', 'price'], 999999999)
  
  wsResult = yield call(detailDeviceSubs, key);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const deviceBody = safeDeepGet(wsResult, ['body', 'devices'], []);
    const deviceList = [];
    var amount = 0;
    for (const i in deviceBody) {
      const id = safeDeepGet(deviceBody[i], 'id', '');
      deviceList.push(id);
      amount += price;
    }
    // const wsResult = yield call(extendedSub, body)
    const body = {
      price: amount * duration,
      method: 'QRIS-MIDTRANS',
      name: name,
      phone: phone.toString(),
    };
    const btoaBody = Buffer.from(decodeURIComponent(encodeURIComponent(JSON.stringify(body)))).toString('base64');
    wsResult = yield call(createPayment, btoaBody);
    if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      const url = safeDeepGet(wsResult, ['body', 'body', 'url'], ' ');
      const transaction_id = safeDeepGet(
        wsResult,
        ['body', 'body', 'transaction_id'],
        ' '
      );
      const firebaseInstance = getInstance();
      const total = amount * duration;
      wsResult = yield call(
        firebaseInstance.subs.extended,
        transaction_id,
        body,
        url,
        total,
        PO,
        end_ts,
        deviceList,
        key
      );
      yield put(
        result(EXTEND_SUBS, {
          code: Result.SUCCESS,
          body: { url: url, order_id: transaction_id, amount },
        })
      );
      return;
    }
    yield put(result(EXTEND_SUBS, { code: Result.FAILED }));
  }
  yield put(result(EXTEND_SUBS, { code: Result.FAILED }));
}
function* payoutSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(CREATE, create);
  yield takeEvery(CANCEL, cancel_);
  yield takeEvery(EDIT, edit);
  yield takeEvery(LIST_SUBS, list_subs);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(LIST_DEVICE_SUBS, list_device);
  yield takeEvery(UNTAG_DEVICE, unTagDevice);
  yield takeEvery(EXTEND_SUBS, extendedSub);
  // yield takeEvery(CHECK_PAYMENT, checkPayment);
}

export default payoutSaga;
