import { call, put, takeEvery } from 'redux-saga/effects';

// Product state
import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  LIST_INGREDIENT,
  REFILL_LIST,
} from './actionTypes';
import { PROJECT } from '../../../Project';
import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, has, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';

function* list(payload) {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  const id = safeDeepGet(payload, ['id']);
  let wsResult = yield call(firebaseInstance.stock.list, id);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const productMap = wsResult.body;
    const powderTypes = [];
    const beanTypes = [];
    wsResult = yield call(firebaseInstance.ingredient.listoptions);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const ingredientsPowder = wsResult.body;
      for (const i in ingredientsPowder) {
        const id = safeDeepGet(ingredientsPowder, [i, 'type']);
        if (has(productMap, id)) {
          powderTypes.push(id);
        }
      }
    }
    wsResult = yield call(firebaseInstance.ingredient.listbeans);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const ingredientsPowder = wsResult.body;
      for (const i in ingredientsPowder) {
        const id = safeDeepGet(ingredientsPowder, [i, 'type']);
        if (has(productMap, id)) {
          beanTypes.push(id);
        }
      }
    }
    wsResult = yield call(firebaseInstance.businessPoint.list);
    const bsList = wsResult.body;
    const filter = Object.values(bsList).filter(user => user.id === id);
    yield put(
      result(LIST, {
        code: Result.SUCCESS,
        body: {
          product: productMap,
          filter: filter,
          powder: powderTypes,
          bean: beanTypes,
        },
      })
    );
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}
function* refill_list() {
  yield put(loading(REFILL_LIST));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.ingredient.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const distributorMap = wsResult.body;
    const distributorList = [];
    const stockRefillList = {};
    let i = 0;
    for (const sku in distributorMap) {
      const id = safeDeepGet(distributorMap, [sku, 'type']);
      stockRefillList[id] = distributorMap[sku];
      // distributorMap[sku].id = sku;
      // safeDeepSet(distributorMap, [sku, "id"], i++);
      distributorList.push(distributorMap[sku]);
    }
    yield put(
      result(REFILL_LIST, { code: Result.SUCCESS, body: stockRefillList })
    );
    return;
  }
  yield put(result(REFILL_LIST, { result: { code: Result.FAILED } }));
}
function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }));
}

function* create(action) {
  const firebaseInstance = getInstance();

  yield put(loading(CREATE));
  const payload = safeDeepGet(action, ['payload']);
  const canister1 = safeDeepGet(action, ['payload', 'canister1']);
  const canister2 = safeDeepGet(action, ['payload', 'canister2']);
  const canister3 = safeDeepGet(action, ['payload', 'canister3']);
  const canister4 = safeDeepGet(action, ['payload', 'canister4']);
  const beans = safeDeepGet(action, ['payload', 'beans']);
  const amount1 = safeDeepGet(action, ['payload', 'amount1']);
  const amount2 = safeDeepGet(action, ['payload', 'amount2']);
  const amount3 = safeDeepGet(action, ['payload', 'amount3']);
  const amount4 = safeDeepGet(action, ['payload', 'amount4']);
  const amountbeans = safeDeepGet(action, ['payload', 'amountbeans']);
  const cupl = safeDeepGet(action, ['payload', 'cupl']);
  const cupm = safeDeepGet(action, ['payload', 'cupm']);
  const lid = safeDeepGet(action, ['payload', 'lid']);
  const water = safeDeepGet(action, ['payload', 'water']);
  const stir = safeDeepGet(action, ['payload', 'stir']);
  const id = safeDeepGet(action, ['payload', 'id']);
  const data = {
    water: water,
    cup_l: cupl,
    cup_m: cupm,
    lid: lid,
    stir: stir,
  };
  data[canister1] = amount1
  data[canister2] = amount2
  data[canister3] = amount3
  data[canister4] = amount4
  data[beans] = amountbeans
  const wsResult = yield call(firebaseInstance.stock.create,id, data);
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(CREATE, { code }));
}

function* read(action) {
  yield put(loading(READ));
  const sku = safeDeepGet(action, ['payload', 'sku']);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.read, sku);
  const ingredientsMaps = wsResult.body.ingredients;
  const maps = wsResult.body;
  for (const id in ingredientsMaps) {
    var type = safeDeepGet(ingredientsMaps[id], ['type']);
    var amount = safeDeepGet(ingredientsMaps[id], ['amount']);
    safeDeepSet(wsResult.body, [id, 'type'], type);
    safeDeepSet(wsResult.body, [id, 'amount'], amount);
  }

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function* update(action) {
  yield put(loading(UPDATE));
  const sku = safeDeepGet(action, ['payload', 'name']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const ingredients = [];
  const selectedVM = safeDeepGet(action, ['payload', 'selectedVM']);
  const selectedVM2 = safeDeepGet(action, ['payload', 'selectedVM2']);
  const selectedVM3 = safeDeepGet(action, ['payload', 'selectedVM3']);
  const selectedVM4 = safeDeepGet(action, ['payload', 'selectedVM4']);
  const amount = safeDeepGet(action, ['payload', 'amount']);
  const amount2 = safeDeepGet(action, ['payload', 'amount2']);
  const amount3 = safeDeepGet(action, ['payload', 'amount3']);
  const amount4 = safeDeepGet(action, ['payload', 'amount4']);
  if (selectedVM || amount) {
    ingredients.push({ type: selectedVM, amount: amount });
  }
  if (selectedVM2 || amount2) {
    ingredients.push({ type: selectedVM2, amount: amount2 });
  }
  if (selectedVM3 || amount3) {
    ingredients.push({ type: selectedVM3, amount: amount3 });
  }
  if (selectedVM4 || amount4) {
    ingredients.push({ type: selectedVM4, amount: amount4 });
  }
  payload['ingredients'] = ingredients;

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile']);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt']);
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url']);
    }
  }
  if (imageUrl != null) {
    payload['imgUrl'] = imageUrl;
  }

  const wsResult = yield call(firebaseInstance.product.update, sku, payload);
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(UPDATE, { code }));
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  const sku = safeDeepGet(payload, ['payload', 'sku'], '');
  const wsResult = yield call(firebaseInstance.product.delete_, sku);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(REFILL_LIST, refill_list);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(DELETE, delete_);
}

export default productSaga;
