/* eslint-disable no-self-assign */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { APPLICATION_ID, NAVBAR, PERMISION, buildTitle } from 'Apps';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Link } from 'react-router-dom';
import { PROJECT_HANDLE } from 'Apps';
import { CSVLink } from 'react-csv';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Table,
  InputGroup,
} from 'reactstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
import { list_business_points } from '../../store/transaction/actions';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// Redux
import { connect } from 'react-redux';
import { list_history } from '../../store/business-point/actions';
import Pages403 from '../pages-403';
const navbar = NAVBAR
const permision = PERMISION
class PlanogramList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - 2);
    let endDate = new Date();
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    let businessPoints = { value: 'all' };
    let arrDate = [newDate, endDate];
    if (name != undefined || name != null) {
      businessPoints = { value: name };
    }
    if (start != undefined || start != null) {
      arrDate = [dateStart, dateEnd];
    } else {
      arrDate = [newDate, endDate];
    }
    this.state = {
      businessPoints: businessPoints,
      dateRange: arrDate,

      planogramListColumns: [
        {
          text: 'time_stamp',
          dataField: 'time_stamp',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.time_stamp}</>,
        },
        {
          dataField: 'time_stamp',
          text: 'Time',
          formatter: (cellContent, row) => (
            <>{this.handleValidDate(row.time_stamp)}</>
          ),
        },
        {
          dataField: 'name',
          text: 'Business Point',
          sort: true,
          formatter: (cellContent, row) => <>{row.name}</>,
        },
        {
          dataField: 'user',
          text: 'User',
          sort: true,
          formatter: (cellContent, row) => <>{row.user}</>,
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, row) => (
            <div className="clearfix mt-4 mt-lg-0">
              <ExcelFile
                filename={
                  row.name + '_' + '_' + this.handleValidDate(row.time_stamp)
                }
                element={
                  <button type="button" className="btn btn-primary w-sm">
                    <i className="mdi mdi-download d-block font-size-16"></i>
                    Download
                  </button>
                }
              >
                {APPLICATION_ID == '1000000211' || APPLICATION_ID == '1000000226' ? (
                  <ExcelSheet data={row.planogram} name="Transaction List">
                    <ExcelColumn
                      label="selection"
                      value="selection"
                      numFmt="0"
                    />
                    <ExcelColumn label="sku" value="sku" />
                    <ExcelColumn label="name" value="name" />
                    <ExcelColumn label="price" value="price" numFmt="0" />
                  </ExcelSheet>
                ) : (
                  <ExcelSheet data={row.planogram} name="Transaction List">
                    <ExcelColumn label="Selection" value="key" />
                    <ExcelColumn label="Product" value="name" />
                    <ExcelColumn label="Product SKU" value="product" />
                    <ExcelColumn label="Product Price" value="price" />
                    <ExcelColumn label="Stock" value="stock" />
                    <ExcelColumn label="Active" value="active" />
                  </ExcelSheet>
                )}
              </ExcelFile>
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleValidDate = this.handleValidDate.bind(this);
  }

  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Product';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRange });
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: dateRange,
      };
      this.props.LoadHistoryList(payload);
      var searchParams = new URLSearchParams(window.location.search);
      if (dateRange[0] == null || dateRange[0] == undefined) {
        searchParams.delete('dateStart');
        searchParams.delete('dateEnd');
        this.props.history.push({
          pathname: '/business-point/history/planogram',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('dateStart', dateRange[0].getTime());
        searchParams.set('dateEnd', dateRange[1].getTime() - 1000);
        this.props.history.push({
          pathname: '/business-point/history/planogram',
          search: searchParams.toString(),
        });
      }
    }
  };
  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: this.state.dateRange,
    };
    this.props.LoadHistoryList(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (businessPoints.value == 'all') {
      searchParams.delete('device_id');
      this.props.history.push({
        pathname: '/business-point/history/planogram',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('device_id', businessPoints.value);
      this.props.history.push({
        pathname: '/business-point/history/planogram',
        search: searchParams.toString(),
      });
    }
  };
  componentDidMount() {
    // Load Productx
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
    };
    this.props.LoadHistoryList(payload);
    this.props.LoadBpFilterList();
  }
  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/product/create');
  };

  handleEditClick = row => {
    this.props.history.push(
      `/product/edit/${safeDeepGet(row, 'sku', 'default')}`
    );
  };

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;
    let { businessPoints, dateRange } = this.state;
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'time_stamp', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    if (start != undefined || start != null) {
      dateRange = [dateStart, dateEnd];
    } else {
      dateRange = dateRange;
    }
    if (name != undefined || name != null) {
      businessPoints = name;
    } else {
      businessPoints = businessPoints.value;
    }

    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
              <title>{buildTitle('History Planogram List')}</title>
            </MetaTags>
        
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs
                title="Planogram"
                breadcrumbItem="History Planogram"
              />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="time_stamp"
                        columns={this.state.planogramListColumns}
                        data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="time_stamp"
                            columns={this.state.planogramListColumns}
                            data={list}
                            search
                          >
                            {toolkitprops => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="2">
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      // defaultValue={bpfilterlist[0]}
                                      onChange={this.handleBusinessPoints}
                                      value={bpfilterlist.filter(function (
                                        option
                                      ) {
                                        return option.value === businessPoints;
                                      })}
                                      name="color"
                                      options={bpfilterlist}
                                      placeholder="Business Point"
                                    />
                                  </Col>
                                  <Col sm="6"></Col>
                                  <Col sm="4">
                                    <Row>
                                      <Col sm="4">
                                        <Label className="col-md-12 col-form-label text-sm-end">
                                          Date Range
                                        </Label>
                                      </Col>
                                      <Col sm="8">
                                        <InputGroup>
                                          <Flatpickr
                                            className="form-control d-block"
                                            placeholder="dd M,yyyy"
                                            options={{
                                              maxDate: new Date(),
                                              mode: 'range',
                                              dateFormat: 'Y-m-d',
                                            }}
                                            value={dateRange}
                                            onChange={this.handleDateRange}
                                            ref={this.refDatePicker}
                                          />
                                        </InputGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                         
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          'table align-middle table-nowrap table-hover'
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={this.node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['businessPoint', 'list_history'], {}),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  test: state,
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
});

const mapDispatchToProps = dispatch => ({
  LoadHistoryList: payload => dispatch(list_history(payload)),
  LoadBpFilterList: () => dispatch(list_business_points()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramList);
