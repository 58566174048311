/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
// Strap
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Row,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Router
// Table
import Pages403 from '../pages-403';
import Result from 'iotera-base/result';
// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
import { APPLICATION_ID, REFUND_PERIOD, buildTitle } from 'Apps';

import 'tippy.js/dist/tippy.css';
// Redux
import { connect } from 'react-redux';
import { detail_transaction } from '../../store/transaction/actions';
import { updateRefund, refunded } from '../../store/transaction/actions';
import{ NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR
const permision = PERMISION
class DetailTransactions extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    let orderId = null;
    const path = this.props.match.path;
    if (path.includes('/detail')) {
      orderId = safeDeepGet(this.props.match.params, 'id');
    }

    this.state = {
      role: '',
      orderId,
      products: [],
      modal: false,
    };
    this.handleValidDate = this.handleValidDate.bind(this);
    this.handleValidTime = this.handleValidTime.bind(this);
    this.handleValidRefundDate = this.handleValidRefundDate.bind(this);
    this.handleRefundedMidTrans = this.handleRefundedMidTrans.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  handleValidTime = date => {
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return time1;
  };
  handleValidRefundDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    return date1;
  };
  handleRefund = row => {
    const payload = {
      order_id: safeDeepGet(row, 'id', ' '),
      transaction_status: 'refund_pending',
    };
    this.props.RefundTransaction(payload);
    this.componentDidMount();
  };
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleValidRefundSubmit = (e, values) => {
    const { onRefund } = this.props;
    const { products } = this.state;
    const refunded = {
      ...values,
      id: products.id,
      order_id: products.order_id,
      amount: products.amount,
      device_id: products.device_id,
      name: products.name,
    };

    onRefund(refunded);
    this.toggle();
    // this.handleRefund(products);
    setTimeout(() => {
      this.componentDidMount()
    }, 3);
    this.componentDidMount();
    this.showToast();
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.refundResult !== prevProps.refundResult) {
      const code = safeDeepGet(
        this.props,
        ['refundResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      // if (code == Result.SUCCESS) {
      //   this.showToast();
      // }
    }
  }
  handleRefundedMidTrans = arg => {
    const product = arg;
    this.setState({
      products: {
        id: product.id,
        amount: product.payment.amount,
        order_id: product.detail.order_id,
        device_id: product.product.device_id,
        name: product.name,
        method: product.payment.method,
      },
    });
    this.toggle();
  };

  timeConvert(n) {
    var num = n;
    var day = num / 60 / 24;
    var rday = Math.floor(day);
    var hours = (day - rday) * 24;
    var rhours = Math.round(hours);
    return rday + ' Days(s) and ' + rhours + ' hours(s) ';
  }
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  handleValidDateMs = date => {
    if (date == 0) {
      return date;
    } else {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    }
  };
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'info';
    var message = 'Refund sedang di Proses';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '10000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidMount() {
    const order_id = this.state.orderId;
    this.props.DetailTransaction(order_id);
  }
  render() {
    // TODO Loading
    const arr_list = safeDeepGet(this.props, ['result', 'body'], []);
    const list = arr_list[0];
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Detail Transaction')}</title>
          </MetaTags>

          {safeDeepGet(navbar, ['Transaction', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Transaction" breadcrumbItem="Detail" />
              <Row>
                <Col lg="12">
                  <Card>
                    {arr_list.length !== 0 ? (
                      <CardBody>
                        <div className="table-responsive mb-2">
                          <Table className="table align-middle table-nowrap">
                            <thead>
                              <tr>
                                <th scope="col">Order Id</th>
                                <th scope="row">
                                  <div>
                                    <h5 className="text-truncate font-size-14">
                                      {list.detail.order_id}
                                    </h5>
                                  </div>
                                </th>
                              </tr>

                              <tr>
                                <th scope="col">
                                  {list.product.sku ? 'Product SKU' : 'Notes'}
                                </th>
                                <th scope="row">
                                  <div>
                                    <h5 className="text -truncate font-size-14">
                                      {list.product.sku ?? list.detail.notes}
                                    </h5>
                                  </div>
                                </th>
                              </tr>
                              {list.product.duration ? (
                                 list.product.duration != '' ? (
                                  <tr>
                                    <th scope="col">Duration</th>
                                    <th scope="row">
                                      <div>
                                        <h5 className="text -truncate font-size-14">
                                          {list.product.duration} Second
                                        </h5>
                                      </div>
                                    </th>
                                  </tr>
                                ) : (
                                  <></>
                                )
                              ) :
                                <></>
                              }

                              <tr>
                                <th scope="col">
                                  {list.name
                                    ? 'Business Point'
                                    : 'PROJECT_HANDLE Name'}
                                </th>
                                <th scope="row">
                                  <div>
                                    <h5 className="text-truncate font-size-14">
                                      {list.name ?? list.detail.project_name}
                                    </h5>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                                <th scope="col">Date</th>
                                <td>
                                  <div>
                                    <span className="text-primary">
                                      {this.handleValidDate(
                                        list.time.firestore_timestamp
                                      )}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              {
                                safeDeepGet(
                                  list,
                                  ['payment', 'detail', 'backup'],
                                  false
                                )
                                ? 
                                <tr>
                                <th scope="col">Transaction Time</th>
                                <td>
                                  <div>
                                    <span className="text-primary">
                                      {this.handleValidDateMs(
                                    safeDeepGet(
                                      list,
                                      ['payment', 'detail', 'ts'],
                                      0
                                    )
                                  )}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                                : null
                              }
                              <tr>
                                <th scope="col">Total Harga</th>
                                <td>
                                  <div>
                                    <span style={{ color: 'green' }}>
                                      {safeDeepGet(
                                        list,
                                        ['payment', 'amount'],
                                        '0'
                                      )}
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                {Number(
                                  safeDeepGet(
                                    list,
                                    ['payment', 'fee', 'mdr_qris'],
                                    '0'
                                  )
                                ) !== 0 && (
                                  <>
                                    <th scope="col">MDR QRIS</th>
                                    <td>
                                      <div>
                                        <span style={{ color: 'red' }}>
                                          {'-' +
                                            Number(
                                              safeDeepGet(
                                                list,
                                                ['payment', 'fee', 'mdr_qris'],
                                                '0'
                                              )
                                            ).toFixed(2)}
                                        </span>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                              {Number(
                                safeDeepGet(
                                  list,
                                  ['payment', 'fee', 'platform_sharing_revenue'],
                                  '0'
                                )
                              ) !== 0 && (
                                <tr>
                                  <th scope="col">Platform Sharing Revenue</th>
                                  <td>
                                    <div>
                                      <span style={{ color: 'red' }}>
                                        {'-' +
                                          Number(
                                            safeDeepGet(
                                              list,
                                              ['payment', 'fee', 'platform_sharing_revenue'],
                                              '0'
                                            )
                                          )}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {Number(
                                safeDeepGet(
                                  list,
                                  ['payment', 'fee', 'landlord_sharing_revenue'],
                                  '0'
                                )
                              ) !== 0 && (
                                <tr>
                                  <th scope="col">Landlord Sharing Revenue</th>
                                  <td>
                                    <div>
                                      <span style={{ color: 'red' }}>
                                        {'-' +
                                          Number(
                                            safeDeepGet(
                                              list,
                                              ['payment', 'fee', 'landlord_sharing_revenue'],
                                              '0'
                                            )
                                          )}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <th scope="col">Nett Harga</th>
                                <td>
                                  <div>
                                    <span style={{ color: 'green' }}>
                                      {Number(
                                        safeDeepGet(
                                          list,
                                          ['payment', 'nett'],
                                          '0'
                                        )
                                      )}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              {safeDeepGet(list, ['payment', 'hpp'],'0') > 0 ? (
                                <>
                                  {' '}
                                  <tr>
                                    <th scope="col">Total HPP</th>
                                    <td>
                                      <div>
                                        <span style={{ color: 'green' }}>
                                          {safeDeepGet(
                                            list,
                                            ['payment', 'hpp'],
                                            '0'
                                          )}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="col">Total Share</th>
                                    <td>
                                      <div>
                                        <span style={{ color: 'green' }}>
                                          {safeDeepGet(
                                            list,
                                            ['payment', 'share'],
                                            '0'
                                          )}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                  {safeDeepGet(
                                    list,
                                    ['detail', 'transaction_status'],
                                    '-'
                                  ) === 'refunded' ? (
                                    <tr>
                                      <th scope="col">Alasan Refund</th>
                                      <td>
                                        <div>
                                          <span>
                                            {safeDeepGet(
                                              list,
                                              ['detail', 'refund_reason'],
                                              '-'
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </thead>
                          </Table>
                        </div>
                        <div className="table-responsive mb-2">
                          <Table className="table align-middle table-nowrap">
                            <thead>
                              <tr>
                                <th scope="col">Slot</th>
                                <th scope="col">Product</th>
                                <th scope="col">Total</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Dispense Status</th>
                                <th scope="col">Payment Method</th>
                                <th scope="col">
                                  {' '}
                                  {list.payment.method == 'RFID'
                                    ? 'RFID'
                                    : 'Issuer'}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">
                                  <div>
                                    <h5 className="text-truncate font-size-14">
                                      {safeDeepGet(
                                        list,
                                        ['product', 'column'],
                                        safeDeepGet(
                                          list,
                                          ['product', 'selection'],
                                          '-'
                                        )
                                      )}
                                    </h5>
                                  </div>
                                </th>
                                <th scope="row">
                                  <div>
                                    <h5 className="text-truncate font-size-14">
                                      {APPLICATION_ID == '1000000211'
                                        ? safeDeepGet(
                                            list,
                                            ['product', 'sku'],
                                            '-'
                                          )
                                        : safeDeepGet(list, 'nameProduct', '-')}
                                    </h5>
                                  </div>
                                </th>
                                <td>
                                  <div>
                                    <h5 className="text-truncate font-size-14">
                                      {safeDeepGet(
                                        list,
                                        ['detail', 'transaction_status'],
                                        '-'
                                      ) === 'refunded' ? (
                                        <p
                                          style={{
                                            color: 'red',
                                            marginTop: 10,
                                          }}
                                        >
                                          {'-' +
                                            Number(
                                              safeDeepGet(
                                                list,
                                                ['payment', 'amount'],
                                                '0'
                                              )
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                '$1,'
                                              )}
                                        </p>
                                      ) : safeDeepGet(
                                          list,
                                          ['detail', 'transaction_status'],
                                          '-'
                                        ) === 'settlement' ? (
                                        <p
                                          style={{
                                            color: 'green',
                                            marginTop: 15,
                                          }}
                                        >
                                          {'+' +
                                            Number(
                                              safeDeepGet(
                                                list,
                                                ['payment', 'nett'],
                                                safeDeepGet(
                                                  list,
                                                  ['payment', 'amount'],
                                                  '0'
                                                )
                                              )
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                '$1,'
                                              )}
                                        </p>
                                      ) : (
                                        <p style={{ marginTop: 15 }}>
                                          {Number(
                                            safeDeepGet(
                                              list,
                                              ['payment', 'nett'],
                                              safeDeepGet(
                                                list,
                                                ['payment', 'amount'],
                                                '0'
                                              )
                                            )
                                          )
                                            .toFixed(2)
                                            .replace(
                                              /(\d)(?=(\d\d\d)+(?!\d))/g,
                                              '$1,'
                                            )}
                                        </p>
                                      )}
                                    </h5>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <span
                                      style={{ border: 'none' }}
                                      className={
                                        'font-size-12 badge badge-pill badge-soft-' +
                                        list.badgeclass
                                      }
                                      color={list.badgeclass}
                                    >
                                      {list.detail.transaction_status}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <span
                                      style={{ border: 'none' }}
                                      className={
                                        'font-size-12 badge badge-pill badge-soft-' +
                                        list.badge
                                      }
                                      color={list.badge}
                                    >
                                      {/* {list.detail.dispense_status} */}
                                      {safeDeepGet(
                                        list,
                                        ['detail', 'dispense_status'],
                                        '-'
                                      )}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <h5 className="text-truncate font-size-14">
                                      {list.payment.method}
                                    </h5>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <h5 className="text-truncate font-size-14">
                                      {list.payment.method == 'ECR'
                                        ? safeDeepGet(
                                            list,
                                            ['payment', 'detail', 'card_type'],
                                            ''
                                          )
                                        : list.payment.method == 'RFID'
                                        ? list.payment.detail.rfid
                                        : list.payment.detail.issuer}
                                    </h5>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="mb-4">
                          {safeDeepGet(
                            navbar,
                            ['Transaction', 'edit'],
                            'off'
                          ) == 'off' ? null : (
                            <Row>
                              <Col sm={1}>
                                <div className="text-center">
                                  {' '}
                                  {list.detail.transaction_status ==
                                  'settlement' ? (
                                    safeDeepGet(
                                      list,
                                      ['payment', 'method'],
                                      '-'
                                    ) == 'QRIS-MIDTRANS' ? (
                                      list.time.timestamp >= twoDay ? (
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-sm btn-rounded btn-success"
                                          onClick={() =>
                                            this.handleRefundedMidTrans(list)
                                          }
                                        >
                                          Refund
                                        </Button>
                                      ) : (
                                        ' '
                                      )
                                    ) : (
                                      ' '
                                    )
                                  ) : list.detail.transaction_status ==
                                    'refunded' ? (
                                    <p className="mb-0">Refunded on</p>
                                  ) : (
                                    ' '
                                  )}
                                </div>
                              </Col>
                              <Col sm={11}>
                                <div className="text-start">
                                  <p
                                    style={{ fontWeight: 'bold' }}
                                    className="mt-1"
                                  >
                                    {list.detail.transaction_status ==
                                    'settlement' ? (
                                      safeDeepGet(
                                        list,
                                        ['payment', 'method'],
                                        '-'
                                      ) == 'QRIS-MIDTRANS' ? (
                                        list.time.timestamp >= twoDay ? (
                                          'Time remaining for Refund ' +
                                          this.timeConvert(
                                            Math.ceil(
                                              (list.time.timestamp - twoDay) /
                                                one_day
                                            )
                                          )
                                        ) : (
                                          ' '
                                        )
                                      ) : (
                                        ' '
                                      )
                                    ) : list.detail.transaction_status ==
                                      'refunded' ? (
                                      <>
                                        <p className="mb-0">
                                          {this.handleValidRefundDate(
                                            list.detail.refund_time
                                          ) + ' '}{' '}
                                          {this.handleValidTime(
                                            list.detail.refund_time
                                          )}
                                        </p>
                                        <p className="mb-1"></p>
                                      </>
                                    ) : (
                                      ' '
                                    )}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </CardBody>
                    ) : null}
                  </Card>
                  <Modal
                    size="lg"
                    isOpen={this.state.modal}
                    className={this.props.className}
                  >
                    <ModalHeader toggle={this.toggle} tag="h4">
                      {'Refund for Order ID: ' + this.state.products.id}
                    </ModalHeader>
                    <ModalBody>
                      <AvForm onValidSubmit={this.handleValidRefundSubmit}>
                        <Row form>
                          <Col className="col-12">
                            <div className="mb-3">
                              <AvField
                                name="orderId"
                                label="Masukan Jumlah Refund"
                                type="number"
                                errorMessage="Invalid amount"
                                validate={{
                                  required: { value: true },
                                  min: { value: 0.01 },
                                  max: { value: this.state.products.amount },
                                }}
                                disabled={true}
                                value={this.state.products.amount || ''}
                              />
                            </div>
                            <p>
                              {'Batas minimum refund Rp. 0.01.- dan Max Rp. ' +
                                this.state.products.amount +
                                '.-'}
                            </p>
                            <div className="mb-3">
                              <AvField
                                name="reason"
                                label="Masukan alasan refund.."
                                type="text"
                                disabled={false}
                                value={''}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                                disabled={this.state.products.button}
                              >
                                Submit
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['transaction', 'transaction_detail'], {}),
  loading: safeDeepGet(state, ['transaction', 'loading'], true),
  refundResult: safeDeepGet(state, ['transaction', 'refunded'], {}),
  test: state,
});
DetailTransactions.propTypes = {
  onRefund: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  DetailTransaction: payload => dispatch(detail_transaction(payload)),
  RefundTransaction: payload => dispatch(updateRefund(payload)),
  onRefund: refund => dispatch(refunded(refund)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailTransactions);
