export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "ADMIN_USER_LIST";
export const LIST_VM = "ADMIN_USER_LIST_VM";
export const INIT_CREATE = "ADMIN_USER_INIT_CREATE";
export const CREATE = "ADMIN_USER_CREATE";
export const READ = "ADMIN_USER_INFO";
export const UPDATE = "ADMIN_USER_UPDATE";
export const STOCK_UPDATE = "STOCK_ADMIN_USER_UPDATE";
export const DELETE = "ADMIN_USER_DELETE";
