/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import {APPLICATION_ID} from 'Apps'
//i18n
import { withTranslation } from 'react-i18next';

// users
import user1 from '../../../assets/images/users/user1.png';

import { connect } from 'react-redux';
import { safeDeepGet } from 'iotera-base/utility/json';

const getUserName = () => {
  if (localStorage.getItem('authUser')) {
    const obj = JSON.parse(localStorage.getItem('authUser'));
    return obj;
  }
};

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      menu: false,
      name: 'Admin',
    };
    this.toggle = this.toggle.bind(this);
    this.handleReload = this.handleReload.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      this.setState({ name: userData.username });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username });
      }
    }
  }
  handleReload() {
    window.location.reload();
    const role = localStorage.getItem('role');
    if (APPLICATION_ID == '1000000211') {
      if (role == 'admin' || role == null) {
        localStorage.setItem('PROJECT', 'Nestle');
        localStorage.setItem('APP_ID', '1000000159');
      } else {
        localStorage.setItem('PROJECT', 'Nestle');
        localStorage.setItem('APP_ID', '1000000159');
        localStorage.removeItem('authUser');
        localStorage.removeItem('role');
      }
    } else if (APPLICATION_ID == '1000000159') {
      if (role == 'admin' || role == null) {
        localStorage.setItem('PROJECT', 'Franke');
        localStorage.setItem('APP_ID', '1000000211');
      } else {
        localStorage.setItem('PROJECT', 'Franke');
        localStorage.setItem('APP_ID', '1000000211');
        localStorage.removeItem('authUser');
        localStorage.removeItem('role');
      }
    }
  }

  render() {
    const length = this.props.data;
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="header-item"
            style={{
              padding: '0px',
              // marginTop: '5px',
              backgroundColor:'transparent'
            }}
            // id="page-header-user-dropdown"
            tag="button"
          >
            <Link
              className="btn header-item noti-icon"
              to="#"
              id="page-header-notifications-dropdown"
            >
              {safeDeepGet(length, 'all', 0)> 0 ? (
                <>
                  <i
                    className="mdi mdi-email-outline mt-2 "
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  />
                  <span className="badge bg-danger rounded-pill">{safeDeepGet(length, 'all', 0)}</span>
                </>
              ) : (
                <>
                  <i
                    className="mdi mdi-email-open-outline  mt-2"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  />
                </>
              )}
            </Link>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/notification/refund/list">
              {this.props.t('Refund')}{' '}
              <span className="badge bg-danger ms-1">
                {safeDeepGet(length, 'refundList', 0)}
              </span>
            </DropdownItem>
            <DropdownItem tag="a" href="/notification/complaint/list">
              {this.props.t('Complaint')}{' '}
              <span className="badge bg-danger ms-1">
                {safeDeepGet(length, 'complaintList', 0)}
              </span>
            </DropdownItem>
            <div className="dropdown-divider" />
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
};

const mapStateToProps = state => {
  const { success } = state.Profile;
  return { success };
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
);
