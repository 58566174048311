import {
  LOADING,
  RESULT,
  LIST,
  LIST_VM,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  UPDATE_CALIBRATION,
  READ_PLANOGRAM,
  UPDATE_PLANOGRAM,
  ADD_PLANOGRAM,
  DELETE_PLANOGRAM,
  DELETE,
  READ_PRODUCT,
  LIST_PRODUCT,
  HISTORY,
  TESTCSV,
  SERI_VM,
  INPUT_CSV,
} from './actionTypes';

const INIT_STATE = {
  loading: true,
};

const BusinessPoint = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case SERI_VM:
          newState['getSeriVM'] = action.payload.result;
          break;
        case HISTORY:
          newState['list_history'] = action.payload.result;
          break;
        case LIST:
          newState['list'] = action.payload.result;
          break;
        case INPUT_CSV:
          newState['inputCsv'] = action.payload.result;
          break;
        case LIST_VM:
          newState['list_vm'] = action.payload.result;
          break;
        case LIST_PRODUCT:
          newState['list_product'] = action.payload.result;
          break;
        case INIT_CREATE:
          newState['initCreate'] = action.payload.result;
          break;
        case CREATE:
          newState['create'] = action.payload.result;
          break;
        case TESTCSV:
          newState['testcsv'] = action.payload.result;
          break;
        case READ_INFO:
          newState['readInfo'] = action.payload.result;
          break;
        case UPDATE_INFO:
          newState['updateInfo'] = action.payload.result;
          break;
        case UPDATE_CALIBRATION:
          newState['updatecalibration'] = action.payload.result;
          break;
        case READ_PLANOGRAM:
          newState['readPlanogram'] = action.payload.result;
          break;
        case READ_PRODUCT:
          newState['readproduct'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM:
          newState['updatePlanogram'] = action.payload.result;
          break;
        case ADD_PLANOGRAM:
          newState['addPlanogram'] = action.payload.result;
          break;
        case DELETE:
          newState['delete'] = action.payload.result;
          break;
        case DELETE_PLANOGRAM:
          newState['deletePlanogram'] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default BusinessPoint;
