import {
    LOADING,
    RESULT,
    LIST,
    INIT_CREATE,
    CREATE,
    READ,
    UPDATE,
    DETAIL_CHAT,
    DELETE,
    STOCK_UPDATE,
  } from "./actionTypes";
  
  export const loading = actionType => ({
    type: LOADING,
    payload: { actionType },
  });
  
  export const result = (actionType, result) => ({
    type: RESULT,
    payload: { actionType, result },
  });
  export const list = (payload) => ({
    type: LIST,
    dateRange: payload.dateRange,
    refundStatus: payload.refundStatus,
    businessPoints: payload.businessPoints,
});
  export const initCreate = () => ({
      type: INIT_CREATE,
    });
  export const create = payload => ({
    type: CREATE,
    payload,
  });
  export const detailChat = payload => ({
    type: DETAIL_CHAT,
    payload,
  });
  export const read = sku => ({
    type: READ,
    payload: { sku },
  });
  
  export const update = payload => ({
    type: UPDATE,
    payload,
  });
  
  export const stockUpdate = payload => ({
    type: STOCK_UPDATE,
    payload,
  });
  
  export const delete_ = account_id => ({
    type: DELETE,
    payload: { account_id },
  });
  