/* eslint-disable no-undef */
import { takeEvery, put, call, takeLatest } from 'redux-saga/effects'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from './actionTypes'
import { loginSuccess, logoutUserSuccess, apiError } from './actions'

//Include Both Helper File with needed methods
import { getInstance } from '../../../firebase/helper'
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from '../../../helpers/fakebackend_helper'

const firebaseInstance = getInstance()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(
        firebaseInstance.loginUser,
        user.email,
        user.password
      )
        const role = { role: 'SuperAdmin' }
        localStorage.setItem('role', JSON.stringify(role))
      var data = yield call(firebaseInstance.role.list, user.email)
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))

    }
    // const role = data.body.role;
      history.push('/admin/dashboard')
      location.reload()
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser')
    localStorage.removeItem('role')

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(firebaseInstance.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push('/login')
    location.reload()
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(firebaseInstance.socialLoginUser, data, type)
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push('/dashboard')
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
