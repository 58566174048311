/* eslint-disable no-empty */
/* eslint-disable no-redeclare */
/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Label,
  ModalBody,
  FormGroup,
  InputGroup,
} from 'reactstrap'
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import ReactExport from 'react-data-export'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
//Tippy
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
//Toast
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb'

// Iotera
import Result from 'iotera-base/result'
import { safeDeepGet } from 'iotera-base/utility/json'

// Redux
import { connect } from 'react-redux'
import {
  payoutlist,
  payout_create,
  accountList,
  get_Balance,
  detailAccount,
  initCreate,
} from '../../store/Payout/actions'

class PayoutList extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.refDatePicker = React.createRef()

    const params = new URLSearchParams(window.location.href.split('?')[1])
    let name = params.get('name')
    let bank = params.get('bank')
    let start = params.get('dateStart')
    let end = params.get('dateEnd')
    let dateStart = new Date(Number(start))
    let dateEnd = new Date(Number(end))
    let selectedName = { value: 'all' }
    let selectedBank = { value: 'all' }
    let arrDate = [null, null]
    if (bank != undefined || bank != null) {
      selectedBank = { value: bank }
    }
    if (name != undefined || name != null) {
      selectedName = { value: name }
    }
    if (start != undefined || start != null) {
      arrDate = [dateStart, dateEnd]
    } else {
      arrDate = [null, null]
    }
    this.state = {
      modal: false,
      modaldetail: false,
      modal_standard: false,
      check: false,
      modalDetail: false,
      loading: false,
      amount: 0,
      detail: {},
      data: [],
      trx: [],
      selectedBank: selectedBank,
      selectedName: selectedName,
      dateRange: arrDate,
      BankAccountColumn: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          text: 'Name',
          dataField: 'account_name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.account ? row.account.acc_name : ''}
                </Link>
              </h5>
              <p className="mb-3">{row.account ? row.created_by : ''}</p>
            </>
          ),
        },
        {
          dataField: 'bank',
          text: 'Bank',
          formatter: (cellContent, row) => (
            <>
              <p style={{ fontWeight: 'bold' }} className="mb-1">
                {row.bank}
              </p>
              <p className="mb-0"></p>
              <p className="text-wrap mb-1">
                {' '}
                {row.account ? row.account.acc_no : ''}
              </p>
            </>
          ),
        },
        {
          dataField: 'status',
          text: 'Status',
          formatter: (cellContent, row) => (
            <>
              <p style={{ fontWeight: 'bold' }} className="mb-1">
                {row.status}
              </p>
              <p className="mb-0"></p>
              <p className="text-wrap mb-1">
                {' '}
                {row.account
                  ? this.handleValidDate(row.time.created_firestore_ts)
                  : ''}
              </p>
            </>
          ),
        },
        {
          dataField: 'amount',
          text: 'Amount',
          formatter: (cellContent, row) => (
            <>
              {row.account ? (
                <p
                  className="text-wrap"
                  style={{
                    color: 'red',
                  }}
                >
                  - {row.fee.amount.toFixed(2)} {'    '}
                  <Tippy content={'Detail'}>
                    <Link className="text-info" to="#">
                      <i
                        className="mdi mdi-alert-octagon-outline font-size-18"
                        id="deletetooltip"
                        onClick={() => this.handleDetailClick(row)}
                      ></i>
                    </Link>
                  </Tippy>
                </p>
              ) : (
                ' '
              )}
            </>
          ),
        },
        {
          dataField: 'fee',
          text: 'Fee',
          formatter: (cellContent, row) => (
            <>
              {row.account ? (
                <p
                  className="text-wrap"
                  style={{
                    color: 'red',
                  }}
                >
                  - {row.fee.transaction_fee.toFixed(2)} {'    '}
                </p>
              ) : (
                ' '
              )}
            </>
          ),
        },
      ],
    }
    this.tog_standard = this.tog_standard.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handleDetailClick = this.handleDetailClick.bind(this)
    this.handleClearDateRange = this.handleClearDateRange.bind(this)
    this.handleOnChangeName = this.handleOnChangeName.bind(this)
    this.handleOnChangeBank = this.handleOnChangeBank.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleDetail = this.toggleDetail.bind(this)
    this.showToast = this.showToast.bind(this)
    this.showToastFailed = this.showToastFailed.bind(this)
    this.handleOnchange = this.handleOnchange.bind(this)
    this.handleClickPayout = this.handleClickPayout.bind(this)
    this.handleChecked = this.handleChecked.bind(this)
  }

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB')
    const time1 = date.toDate().toLocaleTimeString('en-GB')
    return date1 + ' ' + time1
  };

  handleOnchange(value) {
    this.setState({ selectedAccount: value.value })
    this.props.DetailAccount(value.value)
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      loading: false,
    }))
    this.props.GetBalance()
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }))
  }
  handleOnChangeName(selectedName) {
    this.setState({ selectedName })
    const payload = {
      dateRange: [null, null],
      bank: this.state.selectedBank.value,
      name: selectedName.value,
    }
    this.props.LoadPayoutList(payload)

    var searchParams = new URLSearchParams(window.location.search)
    if (selectedName.value == 'all') {
      searchParams.delete('name')
      this.props.history.push({
        pathname: '/payout/list',
        search: searchParams.toString(),
      })
    } else {
      searchParams.set('name', selectedName.value)
      this.props.history.push({
        pathname: '/payout/list',
        search: searchParams.toString(),
      })
    }
  }

  handleOnChangeBank(selectedBank) {
    this.setState({ selectedBank })
    const payload = {
      dateRange: [null, null],
      bank: selectedBank.value,
      name: this.state.selectedName.value,
    }
    this.props.LoadPayoutList(payload)

    var searchParams = new URLSearchParams(window.location.search)
    if (selectedBank.value == 'all') {
      searchParams.delete('bank')
      this.props.history.push({
        pathname: '/payout/list',
        search: searchParams.toString(),
      })
    } else {
      searchParams.set('bank', selectedBank.value)
      this.props.history.push({
        pathname: '/payout/list',
        search: searchParams.toString(),
      })
    }
  }

  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date')
    } else {
      this.setState({ dateRange })
      const payload = {
        dateRange: dateRange,
        name: this.state.selectedName.value,
        bank: this.state.selectedBank.value,
      }
      this.props.LoadPayoutList(payload)
      var searchParams = new URLSearchParams(window.location.search)
      if (dateRange[0] == null || dateRange[0] == undefined) {
        searchParams.delete('dateStart')
        searchParams.delete('dateEnd')
        this.props.history.push({
          pathname: '/payout/list',
          search: searchParams.toString(),
        })
      } else {
        searchParams.set('dateStart', dateRange[0].getTime())
        searchParams.set('dateEnd', dateRange[1].getTime())
        this.props.history.push({
          pathname: '/payout/list',
          search: searchParams.toString(),
        })
      }
    }
  };
  handleClearDateRange = () => {
    console.log('dateRange is cleared')

    if (!this.refDatePicker.current?.flatpickr) {
      return
    } else {
      this.refDatePicker.current.flatpickr.clear()
      this.setState(prevState => ({ dateRange: [null, null] }))
      const payload = {
        dateRange: [null, null],
        name: this.state.selectedName.value,
        bank: this.state.selectedBank.value,
      }
      this.props.LoadPayoutList(payload)
      var searchParams = new URLSearchParams(window.location.search)
      searchParams.delete('dateStart')
      searchParams.delete('dateEnd')
      this.props.history.push({
        pathname: '/payout/list',
        search: searchParams.toString(),
      })
    }
  };

  componentDidMount() {
    const payload = {
      dateRange: [null, null],
      name: this.state.selectedName.value,
      bank: this.state.selectedBank.value,
    }
    // Load business point
    this.props.LoadPayoutList(payload)
    this.props.InitCreatePayout()
    this.props.GetBalance()
    this.props.BankFillter()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      )
      if (code === Result.SUCCESS) {
        this.showToast()
        setTimeout(window.location.reload(), 1000)
        this.toggle()
      } else {
        this.showToastFailed()
      }
    }
  }
  handleValidSubmit = (e, values) => {
    const { CreatePayout } = this.props
    const { selectedAccount } = this.state
    const balance = safeDeepGet(
      this.props,
      ['get_Balance', 'body', 'balance'],
      0
    )
    const payload = {
      id: selectedAccount,
      name: values.name,
      bank: values.idbank,
      email: values.email,
      rekening: values.account_no,
      amount: values.amount,
    }
    if (values.amount > balance) {
      this.showToastFailure()
    } else {
      // CreatePayout(payload);
      // this.setState({ loading: true });
      this.setState(prevState => ({
        modal_standard: !prevState.modal_standard,
      }))
      this.setState({ payload })
    }
    // setTimeout(this.componentDidUpdate, 5000);
  };
  /* Handle click */
  handleAddClick = arg => {
    this.toggle()
    this.props.GetBalance()
  };
  handleDetailClick = row => {
    const trx = row
    this.setState({
      trx,
    })
    this.toggleDetail()
  };
  showToast() {
    var toastType = 'success'
    var message = 'Berhasil Payout'

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    }

    if (toastType === 'info') toastr.info(message)
    else if (toastType === 'warning') toastr.warning(message)
    else if (toastType === 'error') toastr.error(message)
    else toastr.success(message)
  }
  showToastFailed() {
    var toastType = 'error'
    var message = 'Gagal Payout'

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    }

    if (toastType === 'info') toastr.info(message)
    else if (toastType === 'warning') toastr.warning(message)
    else if (toastType === 'error') toastr.error(message)
    else toastr.success(message)
  }
  showToastFailure() {
    var toastType = 'error'
    var message = 'Gagal Payout, Melebihi Saldo'

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    }

    if (toastType === 'info') toastr.info(message)
    else if (toastType === 'warning') toastr.warning(message)
    else if (toastType === 'error') toastr.error(message)
    else toastr.success(message)
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page)
    }
  };
  format_report = list => {
    const dataSet = []
    let trx_data = {}
    if (list.length > 0) {
      for (const el in list) {
        if (list[el].account == null) {
          trx_data = {}
        } else {
          trx_data = {
            name: list[el].account.acc_name,
            bank: list[el].bank,
            accountNo: list[el].account.acc_no,
            amount: list[el].fee.amount,
            transactionfee: list[el].fee.transaction_fee,
            total: list[el].total,
            date: this.handleValidDate(list[el].time.created_firestore_ts),
            status: list[el].status,
            payoutid: list[el].payout_id,
          }
          dataSet.push(trx_data)
        }
      }
      return dataSet
    }
  };
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
  }
  handleTime(time) {
    const date1 = new Date(time).toLocaleDateString('en-GB')
    const time1 = new Date(time).toLocaleTimeString('en-GB')
    return date1 + ' ' + time1
  }
  handleClickPayout() {
    const { payload } = this.state
    const { CreatePayout } = this.props

    CreatePayout(payload)
    this.setState({ loading: true })
  }
  handleChecked(e) {
    const value = e.target.value
    const isChecked = e.target.checked
    if (isChecked) {
      this.setState({ check: true })
    } else {
      this.setState({ check: false })
    }
  }
  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['createResult', 'result', 'code'],
      Result.UNKNOWN_ERROR
    )
    const data = safeDeepGet(this.props, ['detailAccount', 'body'], [])
    var account = {}
    const balance = safeDeepGet(
      this.props,
      ['get_Balance', 'body', 'balance'],
      0
    )

    const active = safeDeepGet(
      this.props,
      ['get_Balance', 'body', 'flag_payout'],
      false
    )
    const time = safeDeepGet(
      this.props,
      ['get_Balance', 'body', 'commit_point'],
      false
    )

    const VmList = safeDeepGet(this.props, ['initCreateResult', 'body'], [])
    const BankList = safeDeepGet(this.props, ['initCreate', 'body'], [])
    // const balance = 1000000;
    const number = balance.toFixed(2)
    const string = number.toString()
    const arr_string = string.split('.')
    const stringNumber = arr_string[0]
    var number_string = stringNumber.replace(/[^,\d]/g, '').toString()
    var split = number_string.split(',')
    var sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      var separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah
    let belakang = 0
    if (arr_string[1]) {
      belakang = arr_string[1]
    } else {
      belakang = 0
    }
    const rp = rupiah + ',' + belakang

    if (data[0] != null) {
      account = data[0]
    }
    const dataColumn = safeDeepGet(this.props, ['result', 'body'], [])
    let {
      selectedAccount,
      selectedBank,
      selectedName,
      dateRange,
      detail,
      name,
      bank,
      loading,
    } = this.state
    const params = new URLSearchParams(window.location.href.split('?')[1])
    let namee = params.get('name')
    let bankk = params.get('bank')
    let start = params.get('dateStart')
    let end = params.get('dateEnd')
    let dateStart = new Date(Number(start))
    let dateEnd = new Date(Number(end))
    if (start != undefined || start != null) {
      dateRange = [dateStart, dateEnd]
    } else {
      dateRange = dateRange
    }
    if (namee != undefined || namee != null) {
      selectedName = namee
    } else {
      selectedName = selectedName.value
    }
    if (bankk != undefined || bankk != null) {
      selectedBank = bankk
    } else {
      selectedBank = selectedBank.value
    }
    let amountrp = 0
    if (detail.amount) {
      const amountt = detail.amount
      // const numberA = amountt.toFixed(2);
      const stringA = amountt.toString()
      const arr_stringA = stringA.split('.')
      const stringBalanceA = arr_stringA[0]
      var number_stringA = stringBalanceA.replace(/[^,\d]/g, '').toString()
      var splitA = number_stringA.split(',')
      var sisaA = splitA[0].length % 3
      let rupiahbalanceA = splitA[0].substr(0, sisaA)
      var ribuanA = splitA[0].substr(sisaA).match(/\d{3}/gi)

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuanA) {
        var separatorA = sisaA ? '.' : ''
        rupiahbalanceA += separatorA + ribuanA.join('.')
      }

      rupiahbalanceA =
        splitA[1] != undefined
          ? rupiahbalanceA + ',' + splitA[1]
          : rupiahbalanceA
      let abelakang = 0
      if (arr_string[1]) {
        abelakang = arr_string[1]
      } else {
        abelakang = 0
      }
      amountrp = rupiahbalanceA
    }
    let feerp = 0

    if (detail.transaction_fee) {
      const amountfee = detail.transaction_fee
      // const numberA = amountfee.toFixed(2);
      const stringFee = amountfee.toString()
      const arr_stringFee = stringFee.split('.')
      const stringBalanceFee = arr_stringFee[0]
      var number_stringFee = stringBalanceFee.replace(/[^,\d]/g, '').toString()
      var splitFee = number_stringFee.split(',')
      var sisaFee = splitFee[0].length % 3
      let rupiahbalanceFee = splitFee[0].substr(0, sisaFee)
      var ribuanFee = splitFee[0].substr(sisaFee).match(/\d{3}/gi)

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuanFee) {
        var separatorFee = sisaFee ? '.' : ''
        rupiahbalanceFee += separatorFee + ribuanFee.join('.')
      }

      rupiahbalanceFee =
        splitFee[1] != undefined
          ? rupiahbalanceFee + ',' + splitFee[1]
          : rupiahbalanceFee
      let abelakang = 0
      if (arr_string[1]) {
        abelakang = arr_string[1]
      } else {
        abelakang = 0
      }
      feerp = rupiahbalanceFee
    }

    let totalRp = 0
    if (detail.total) {
      const amounttotal = detail.total
      // const numberA = amounttotal.toFixed(2);
      const stringtotal = amounttotal.toString()
      const arr_stringtotal = stringtotal.split('.')
      const stringBalancetotal = arr_stringtotal[0]
      var number_stringtotal = stringBalancetotal
        .replace(/[^,\d]/g, '')
        .toString()
      var splitTotal = number_stringtotal.split(',')
      var sisaTotal = splitTotal[0].length % 3
      let rupiahbalanceTotal = splitTotal[0].substr(0, sisaTotal)
      var ribuanTotal = splitTotal[0].substr(sisaTotal).match(/\d{3}/gi)

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuanTotal) {
        var separatorFee = sisaTotal ? '.' : ''
        rupiahbalanceTotal += separatorFee + ribuanTotal.join('.')
      }

      rupiahbalanceTotal =
        splitTotal[1] != undefined
          ? rupiahbalanceTotal + ',' + splitTotal[1]
          : rupiahbalanceTotal
      let abelakang = 0
      if (arr_string[1]) {
        abelakang = arr_string[1]
      } else {
        abelakang = 0
      }
      totalRp = rupiahbalanceTotal
    }

    const { SearchBar } = Search
    const pageOptions = {
      sizePerPage: 25,
      totalSize: dataColumn.length, // replace later with size(users),
      custom: true,
    }
    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ]

    const selectRow = {
      mode: 'checkbox',
    }
    const report_list = this.format_report(dataColumn)
    let report_filename = ''
    let bank_name = selectedBank.value ? selectedBank.label : selectedBank
    let sname = selectedName.value ? selectedName.label : selectedName
    if (dateRange[1] == null) {
      var endDate = new Date()
      var startDate = new Date()
      startDate.setDate(1)
      if ((bank_name != 'all') & (sname == 'all')) {
        report_filename =
          'Report Payout ' +
          bank_name +
          ' Sampai ' +
          endDate.toLocaleDateString('en-GB')
      } else if ((bank_name != 'all') & (sname != 'all')) {
        report_filename =
          'Report Payout ' +
          sname +
          ' ' +
          bank_name +
          ' Sampai ' +
          endDate.toLocaleDateString('en-GB')
      } else if ((bank_name == 'all') & (sname != 'all')) {
        report_filename =
          'Report Payout ' +
          sname +
          ' Sampai ' +
          endDate.toLocaleDateString('en-GB')
      } else {
        report_filename =
          'Report Payout All ' +
          ' Sampai ' +
          endDate.toLocaleDateString('en-GB')
      }
      if (bank_name != 'all') {
      } else {
        report_filename =
          'Report Payout ' +
          sname +
          ' Sampai ' +
          endDate.toLocaleDateString('en-GB')
      }
    } else {
      if ((bank_name != 'all') & (sname == 'all')) {
        report_filename =
          'Report Payout ' +
          bank_name +
          ' ' +
          dateRange[0].toLocaleDateString('en-GB') +
          ' ke ' +
          dateRange[1].toLocaleDateString('en-GB')
      } else if ((bank_name != 'all') & (sname != 'all')) {
        report_filename =
          'Report Payout ' +
          sname +
          ' ' +
          bank_name +
          ' ' +
          dateRange[0].toLocaleDateString('en-GB') +
          ' ke ' +
          dateRange[1].toLocaleDateString('en-GB')
      } else if ((bank_name == 'all') & (sname != 'all')) {
        report_filename =
          'Report Payout ' +
          sname +
          ' ' +
          dateRange[0].toLocaleDateString('en-GB') +
          ' ke ' +
          dateRange[1].toLocaleDateString('en-GB')
      } else {
        report_filename =
          'Report Payout All ' +
          dateRange[0].toLocaleDateString('en-GB') +
          ' ke ' +
          dateRange[1].toLocaleDateString('en-GB')
      }
    }
    const date = new Date()
    const twoDay = new Date(date.setDate(date.getDate() - 2))
    const date1 = twoDay.toLocaleDateString('en-GB')
    const time1 = twoDay.toLocaleTimeString('en-GB')
    const twoDayBefore = date1 + ' ' + time1
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Payout List | Smart Vending Machine</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Payout" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.BankAccountColumn}
                      data={dataColumn}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.BankAccountColumn}
                          data={dataColumn}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="2">
                                  <FormGroup className="mb-3 ajax-select select2-container">
                                    <Select
                                      // defaultValue={bpfilterlist[0]}
                                      // value={name}
                                      value={VmList.filter(function (option) {
                                        return option.value === selectedName
                                      })}
                                      onChange={this.handleOnChangeName}
                                      options={VmList}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      placeholder="Select Name"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="2">
                                  <FormGroup className="mb-3 ajax-select select2-container">
                                    <Select
                                      // defaultValue={bpfilterlist[0]}
                                      // value={bank}
                                      value={BankList.filter(function (option) {
                                        return option.value === selectedBank
                                      })}
                                      onChange={this.handleOnChangeBank}
                                      options={BankList}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      placeholder="Select Bank"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="4">
                                  <Row className="m-auto text-sm-end">
                                    <Col>
                                      <InputGroup>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Date Range"
                                          options={{
                                            mode: 'range',
                                            maxDate: new Date(),
                                            dateFormat: 'Y-m-d',
                                          }}
                                          value={dateRange}
                                          onChange={this.handleDateRange}
                                          ref={this.refDatePicker}
                                        />
                                        <Button
                                          type="reset"
                                          color="secondary"
                                          onClick={this.handleClearDateRange}
                                        >
                                          Clear
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm="2">
                                  <div className="text-sm-end">
                                    <Button
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleAddClick}
                                      disabled={active ? false : true}
                                      // disabled={active}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create Payout
                                    </Button>
                                  </div>
                                </Col>
                                <Col
                                  sm="2"
                                  className="d-none d-lg-block"
                                  align="center"
                                >
                                  <div className="clearfix mt-4 mt-lg-0">
                                    <ExcelFile
                                      filename={report_filename}
                                      element={
                                        <button
                                          type="button"
                                          className="btn btn-primary white w-sm"
                                        >
                                          <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                          Download
                                        </button>
                                      }
                                    >
                                      <ExcelSheet
                                        data={report_list}
                                        name="Alert List"
                                      >
                                        <ExcelColumn
                                          label="Payout Id"
                                          value="payoutid"
                                        />
                                        <ExcelColumn
                                          label="Name"
                                          value="name"
                                        />
                                        <ExcelColumn
                                          label="Bank"
                                          value="bank"
                                        />
                                        <ExcelColumn
                                          label="Account Number"
                                          value="accountNo"
                                        />
                                        <ExcelColumn
                                          label="Date"
                                          value="date"
                                        />
                                        <ExcelColumn
                                          label="Status"
                                          value="status"
                                        />
                                        <ExcelColumn
                                          label="Amount"
                                          value="amount"
                                        />
                                        <ExcelColumn
                                          label="Transaction Fee"
                                          value="transactionfee"
                                        />
                                        <ExcelColumn
                                          label="Total"
                                          value="total"
                                        />
                                      </ExcelSheet>
                                    </ExcelFile>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable  noDataIndication="Table is Empty" 
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="xl"
                      isOpen={this.state.modalDetail}
                      className={this.props.className}
                    >
                      <div className="modal-header">
                        <button
                          onClick={() => this.setState({ modalDetail: false })}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {this.state.trx.id != undefined ? (
                        <ModalBody>
                          <Row>
                            <Col sm={6}>
                              <div>
                                <h5 className="mb-4">
                                  Informations Account :{' '}
                                </h5>
                                <Row>
                                  <Col sm={6}>
                                    <p className="mb-3">Account Name : </p>
                                    <p className="mb-3">Account No :</p>
                                    <p className="mb-3">Create Date : </p>
                                    {this.state.trx.status ==
                                    'failed' ? null : (
                                      <>
                                        <p className="mb-3">Approved Date : </p>
                                        <p className="mb-3">
                                          Completed Date :{' '}
                                        </p>
                                      </>
                                    )}
                                  </Col>
                                  <Col sm={6}>
                                    <p className="mb-3">
                                      {this.state.trx.account.acc_name}
                                    </p>
                                    <p className="mb-3">
                                      {this.state.trx.account.acc_no}
                                    </p>
                                    <p className="mb-3">
                                      {this.state.trx.time
                                        .created_firestore_ts != undefined
                                        ? this.handleValidDate(
                                            this.state.trx.time
                                              .created_firestore_ts
                                          )
                                        : null}
                                    </p>
                                    {this.state.trx.status ==
                                    'failed' ? null : (
                                      <>
                                        <p className="mb-3">
                                          {this.state.trx.time
                                            .approved_firestore_ts != undefined
                                            ? this.handleValidDate(
                                                this.state.trx.time
                                                  .approved_firestore_ts
                                              )
                                            : null}
                                        </p>

                                        <p className="mb-4">
                                          {this.state.trx.time
                                            .settled_firestore_ts != undefined
                                            ? this.handleValidDate(
                                                this.state.trx.time
                                                  .settled_firestore_ts
                                              )
                                            : null}
                                        </p>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div>
                                <h5 className="mb-4">Payment details : </h5>
                                <Row>
                                  <Col sm={7}>
                                    <p className="mb-3">Reference No : </p>
                                    <p className="mb-3">Bank :</p>
                                    <p className="mb-3">Amount Payout : </p>
                                    <p className="mb-3">Transactions Fee : </p>
                                    <p
                                      className="mb-4"
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      Total :{' '}
                                    </p>
                                  </Col>
                                  <Col sm={4}>
                                    <p className="mb-3 text-end">
                                      {this.state.trx.reference_no}
                                    </p>
                                    <p className="mb-3 text-end">
                                      {this.state.trx.bank}
                                    </p>
                                    <p className="mb-3 text-end">
                                      {this.state.trx.fee.amount.toFixed(2)}
                                    </p>
                                    <p className="mb-3 text-end">
                                      {this.state.trx.fee.transaction_fee.toFixed(
                                        2
                                      )}
                                    </p>
                                    <p
                                      className="mb-4 text-end"
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      {this.state.trx.total.toFixed(2)}{' '}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </ModalBody>
                      ) : null}
                    </Modal>
                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {'Payout '}
                      </ModalHeader>
                      <ModalBody>
                        <h4 style={{ color: 'blue' }}>
                          {' '}
                          Saldo Rp{' '}
                          {Number(balance)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                        </h4>{' '}
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <Row form>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="mt-1" htmlFor="selectAccount">
                                  Select Account Name :
                                </Label>
                                <Select
                                  id="selectAccount"
                                  name="selectAccount"
                                  options={VmList}
                                  className="mb-3"
                                  maxMenuHeight={150}
                                  placeholder="Select Account Name"
                                  onChange={this.handleOnchange}
                                  value={VmList.filter(function (option) {
                                    return option.value === selectedAccount
                                  })}
                                ></Select>
                              </div>
                              {selectedAccount == null ? (
                                ''
                              ) : (
                                <Col className="col-12">
                                  <div className="mb-3">
                                    <Label
                                      className="mt-1"
                                      htmlFor="account_no"
                                    >
                                      Account Number :
                                    </Label>
                                    <AvField
                                      type="number"
                                      id="account_no"
                                      name="account_no"
                                      value={account.account_no || 0}
                                      placeholder="Masukan Rekening..."
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label htmlFor="name">Name : </Label>
                                    <AvField
                                      value={account.account_name || ''}
                                      type="text"
                                      id="name"
                                      name="name"
                                      placeholder="Masukan Nama..."
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label htmlFor="name">Email : </Label>
                                    <AvField
                                      value={account.account_email || ''}
                                      type="email"
                                      id="email"
                                      name="email"
                                      placeholder="Masukan Email..."
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      disabled={true}
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Label className="mt-1" htmlFor="bank">
                                      Bank :
                                    </Label>
                                    <AvField
                                      type="text"
                                      id="bank"
                                      value={account.bank || ''}
                                      name="bank"
                                      placeholder="Masukan Bank"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      disabled={true}
                                    />
                                    <AvField
                                      type="hidden"
                                      id="idbank"
                                      value={account.account_bank || ''}
                                      name="idbank"
                                      errorMessage="Masukkan Angka saja"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Label className="mt-1" htmlFor="amount">
                                      Amount Payout :
                                    </Label>
                                    <AvField
                                      type="number"
                                      id="amount"
                                      value={balance.toFixed(2)}
                                      min={10000}
                                      max={
                                        balance.toFixed(2) - 5000 < 0
                                          ? 0
                                          : (balance - 5000).toFixed(2)
                                      }
                                      name="amount"
                                      errorMessage={`Batas Minimum Penarikan Rp.${10000} dan Batas Maksimum Saldo yang dapat ditarik Rp.${
                                        (balance - 5000).toFixed(2) < 0
                                          ? 0
                                          : (balance - 5000).toFixed(2)
                                      }.`}
                                      placeholder="Masukan Jumlah Payout"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                    <p>
                                      Saldo diatas yang bisa ditarik per tanggal{' '}
                                      {this.handleTime(time)}{' '}
                                    </p>
                                    {/* <p>Not included transaction fee (5000) </p> */}
                                  </div>
                                </Col>
                              )}
                            </Col>
                            <Col sm={6}>
                              <div>
                                <h4>Syarat & Ketentuan</h4>
                                <p className="mb-3">
                                  1. Penarikan dapat dilakukan ke Rekening bank
                                  yang sudah di daftarkan pada List Bank Account{' '}
                                </p>
                                <p className="mb-3">
                                  2. Pengguna hanya dapat melakukan Penarikan
                                  Saldo dengan minimal nominal{' '}
                                  <strong>Rp. 10000</strong>
                                </p>
                                <p className="mb-3">
                                  3. Penarikan Saldo belum termasuk Transaction
                                  Fee sebesar <strong>Rp. 5000</strong>
                                </p>
                                <p className="mb-3">
                                  4. Penarikan saldo akan di proses Maksimal
                                  1x24 Jam
                                </p>
                                <p className="mb-3">
                                  5. Pengguna wajib memperhatikan kesesuaian
                                  informasi rekening bank tujuan & Nilai jumlah
                                  untuk penarikan saldo, kesalahan nomer
                                  rekening dan jumlah penarikan menjadi tanggung
                                  jawab pengguna dan <strong>Bukan</strong>{' '}
                                  merupakan tanggung jawab dari IOTERA
                                </p>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="check"
                                    id="defaultCheck4"
                                    onClick={this.handleChecked}
                                    checked={this.state.check}
                                  />{' '}
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck4"
                                  >
                                    Saya telah membaca dan menyetujui Syarat Dan
                                    Ketentuan yang berlaku
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                {loading ? (
                                  <button
                                    type="button"
                                    className="btn btn-dark "
                                  >
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{' '}
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                    disabled={this.state.check ? false : true}
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                    <Modal
                      isOpen={this.state.modal_standard}
                      toggle={this.tog_standard}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Syarat & Ketentuan
                        </h5>
                        <button
                          type="button"
                          onClick={() =>
                            this.setState({ modal_standard: false })
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <h4>Apakah data sudah sesuai?</h4>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <div className="text-end">
                          {loading ? (
                            <button type="button" className="btn btn-dark ">
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{' '}
                              Loading
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={this.handleClickPayout}
                              className="btn btn-primary"
                            >
                              Continue
                            </button>
                          )}
                        </div>
                      </div>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['payoutAdmin', 'payoutlist'], {}),
  loading: safeDeepGet(state, ['payoutAdmin', 'loading'], true),
  createResult: safeDeepGet(state, ['payoutAdmin', 'payout_create']),
  updateResult: safeDeepGet(state, ['payoutAdmin', 'update'], {}),
  initCreateResult: safeDeepGet(state, ['payoutAdmin', 'accountList'], {}),
  initCreate: safeDeepGet(state, ['payoutAdmin', 'initCreate'], {}),
  detailAccount: safeDeepGet(state, ['payoutAdmin', 'detailAccount'], []),
  get_Balance: safeDeepGet(state, ['payoutAdmin', 'get_Balance'], []),
  test: state,
})

const mapDispatchToProps = dispatch => ({
  InitCreatePayout: () => dispatch(accountList()),
  BankFillter: () => dispatch(initCreate()),
  GetBalance: () => dispatch(get_Balance()),
  DetailAccount: payload => dispatch(detailAccount(payload)),
  LoadPayoutList: payload => dispatch(payoutlist(payload)),
  CreatePayout: payload => dispatch(payout_create(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PayoutList)
